import { ExtendedFlatlist, UserItem } from 'components/elements';
import fp from 'lodash/fp';
import { Flex, Text } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IUser } from 'services/resources/users/types.d';
import { fontSizes } from 'styles/theme';

import { CalendarUserItem } from './CalendarUserItem';

interface ICalendarUserListProps {
  /**
   * Flag for indicating if the data is being fetched.
   */
  loading?: boolean;
  /**
   * User related to the selected appointment.
   */
  selectedUser: IUser;
  /**
   * Function for setting a user as selected.
   */
  setSelectedUser: (user: IUser) => void;
  /**
   * Users to be shown in the list: either patients or therapists.
   */
  users: IUser[];
  /**
   * Flag for dynamically rendering strings on the component.
   */
  displayMode: 'patients' | 'therapists';
}

export const CalendarUserList: React.FC<ICalendarUserListProps> = (
  props,
): JSX.Element => {
  const {
    selectedUser,
    loading = false,
    setSelectedUser,
    displayMode,
    users,
  } = props;

  const themeData = useSelector(getTheme);

  const stringContent = displayMode === 'patients' ? 'pacientes' : 'terapeutas';
  const secondaryString = displayMode === 'patients' ? 'Paciente' : 'Terapeuta';
  const thirdLevelString =
    displayMode === 'patients' ? 'paciente' : 'terapeuta';

  if (fp.isEmpty(selectedUser)) {
    return (
      <ExtendedFlatlist
        data={users}
        flex={11}
        noDataMessage={
          loading
            ? `Cargando ${stringContent} ...`
            : `No se encontraron ${stringContent}.`
        }
        renderItem={
          <UserItem onSelectUser={setSelectedUser} useDefaultSelector />
        }
        searchBarPlaceholder={thirdLevelString}
        searchKey={['firstName', 'fatherName', 'motherName']}
        sort
        sortKey="firstName"
        useSearchBar
        w="100%"
      />
    );
  } else {
    return (
      <Flex flex={1} w="100%">
        <Text
          color={themeData.mainColorDark}
          fontSize={fontSizes.regular}
          fontWeight="bold"
          mb={4}
          textAlign="center"
        >
          {`${secondaryString} Seleccionado: `}
        </Text>
        <CalendarUserItem item={selectedUser} />
      </Flex>
    );
  }
};
