import { FontAwesome } from '@expo/vector-icons';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { usePlatform } from 'hooks/platform-hooks';
import { Button, Divider, IconButton, Text, VStack } from 'native-base';
import { colors } from 'styles/colors';

import { IAppointmentWithTherapyProgress } from '../types';

interface ITherapyProgressItemProps {
  /**
   * Item to be inherited from the cloneElement function.
   */
  item?: any;
  /**
   * Function to call the edit therapy progress form and send the selected
   * appointment with therapy progress.
   */
  onOpenPutTherapyProgressModal: (
    appointmentWithTherapyProgress: IAppointmentWithTherapyProgress,
  ) => void;
}

export const TherapyProgressItem: React.FC<ITherapyProgressItemProps> = (
  props,
) => {
  const { item, onOpenPutTherapyProgressModal } = props;

  const { web } = usePlatform();

  const appointmentWithTherapyProgress =
    item as IAppointmentWithTherapyProgress;

  return (
    <VStack>
      {!appointmentWithTherapyProgress.appointment.confirmedByTherapist ? (
        <VStack>
          <Text fontSize={16} fontWeight="bold" pt={2} textAlign="center">
            Fecha
          </Text>
          <Text pb={6} textAlign="center">
            {`${format(
              new Date(appointmentWithTherapyProgress.appointment.date),
              'EEE dd MMM yyyy',
              {
                locale: es,
              },
            )}`}
          </Text>
          <Button
            alignSelf="center"
            bg={colors.warning}
            isDisabled
            mb={6}
            w={!web ? '70%' : '30%'}
          >
            Añadir Progreso
          </Button>
          <Text
            color={colors.error}
            fontSize={12}
            pb={5}
            px={5}
            textAlign="center"
          >
            Recuerda que solo puedes añadir progresos a citas que ya hayas
            confirmado.
          </Text>
        </VStack>
      ) : (
        <>
          {appointmentWithTherapyProgress.therapyProgress.patientProgress
            .length === 0 &&
          appointmentWithTherapyProgress.therapyProgress.therapistProgress
            .length === 0 ? (
            <VStack>
              <Text fontSize={16} fontWeight="bold" mt={6} textAlign="center">
                Fecha
              </Text>
              <Text pb={6} textAlign="center">
                {`${format(
                  new Date(appointmentWithTherapyProgress.appointment.date),
                  'EEE dd MMM yyyy',
                  {
                    locale: es,
                  },
                )}`}
              </Text>
              <Button
                alignSelf="center"
                bg={colors.warning}
                mb={6}
                onPress={() =>
                  onOpenPutTherapyProgressModal(appointmentWithTherapyProgress)
                }
                w="30%"
              >
                Añadir Progreso
              </Button>
            </VStack>
          ) : (
            <VStack>
              <Text fontSize={16} fontWeight="bold" mt={6} textAlign="center">
                Fecha
              </Text>
              <Text pb={6} textAlign="center">
                {`${format(
                  new Date(appointmentWithTherapyProgress.appointment.date),
                  'EEE dd MMM yyyy',
                  {
                    locale: es,
                  },
                )}`}
              </Text>
              <Text fontSize={16} fontWeight="bold" textAlign="center">
                Progreso del Paciente
              </Text>
              <Text mx={4} pb={6} textAlign="center">
                {appointmentWithTherapyProgress.therapyProgress.patientProgress}
              </Text>
              <Text fontSize={16} fontWeight="bold" textAlign="center">
                Progreso como Terapeuta
              </Text>
              <Text mx={4} pb={4} textAlign="center">
                {
                  appointmentWithTherapyProgress.therapyProgress
                    .therapistProgress
                }
              </Text>
              <IconButton
                alignSelf="center"
                bg={colors.warning}
                icon={<FontAwesome color="white" name="pencil" size={24} />}
                mb={6}
                onPress={() =>
                  onOpenPutTherapyProgressModal(appointmentWithTherapyProgress)
                }
                w="20%"
              />
            </VStack>
          )}
        </>
      )}
      <Divider />
    </VStack>
  );
};
