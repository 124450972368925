/**
 * Function that returns the corresponding placeholder string upon value.
 * @param fieldValue
 * @returns
 */
export const getPlaceHolder = (fieldValue: string): string => {
  if (fieldValue === 'package') {
    return 'Paquete';
  } else if (fieldValue === 'promo') {
    return 'Promoción';
  } else if (fieldValue === 'serviceExchange') {
    return 'Intercambio de Servicios';
  } else if (fieldValue === 'donation') {
    return 'Donación';
  } else if (fieldValue === 'cash') {
    return 'Efectivo';
  } else if (fieldValue === 'card') {
    return 'Tarjeta';
  } else if (fieldValue === 'transfer') {
    return 'Transferencia';
  } else if (fieldValue === 'Check') {
    return 'Cheque';
  } else return '';
};
