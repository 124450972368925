export const themeColors = {
  programmingGreen: '#02e311',
};

/**
 *  Notice that these are expressed in pixels, for its usage in a template
 */
export const inputSizes = {
  regular: 40,
  small: 36,
};

/**
 *  Notice that these are expressed in pixels, for its usage in a template
 */
export const buttonSizes = {
  regular: 40,
  small: 36,
};

export const fontSizes = {
  big: 18,
  bigger: 20,
  micro: 8,
  regular: 16,
  regularSmaller: 14,
  small: 13,
  smaller: 12,
};
