import { IAppointmentInfo } from 'services/resources/appointments-info/types.d';

import { IManagedAppointment } from '../types.d';
import { AppointmentInfoItem } from './AppointmentInfoItem';
import { AppointmentToConfirmItem } from './AppointmentToConfirmItem';

interface IManagedAppointmentItemProps {
  /**
   * Item to be inherited from the ExtendedFlatList.
   */
  item?: any;
  /**
   * Handler function for confirming an appointment by a third.
   */
  onConfirmByThird: (appointment: string) => void;
  /**
   * Function to toggle the appointment info form.
   */
  onOpenAppointmentInfoForm: (appointment: IAppointmentInfo) => void;
}

export const ManagedAppointmentItem: React.FC<IManagedAppointmentItemProps> = (
  props,
): JSX.Element => {
  const { item, onConfirmByThird, onOpenAppointmentInfoForm } = props;

  const managedAppointment = item as IManagedAppointment;

  if (managedAppointment.itemType === 'appointment') {
    return (
      <AppointmentToConfirmItem
        item={managedAppointment.item}
        onConfirmByThird={onConfirmByThird}
      />
    );
  } else {
    return (
      <AppointmentInfoItem
        item={managedAppointment.item}
        onOpenAppointmentInfoForm={onOpenAppointmentInfoForm}
      />
    );
  }
};
