import { Text, VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

import { DashboardInputField } from '../DashboardInputField';
import { IInputFieldProps } from '../types.d';

interface ILinksFieldProps extends IInputFieldProps {
  /**
   * Title to be shown above the field.
   */
  title: string;
}

export const LinksField: React.FC<ILinksFieldProps> = (props): JSX.Element => {
  const { title, ...rest } = props;
  const themeData = useSelector(getTheme);

  return (
    <VStack alignItems="center" w="100%">
      <Text
        color={themeData.mainColorDark}
        opacity={0.8}
        pb={2}
        textAlign="center"
      >
        {title}
      </Text>
      <DashboardInputField {...rest} />
    </VStack>
  );
};
