import { FontAwesome } from '@expo/vector-icons';
import { HStack, IconButton, IStackProps } from 'native-base';
import { colors } from 'styles/colors';

interface IEditionButtonProps extends IStackProps {
  /**
   * Function for handling an item deletion on database.
   */
  onDelete: (item: any) => void;
  /**
   * Function for handling an item edition on database.
   */
  onEdit: (item: any) => void;
  /**
   * Flag for indicating if the edit button should be disabled.
   */
  editDisabled?: boolean;
  /**
   * Flag for indicating if the edit button should be disabled.
   */
  deleteDisabled?: boolean;
}

export const EditionButtons: React.FC<IEditionButtonProps> = (props) => {
  const {
    editDisabled = false,
    deleteDisabled = false,
    onEdit,
    onDelete,
    ...rest
  } = props;
  return (
    <HStack {...rest}>
      <IconButton
        bg={colors.warning}
        disabled={editDisabled}
        icon={<FontAwesome color="white" name="pencil" size={24} />}
        onPress={onEdit}
      />
      <IconButton
        bg={colors.error}
        disabled={deleteDisabled}
        icon={<FontAwesome color="white" name="trash" size={24} />}
        onPress={onDelete}
      />
    </HStack>
  );
};

EditionButtons.defaultProps = {
  justifyContent: 'space-around',
  space: 3,
};
