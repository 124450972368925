import { instance } from 'services';

import {
  IAppointment,
  IAppointmentIdResponse,
  IAppointmentPayload,
  IAppointmentResponse,
  IAppointmentWithFullNamesResponse,
} from './types.d';

export const appointmentsResources = {
  create: (
    payload: IAppointmentPayload,
    token: string,
  ): Promise<IAppointmentIdResponse> =>
    instance.post(
      '/appointmentsAPI',
      { appointment: payload },
      { headers: { authorization: token } },
    ),

  getByMonthAndYearWithFullNames: (
    date: string,
    token: string,
  ): Promise<IAppointmentWithFullNamesResponse> =>
    instance.get('/appointmentsAPI/byMonthAndYearWithFullNames', {
      headers: { authorization: token },
      params: {
        date,
      },
    }),

  getForPatient: (
    patient: string,
    token: string,
  ): Promise<IAppointmentResponse> =>
    instance.get('/appointmentsAPI', {
      headers: { authorization: token },
      params: {
        accountType: 'patient',
        id: patient,
      },
    }),

  getForPatientByMonthAndYear: (
    patient: string,
    date: string,
    token: string,
  ): Promise<IAppointmentResponse> =>
    instance.get('/appointmentsAPI/forPatientByMonthAndYear', {
      headers: { authorization: token },
      params: {
        accountType: 'patient',
        date,
        id: patient,
      },
    }),

  getForTherapist: (
    therapist: string,
    token: string,
  ): Promise<IAppointmentResponse> =>
    instance.get('/appointmentsAPI', {
      headers: { authorization: token },
      params: {
        accountType: 'therapist',
        id: therapist,
      },
    }),

  getForTherapistByMonthAndYear: (
    patient: string,
    date: string,
    token: string,
  ): Promise<IAppointmentResponse> =>
    instance.get('/appointmentsAPI/forTherapistByMonthAndYear', {
      headers: { authorization: token },
      params: {
        accountType: 'patient',
        date,
        id: patient,
      },
    }),

  getForTherapistCheckProgress: (
    therapist: string,
    token: string,
  ): Promise<IAppointmentResponse> =>
    instance.get('/appointmentsAPI/forTherapistCheckProgress', {
      headers: { authorization: token },
      params: {
        id: therapist,
      },
    }),

  getForThirdsByMonthAndYear: (
    date: string,
    token: string,
  ): Promise<IAppointmentResponse> =>
    instance.get('/appointmentsAPI/forThirdsByMonthAndYear', {
      headers: { authorization: token },
      params: {
        accountType: 'secretary',
        date,
      },
    }),

  putAll: (
    payload: IAppointment,
    appointment: string,
    token: string,
  ): Promise<void> =>
    instance.put(
      '/appointmentsAPI',
      { appointment: payload },
      {
        headers: { authorization: token },
        params: { id: appointment, updateField: 'all' },
      },
    ),

  putCancellation: (appointment: string, token: string): Promise<void> =>
    instance.put(
      '/appointmentsAPI',
      {},
      {
        headers: { authorization: token },
        params: { id: appointment, updateField: 'cancellation' },
      },
    ),

  putConfirmedByTherapist: (
    appointment: string,
    token: string,
  ): Promise<void> =>
    instance.put(
      '/appointmentsAPI',
      {},
      {
        headers: { authorization: token },
        params: { id: appointment, updateField: 'confirmation' },
      },
    ),

  putConfirmedByThird: (
    appointment: string,
    thirdId: string,
    token: string,
  ): Promise<void> =>
    instance.put(
      '/appointmentsAPI',
      { appointment: {} },
      {
        headers: { authorization: token },
        params: {
          id: appointment,
          third: thirdId,
          updateField: 'thirdConfirmation',
        },
      },
    ),

  putCost: (appointment: string, cost: number, token: string): Promise<void> =>
    instance.put(
      '/appointmentsAPI/cost',
      {},
      {
        headers: { authorization: token },
        params: { cost, id: appointment },
      },
    ),
};
