import { instance } from 'services';

import {
  IPredefinedTaskPayload,
  IPredefinedTaskResponse,
  ITaskPayload,
  ITaskResponse,
  IUpdateTaskPayload,
} from './types.d';

export const tasksResources = {
  create: (payload: ITaskPayload, token: string): Promise<void> =>
    instance.post(
      '/tasksAPI',
      { task: payload },
      { headers: { authorization: token } },
    ),

  createPredefined: (
    payload: IPredefinedTaskPayload,
    token: string,
  ): Promise<void> =>
    instance.post(
      '/predefinedTasksAPI',
      { predefinedTask: payload },
      { headers: { authorization: token } },
    ),

  delete: (task: string, token: string): Promise<void> =>
    instance.delete('/tasksAPI', {
      headers: { authorization: token },
      params: { id: task },
    }),

  deletePredefined: (predefinedTask: string, token: string): Promise<void> =>
    instance.delete('/predefinedTasksAPI', {
      headers: { authorization: token },
      params: { id: predefinedTask },
    }),

  get: (
    token: string,
    therapist: string,
    patient: string,
  ): Promise<ITaskResponse> =>
    instance.get('/tasksAPI', {
      headers: { authorization: token },
      params: { patient, therapist },
    }),

  getPredefinedTasks: (token: string): Promise<IPredefinedTaskResponse> =>
    instance.get('/predefinedTasksAPI', {
      headers: { authorization: token },
    }),

  putAll: (payload: IUpdateTaskPayload, token: string): Promise<void> =>
    instance.put(
      '/tasksAPI',
      { task: payload },
      {
        headers: { authorization: token },
        params: {
          updateField: 'description',
        },
      },
    ),

  putPredefined: (
    payload: IPredefinedTaskPayload,
    predefinedTask: string,
    token: string,
  ): Promise<void> =>
    instance.put(
      '/predefinedTasksAPI',
      { predefinedTask: payload },
      {
        headers: { authorization: token },
        params: { field: 'all', id: predefinedTask },
      },
    ),
};
