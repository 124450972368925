import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  getUserFullName,
  getUserInitials,
} from 'helpers/data-helpers/string-helpers';
import fp from 'lodash/fp';
import { Avatar, Divider, HStack, IconButton, Text, VStack } from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IUser } from 'services/resources/users/types.d';

import { IUserItemProps } from '../types.d';

export const UserItem: React.FC<IUserItemProps> = (props) => {
  const {
    children,
    item,
    onSelectUser,
    useDefaultSelector = false,
    showDivider = true,
    ...rest
  } = props;
  const themeData = useSelector(getTheme);

  const user = item as IUser;

  const [stackWidth, setStackWidth] = useState<number>(0);

  return (
    <VStack {...rest}>
      <HStack
        flex={1}
        justifyContent="space-around"
        onLayout={({
          nativeEvent: {
            layout: { width },
          },
        }) => setStackWidth(width)}
        p={2}
        w="100%"
      >
        <HStack alignItems="center" flex={!fp.isNil(children) ? 1 : 2}>
          <Avatar
            _text={{ color: 'white' }}
            bg={themeData.mainColorDark}
            source={{
              uri: user.pictures?.profilePicture,
            }}
          >
            {getUserInitials(user)}
          </Avatar>
          <Text
            fontWeight="bold"
            maxW={!fp.isNil(children) ? `${stackWidth / 3}px` : undefined}
            ml={2}
            textAlign="left"
          >
            {getUserFullName(user)}
          </Text>
        </HStack>
        {!fp.isNil(children) && !useDefaultSelector ? (
          <HStack alignItems="center" flex={1} justifyContent="space-around">
            {children}
          </HStack>
        ) : null}
        {useDefaultSelector ? (
          <IconButton
            icon={
              <MaterialCommunityIcons
                color={themeData.mainColorDark}
                name="checkbox-blank-circle-outline"
                size={24}
              />
            }
            mr={4}
            onPress={() => !fp.isNil(onSelectUser) && onSelectUser(user)}
          />
        ) : null}
      </HStack>
      {showDivider ? <Divider mt={1} /> : null}
    </VStack>
  );
};

UserItem.defaultProps = {
  flex: 1,
  w: '100%',
};
