import { IAnyOption, IOption } from 'components/elements/types.d';

export const fieldOptions: IOption[] = [
  { title: 'Estatus de Pago: ', value: 'paidStatus' },
  { title: 'Método de Pago: ', value: 'paymentMethod' },
  { title: 'Fecha de Pago: ', value: 'paymentDate' },
  { title: 'Tipo de Adquisición: ', value: 'acquisitionType' },
  { title: 'Nombre del Banco: ', value: 'bankName' },
  { title: 'Solicitó Factura: ', value: 'invoiceRequest' },
  { title: 'Concepto de Factura: ', value: 'invoiceConcept' },
  { title: 'Costo: ', value: 'cost' },
  { title: 'Porcentaje de Comisión: ', value: 'commissionPercentage' },
  { title: 'Período para Factura: ', value: 'invoicePeriod' },
];

export const dateOptions: IOption[] = [
  { title: 'Día', value: 'day' },
  { title: 'Mes', value: 'month' },
  { title: 'Año', value: 'year' },
];

export const acquisitionTypeOptions: IOption[] = [
  { title: 'Paquete', value: 'package' },
  { title: 'Intercambio de Servicios', value: 'serviceExchange' },
  { title: 'Promoción', value: 'promo' },
  { title: 'Donación', value: 'donation' },
];

export const paymentMethodOptions: IOption[] = [
  { title: 'Efectivo', value: 'cash' },
  { title: 'Tarjeta', value: 'card' },
  { title: 'Cheque', value: 'check' },
  { title: 'Transferencia', value: 'transfer' },
];

export const paidStatusOptions: IOption[] = [
  { title: 'Pagado', value: 'paid' },
  { title: 'Pendiente', value: 'pending' },
];

export const invoiceRequestOptions: IAnyOption[] = [
  { title: 'Sí', value: true },
  { title: 'No', value: false },
];

export const invoicePeriodOptions: IOption[] = [
  { title: 'Por Cita', value: 'appointment' },
  { title: 'Por Mes', value: 'month' },
];

export const allConstrainedOptions = paymentMethodOptions.concat(
  paidStatusOptions,
  invoiceRequestOptions,
  invoicePeriodOptions,
  acquisitionTypeOptions,
);
