import { initializeApp } from 'firebase/app';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ListResult,
  ref,
  StorageReference,
  uploadBytes,
  UploadResult,
  uploadString,
} from 'firebase/storage';
import { Platform } from 'react-native';

import { appConfig } from '../firebase-service';

const storage = getStorage(initializeApp(appConfig));

export const storageResources = {
  createPictureToPath: (path: string, imageBlob: any): Promise<UploadResult> =>
    uploadBytes(ref(storage, `images/${path}`), imageBlob),

  createQRPicture: (imageString: string, eventId: string) =>
    uploadString(
      ref(storage, `images/eventsQRs/${eventId}`),
      imageString,
      'data_url',
    ),

  createQRPictureFile: (
    imageFile: any,
    eventId: string,
  ): Promise<UploadResult> =>
    uploadBytes(ref(storage, `images/eventsQRs/${eventId}`), imageFile),

  createUserPicture: (
    userEmail: string,
    path: string,
    imageBlob: any,
  ): Promise<UploadResult> =>
    uploadBytes(
      ref(
        storage,
        `images/${userEmail}/${path}/${
          Platform.OS !== 'web' ? imageBlob._data.name : new Date().toString()
        }`,
      ),
      imageBlob,
    ),

  deleteFileFromPath: (path: string): Promise<void> =>
    deleteObject(ref(storage, path)),

  deletePicture: (userEmail: string, path: string): Promise<void> =>
    deleteObject(ref(storage, `images/${userEmail}/${path}`)),

  getPictureURL: (userEmail: string, path: string): Promise<string> =>
    getDownloadURL(ref(storage, `images/${userEmail}/${path}`)),

  getPictureURLByRef: (ref: StorageReference): Promise<string> =>
    getDownloadURL(ref),

  listBlogFiles: (entry: string): Promise<ListResult> =>
    listAll(ref(storage, `images/images/blog/${entry}/media`)),

  listTempFiles: (user: string): Promise<ListResult> =>
    listAll(ref(storage, `images/${user}/temp`)),
};
