import * as ImagePicker from 'expo-image-picker';
import { ImagePickerResult } from 'expo-image-picker';
import fp from 'lodash/fp';
import { Platform } from 'react-native';

import { isValidImage, isVideoMedia } from './content-helpers';

/**
 * Function that returns an image URI after picking a single image from the
 * image picker.
 * @returns image uri
 */
export const getSingleImagePickerURI = async (
  aspect: 'horizontal' | 'vertical' = 'horizontal',
): Promise<string> => {
  let result: ImagePickerResult;
  let imageURI = '';
  // Open device's gallery and use different settings based on the platform
  if (Platform.OS !== 'web') {
    result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true, // not available for web
      aspect: aspect === 'vertical' ? [4, 3] : undefined,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 0.1,
    });
  } else {
    result = await ImagePicker.launchImageLibraryAsync({
      quality: 1,
    });
  }
  if (!result.canceled && !isVideoMedia(result.assets[0])) {
    if (Platform.OS === 'web' && isValidImage(result.assets[0])) {
      imageURI = result.assets[0].uri;
    } else if (Platform.OS !== 'web') imageURI = result.assets[0].uri;
  }
  return imageURI;
};

/**
 * Function that returns an image URI after picking multiple images from the
 * image picker.
 * @returns image uri
 */
export const getMultipleImagePickerURIs = async (): Promise<string[]> => {
  let result: ImagePicker.ImagePickerResult;
  let imagesURIs: string[] = [];
  // Open device's gallery and use different settings based on the platform
  if (Platform.OS !== 'web') {
    result = await ImagePicker.launchImageLibraryAsync({
      allowsMultipleSelection: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
    });
  } else {
    result = await ImagePicker.launchImageLibraryAsync({
      allowsMultipleSelection: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      presentationStyle: ImagePicker.UIImagePickerPresentationStyle.AUTOMATIC,
    });
  }
  if (fp.isArray(result.assets)) {
    const unfilteredURIs = result.assets.map((imageInfo) => {
      if (isValidImage(imageInfo)) {
        return imageInfo.uri;
      }
      return '';
    });
    // Remove all empty results
    imagesURIs = unfilteredURIs.filter((uri) => {
      if (uri.length > 0) {
        return uri;
      }
      return null;
    });
  } else {
    // eslint-disable-next-line prettier/prettier
      const typedResult = (result as unknown) as ImagePicker.ImagePickerResult;
    if (!typedResult.canceled) imagesURIs = [typedResult.assets[0].uri];
  }
  return imagesURIs;
};
