import { format } from 'date-fns';
import { IAppointmentWithUser } from 'screens/types.d';
import { IEvent } from 'services/resources/events/types.d';
import { IEventRegistry } from 'services/resources/events-registries/types.d';

/**
 * Function that filter the appointments returning only those that correspond
 * to the selected date.
 * @param selectedDate
 * @param allAppointments
 * @returns selected date appointments.
 */
export const getAppointmentIncidences = (
  selectedDate: Date,
  allAppointments: IAppointmentWithUser[],
): IAppointmentWithUser[] => {
  return allAppointments.filter((appointment) => {
    const formattedCurrent = format(
      new Date(appointment.date),
      // eslint-disable-next-line prettier/prettier, quotes
      "yyyy'-'MM'-'dd'",
    );
    const formattedSelected = format(
      new Date(selectedDate),
      // eslint-disable-next-line prettier/prettier, quotes
      "yyyy'-'MM'-'dd'",
    );
    if (formattedCurrent === formattedSelected && !appointment.cancelled) {
      return appointment.date;
    } else {
      return null;
    }
  });
};

/**
 * Function that filter the events returning only those that correspond
 * to the selected date.
 * @param selectedDate
 * @param allEvents
 * @returns selected date events.
 */
export const getEventsIncidences = (
  selectedDate: Date,
  allEvents: IEvent[],
): IEvent[] => {
  return allEvents.filter((event) => {
    const formattedCurrent = format(
      new Date(event.date),
      // eslint-disable-next-line prettier/prettier, quotes
      "yyyy'-'MM'-'dd'",
    );
    const formattedSelected = format(
      new Date(selectedDate),
      // eslint-disable-next-line prettier/prettier, quotes
      "yyyy'-'MM'-'dd'",
    );
    if (formattedCurrent === formattedSelected) {
      return event.date;
    } else {
      return null;
    }
  });
};

/**
 * Function that will retrieve all those events that have registries linked to
 * it, using a match with the patient email
 * @param user
 * @param allEvents
 * @param eventRegistries
 * @returns filtered events by email
 */
export const getEventsIncidencesByRegistries = (
  user: string,
  allEvents: IEvent[],
  eventRegistries: IEventRegistry[],
): IEvent[] => {
  const output: IEvent[] = [];
  const matchingUserRegistries = eventRegistries.filter((registry) => {
    if (registry.email === user) {
      return registry;
    } else {
      return null;
    }
  });
  matchingUserRegistries.forEach((registry) => {
    const matchingEvents = allEvents.filter((event) => {
      if (registry.event === event.id) {
        return event;
      } else {
        return null;
      }
    });
    if (matchingEvents.length > 0) {
      output.push(matchingEvents[0]);
    }
  });
  return output;
};
