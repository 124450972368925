import { MaterialCommunityIcons } from '@expo/vector-icons';
import { IUserItemProps } from 'components/elements/types';
import {
  getUserFullName,
  getUserInitials,
} from 'helpers/data-helpers/string-helpers';
import { Avatar, Divider, HStack, Text, VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IUser } from 'services/resources/users/types.d';

interface ICalendarUserItemProps extends IUserItemProps {
  isSelectRendered?: boolean;
}

export const CalendarUserItem: React.FC<ICalendarUserItemProps> = (props) => {
  const { item, onSelectUser, isSelectRendered = true, ...rest } = props;
  const themeData = useSelector(getTheme);

  const user = item as IUser;

  return (
    <VStack minH="60px" {...rest}>
      <HStack
        alignItems="center"
        flex={1}
        justifyContent="space-around"
        p={2}
        w="100%"
      >
        <HStack alignItems="center" flex={2}>
          <Avatar
            _text={{ color: 'white' }}
            bg={themeData.mainColorDark}
            ml={4}
            source={{
              uri: user.pictures?.profilePicture,
            }}
          >
            {getUserInitials(user)}
          </Avatar>
          <Text fontWeight="bold" ml={2} textAlign="left">
            {getUserFullName(user)}
          </Text>
        </HStack>
        {isSelectRendered ? (
          <MaterialCommunityIcons
            color={themeData.mainColorDark}
            name="checkbox-marked-circle"
            size={24}
            style={{ paddingRight: 24 }}
          />
        ) : null}
      </HStack>
      <Divider mt={1} />
    </VStack>
  );
};

CalendarUserItem.defaultProps = {
  flex: 1,
  w: '100%',
};
