import { useFocusEffect } from '@react-navigation/native';
import {
  DisclaimerModal,
  LoadingStatusModal,
  UIWrapper,
} from 'components/elements';
import { checkDeviceId } from 'helpers/notifications-helpers/device-helpers';
import { useSmallScreen } from 'hooks/dimensions-hooks';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setUserData } from 'redux-service/slices';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { userResources } from 'services/resources/users';
import { IUser } from 'services/resources/users/types.d';
import { handleUploadProfilePicture } from 'services/resources-recipes/storage-recipes';

import { MainMenu } from './components/MainMenu';
import { UserUI } from './components/UserUI';

export const Home: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);
  const dispatch = useDispatch();
  const small = useSmallScreen();
  const { web } = usePlatform();

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [disclaimerVisible, setDisclaimerVisible] = useState<boolean>(false);

  const handleImageUpload = async (): Promise<void> => {
    setLoadingData(true);
    try {
      const imageURL = await handleUploadProfilePicture(
        userData.email,
        'profilePicture',
      );
      if (!fp.isEmpty(imageURL)) {
        await userResources.putProfilePicture(
          imageURL,
          userData.id,
          userData.token,
        );
        const { data: d } = await userResources.getByMail(
          userData.email,
          userData.token,
        );
        const data = d as IUser;
        if (!fp.isEmpty(data)) {
          dispatch(setUserData({ ...userData, pictures: data.pictures }));
        }
      } else {
        setDisclaimerVisible(true);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'image-upload',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData(false);
  };

  // Re-render screen if user data changed
  useFocusEffect(
    useCallback(() => {
      // Check if user has device id on database, otherwise, storage it as
      // long as no web platform.
      if (!web) {
        checkDeviceId(
          userData.deviceId,
          userData.token,
          userData.id,
          userData.email,
        );
      }
    }, [userData.pictures]),
  );

  return (
    <UIWrapper title="Menú Principal">
      <LoadingStatusModal loading={loadingData}>
        Subiendo Imagen...
      </LoadingStatusModal>
      <DisclaimerModal
        isOpen={disclaimerVisible}
        onClose={() => setDisclaimerVisible(false)}
      >
        Cancelaste la operación, usaste un tipo de archivo no admitido o hubo
        algún problema con tu archivo.
      </DisclaimerModal>
      <UserUI flex={!small ? 1 : 4} onImageUpload={handleImageUpload} />
      <MainMenu flex={!small ? 1 : 3} w="100%" />
    </UIWrapper>
  );
};
