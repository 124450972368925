import { Ionicons } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import {
  AcceptDeclineModal,
  ExtendedFlatlist,
  LoadingStatusModal,
  UIWrapper,
} from 'components/elements';
import { format } from 'date-fns';
import { getUserFullName } from 'helpers/data-helpers/string-helpers';
import fp from 'lodash/fp';
import { Button, Text, VStack } from 'native-base';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux-service/slices';
import { blogResources } from 'services/resources/blog';
import {
  IBlogEntry,
  IBlogEntryFormPayload,
  IBlogPayload,
} from 'services/resources/blog/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { storageResources } from 'services/resources/storage';
import { colors } from 'styles/colors';
import { ILoadingData } from 'types.d';

import { BlogEntryItem } from './components/BlogEntryItem';
import { EntryModal } from './components/EntryModal';

export const Blog: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [entryModalOpen, setEntryModalOpen] = useState<boolean>(false);
  const [disclaimerModalOpen, setDisclaimerModalOpen] =
    useState<boolean>(false);
  const [blogEntries, setBlogEntries] = useState<IBlogEntry[]>([]);
  const [selectedEntry, setSelectedEntry] = useState<IBlogEntry>(
    {} as IBlogEntry,
  );
  const [tempNewEntryID, setNewTempEntryID] = useState('');

  const verifiedTherapist =
    !fp.isNil(userData.verifiedTherapist) && userData.verifiedTherapist;

  const toggleDisclaimerModalOpen = (): void => {
    setDisclaimerModalOpen(!disclaimerModalOpen);
  };

  const toggleEntryModalOpen = (): void => {
    setEntryModalOpen(!entryModalOpen);
  };

  /**
   * Function for retrieving all the blog entries that are currently stored
   * in the database.
   */
  const retrieveBlogEntries = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Entradas de Blog...',
    });
    try {
      const { data: d } = await blogResources.get(userData.token);
      const data = d as IBlogEntry[];
      if (!fp.isNil(data)) {
        setBlogEntries(data);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Blog- retrieveBlogEntries',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that handles the file deletion for blog media that was not used
   * at the end.
   */
  const handleUnusedFilesDeletion = async (
    givenEntry: string = '',
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Eliminando archivos sin utilizar...',
    });
    try {
      const { items } = await storageResources.listBlogFiles(
        fp.isEmpty(givenEntry) ? tempNewEntryID : givenEntry,
      );
      // We get their full paths, since we need them for the file deletion
      const tempItemsPaths = items.map((item) => {
        return item.fullPath;
      });
      // Delete each file that matches the path
      tempItemsPaths.forEach((path) => {
        storageResources.deleteFileFromPath(path);
      });
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Blog-handleUnusedFilesDeletion',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    setNewTempEntryID('');
  };

  /**
   * Function for deleting a given blog entry.
   */
  const handleOnDeleteBlogEntry = async (): Promise<void> => {
    toggleDisclaimerModalOpen();
    setLoadingData({
      loading: true,
      loadingMessage: 'Eliminando entrada...',
    });
    try {
      await blogResources.delete(selectedEntry.id, userData.token);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Blog-handleOnDeleteBlogEntry',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    setSelectedEntry({} as IBlogEntry);
    retrieveBlogEntries();
  };

  /**
   * Function for updating an existing blog entry.
   * @param entry
   */
  const handleOnUpdateTerm = async (entry: IBlogEntry): Promise<void> => {
    toggleEntryModalOpen();
    setLoadingData({
      loading: true,
      loadingMessage: 'Actualizando Entrada de Blog...',
    });
    try {
      await blogResources.putAll(entry, selectedEntry.id, userData.token);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Blog-handleOnUpdateTerm',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    setSelectedEntry({} as IBlogEntry);
    retrieveBlogEntries();
  };

  /**
   * Function for creating a blog entry.
   * @param entry
   */
  const handleOnCreateBlogEntry = async (
    entry: IBlogEntryFormPayload,
  ): Promise<void> => {
    toggleEntryModalOpen();
    setLoadingData({
      loading: true,
      loadingMessage: 'Creando Entrada de Blog...',
    });
    try {
      const outputPayload: IBlogPayload = {
        ...entry,
        author: getUserFullName(userData),
      };
      await blogResources.create(outputPayload, userData.token);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Blog-handleOnCreateTerm',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    retrieveBlogEntries();
  };

  /**
   * Function that chooses weather the blog entry is being edited, created.
   * content.
   * @param entry
   */
  const handleOnBlogSubmit = async (
    entry: IBlogEntryFormPayload | IBlogEntry,
  ): Promise<void> => {
    fp.isEmpty(selectedEntry)
      ? await handleOnCreateBlogEntry(entry)
      : await handleOnUpdateTerm(entry as IBlogEntry);
  };

  useFocusEffect(
    useCallback(() => {
      retrieveBlogEntries();
    }, []),
  );

  return (
    <UIWrapper title="Blog">
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      <EntryModal
        isOpen={entryModalOpen}
        onCancel={() => {
          if (tempNewEntryID !== '' && fp.isEmpty(selectedEntry)) {
            handleUnusedFilesDeletion();
          }
          setSelectedEntry({} as IBlogEntry);
          setEntryModalOpen(false);
        }}
        onSubmit={(entry) => {
          setDisclaimerModalOpen(false);
          handleOnBlogSubmit(entry);
        }}
        previousValues={fp.isEmpty(selectedEntry) ? undefined : selectedEntry}
        tempNewEntryID={tempNewEntryID}
      />
      <AcceptDeclineModal
        invertColors
        isOpen={disclaimerModalOpen}
        onAccept={handleOnDeleteBlogEntry}
        onDecline={() => {
          setSelectedEntry({} as IBlogEntry);
          toggleDisclaimerModalOpen();
        }}
      >
        <Text fontWeight="bold">
          {`¿Estás seguro de querer eliminar la entrada de blog ${selectedEntry.title}? Esta acción no se puede deshacer.`}
        </Text>
      </AcceptDeclineModal>
      <VStack bg="white" flex={17}>
        <ExtendedFlatlist
          data={blogEntries}
          noDataMessage="No se encontraron entradas de blog."
          renderItem={
            <BlogEntryItem
              onDelete={(entry) => {
                setSelectedEntry(entry);
                toggleDisclaimerModalOpen();
              }}
              onEdit={(entry) => {
                setSelectedEntry(entry);
                setEntryModalOpen(true);
              }}
            />
          }
          searchBarPlaceholder="entrada de blog"
          searchKey={['title', 'content']}
          sort
          sortKey="date"
          useSearchBar
        />
      </VStack>
      {userData.accountType === 'therapist' && verifiedTherapist ? (
        <Button
          _hover={{ bg: colors.success, opacity: 0.8 }}
          _pressed={{ bg: colors.success, opacity: 0.8 }}
          _text={{ fontWeight: 'bold' }}
          bg={colors.success}
          borderRadius={0}
          flex={1}
          leftIcon={<Ionicons color="white" name="add" size={24} />}
          onPress={() => {
            setNewTempEntryID(format(new Date(), 'dd_MM_yyyy_HH_mm_ss'));
            toggleEntryModalOpen();
          }}
          w="100%"
        >
          Agregar Entrada de Blog
        </Button>
      ) : null}
    </UIWrapper>
  );
};
