import { IOption } from 'components/elements/types.d';
import { HStack, IStackProps, Text, VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

interface IAppointmentInfoItemProps extends IStackProps {
  /**
   * Info item from which actual existing data will be displayed.
   */
  info: IOption;
}

export const AppointmentInfoItem: React.FC<IAppointmentInfoItemProps> = (
  props,
) => {
  const { info, ...rest } = props;

  const themeData = useSelector(getTheme);

  return (
    <VStack bg={themeData.mainColorDark} {...rest}>
      <HStack>
        <Text color="white" fontSize={14} fontWeight="bold">
          {info.title}
        </Text>
        <Text color="white">{info.value}</Text>
      </HStack>
    </VStack>
  );
};

AppointmentInfoItem.defaultProps = {
  pl: 2,
  py: 2,
};
