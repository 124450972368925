import { MaterialCommunityIcons, SimpleLineIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { DisclaimerModal, IconTextButton } from 'components/elements';
import { useSmallScreen } from 'hooks/dimensions-hooks';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { Button, HStack, IStackProps, VStack } from 'native-base';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import { colors } from 'styles/colors';

import {
  calendarDescription,
  glossaryDescription,
  tasksDescription,
} from '../helpers/content-helpers';
import { MenuDescriptor } from './MenuDescriptor';
import { QRScannerModal } from './QRScannerModal';

export const MainMenu: React.FC<IStackProps> = (props): JSX.Element => {
  const { ...rest } = props;
  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);
  const navigation = useNavigation();
  const small = useSmallScreen();
  const { web } = usePlatform();

  const [disclaimerVisible, setDisclaimerVisible] = useState<boolean>(false);
  const [qRScannerVisible, setQRScannerVisible] = useState<boolean>(false);

  const toggleQRScannerVisible = (): void => {
    setQRScannerVisible(!qRScannerVisible);
  };

  const handleScreenChange = (screen: string): void => {
    // If user is patient
    if (
      screen !== 'glossary' &&
      !fp.isNil(userData.accountType) &&
      userData.accountType === 'patient'
    ) {
      navigation.navigate(screen as never);
      // If user is therapist and is verified as therapist or if user is
      // secretary
    } else if (
      screen !== 'glossary' &&
      !fp.isNil(userData.verifiedTherapist) &&
      userData.verifiedTherapist
    ) {
      navigation.navigate(screen as never);
      // If user is secretary and wants to access calendar
    } else if (
      screen === 'calendar' &&
      !fp.isNil(userData.secretary) &&
      userData.secretary
    ) {
      navigation.navigate(screen as never);
      // It does not matter the account type, anyone can access the glossary
    } else if (screen === 'glossary') {
      navigation.navigate(screen as never);
    } else {
      setDisclaimerVisible(true);
    }
  };

  return (
    <VStack p={3} {...rest}>
      <DisclaimerModal
        isOpen={disclaimerVisible}
        onClose={() => setDisclaimerVisible(false)}
      >
        Para acceder a esta sección debes de ser un terapeuta verificado o ser
        registrado como paciente por uno.
      </DisclaimerModal>
      <VStack flex={9} space={2}>
        <HStack flex={!web ? 2 : 1} space={3}>
          <IconTextButton
            bg={colors.terappiaGreen.light}
            flex={1}
            icon={
              <SimpleLineIcons
                color="white"
                name="note"
                size={!small ? 40 : 30}
              />
            }
            label="Tareas"
            labelColor="white"
            onPress={() => handleScreenChange('tasks')}
          />
          <IconTextButton
            bg={colors.terappiaOrange.light}
            flex={1}
            icon={
              <SimpleLineIcons
                color="white"
                name="calendar"
                size={!small ? 40 : 30}
              />
            }
            label="Calendario"
            labelColor="white"
            onPress={() => handleScreenChange('calendar')}
          />
          <IconTextButton
            bg={colors.terappiaPurple.light}
            flex={1}
            icon={
              <SimpleLineIcons
                color="white"
                name="book-open"
                size={!small ? 40 : 30}
              />
            }
            label="Glosario"
            labelColor="white"
            onPress={() => handleScreenChange('glossary')}
          />
        </HStack>
        <HStack
          bg="transparent"
          flex={1}
          justifyContent="space-evenly"
          space={3}
        >
          <MenuDescriptor
            bg={colors.terappiaGreen.dark}
            tooltipTextColor={colors.terappiaGreen.dark}
          >
            {userData.accountType === 'therapist'
              ? tasksDescription.therapist
              : tasksDescription.patient}
          </MenuDescriptor>
          <MenuDescriptor
            bg={colors.terappiaOrange.dark}
            tooltipTextColor={colors.terappiaOrange.dark}
          >
            {userData.accountType === 'therapist'
              ? calendarDescription.therapist
              : calendarDescription.patient}
          </MenuDescriptor>
          <MenuDescriptor
            bg={colors.terappiaPurple.dark}
            tooltipTextColor={colors.terappiaPurple.dark}
          >
            {userData.accountType === 'therapist'
              ? glossaryDescription.therapist
              : glossaryDescription.patient}
          </MenuDescriptor>
        </HStack>
      </VStack>
      {!web ? (
        <>
          <Button
            _text={{ fontSize: 12 }}
            bg={themeData.mainColorDark}
            flex={1}
            justifyContent="center"
            leftIcon={
              <MaterialCommunityIcons
                color="white"
                name="qrcode-scan"
                size={18}
              />
            }
            onPress={toggleQRScannerVisible}
          >
            Escanear QR
          </Button>
          <QRScannerModal
            isOpen={qRScannerVisible}
            onCloseModal={() => setQRScannerVisible(false)}
          />
        </>
      ) : null}
    </VStack>
  );
};

MainMenu.defaultProps = {
  space: 3,
};
