/* eslint-disable quotes */
import { AntDesign } from '@expo/vector-icons';
import { AcceptDeclineModal } from 'components/elements';
import { format } from 'date-fns';
import {
  Divider,
  HStack,
  IconButton,
  IStackProps,
  Text,
  VStack,
} from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IAppointmentWithFullNames } from 'services/resources/appointments/types.d';

interface IAppointmentToConfirmItemProps extends IStackProps {
  /**
   * Item to be inherited from the ExtendedFlatList.
   */
  item?: any;
  /**
   * Handler function for confirming an appointment by a third.
   */
  onConfirmByThird: (appointment: string) => void;
}

export const AppointmentToConfirmItem: React.FC<
  IAppointmentToConfirmItemProps
> = (props): JSX.Element => {
  const { item, onConfirmByThird, ...rest } = props;
  const themeData = useSelector(getTheme);

  const { appointment, therapistFullName, patientFullName } =
    item as IAppointmentWithFullNames;

  const [disclaimerVisible, setDisclaimerVisible] = useState<boolean>(false);

  return (
    <VStack {...rest}>
      <AcceptDeclineModal
        isOpen={disclaimerVisible}
        onAccept={() => {
          setDisclaimerVisible(false);
          onConfirmByThird(appointment.id);
        }}
        onDecline={() => setDisclaimerVisible(false)}
      >
        <Text fontWeight="bold" px={3}>
          {`¿Estás seguro de querer confirmar como completada la cita del día ${format(
            new Date(appointment.date),
            'dd-MM-yyyy',
          )} a las ${format(
            new Date(appointment.date),
            'HH:mm',
          )} hrs llevada a cabo por ${therapistFullName} con ${patientFullName}? Esta acción no se puede deshacer.`}
        </Text>
      </AcceptDeclineModal>
      <HStack alignItems="center" justifyContent="space-around">
        <VStack ml={4} w="85%">
          <HStack>
            <Text color={themeData.mainColorDark} fontWeight="bold">
              Terapeuta:{' '}
            </Text>
            <Text color={themeData.mainColorDark}>{therapistFullName}</Text>
          </HStack>
          <HStack>
            <Text color={themeData.mainColorDark} fontWeight="bold">
              Paciente:{' '}
            </Text>
            <Text color={themeData.mainColorDark}>{patientFullName}</Text>
          </HStack>
          <HStack>
            <Text color={themeData.mainColorDark} fontWeight="bold">
              Cita:{' '}
            </Text>
            <Text color={themeData.mainColorDark}>
              {`${format(
                new Date(appointment.date),
                "dd-MM-yyyy, HH:mm 'hrs'",
              )}`}
            </Text>
          </HStack>
        </VStack>
        <VStack mr={4}>
          <Text color={themeData.mainColorDark}>
            {appointment.confirmedByThird ? 'Confirmado' : 'Confirmar:'}
          </Text>
          <IconButton
            bg="transparent"
            icon={
              <AntDesign
                color={themeData.mainColorDark}
                name={
                  appointment.confirmedByThird ? 'checksquare' : 'checksquareo'
                }
                size={24}
              />
            }
            isDisabled={appointment.confirmedByThird}
            onPress={() => setDisclaimerVisible(true)}
          />
        </VStack>
      </HStack>
      <Divider mt="32px" w="110%" />
    </VStack>
  );
};

AppointmentToConfirmItem.defaultProps = {
  mb: '10px',
  mt: '16px',
  w: '100%',
};
