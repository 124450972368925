import fp from 'lodash/fp';
import { HStack, IStackProps } from 'native-base';
import { useSelector } from 'react-redux';
import { getUser } from 'redux-service/slices';

import { MenuProfilePicture } from './MenuProfilePicture';
import { NoPicture } from './NoPicture';
import { StatusButtonGroup } from './StatusButtonGroup';

interface IUserUIProps extends IStackProps {
  /**
   * Function used to handle user profile picture upload.
   */
  onImageUpload: () => void;
}

export const UserUI: React.FC<IUserUIProps> = (props) => {
  const { onImageUpload, ...rest } = props;

  const userData = useSelector(getUser);

  return (
    <HStack {...rest}>
      {fp.isEmpty(userData.pictures?.profilePicture) ? (
        <NoPicture flex={1} onPress={onImageUpload} />
      ) : (
        <MenuProfilePicture
          flex={1}
          src={userData.pictures?.profilePicture as string}
        />
      )}
      <StatusButtonGroup flex={1} />
    </HStack>
  );
};

UserUI.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
};
