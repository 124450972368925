import { usePlatform } from 'hooks/platform-hooks';
import { Heading, IModalProps, Modal, VStack } from 'native-base';
import { ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

interface ILoadingStatusModalProps extends IModalProps {
  /**
   * Flag for controlling either to show the loading status or not.
   */
  loading: boolean;
}

export const LoadingStatusModal: React.FC<ILoadingStatusModalProps> = (
  props,
): JSX.Element => {
  const { children, loading, ...rest } = props;

  const themeData = useSelector(getTheme);
  const { ios, web } = usePlatform();

  return (
    <Modal isOpen={loading} size="full" {...rest}>
      <Modal.Content
        alignItems="center"
        bg="white"
        borderRadius={!web ? 0 : 10}
        py={6}
        w={!web ? '100%' : '30%'}
      >
        <VStack space={6}>
          <ActivityIndicator color={themeData.mainColorDark} size="large" />
          <Heading
            color={themeData.mainColorDark}
            fontSize="md"
            px={ios ? 4 : 0}
          >
            {children}
          </Heading>
        </VStack>
      </Modal.Content>
    </Modal>
  );
};

LoadingStatusModal.defaultProps = {
  size: 'full',
};
