import { ImagePickerAsset } from 'expo-image-picker';
import { ImageInfo } from 'services/resources/storage/types.d';

/**
 * Function that takes the result from a media pick operation with ImagePicker
 * and determines if the picked data is video.
 * @param mediaResult
 * @returns
 */
export const isVideoMedia = (mediaResult: ImagePickerAsset): boolean => {
  let isVideo: boolean = false;
  const successResult = mediaResult as ImageInfo;
  if (successResult.uri.includes('video')) isVideo = true;
  return isVideo;
};

/**
 * Function that takes the result from a media pick operation with ImagePicker
 * and determines if the picked data is a valid image.
 * @param mediaResult
 * @returns valid image result
 */
export const isValidImage = (mediaResult: ImagePickerAsset): boolean => {
  let isImage: boolean = false;
  const successResult = mediaResult as ImageInfo;
  if (successResult.uri.includes('image')) isImage = true;
  if (successResult.type === 'image') isImage = true;
  return isImage;
};
