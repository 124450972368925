import { Ionicons } from '@expo/vector-icons';
import { format } from 'date-fns/esm';
import { es } from 'date-fns/esm/locale';
import {
  Divider,
  HStack,
  IconButton,
  IStackProps,
  Text,
  VStack,
} from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IEvent } from 'services/resources/events/types.d';

interface IEventProps extends IStackProps {
  /**
   * Item to be inherited from the cloneElement function.
   */
  item?: any;
  /**
   * Function for retrieving selected event data.
   */
  onSelectEvent: (selectedEvent: IEvent) => void;
}

export const EventItem: React.FC<IEventProps> = (props): JSX.Element => {
  const { onSelectEvent, item } = props;
  const event = item as IEvent;
  const themeData = useSelector(getTheme);

  return (
    <VStack>
      <HStack>
        <HStack
          alignItems="center"
          flex={4}
          justifyContent="flex-start"
          m={2}
          space={3}
          w="100%"
        >
          <Ionicons
            color={themeData.mainColorDark}
            name="md-calendar-sharp"
            size={36}
          />
          <VStack w="100%">
            <Text fontWeight="bold">{event.title}</Text>
            <Text>
              {`${format(new Date(event.date), 'EEE dd MMM yyyy', {
                locale: es,
              })}`}
            </Text>
          </VStack>
        </HStack>
        <HStack alignItems="center" flex={1} justifyContent="flex-end">
          <IconButton
            icon={
              <Ionicons
                color={themeData.mainColorDark}
                name="eye"
                onPress={() => onSelectEvent(event)}
                size={24}
              />
            }
            mr={3}
          />
        </HStack>
      </HStack>
      <Divider />
    </VStack>
  );
};
