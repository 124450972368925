import { MaterialIcons } from '@expo/vector-icons';
import {
  Button,
  Divider,
  HStack,
  IconButton,
  IStackProps,
  Text,
  VStack
} from 'native-base';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IContactRequest } from 'services/resources/contact-requests/types';

interface IContactRequestProps extends IStackProps {
  /**
   * Item to be inherited from the cloneElement function.
   */
  item?: any;
  /**
   * Function for setting a contact request as read.
   */
  onMarkAsRead: (contactRequest: IContactRequest) => void;
}

export const ContactRequestItem: React.FC<IContactRequestProps> = (
  props,
): JSX.Element => {
  const { onMarkAsRead, children, item, ...rest } = props;
  const themeData = useSelector(getTheme);

  const [childrenVisible, setChildrenVisible] = useState<boolean>(false);

  const toggleChildrenVisible = (): void => {
    setChildrenVisible(!childrenVisible);
  };

  const entry = item as IContactRequest;

  return (
    <VStack {...rest}>
      <HStack
        alignItems="center"
        flex={1}
        justifyContent="space-between"
        m={2}
        w="100%"
      >
        <Text flex={3} fontWeight="bold" pl={2}>
          {`${entry.firstName} ${entry.fatherName}`}
        </Text>
        <Text color={entry.requestRead ? 'green.700' : 'red.500'} flex={3}>
          {entry.requestRead ? 'Leído' : 'Pendiente'}
        </Text>
        <IconButton
          icon={
            <MaterialIcons
              color={themeData.mainColorDark}
              name={
                !childrenVisible ? 'keyboard-arrow-down' : 'keyboard-arrow-up'
              }
              size={24}
            />
          }
          mr={3}
          onPress={toggleChildrenVisible}
        />
      </HStack>
      {childrenVisible ? (
        <>
          <HStack justifyContent="center">
            <Text fontStyle="italic" fontWeight="bold">
              Correo electrónico:{' '}
            </Text>
            <Text>{entry.email}</Text>
          </HStack>
          <HStack justifyContent="center">
            <Text fontStyle="italic" fontWeight="bold">
              Número de teléfono:{' '}
            </Text>
            <Text>{entry.phone}</Text>
          </HStack>
          <VStack alignItems="center" justifyContent="center">
            <Text fontStyle="italic" fontWeight="bold">
              Asunto:{' '}
            </Text>
            <Text mb={4} px={10}>
              {entry.contactReason}
            </Text>
          </VStack>
          {!entry.requestRead ? (
            <Button
              alignSelf="center"
              mb={4}
              onPress={() => {
                toggleChildrenVisible();
                onMarkAsRead(entry);
              }}
              width="xs"
            >
              Marcar como leído
            </Button>
          ) : null}
        </>
      ) : null}
      <Divider />
    </VStack>
  );
};
