import { IStackProps, VStack } from 'native-base';
import { useRef, useState } from 'react';
import { Dimensions } from 'react-native';
import {
  actions,
  RichEditor,
  RichToolbar,
} from 'react-native-pell-rich-editor';

import { IListItem } from '../types';
import { ImageSelector } from './ImageSelector';
import { LinkSelector } from './LinkSelector';

interface IMobileEditorProps extends IStackProps {
  /**
   * State that contains the editor content.
   */
  editorContent: string;
  /**
   * State setter for the editor content.
   */
  setEditorContent: (content: string) => void;
  /**
   * List of links to be used in order to insert links while using the editor.
   */
  links: IListItem[];
  /**
   * List of images to be used in order to insert images while using the editor.
   */
  imageURLs: IListItem[];
  /**
   * Flag that indicated if we are editing an existing entry.
   */
  previousEnabled: boolean;
}

export const MobileEditor: React.FC<IMobileEditorProps> = (
  props,
): JSX.Element => {
  const {
    editorContent,
    setEditorContent,
    links = [],
    imageURLs = [],
    previousEnabled,
    ...rest
  } = props;

  const richText = useRef<RichEditor | null>(null);

  const [imageSelectorVisible, setImageSelectorVisible] =
    useState<boolean>(false);
  const [linkSelectorVisible, setLinkSelectorVisible] =
    useState<boolean>(false);

  return (
    <VStack {...rest}>
      <ImageSelector
        imageURLs={imageURLs}
        richText={richText}
        selectorVisible={imageSelectorVisible}
        setSelectorVisible={setImageSelectorVisible}
      />
      <LinkSelector
        links={links}
        richText={richText}
        selectorVisible={linkSelectorVisible}
        setSelectorVisible={setLinkSelectorVisible}
      />
      <RichToolbar
        actions={[
          actions.undo,
          actions.redo,
          actions.setBold,
          actions.setItalic,
          actions.setUnderline,
          actions.setStrikethrough,
          actions.insertLink,
          actions.insertImage,
          actions.removeFormat,
        ]}
        editor={richText}
        onInsertLink={() => setLinkSelectorVisible(true)}
        onPressAddImage={() => setImageSelectorVisible(true)}
      />
      <RichEditor
        initialContentHTML={previousEnabled ? editorContent : undefined}
        initialHeight={300}
        onChange={setEditorContent}
        ref={richText}
        style={{
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          borderColor: '#ccaf9b',
          borderWidth: 1,
          elevation: 4,
          minWidth: Dimensions.get('screen').width / 1.1,
          shadowColor: '#000',
          shadowOffset: {
            height: 2,
            width: 0,
          },
          shadowOpacity: 0.23,
          shadowRadius: 2.62,
        }}
      />
    </VStack>
  );
};
