import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

/**
 * Custom hook that return the current platform status (android, ios, web,
 * mobile) as booleans to avoid keeping use Platform.OS === 'platform' all
 * along the app.
 * @returns the 'isPlatform' state for each platform
 */
export const usePlatform = (): {
  android: boolean;
  ios: boolean;
  mobile: boolean;
  web: boolean;
} => {
  const [isAndroid, setIsAndroid] = useState<boolean>(false);
  const [isIOS, setIsIOS] = useState<boolean>(false);
  const [isWeb, setIsWeb] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    if (Platform.OS === 'web') {
      setIsWeb(true);
      setIsAndroid(false);
      setIsMobile(false);
      setIsIOS(false);
    } else if (Platform.OS === 'ios') {
      setIsWeb(false);
      setIsAndroid(false);
      setIsMobile(true);
      setIsIOS(true);
    } else if (Platform.OS === 'android') {
      setIsWeb(false);
      setIsAndroid(true);
      setIsMobile(true);
      setIsIOS(false);
    }
  });

  return { android: isAndroid, ios: isIOS, mobile: isMobile, web: isWeb };
};
