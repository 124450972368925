import { AntDesign, Entypo } from '@expo/vector-icons';
import { UserItem } from 'components/elements';
import { IUserItemProps } from 'components/elements/types.d';
import { usePlatform } from 'hooks/platform-hooks';
import { Button, HStack, IconButton, Text, Tooltip } from 'native-base';
import { IUser } from 'services/resources/users/types.d';
import { colors } from 'styles/colors';

interface IPatientItemProps extends IUserItemProps {
  /**
   * Function for retrieving selected user data and toggle the appointments
   * with such user and the progress made.
   */
  onSelectProgress?: (user: IUser) => void;
}

export const PatientItem: React.FC<IPatientItemProps> = (props) => {
  const { onSelectProgress = () => {}, onSelectUser = () => {}, item } = props;

  const user = item as IUser;
  const { web } = usePlatform();

  return (
    <UserItem item={user}>
      {web ? (
        <Tooltip label="Progresos de terapias con el/la paciente.">
          <Button
            bg={colors.warning}
            onPress={() => onSelectProgress(item)}
            right={0}
          >
            <HStack>
              <Text color="white" mr={2}>
                Progreso
              </Text>
              <Entypo color="white" name="slideshare" size={24} />
            </HStack>
          </Button>
        </Tooltip>
      ) : (
        <IconButton
          bg={colors.warning}
          icon={<Entypo color="white" name="slideshare" size={24} />}
          onPress={() => onSelectProgress(item)}
          right={4}
          title="Progresos de terapias con el/la paciente."
        />
      )}
      <IconButton
        bg={colors.error}
        icon={<AntDesign color="white" name="delete" size={24} />}
        onPress={() => onSelectUser(item)}
        position="absolute"
        right={4}
      />
    </UserItem>
  );
};
