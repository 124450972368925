import { FontAwesome } from '@expo/vector-icons';
import { Button, VStack } from 'native-base';
import React, { useState } from 'react';

import { InputField } from '../InputField';
import { IInputFieldProps } from '../types.d';

export interface IPasswordFieldProps extends IInputFieldProps {
  /**
   * Color to be applied to the show/hide icon color.
   */
  customShowHideColor?: string;
  /**
   * Color to be applied to the show/hide button background.
   */
  customShowHideBG?: string;
  /**
   * Size to be applied to the show/hide icon.
   */
  customShowHideSize?: number;
}

export const PasswordField: React.FC<IPasswordFieldProps> = (props) => {
  const {
    label = 'Contraseña',
    error,
    onChangeText,
    customShowHideBG = 'transparent',
    customShowHideColor = undefined,
    customShowHideSize = undefined,
    ...rest
  } = props;

  const [secureText, setSecureText] = useState(true);

  const toggleSecureText = (): void => {
    setSecureText(!secureText);
  };

  return (
    <VStack>
      <InputField
        InputRightElement={
          <Button
            _hover={{ bg: 'transparent' }}
            _pressed={{ bg: 'transparent' }}
            bg={customShowHideBG}
            onPress={() => toggleSecureText()}
            position="absolute"
            right={4}
            rightIcon={
              secureText ? (
                <FontAwesome
                  color={customShowHideColor}
                  name="eye"
                  size={customShowHideSize}
                />
              ) : (
                <FontAwesome
                  color={customShowHideColor}
                  name="eye-slash"
                  size={customShowHideSize}
                />
              )
            }
          />
        }
        error={error}
        label={label}
        onChangeText={onChangeText}
        secureText={secureText}
        {...rest}
      />
    </VStack>
  );
};

PasswordField.defaultProps = {
  justifyContent: 'center',
  w: '100%',
};
