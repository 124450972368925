import { Ionicons } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import {
  CalendarSelector,
  ExtendedFlatlist,
  LoadingStatusModal,
  UIWrapper,
} from 'components/elements';
import { differenceInDays, format, formatISO } from 'date-fns';
import { getNonCancelledAppointments } from 'helpers/appointment-helpers/confirmation-helpers';
import { removeNilFromArray } from 'helpers/data-helpers/filter-helpers';
import { handleNotificate } from 'helpers/notifications-helpers/notifications-recipes';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { Box, Button, Stack, Text, VStack } from 'native-base';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTheme, getUser, setUserData } from 'redux-service/slices';
import { IAppointmentWithUser } from 'screens/types.d';
import { appointmentsResources } from 'services/resources/appointments';
import {
  IAppointment,
  IAppointmentFormPayload,
  IAppointmentPayload,
} from 'services/resources/appointments/types.d';
import { appointmentsInfoResources } from 'services/resources/appointments-info';
import { IAppointmentInfoPayload } from 'services/resources/appointments-info/types';
import { eventsResources } from 'services/resources/events';
import { IEvent } from 'services/resources/events/types.d';
import { eventsRegistriesResources } from 'services/resources/events-registries';
import { IEventRegistry } from 'services/resources/events-registries/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { therapyProgressResources } from 'services/resources/therapy-progress';
import { ITherapyProgressPayload } from 'services/resources/therapy-progress/types';
import { userResources } from 'services/resources/users';
import { IUser } from 'services/resources/users/types.d';
import {
  handleGetPatients,
  handleGetTherapists,
} from 'services/resources-recipes/user-recipes';
import { colors } from 'styles/colors';
import { ILoadingData } from 'types.d';

import { AppointmentModal } from './components/AppointmentModal';
import { CalendarItem } from './components/CalendarItem';
import { CancelDisclaimer } from './components/CancelDisclaimer';
import { ConfirmDisclaimer } from './components/ConfirmDisclaimer';
import { SelectedTherapist } from './components/SelectedTherapist';
import { TherapistsModal } from './components/TherapistsModal';
import { getCalendarMarkedDatesStyle } from './helpers/calendar-style-helpers';
import {
  createCalendarItems,
  createDatesToMark,
  getSelectedTherapistPatients,
} from './helpers/data-helpers';
import {
  getAppointmentIncidences,
  getEventsIncidences,
  getEventsIncidencesByRegistries,
} from './helpers/filter-helpers';

export const Calendar: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);
  const dispatch = useDispatch();
  const { web } = usePlatform();

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [disclaimerModalOpen, setDislaimerModalOpen] = useState<boolean>(false);
  const [therapistModalOpen, setTherapistModalOpen] = useState<boolean>(false);
  const [confirmationDisclaimerVisible, setConfirmationDisclaimerVisible] =
    useState<boolean>(false);
  const [therapists, setTherapists] = useState<IUser[]>([]);
  const [selectedTherapist, setSelectedTherapist] = useState<IUser>(
    {} as IUser,
  );
  const [therapistPatients, setTherapistPatients] = useState<IUser[]>([]);
  const [appointmentsWithUser, setAppointmentsWithUser] = useState<
    IAppointmentWithUser[]
  >([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedUser, setSelectedUser] = useState<IUser>({} as IUser);
  const [selectedAppointmentWithUser, setSelectedAppointmentWithUser] =
    useState<IAppointmentWithUser>({} as IAppointmentWithUser);
  const [appointmentModalVisible, setAppointmentModalVisible] =
    useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] = useState<IAppointment>({
    date: new Date(),
  } as IAppointment);
  const [editing, setEditing] = useState<boolean>(false);
  const [eventsEntries, setEventsEntries] = useState<IEvent[]>([]);
  const [currentMonthAndYear, setCurrentMonthAndYear] = useState<{
    month: number;
    year: number;
  }>({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const secretary = !fp.isNil(userData.secretary) && userData.secretary;
  const selectedTherapistValid = !fp.isEmpty(selectedTherapist);

  // Since now we can have two sources of therapist data: selected therapist
  // or logged-in user, we need to choose the right data
  const therapistToBeUsed = !secretary ? userData : selectedTherapist;

  const toggleAppointmentModalVisible = (): void => {
    setAppointmentModalVisible(!appointmentModalVisible);
  };

  const toggleTherapistModalVisible = (): void => {
    setTherapistModalOpen(!therapistModalOpen);
  };

  const toggleDisclaimerModalOpen = (): void => {
    setDislaimerModalOpen(!disclaimerModalOpen);
  };

  const toggleConfirmDisclaimerModalOpen = (): void => {
    setConfirmationDisclaimerVisible(!confirmationDisclaimerVisible);
  };

  /**
   * Function for setting the state with the therapist patients' appointments.
   */
  const retrievePatientsAppointments = async (): Promise<void> => {
    setLoadingData({ loading: true, loadingMessage: 'Cargando Citas...' });
    try {
      // Retrieve therapist's patients
      const { patients: retrievedPatients, patientsIds } =
        await handleGetPatients(userData);
      // Only write state if patients changed
      if (!fp.isEmpty(retrievedPatients)) {
        setTherapistPatients(retrievedPatients);
        // Write redux state
        dispatch(setUserData({ ...userData, patients: patientsIds }));
        // Get appointments for a given therapist
        const { data: pA } =
          await appointmentsResources.getForTherapistByMonthAndYear(
            userData.id,
            formatISO(
              new Date(currentMonthAndYear.year, currentMonthAndYear.month),
            ),
            userData.token,
          );
        const patientAppointments = pA as IAppointment[];
        // From the retrieved appointments, combine the results of such
        // with the corresponding user.
        const appointmentsWithUser: IAppointmentWithUser[] =
          getNonCancelledAppointments(patientAppointments).map(
            (appointment) => {
              const matchingUser = retrievedPatients.filter((patient) => {
                if (patient.id === appointment.patient) {
                  return patient;
                } else return null;
              });
              return {
                ...appointment,
                user:
                  !fp.isNil(matchingUser[0]) || !fp.isEmpty(matchingUser[0])
                    ? matchingUser[0]
                    : ({
                        fatherName: 'eliminado',
                        firstName: 'usuario',
                      } as IUser),
              };
            },
          );
        if (!fp.isNil(appointmentsWithUser[0].user)) {
          setAppointmentsWithUser(appointmentsWithUser);
        }
      } else {
        // Otherwise overwrite with empty array
        setAppointmentsWithUser([]);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Calendar-retrievePatientsAppointments',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function for setting the state with all the appointments for all the
   * patients, so a third can set appointments on behalf of others.
   */
  const retrieveAppointmentsForThirds = async (): Promise<void> => {
    setLoadingData({ loading: true, loadingMessage: 'Cargando Citas...' });
    try {
      // Get all the verified therapists on database with full data
      const { data: vTD } =
        await userResources.getAllVerifiedTherapistsWithFullData(
          userData.token,
        );
      const allVerifiedTherapists = vTD as IUser[];
      setTherapists(allVerifiedTherapists);
      // Get all the patients on database with full data
      const { data: pD } = await userResources.getAllPatientsWithFullData(
        userData.token,
      );
      const allPatients = pD as IUser[];
      setTherapistPatients(allPatients);
      // Get appointments for a given therapist
      const { data: aD } =
        await appointmentsResources.getForThirdsByMonthAndYear(
          formatISO(
            new Date(currentMonthAndYear.year, currentMonthAndYear.month),
          ),
          userData.token,
        );
      const appointments = aD as IAppointment[];
      // From the retrieved appointments, combine the results of such
      // with the corresponding user.
      const appointmentsWithUser: IAppointmentWithUser[] =
        getNonCancelledAppointments(appointments).map((appointment) => {
          const matchingUser = allPatients.filter((patient) => {
            if (patient.id === appointment.patient) {
              return patient;
            } else return null;
          });
          return {
            ...appointment,
            user:
              !fp.isNil(matchingUser[0]) || !fp.isEmpty(matchingUser[0])
                ? matchingUser[0]
                : ({
                    fatherName: 'eliminado',
                    firstName: 'usuario',
                  } as IUser),
          };
        });
      if (!fp.isNil(appointmentsWithUser[0].user)) {
        setAppointmentsWithUser(appointmentsWithUser);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Calendar-retrieveAppointmentsForThirds',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function for setting the state with the patient therapists' appointments.
   */
  const retrieveTherapistsAppointments = async (): Promise<void> => {
    setLoadingData({ loading: true, loadingMessage: 'Cargando Citas...' });
    try {
      // Retrieve patient's therapists
      const { therapists: retrievedTherapists, therapistsIds } =
        await handleGetTherapists(userData);
      // Only write state if therapists changed
      if (!fp.isEmpty(retrievedTherapists)) {
        setTherapistPatients(retrievedTherapists);
        // Write redux state
        dispatch(setUserData({ ...userData, therapists: therapistsIds }));
        // Get appointments for a given patient
        const { data: tA } =
          await appointmentsResources.getForPatientByMonthAndYear(
            userData.id,
            formatISO(
              new Date(currentMonthAndYear.year, currentMonthAndYear.month),
            ),
            userData.token,
          );
        const therapistAppointments = tA as IAppointment[];
        // From the retrieved appointments, combine the results of such
        // with the corresponding user.
        const appointmentsWithUser: IAppointmentWithUser[] =
          getNonCancelledAppointments(therapistAppointments).map(
            (appointment) => {
              const matchingUser = retrievedTherapists.filter((therapist) => {
                if (therapist.id === appointment.therapist) {
                  return therapist;
                } else return null;
              });
              return {
                ...appointment,
                user:
                  !fp.isNil(matchingUser[0]) || !fp.isEmpty(matchingUser[0])
                    ? matchingUser[0]
                    : ({
                        fatherName: 'eliminado',
                        firstName: 'usuario',
                      } as IUser),
              };
            },
          );
        if (!fp.isNil(appointmentsWithUser[0].user)) {
          setAppointmentsWithUser(appointmentsWithUser);
        }
      } else {
        // Otherwise overwrite with empty array
        setAppointmentsWithUser([]);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Calendar-retrieveTherapistsAppointments',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function for deleting a given appointment.
   */
  const handleOnDeleteAppointment = async (): Promise<void> => {
    toggleDisclaimerModalOpen();
    setLoadingData({
      loading: true,
      loadingMessage: 'Cancelando Cita...',
    });
    try {
      await appointmentsResources.putCancellation(
        selectedAppointment.id,
        userData.token,
      );
      // Send notification
      let notification = { body: '', title: '' };
      if (userData.accountType === 'therapist') {
        notification = {
          body: `Tu terapeuta ${
            therapistToBeUsed.firstName
          } ha cancelado la cita programada contigo el ${format(
            new Date(selectedAppointment.date),
            'dd-MM-yyyy',
          )} a las ${format(new Date(selectedAppointment.date), 'HH:mm')} hrs.`,
          title: 'Cita cancelada',
        };
      } else {
        notification = {
          body: `Tu paciente ${
            userData.firstName
          } ha cancelado la cita programada contigo el ${format(
            new Date(selectedAppointment.date),
            'dd-MM-yyyy',
          )} a las ${format(new Date(selectedAppointment.date), 'HH:mm')} hrs.`,
          title: 'Cita cancelada',
        };
      }
      const { data: u } = await userResources.getById(
        userData.accountType === 'therapist' || secretary
          ? selectedAppointment.patient
          : selectedAppointment.therapist,
        userData.token,
      );
      const user = u as IUser;
      handleNotificate(notification, 'appointments', {
        ...user,
        logged: false,
        token: userData.token,
      });
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Calendar-handleOnDeleteAppointment',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    if (userData.accountType === 'therapist') {
      retrievePatientsAppointments();
    } else {
      retrieveTherapistsAppointments();
    }
    setSelectedAppointment({ date: new Date() } as IAppointment);
  };

  /**
   * Function for updating an existing appointment.
   * @param payload
   */
  const handleOnUpdateAppointment = async (
    payload: IAppointmentFormPayload,
    user: IUser,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Actualizando Cita...',
    });
    try {
      const updatePayload: IAppointment = {
        ...payload,
        id: selectedAppointment.id,
        patient: selectedAppointment.patient,
        therapist: selectedAppointment.therapist,
      };
      await appointmentsResources.putAll(
        updatePayload,
        selectedAppointment.id,
        userData.token,
      );
      // Send notification
      const notification = {
        body: `Tu terapeuta ${
          therapistToBeUsed.firstName
        } ha modificado una cita que tenía programada contigo el ${format(
          selectedAppointment.date as Date,
          'dd-MM-yyyy',
        )} a las ${format(
          selectedAppointment.date as Date,
          'HH:mm',
        )} hrs. La nueva cita es el ${format(
          payload.date as Date,
          'dd-MM-yyyy',
        )} a las ${format(payload.date as Date, 'HH:mm')} hrs.`,
        title: 'Cita modificada',
      };
      handleNotificate(notification, 'appointments', {
        ...user,
        logged: false,
        token: userData.token,
      });
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Calendar-handleOnUpdateAppointment',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    setEditing(false);
    setSelectedUser({} as IUser);
    setSelectedAppointment({ date: new Date() } as IAppointment);
    if (!secretary) {
      retrievePatientsAppointments();
    } else {
      retrieveAppointmentsForThirds();
    }
  };

  /**
   * Function for creating an empty therapy progress for a new appointment.
   */
  const handleCreateTherapyProgress = async (
    newAppointmentId: string,
  ): Promise<void> => {
    try {
      const newTherapyProgressPayload: ITherapyProgressPayload = {
        appointment: newAppointmentId,
        patientProgress: '',
        therapistProgress: '',
      };
      await therapyProgressResources.create(
        newTherapyProgressPayload,
        userData.token,
      );
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Calendar-handleCreateTherapyProgress',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
  };

  /**
   * Function for creating an empty appointment info for a new appointment.
   */
  const handleCreateAppointmentInfo = async (
    newAppointmentId: string,
    newAppointmentPayload: IAppointmentFormPayload,
  ): Promise<void> => {
    try {
      const appliedDiscount = !fp.isNil(newAppointmentPayload.discountInPesos)
        ? newAppointmentPayload.discountInPesos
        : 0;
      const newAppointmentInfoPayload: IAppointmentInfoPayload = {
        appointment: newAppointmentId,
        commissionPercentage: !fp.isNil(therapistToBeUsed.commissionPercentage)
          ? therapistToBeUsed.commissionPercentage
          : 0,
        cost: newAppointmentPayload.cost - appliedDiscount,
        invoiceRequest: false,
        paidStatus: 'pending',
      };
      await appointmentsInfoResources.create(
        newAppointmentInfoPayload,
        userData.token,
      );
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Calendar-handleCreateAppointmentInfo',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
  };

  /**
   * Function for creating a new appointment.
   * @param payload
   * @param user
   */
  const handleOnCreateAppointment = async (
    payload: IAppointmentFormPayload,
    user: IUser,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Creando Cita...',
    });
    try {
      const appointmentPayload: IAppointmentPayload = {
        ...payload,
        patient: user.id,
        therapist: therapistToBeUsed.id,
      };
      const { data: aI } = await appointmentsResources.create(
        appointmentPayload,
        userData.token,
      );
      handleCreateTherapyProgress(aI as string);
      handleCreateAppointmentInfo(aI as string, payload);
      // Send notification
      const notification = {
        body: `Tu terapeuta ${
          therapistToBeUsed.firstName
        } ha agendado una cita contigo el ${format(
          payload.date as Date,
          'dd-MM-yyyy',
        )} a las ${format(payload.date as Date, 'HH:mm')} hrs.`,
        title: 'Nueva cita',
      };
      handleNotificate(notification, 'appointments', {
        ...user,
        logged: false,
        token: userData.token,
      });
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Calendar-handleOnCreateAppointment',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    setSelectedUser({} as IUser);
    if (!secretary) {
      retrievePatientsAppointments();
    } else {
      retrieveAppointmentsForThirds();
    }
  };

  /**
   * Function that updates the confirmedByTherapist field on the database.
   */
  const handleOnConfirmAppointment = async (
    appointment: string,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Confirmando Cita Completada...',
    });
    try {
      await appointmentsResources.putConfirmedByTherapist(
        appointment,
        userData.token,
      );
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Calendar-handleOnConfirmAppointment',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    retrievePatientsAppointments();
    setSelectedAppointment({ date: new Date() } as IAppointment);
  };

  /**
   * Function that will retrieve existing events registries for a given event.
   */
  const retrieveRegistries = async (
    event: string,
  ): Promise<IEventRegistry[] | undefined> => {
    try {
      const { data } = await eventsRegistriesResources.getByEventId(
        event,
        userData.token,
      );
      const d = data as IEventRegistry[];
      if (!fp.isEmpty(d)) {
        return d;
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Calendar-retrieveRegistries',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
  };

  /**
   * Function for retrieving all the events that are currently stored in the
   * database.
   */
  const retrieveEvents = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Eventos...',
    });
    try {
      const { data: d } = await eventsResources.get(userData.token);
      const data = d as IEvent[];
      if (!fp.isEmpty(data)) {
        if (userData.accountType === 'patient') {
          setLoadingData({
            loading: true,
            loadingMessage: 'Consultando registros existentes...',
          });
          const eventsRegistries = removeNilFromArray(
            await Promise.all(
              data.map(async (event) => await retrieveRegistries(event.id)),
            ),
          ).flat() as IEventRegistry[];
          setLoadingData({ ...loadingData, loading: false });
          setEventsEntries(
            getEventsIncidencesByRegistries(
              userData.email,
              data,
              eventsRegistries,
            ),
          );
        } else {
          setEventsEntries(data);
        }
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Calendar-retrieveEvents',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that verifies if based on a given time condition we can create
   * an appointment or not.
   * @returns appointment creation enabled/disabled
   */
  const getDisabledAppointmentCreation = (): boolean => {
    const difference = differenceInDays(selectedDate, new Date());
    if (difference < 0) return true;
    return false;
  };

  const getListFlex = (): number => {
    if (selectedTherapistValid && !web) return 1;
    if (!selectedTherapistValid && web) return 5;
    if (selectedTherapistValid && web) return 2;
    return 5;
  };

  useFocusEffect(
    useCallback(() => {
      if (userData.accountType === 'therapist') {
        retrievePatientsAppointments();
      } else if (userData.accountType === 'patient') {
        retrieveTherapistsAppointments();
      } else if (secretary) {
        retrieveAppointmentsForThirds();
      }
      // Without caring about the account type, we retrieve events
      retrieveEvents();
    }, []),
  );

  return (
    <UIWrapper title="Calendario">
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      <AppointmentModal
        isEditing={editing}
        isOpen={appointmentModalVisible}
        loading={loadingData.loading}
        onCancel={() => {
          if (secretary) {
            retrieveAppointmentsForThirds();
          }
          setEditing(false);
          setSelectedUser({} as IUser);
          setSelectedAppointment({ date: new Date() } as IAppointment);
          toggleAppointmentModalVisible();
        }}
        onSubmit={(payload, user) => {
          toggleAppointmentModalVisible();
          if (!editing) {
            handleOnCreateAppointment(payload, user);
          } else {
            handleOnUpdateAppointment(payload, user);
          }
        }}
        patients={therapistPatients}
        previousValues={selectedAppointment}
        selectedDate={selectedDate}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      <TherapistsModal
        isOpen={therapistModalOpen}
        loading={loadingData.loading}
        onCancel={() => toggleTherapistModalVisible()}
        selectedTherapist={selectedTherapist}
        setSelectedTherapist={setSelectedTherapist}
        therapists={therapists}
      />
      <CancelDisclaimer
        isOpen={disclaimerModalOpen}
        onAccept={handleOnDeleteAppointment}
        onDecline={() => {
          setSelectedAppointment({ date: new Date() } as IAppointment);
          toggleDisclaimerModalOpen();
        }}
        selectedAppointment={selectedAppointment}
      />
      <ConfirmDisclaimer
        isOpen={confirmationDisclaimerVisible}
        onAccept={() => {
          toggleConfirmDisclaimerModalOpen();
          handleOnConfirmAppointment(selectedAppointmentWithUser.id);
          setSelectedAppointmentWithUser({
            date: new Date(),
            user: {} as IUser,
          } as IAppointmentWithUser);
        }}
        onDecline={() => {
          setSelectedAppointmentWithUser({
            date: new Date(),
            user: {} as IUser,
          } as IAppointmentWithUser);
          toggleConfirmDisclaimerModalOpen();
        }}
        selectedAppointmentWithUser={selectedAppointmentWithUser}
      />
      <Stack
        alignItems="center"
        direction={web ? 'row' : 'column'}
        h="100%"
        w="100%"
      >
        <CalendarSelector
          datesToMark={createDatesToMark(appointmentsWithUser, eventsEntries)}
          flex={1}
          h="100%"
          markedDatesStyles={getCalendarMarkedDatesStyle(themeData)}
          onNextMonthSelect={(newMonth, newYear) =>
            setCurrentMonthAndYear({
              month: newMonth,
              year: newYear,
            })
          }
          onPreviousMonthSelect={(newMonth, newYear) =>
            setCurrentMonthAndYear({
              month: newMonth,
              year: newYear,
            })
          }
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <VStack bg="white" flex={1} h="100%" w="100%">
          <ExtendedFlatlist
            alternativeSortKey="event"
            data={createCalendarItems(
              getAppointmentIncidences(selectedDate, appointmentsWithUser),
              getEventsIncidences(selectedDate, eventsEntries),
              therapists,
            )}
            flex={getListFlex()}
            noDataMessage="No se encontraron citas ni eventos programadas para el día seleccionado."
            renderItem={
              <CalendarItem
                appointmentModalVisible={appointmentModalVisible}
                onDelete={(payload) => {
                  setSelectedAppointment(payload);
                  toggleDisclaimerModalOpen();
                }}
                onEdit={(appointment, user) => {
                  setEditing(true);
                  setSelectedUser(user);
                  setSelectedAppointment({
                    ...appointment,
                    date: new Date(appointment.date),
                  });
                  toggleAppointmentModalVisible();
                }}
                setSelectedAppointmentWithUser={(sAWU) => {
                  toggleConfirmDisclaimerModalOpen();
                  setSelectedAppointmentWithUser(sAWU);
                }}
                toggleAppointmentModalVisible={toggleAppointmentModalVisible}
              />
            }
            sort
            sortByDateInObject
            sortKey="appointmentWithUser"
            sortSubKey="date"
            useSearchBar={false}
          />
          <VStack flex={!web ? 1 : 0.5} w="100%">
            {secretary && selectedTherapistValid ? (
              <SelectedTherapist
                flex={1}
                onUnselect={() => setSelectedTherapist({} as IUser)}
                py={2}
                selectedTherapist={selectedTherapist}
              />
            ) : null}
            {userData.accountType === 'therapist' || secretary ? (
              <>
                {secretary && !selectedTherapistValid ? (
                  <Button
                    _hover={{ bg: colors.success, opacity: 0.8 }}
                    _pressed={{ bg: colors.success, opacity: 0.8 }}
                    _text={{ fontWeight: 'bold' }}
                    bg={colors.success}
                    borderRadius={0}
                    flex={1}
                    isDisabled={getDisabledAppointmentCreation()}
                    leftIcon={<Ionicons color="white" name="add" size={24} />}
                    onPress={() => toggleTherapistModalVisible()}
                    w="100%"
                  >
                    Seleccionar terapeuta
                  </Button>
                ) : (
                  <Button
                    _hover={{ bg: colors.success, opacity: 0.8 }}
                    _pressed={{ bg: colors.success, opacity: 0.8 }}
                    _text={{ fontWeight: 'bold' }}
                    bg={colors.success}
                    borderRadius={0}
                    flex={1}
                    isDisabled={getDisabledAppointmentCreation()}
                    leftIcon={<Ionicons color="white" name="add" size={24} />}
                    onPress={() => {
                      if (secretary) {
                        setTherapistPatients(
                          getSelectedTherapistPatients(
                            selectedTherapist,
                            therapistPatients,
                          ),
                        );
                      }
                      toggleAppointmentModalVisible();
                    }}
                    w="100%"
                  >
                    {`Crear Cita (${format(selectedDate, 'dd-MM-yyyy')})`}
                  </Button>
                )}
              </>
            ) : (
              <Box
                bg={themeData.mainColorDark}
                borderBottomColor="white"
                borderBottomWidth={1}
                flex={1}
                justifyContent="center"
                w="100%"
              >
                <Text
                  color="white"
                  fontWeight="bold"
                  textAlign="center"
                >{`Citas y Eventos del día ${format(
                  selectedDate,
                  'dd-MM-yyyy',
                )}`}</Text>
              </Box>
            )}
          </VStack>
        </VStack>
      </Stack>
    </UIWrapper>
  );
};
