import { AntDesign } from '@expo/vector-icons';
import {
  addMonths,
  addQuarters,
  addYears,
  format,
  subMonths,
  subQuarters,
  subYears,
} from 'date-fns';
import { es } from 'date-fns/esm/locale';
import { HStack, IconButton, IStackProps, Text, VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

interface IDateSelectorProps extends IStackProps {
  /**
   * Actual value of selected date state.
   */
  selectedQueryDate: Date;
  /**
   * State setter for the sate query.
   */
  setSelectedQueryDate: (newDate: Date) => void;
  /**
   * Value of the selected date option. Used to constraint date selector
   * behavior.
   */
  selectedDateOption: string;
}

export const DateSelector: React.FC<IDateSelectorProps> = (
  props,
): JSX.Element => {
  const {
    selectedDateOption,
    setSelectedQueryDate,
    selectedQueryDate,
    ...rest
  } = props;

  const themeData = useSelector(getTheme);

  const isMonthDisabled = selectedDateOption === 'quarter';

  return (
    <HStack {...rest}>
      {!isMonthDisabled ? (
        <VStack>
          <IconButton
            icon={
              <AntDesign
                color={themeData.mainColorDark}
                name="caretup"
                size={24}
              />
            }
            onPress={() => {
              // We only add the month if we don't go out of current year
              // boundary.
              if (
                addMonths(selectedQueryDate, 1).getFullYear() ===
                selectedQueryDate.getFullYear()
              ) {
                setSelectedQueryDate(addMonths(selectedQueryDate, 1));
              }
            }}
          />
          <Text textAlign="center">
            {format(selectedQueryDate, 'MMMM', {
              locale: es,
            })}
          </Text>
          <IconButton
            icon={
              <AntDesign
                color={themeData.mainColorDark}
                name="caretdown"
                size={24}
              />
            }
            onPress={() => {
              // We only substract the month if we don't go out of current year
              // boundary.
              if (
                subMonths(selectedQueryDate, 1).getFullYear() ===
                selectedQueryDate.getFullYear()
              ) {
                setSelectedQueryDate(subMonths(selectedQueryDate, 1));
              }
            }}
          />
        </VStack>
      ) : (
        <VStack>
          <IconButton
            icon={
              <AntDesign
                color={themeData.mainColorDark}
                name="caretup"
                size={24}
              />
            }
            onPress={() => {
              // We only add the quarter if we don't go out of current year
              // boundary.
              if (
                addQuarters(selectedQueryDate, 1).getFullYear() ===
                selectedQueryDate.getFullYear()
              ) {
                setSelectedQueryDate(addQuarters(selectedQueryDate, 1));
              }
            }}
          />
          <Text textAlign="center">
            {format(selectedQueryDate, 'QQQQ', {
              locale: es,
            })}
          </Text>
          <IconButton
            icon={
              <AntDesign
                color={themeData.mainColorDark}
                name="caretdown"
                size={24}
              />
            }
            onPress={() => {
              // We only substract the quarter if we don't go out of current year
              // boundary.
              if (
                subQuarters(selectedQueryDate, 1).getFullYear() ===
                selectedQueryDate.getFullYear()
              ) {
                setSelectedQueryDate(subQuarters(selectedQueryDate, 1));
              }
            }}
          />
        </VStack>
      )}
      <VStack>
        <IconButton
          icon={
            <AntDesign
              color={themeData.mainColorDark}
              name="caretup"
              size={24}
            />
          }
          onPress={() => {
            if (
              addYears(selectedQueryDate, 1).getFullYear() <=
              new Date().getFullYear()
            ) {
              setSelectedQueryDate(addYears(selectedQueryDate, 1));
            }
          }}
        />
        <Text textAlign="center">
          {format(selectedQueryDate, 'yyyy', {
            locale: es,
          })}
        </Text>
        <IconButton
          icon={
            <AntDesign
              color={themeData.mainColorDark}
              name="caretdown"
              size={24}
            />
          }
          onPress={() => {
            setSelectedQueryDate(subYears(selectedQueryDate, 1));
          }}
        />
      </VStack>
    </HStack>
  );
};

DateSelector.defaultProps = {
  space: 2,
};
