import { createBlob } from 'helpers/data-helpers/blob-helpers';
import { getSingleImagePickerURI } from 'helpers/data-helpers/picker-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { storageResources } from 'services/resources/storage';

/**
 * Function that handles the cover image upload to firebase storage.
 * @param eventTitle
 * @param imageURI
 * @returns event cover url
 */
export const handleUploadEventCover = async (
  eventTitle: string,
  imageURI: string,
): Promise<string> => {
  let imageURL = '';
  if (!fp.isEmpty(imageURI)) {
    // Create blob from ImagePicker uri result
    const blob = await createBlob(imageURI);
    // Create a path from the event title
    const outputPath = eventTitle.replace(/ /g, '_');
    // Get reference from storage file upload
    const { ref } = await storageResources.createPictureToPath(
      `images/events/${outputPath}/cover`,
      blob,
    );
    // Get storage file URL
    imageURL = await storageResources.getPictureURLByRef(ref);
  }
  return imageURL;
};

/**
 * Function that handles media file upload to firebase storage.
 * @param eventTitle
 * @param imagesURIs
 * @returns firebase media urls
 */
export const handleUploadEventMedia = async (
  eventTitle: string,
  imagesURIs: string[],
): Promise<string[]> => {
  // eslint-disable-next-line prefer-const
  let imagesURLS: string[] = [];
  // Create a path from the event title
  const outputPath = eventTitle.replace(/ /g, '_');
  if (!fp.isEmpty(imagesURIs)) {
    await Promise.all(
      imagesURIs.map(async (uri) => {
        if (!uri.includes('http')) {
          // Create blob from ImagePicker uri result
          const blob = await createBlob(uri);
          // Get reference from storage file upload
          const { ref } = await storageResources.createPictureToPath(
            `images/events/${outputPath}/media/${uri.substring(
              uri.length - 1,
              uri.length - 6,
            )}`,
            blob,
          );
          // Get storage file URL
          const imageURL = await storageResources.getPictureURLByRef(ref);
          // Add URL to the output array
          imagesURLS.push(imageURL);
        }
      }),
    );
  }
  if (imagesURLS.length === 0) imagesURLS = imagesURIs;
  return imagesURLS;
};

/**
 * Function that creates images from given URIs in Firestore and retrieves the
 * URLs from such images.
 * @param imagesURIs
 * @param tempNewEntryID
 * @returns imagesURLs
 */
export const handleUploadBlogMedia = async (
  imagesURIs: string[],
  tempNewEntryID: string,
): Promise<string[]> => {
  // eslint-disable-next-line prefer-const
  let imagesURLS: string[] = [];
  if (!fp.isEmpty(imagesURIs)) {
    await Promise.all(
      imagesURIs.map(async (uri, index) => {
        if (!uri.includes('http')) {
          // Create blob from ImagePicker uri result
          const blob = await createBlob(uri);
          // Get reference from storage file upload
          const { ref } = await storageResources.createPictureToPath(
            `images/blog/${tempNewEntryID}/media/uploaded_image_${index + 1}`,
            blob,
          );
          // Get storage file URL
          const imageURL = await storageResources.getPictureURLByRef(ref);
          // Add URL to the output array
          imagesURLS.push(imageURL);
        }
      }),
    );
  }
  if (imagesURLS.length === 0) imagesURLS = imagesURIs;
  return imagesURLS;
};

/**
 * Function meant to create a temporary image associated to a given user.
 * @param userEmail
 * @param imageURI
 * @returns url of the uploaded image.
 */
export const handleUploadTempImage = async (
  userEmail: string,
  imageURI: string,
): Promise<string> => {
  let imageURL = '';
  if (!fp.isEmpty(imageURI)) {
    // Create blob from ImagePicker uri result
    const blob = await createBlob(imageURI);
    // Get reference from storage file upload
    const { ref } = await storageResources.createUserPicture(
      userEmail,
      'temp',
      blob,
    );
    // Get storage file URL
    imageURL = await storageResources.getPictureURLByRef(ref);
  }
  return imageURL;
};

/**
 * Function that tries to upload a picture retrieved from Expo Image Picker
 * to firebase storage.
 * @param userEmail
 * @param targetPicture
 * @returns storaged image URL
 */
export const handleUploadProfilePicture = async (
  userEmail: string,
  targetPicture: string,
): Promise<string> => {
  let imageURL = '';
  const imageURI = await getSingleImagePickerURI();
  if (!fp.isEmpty(imageURI)) {
    // Create blob from ImagePicker uri result
    const blob = await createBlob(imageURI);
    // Get reference from storage file upload
    const { ref } = await storageResources.createUserPicture(
      userEmail,
      targetPicture,
      blob,
    );
    // Get storage file URL
    imageURL = await storageResources.getPictureURLByRef(ref);
  }
  return imageURL;
};

/**
 * Function that takes the URI of a QR code and saves it in a readable format
 * for Firebase storage. It works with a data-url or blob based on the used
 * platform.
 * @param targetPicture
 * @param eventInfo
 * @returns the download URL from Firebase storage.
 */
export const handleUploadQRFile = async (
  targetPicture: string,
  eventInfo: string,
): Promise<string> => {
  let imageURL = '';
  const { web } = usePlatform();
  if (!fp.isEmpty(targetPicture)) {
    const blob = await createBlob(targetPicture);
    // Get reference from storage file upload
    const { ref } = !web
      ? await storageResources.createQRPictureFile(blob, eventInfo)
      : await storageResources.createQRPicture(targetPicture, eventInfo);
    // Get storage file URL
    imageURL = await storageResources.getPictureURLByRef(ref);
  }
  return imageURL;
};
