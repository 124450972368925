import { MaterialIcons } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import {
  AcceptDeclineModal,
  ExtendedFlatlist,
  LoadingStatusModal,
  UIWrapper,
} from 'components/elements';
import { getUserFullName } from 'helpers/data-helpers/string-helpers';
import { handleNotificate } from 'helpers/notifications-helpers/notifications-recipes';
import fp from 'lodash/fp';
import { Button, Text, VStack } from 'native-base';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setUserData } from 'redux-service/slices';
import { appointmentsResources } from 'services/resources/appointments';
import { IAppointment } from 'services/resources/appointments/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { therapyProgressResources } from 'services/resources/therapy-progress';
import {
  ITherapyProgress,
  ITherapyProgressPayload,
} from 'services/resources/therapy-progress/types';
import { userResources } from 'services/resources/users';
import { IUser } from 'services/resources/users/types.d';
import { colors } from 'styles/colors';
import { ILoadingData } from 'types.d';

import { AppointmentsModal } from './components/AppointmentsModal';
import { NewPatientModal } from './components/NewPatientModal';
import { PatientItem } from './components/PatientItem';
import { PutTherapyProgressModal } from './components/PutTherapyProgressModal';
import { getSelectedUserAppointmentsProgresses } from './helpers/data-helpers';
import { getAppointmentsWithDefinedTherapyProgress } from './helpers/filter-helpers';
import { IAppointmentWithTherapyProgress } from './types.d';

export const TherapistPatients: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);
  const dispatch = useDispatch();

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [modalInfo, setModalInfo] = useState<{
    isOpen: boolean;
    message: string;
  }>({
    isOpen: false,
    message: '',
  });
  const [patientModalOpen, setPatientModalOpen] = useState<boolean>(false);
  const [appointmentsModalOpen, setAppointmentsModalOpen] =
    useState<boolean>(false);
  const [putTherapyProgressModalOpen, setPutTherapyProgressModalOpen] =
    useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser>({} as IUser);
  const [patients, setPatients] = useState<IUser[]>([]);
  const [appointmentsWithTherapyProgress, setAppointmentsWithTherapyProgress] =
    useState<IAppointmentWithTherapyProgress[]>([]);
  const [
    selectedAppointmentWithTherapyProgress,
    setSelectedAppointmentWithTherapyProgress,
  ] = useState<IAppointmentWithTherapyProgress>(
    {} as IAppointmentWithTherapyProgress,
  );

  const toggleModalOpen = (): void => {
    setModalInfo({ isOpen: !modalInfo.isOpen, message: modalInfo.message });
  };

  const togglePatientModalOpen = (): void => {
    if (patientModalOpen) {
      setSelectedUser({} as IUser);
    }
    setPatientModalOpen(!patientModalOpen);
  };

  const toggleAppointmentsModalOpen = (): void => {
    if (appointmentsModalOpen) {
      setSelectedUser({} as IUser);
    }
    setAppointmentsModalOpen(!appointmentsModalOpen);
  };

  const togglePutTherapyProgressModalOpen = (
    appointmentWithTherapyProgress: IAppointmentWithTherapyProgress,
  ): void => {
    setSelectedAppointmentWithTherapyProgress(appointmentWithTherapyProgress);
    setPutTherapyProgressModalOpen(!putTherapyProgressModalOpen);
  };

  /**
   * Function for retrieving the patients of a given therapist and writing them
   * to state.
   */
  const retrievePatients = async (): Promise<void> => {
    setLoadingData({ loading: true, loadingMessage: 'Cargando Pacientes...' });
    try {
      const { data: d } = await userResources.getPatients(
        userData.id,
        userData.token,
      );
      const data = d as string[];
      let retrievedPatients: IUser[] = [];
      // We verify that there is at least one patient
      if (data.length > 0) {
        // We wait until all the promises are resolved or one is rejected
        retrievedPatients = await Promise.all(
          data.map(async (patient) => {
            const { data } = await userResources.getById(
              patient,
              userData.token,
            );
            return data as IUser;
          }),
        );
      }
      // Only write states if patients changed
      if (!fp.isEmpty(retrievedPatients)) {
        // Write redux state
        dispatch(setUserData({ ...userData, patients: userData.patients }));
        setPatients(retrievedPatients);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'TherapistPatients-retrievePatients',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function to retrieve the therapy progress of all therapist's appointments.
   * @param inputAppointments
   */
  const retrieveAppointmentsProgress = async (
    inputAppointments: IAppointment[],
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Progresos con Pacientes...',
    });
    try {
      const appointmentsWithTherapyProgress: IAppointmentWithTherapyProgress[] =
        await Promise.all(
          inputAppointments.map(async (appointment) => {
            // Get the progress of a given appointment
            const { data: aP } = await therapyProgressResources.get(
              appointment.id,
              userData.token,
            );
            const therapyProgress = aP as ITherapyProgress[];
            return {
              appointment,
              therapyProgress: therapyProgress[0],
            };
          }),
        );
      const appointmentsWithDefinedTherapyProgress =
        getAppointmentsWithDefinedTherapyProgress(
          appointmentsWithTherapyProgress,
        );
      if (!fp.isNil(appointmentsWithDefinedTherapyProgress)) {
        setAppointmentsWithTherapyProgress(
          appointmentsWithDefinedTherapyProgress,
        );
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'therapistPatients-retrieveAppointmentsProgress',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function for retrieving the appointments of a given therapist
   * with all his/her patients.
   */
  const retrieveAppointments = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Pacientes y Citas...',
    });
    try {
      // Get appointments for a given therapist
      const { data: pA } =
        await appointmentsResources.getForTherapistCheckProgress(
          userData.id,
          userData.token,
        );
      const therapistAppointments = pA as IAppointment[];
      if (!fp.isNil(therapistAppointments)) {
        retrieveAppointmentsProgress(therapistAppointments);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'TherapistPatients-retrieveTherapistsAppointments',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that searches the target user by email in the database
   * @param user
   */
  const findUser = async (user: { email: string }): Promise<void> => {
    setLoadingData({ loading: true, loadingMessage: 'Buscando Usuarios...' });
    try {
      const { data } = await userResources.getByMail(
        user.email,
        userData.token,
      );
      if (!fp.isEmpty(data)) setSelectedUser(data);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'therapistPatients',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ loading: false, loadingMessage: '' });
  };

  /**
   * Function that saves to the state the selected user for its interactions
   * for this dashboard.
   * @param user
   */
  const setSelectedUserData = (user: IUser): void => {
    setModalInfo({
      isOpen: true,
      message: `¿Estás seguro de querer eliminar a ${getUserFullName(
        user,
      )} como paciente?`,
    });
    setSelectedUser(user);
  };

  /**
   * Function for handling patient deletion and notificates about it.
   */
  const handleOnPatientDelete = async (): Promise<void> => {
    setLoadingData({ loading: true, loadingMessage: 'Eliminando Relación...' });
    try {
      await userResources.deleteTherapyRelationship(
        { requestingUserId: userData.id, targetUserId: selectedUser.id },
        userData.token,
      );
      // Send notification
      const notification = {
        body: `El usuario ${getUserFullName(
          selectedUser,
        )} ha dejado de ser tu paciente`,
        title: 'Actualización de cuenta',
      };
      await handleNotificate(notification, 'therapistPatients', userData);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'therapistPatients',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    toggleModalOpen();
    setLoadingData({ loading: false, loadingMessage: '' });
    retrievePatients();
  };

  /**
   * Function for handling patient addition and notificates about it.
   */
  const handleOnPatientAdd = async (): Promise<void> => {
    setLoadingData({ loading: true, loadingMessage: 'Creando Relación...' });
    try {
      await userResources.createTherapyRelationship(
        { requestingUserId: userData.id, targetUserId: selectedUser.id },
        userData.token,
      );
      // Send notification
      const notification = {
        body: `Has sido añadido como paciente por ${userData.firstName}`,
        title: 'Actualización de cuenta',
      };
      handleNotificate(notification, 'therapistPatients', {
        ...selectedUser,
        logged: false,
        token: userData.token,
      });
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'therapistPatients',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    togglePatientModalOpen();
    setLoadingData({ loading: false, loadingMessage: '' });
    retrievePatients();
  };

  /**
   * Function to update the therapy progress of an appointment.
   * @param therapyProgressPayload
   */
  const handleSubmitTherapyProgress = async (
    therapyProgressPayload: ITherapyProgress | ITherapyProgressPayload,
  ): Promise<void> => {
    setLoadingData({ loading: true, loadingMessage: 'Guardando Progreso...' });
    try {
      await therapyProgressResources.put(
        therapyProgressPayload,
        userData.token,
      );
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'therapistPatients',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setPutTherapyProgressModalOpen(!putTherapyProgressModalOpen);
    setLoadingData({ loading: false, loadingMessage: '' });
    retrieveAppointments();
  };

  useFocusEffect(
    useCallback(() => {
      retrievePatients();
      retrieveAppointments();
    }, []),
  );

  return (
    <UIWrapper title="Pacientes">
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      <NewPatientModal
        currentPatients={patients}
        foundUser={selectedUser}
        isOpen={patientModalOpen}
        onAccept={handleOnPatientAdd}
        onDecline={togglePatientModalOpen}
        onSearchSubmit={findUser}
      />
      <AppointmentsModal
        appointmentsWithTherapyProgress={getSelectedUserAppointmentsProgresses(
          appointmentsWithTherapyProgress,
          selectedUser.id,
        )}
        isOpen={appointmentsModalOpen}
        onCloseAppointmentsModal={toggleAppointmentsModalOpen}
        togglePutTherapyProgressModal={(appoWithTP) =>
          togglePutTherapyProgressModalOpen(appoWithTP)
        }
      />
      {!fp.isEmpty(selectedAppointmentWithTherapyProgress) ? (
        <PutTherapyProgressModal
          isOpen={putTherapyProgressModalOpen}
          onClosePutTherapyProgressModal={() => {
            setPutTherapyProgressModalOpen(!putTherapyProgressModalOpen);
            setSelectedAppointmentWithTherapyProgress(
              {} as IAppointmentWithTherapyProgress,
            );
          }}
          onSubmitTherapyProgress={(payload) => {
            handleSubmitTherapyProgress(payload);
            setSelectedAppointmentWithTherapyProgress(
              {} as IAppointmentWithTherapyProgress,
            );
            toggleAppointmentsModalOpen();
          }}
          previousValues={
            selectedAppointmentWithTherapyProgress.therapyProgress
          }
        />
      ) : null}
      <AcceptDeclineModal
        invertColors
        isOpen={modalInfo.isOpen}
        onAccept={handleOnPatientDelete}
        onDecline={toggleModalOpen}
      >
        <Text fontWeight="bold">{modalInfo.message}</Text>
      </AcceptDeclineModal>
      <VStack bg="white" flex={17}>
        <ExtendedFlatlist
          data={patients}
          noDataMessage="No se encontraron pacientes registrados."
          renderItem={
            <PatientItem
              onSelectProgress={(patient) => {
                toggleAppointmentsModalOpen();
                setSelectedUser(patient);
              }}
              onSelectUser={(patient) => {
                toggleModalOpen();
                setSelectedUserData(patient);
              }}
            />
          }
          searchBarPlaceholder="pacientes"
          searchKey={['firstName', 'fatherName', 'motherName']}
          sort
          sortKey="firstName"
          useSearchBar
        />
      </VStack>
      <Button
        _hover={{ bg: colors.success, opacity: 0.8 }}
        _pressed={{ bg: colors.success, opacity: 0.8 }}
        _text={{ fontWeight: 'bold' }}
        bg={colors.success}
        borderRadius={0}
        flex={1}
        leftIcon={
          <MaterialIcons
            color="white"
            name="add"
            size={24}
            style={{ marginRight: 10 }}
          />
        }
        onPress={togglePatientModalOpen}
        w="100%"
      >
        Añadir Paciente
      </Button>
    </UIWrapper>
  );
};
