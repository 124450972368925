import { useSmallScreen } from 'hooks/dimensions-hooks';
import fp from 'lodash/fp';
import { Box, IPressableProps, Pressable, Text, VStack } from 'native-base';
import { fontSizes } from 'styles/theme';

interface IIconTextButtonProps extends IPressableProps {
  /**
   * Icon to be rendered inside the Button.
   */
  icon?: JSX.Element;
  /**
   * String to be rendered below the desired icon.
   */
  label: string;
  /**
   * Custom color to be applied to the label text.
   */
  labelColor?: string;
  /**
   * Space to be left between icon and text.
   */
  space?: number;
}

export const IconTextButton: React.FC<IIconTextButtonProps> = (
  props,
): JSX.Element => {
  const { icon, label, labelColor, space = 1, ...rest } = props;
  const small = useSmallScreen();
  return (
    <Pressable {...rest}>
      <VStack
        alignItems="center"
        flex={1}
        justifyContent="center"
        space={space}
      >
        {!fp.isNil(icon) ? (
          <Box flex={2} justifyContent="center">
            {icon}
          </Box>
        ) : null}
        <Text
          color={!fp.isEmpty(labelColor) ? labelColor : undefined}
          flex={1}
          fontSize={!small ? fontSizes.big : fontSizes.small}
          textAlign="center"
        >
          {label}
        </Text>
      </VStack>
    </Pressable>
  );
};

IconTextButton.defaultProps = {
  borderRadius: 10,
  py: 3,
};
