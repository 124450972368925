import { FontAwesome } from '@expo/vector-icons';
import { UserItem } from 'components/elements';
import { IUserItemProps } from 'components/elements/types.d';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { Button, IconButton, Text } from 'native-base';
import { IUser } from 'services/resources/users/types.d';
import { colors } from 'styles/colors';

interface ITherapistItemProps extends IUserItemProps {
  /**
   * Function for toggling the put commission modal.
   */
  onToggleCommissionForm: (selectedTherapist: IUser) => void;
}

export const TherapistItem: React.FC<ITherapistItemProps> = (props) => {
  const { onToggleCommissionForm = () => {}, item } = props;

  const user = item as IUser;
  const { web } = usePlatform();

  return (
    <UserItem item={user}>
      <Text fontWeight="bold">
        {fp.isNil(user.commissionPercentage)
          ? 'S/C'
          : `${user.commissionPercentage}%`}
      </Text>
      {web ? (
        <Button
          bg={
            fp.isNil(user.commissionPercentage)
              ? colors.success
              : colors.warning
          }
          onPress={() => onToggleCommissionForm(user)}
          right={0}
        >
          {fp.isNil(user.commissionPercentage)
            ? 'Añadir Comisión'
            : 'Editar Comisión'}
        </Button>
      ) : (
        <IconButton
          bg={
            fp.isNil(user.commissionPercentage)
              ? colors.success
              : colors.warning
          }
          icon={
            <FontAwesome
              color="white"
              name={fp.isNil(user.commissionPercentage) ? 'plus' : 'pencil'}
              size={24}
            />
          }
          onPress={() => onToggleCommissionForm(user)}
        />
      )}
    </UserItem>
  );
};
