// This algorithm was created using ChatGPT Mar 23 Version

/**
 * Function that removes an image tag from a given html template with a given
 * indexed_image file name to remove the image tag that matches such image
 * file name.
 * @param template
 * @param imageString
 * @returns template without img string that matches indexed image file name
 */
export const removeImageFromTemplate = (
  template: string,
  imageString: string,
): string => {
  const regex = new RegExp(
    `<img[^>]*src=["'][^"']*${imageString}[^"']*["'][^>]*>`,
    'gi',
  );
  const srcRegex = new RegExp(`${imageString}`, 'g');
  const result = template.replace(regex, '').replace(srcRegex, '');
  return result;
};

/**
 * Function that verifies if in a given template there are present img tags.
 * @param template
 * @returns
 */
export const areImagesPresent = (template: string): boolean => {
  return /<img\b[^>]*>/i.test(template);
};
