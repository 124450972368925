import { Entypo } from '@expo/vector-icons';
import { InputScroller } from 'components/elements';
import { EventSignUpForm } from 'components/forms';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { IconButton, IModalProps, Modal } from 'native-base';
import { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IEventRegistryPayload } from 'services/resources/events-registries/types.d';
import { ICustomField } from 'services/resources/events/types';

interface IEventSignUpModalProps extends IModalProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IEventRegistryPayload) => void;
  /**
   * Function that only calls the toggle modal at Events.tsx, but may be used
   * in the future for calling other functions.
   */
  onCloseModal: () => void;
  /**
   * Event from which the registry is going to be created from.
   */
  eventId: string;
  /**
   * Custom fields for the event.
   */
  customFields?: ICustomField[];
}

export const EventSignUpModal: React.FC<IEventSignUpModalProps> = (
  props,
): JSX.Element => {
  const { customFields = [], eventId, onSubmit, onCloseModal, ...rest } = props;
  const themeData = useSelector(getTheme);

  const { web } = usePlatform();

  const [scrollRef, setScrollRef] = useState<ScrollView | null>(null);

  useEffect(() => {
    if (!fp.isNil(scrollRef)) scrollRef.flashScrollIndicators();
  }, []);

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        bg="white"
        borderRadius={!web ? 0 : 10}
        flex={1}
        h="100%"
        justifyContent="center"
        w={!web ? '100%' : '30%'}
      >
        <IconButton
          alignSelf="flex-end"
          icon={
            <Entypo color={themeData.mainColorDark} name="cross" size={28} />
          }
          mr={3}
          onPress={() => onCloseModal()}
        />
        {!web ? (
          <InputScroller
            bottomOffset
            contentContainerStyle={{
              width: '100%',
            }}
            disableScrollToStart
          >
            <EventSignUpForm
              alignItems="center"
              anonymous={false}
              customFields={customFields}
              eventId={eventId}
              onSubmit={(p) => {
                onCloseModal();
                onSubmit(p);
              }}
            />
          </InputScroller>
        ) : (
          <ScrollView
            ref={(scrollViewRef) => setScrollRef(scrollViewRef)}
            showsVerticalScrollIndicator
            style={{ width: '100%' }}
          >
            <EventSignUpForm
              anonymous={false}
              customFields={customFields}
              eventId={eventId}
              mt={6}
              onSubmit={(p) => {
                onCloseModal();
                onSubmit(p);
              }}
              pb={8}
            />
          </ScrollView>
        )}
      </Modal.Content>
    </Modal>
  );
};

EventSignUpModal.defaultProps = {
  size: 'full',
};
