import { useNavigation } from '@react-navigation/native';
import { ExternalUIWrapper, InputScroller } from 'components/elements';
import { PasswordResetForm } from 'components/forms';
import { authErrorHandler } from 'helpers/error-helpers/auth-helpers';
import { Logo } from 'helpers/source-helpers/img-src-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { Center, Image, VStack } from 'native-base';
import React, { useState } from 'react';
import { BackButton } from 'screens/SignUp/components/BackButton';
import { authResources } from 'services/resources/auth';
import { IOperationError } from 'types';

import { styles } from './styles';

export const PasswordReset: React.FC = () => {
  const navigation = useNavigation();
  const { web } = usePlatform();

  const [error, setError] = useState<IOperationError>({
    detected: false,
    errorMessage: '',
  });
  const [successMessage, setSuccessMessage] = useState<string>('');

  const handleOnSubmit = async (payload: { email: string }): Promise<void> => {
    try {
      await authResources.passwordReset(payload.email);
      setSuccessMessage('Correo de restablecimiento de contraseña enviado.');
    } catch (e: any) {
      if (!fp.isNil(e.code)) {
        setError({
          detected: true,
          errorMessage: authErrorHandler.passwordResetError(e.code),
        });
      } else if (!fp.isNil(e.message)) {
        setError({
          detected: true,
          errorMessage: authErrorHandler.passwordResetError(e.message),
        });
      }
    }
  };

  /**
   * Handler function for navigating to logIn screen when signUp process
   * finished.
   */
  const handleOnDiscardSuccess = (): void => {
    if (!fp.isEmpty(successMessage)) {
      navigation.navigate('logIn' as never);
    }
  };

  return (
    <ExternalUIWrapper
      error={error.detected}
      onDiscard={() =>
        error.detected
          ? setError({ detected: false, errorMessage: '' })
          : handleOnDiscardSuccess()
      }
      operationStatus={error.detected ? error.errorMessage : successMessage}
    >
      {!web ? (
        <VStack alignItems="center" flex={1} justifyContent="center">
          <BackButton />
          <Image
            alt="logo"
            h={styles.logo.height}
            mb={30}
            mt={10}
            resizeMode="contain"
            source={Logo}
          />
          <InputScroller
            bottomOffset
            contentContainerStyle={{
              alignItems: 'center',
            }}
          >
            <PasswordResetForm onSubmit={handleOnSubmit} />
          </InputScroller>
        </VStack>
      ) : (
        <Center flex={1} h="100%" w="100%">
          <BackButton />
          <img
            alt="logo"
            height="16%"
            src={Logo}
            style={{ marginBottom: 30, marginTop: 0 }}
          />
          <PasswordResetForm onSubmit={handleOnSubmit} />
        </Center>
      )}
    </ExternalUIWrapper>
  );
};
