import { differenceInHours } from 'date-fns';
import {
  IEventRegistry,
  IEventWithRegistries,
} from 'services/resources/events-registries/types';

/**
 * Function that counts how many events have been completed from the
 * given input.
 * @param data
 * @returns completed events
 */
export const getCompletedEventsAmount = (
  data: IEventWithRegistries[],
): number => {
  return data.filter((eWR) => {
    if (differenceInHours(new Date(eWR.event.date), new Date()) < -1) {
      return eWR;
    } else return null;
  }).length;
};

/**
 * Function that counts how many events remain to happen from the
 * given input.
 * @param data
 * @returns upcoming events
 */
export const getUpcomingEventsAmount = (
  data: IEventWithRegistries[],
): number => {
  return data.filter((eWR) => {
    if (differenceInHours(new Date(eWR.event.date), new Date()) >= 0) {
      return eWR;
    } else return null;
  }).length;
};

/**
 * Function that counts all registries from all events.
 * @param data
 * @returns registries of all events
 */
export const getTotalRegistriesAmount = (
  data: IEventWithRegistries[],
): number => {
  let totalRegistries = 0;
  data.forEach(({ registries }) => {
    totalRegistries += registries.length;
  });
  return totalRegistries;
};

/**
 * Function that counts confirmed assistance from all events.
 * @param data
 * @returns confirmed assistance of all events
 */
export const getTotalAssistanceAmount = (
  data: IEventWithRegistries[],
): number => {
  let totalAssistance = 0;
  data.forEach(({ event: { cost }, registries }) => {
    registries.forEach((registry: IEventRegistry) => {
      if (registry.assistedConfirmation) {
        totalAssistance += 1;
      }
    });
  });
  return totalAssistance;
};

/**
 * Function that counts how much profits has ET made from the given input.
 * @param data
 * @returns total profit of all events
 */
export const getETProfitsAmount = (data: IEventWithRegistries[]): number => {
  let fullProfit = 0;
  data.forEach(({ event: { cost }, registries }) => {
    registries.forEach((registry: IEventRegistry) => {
      if (cost !== 0 && registry.paid) {
        fullProfit += Number(cost);
      }
    });
  });
  return fullProfit;
};
