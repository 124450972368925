import React from 'react';

import { InputField } from '../InputField';
import { IInputFieldProps } from '../types.d';

export const EmailField: React.FC<IInputFieldProps> = (props) => {
  const {
    label = 'Correo Electrónico',
    error = '',
    onChangeText,
    ...rest
  } = props;
  return (
    <InputField
      error={error}
      label={label}
      onChangeText={onChangeText}
      {...rest}
    />
  );
};
