/* eslint-disable quotes */
export const liveTitle = 'Vive plenamente';

export const eTTitle: string =
  'Evolución Terapéutica es un lugar en el que de forma juguetona,\n asertiva y amorosa, aprenderás\n de ti';

export const hurtTitle: string = `'Somos imperfectos; no hay gente mala, hay gente herida'`;

export const genericContent: string =
  'Para nosotros lo más importante es que te sientas bienvenido, visto y aceptado. \n\nEste es ese lugar donde te encuentras a ti mismo, aunque a veces no sabes que te tenías perdido, y en ese lapso haces un lugar en tu corazón para esa nueva versión de ti, ya que en ella te das permiso de ser imperfecto y con limitaciones, y aprender a sentir que así eres digno de ser amado.';
