import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { usePlatform } from 'hooks/platform-hooks';
import { Image, IStackProps, Text, VStack } from 'native-base';
import { IEvent } from 'services/resources/events/types.d';

interface IEventProps extends IStackProps {
  /**
   * Event which the user wants to sign-up for.
   */
  selectedEvent: IEvent;
}

export const Event: React.FC<IEventProps> = (props) => {
  const { selectedEvent, ...rest } = props;
  const { web } = usePlatform();

  return (
    <VStack bg={selectedEvent.accentColor} {...rest}>
      <Text
        color="white"
        fontSize={20}
        fontWeight="bold"
        mb={6}
        textAlign="center"
      >
        {selectedEvent.title}
      </Text>
      <Image
        alt="cover"
        h={!web ? '150px' : '300px'}
        resizeMode="cover"
        src={selectedEvent.cover}
        w="100%"
      />
      <Text color="white" fontSize={16} my={6} textAlign="center">
        {selectedEvent.description}
      </Text>
      <Text color="white" fontSize={16} pb={6} textAlign="center">
        {`${format(new Date(selectedEvent.date), 'EEE dd MMM yyyy', {
          locale: es,
        })} \n a las ${format(new Date(selectedEvent.date), 'HH:mm')} hrs.`}
      </Text>
    </VStack>
  );
};

Event.defaultProps = {
  flex: 1,
  h: '100%',
  justifyContent: 'center',
};
