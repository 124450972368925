import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { compareDates } from 'helpers/time-helpers/comparison-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import { Button, Image, IStackProps, Text, VStack } from 'native-base';
import { useState } from 'react';
import { IEvent } from 'services/resources/events/types.d';
import { IEventRegistryPayload } from 'services/resources/events-registries/types.d';
import { colors } from 'styles/colors';

import { EventSignUpModal } from './EventSignUpModal';

interface IEventCardProps extends IStackProps {
  /**
   * Item to be inherited from the cloneElement function.
   */
  item?: any;
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IEventRegistryPayload) => void;
}

export const EventCard: React.FC<IEventCardProps> = (props) => {
  const { onSubmit, item, ...rest } = props;

  const { web } = usePlatform();
  const event = item as IEvent;

  const [eventSignUpFormVisible, setEventSignUpFormVisible] =
    useState<boolean>(false);

  return (
    <VStack bg={event.accentColor} {...rest}>
      <EventSignUpModal
        customFields={event.customFields}
        eventId={event.id}
        isOpen={eventSignUpFormVisible}
        onCloseModal={() => setEventSignUpFormVisible(false)}
        onSubmit={onSubmit}
      />
      <Text
        color="white"
        fontSize={20}
        fontWeight="bold"
        mb={6}
        textAlign="center"
      >
        {event.title}
      </Text>
      <Image
        alt="cover"
        h={!web ? '150px' : '300px'}
        resizeMode="cover"
        src={event.cover}
        w="100%"
      />
      <Text color="white" fontSize={16} my={6} textAlign="center">
        {event.description}
      </Text>
      <Text color="white" fontSize={16} pb={6} textAlign="center">
        {`${format(new Date(event.date), 'EEE dd MMM yyyy', {
          locale: es,
        })} \n a las ${format(new Date(event.date), 'HH:mm')} hrs.`}
      </Text>
      <Button
        bg={colors.success}
        borderRadius={0}
        isDisabled={!compareDates(event.date)}
        onPress={() => setEventSignUpFormVisible(true)}
        w="100%"
      >
        Suscribirse
      </Button>
    </VStack>
  );
};

EventCard.defaultProps = {
  flex: 1,
  h: '100%',
  justifyContent: 'center',
  pt: 3,
};
