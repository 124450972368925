import { readWelcome } from 'helpers/storage-helpers/welcome-helpers';
import fp from 'lodash/fp';
import { useEffect, useState } from 'react';

export const useWelcomeRead = (): boolean => {
  const [welcomeRead, setWelcomeRead] = useState(false);

  const checkWelcome = async (): Promise<boolean> => {
    let result: boolean = false;
    const read = await readWelcome();
    if (!fp.isEmpty(read) && read === 'true') result = true;
    return result;
  };

  useEffect(() => {
    (async () => {
      const r = await checkWelcome();
      if (r) {
        setWelcomeRead(true);
      } else {
        setWelcomeRead(false);
      }
    })();
  });

  return welcomeRead;
};
