import { integerOrDecimalNumberRegex } from 'helpers/data-helpers/regex-helpers';
import { IAppointmentFormPayload } from 'services/resources/appointments/types.d';
import * as Yup from 'yup';

export const AppointmentFormValidationSchema = Yup.object().shape({
  cost: Yup.string()
    .matches(integerOrDecimalNumberRegex, 'Debes ingresar un número válido.')
    .required('Este campo es requerido.'),
});

export const INITIAL_VALUES: IAppointmentFormPayload = {
  cancelled: false,
  confirmedByTherapist: false,
  confirmedByThird: false,
  cost: 0,
  date: new Date(),
  discount: 0,
  discountInPesos: 0,
};
