import {
  alphaSpaceRegex,
  emailRegex,
  RFCregex,
} from 'helpers/data-helpers/regex-helpers';
import { ITaxInfo } from 'services/resources/users/types';
import * as Yup from 'yup';

export const TaxInfoFormCopies = {
  RFC: 'RFC',
  legalName: 'Razón Social',
  taxRegime: 'Régimen Fiscal',
  CP: 'Código Postal',
  expenseType: 'Tipo de Gasto',
  email: 'Correo Electrónico',
};

export const TaxInfoFormValidationSchema = Yup.object().shape({
    RFC: Yup.string()
      .matches(RFCregex, 'Debes ingresar un RFC válido.')
      .required('Este campo es requerido.'),
    legalName: Yup.string()
      .matches(
        alphaSpaceRegex,
        'La razón social no puede contener caracteres especiales ni números.',
      )
      .required('Este campo es requerido.'),
    taxRegime: Yup.string()
      .matches(
        alphaSpaceRegex,
        'El régimen fiscal no puede contener caracteres especiales ni números.',
      )
      .required('Este campo es requerido.'),
    CP: Yup.string().required('Este campo es requerido'),
    expenseType: Yup.string()
      .oneOf(['medical', 'general'], 'Solo se admite "medical" o "general".'),
    email: Yup.string()
    .matches(emailRegex, 'Debes ingresar un correo válido.')
    .required('Este campo es requerido.'),
});

export const INITIAL_VALUES: ITaxInfo = {
    RFC: '',
    legalName: '',
    taxRegime: '',
    CP: '',
    expenseType: 'general',
    email: '',
};