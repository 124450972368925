import fp from 'lodash/fp';

export const removeNilFromArray = (array: any[]): any[] => {
  return array.filter((item) => {
    if (!fp.isNil(item)) {
      return item;
    } else {
      return null;
    }
  });
};
