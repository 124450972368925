import { Ionicons, SimpleLineIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { IconTextButton, UIWrapper } from 'components/elements';
import { usePlatform } from 'hooks/platform-hooks';
import { HStack, Text, VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { colors } from 'styles/colors';

export const PatientDashboard: React.FC = (): JSX.Element => {
  const themeData = useSelector(getTheme);
  const navigation = useNavigation();
  const { web } = usePlatform();

  const handleScreenChange = (screen: string): void => {
    navigation.navigate(screen as never);
  };

  return (
    <UIWrapper title="Paciente">
      <Text
        color={themeData.mainColorDark}
        flex={1}
        fontSize={22}
        fontWeight="bold"
        pt={3}
        textAlign="center"
      >
        Selecciona un dashboard:
      </Text>
      <VStack alignItems="center" flex={7} mx={4} my={3}>
        <HStack
          alignItems="center"
          flex={1}
          justifyContent="space-around"
          space={2}
          w={!web ? '100%' : '50%'}
        >
          <IconTextButton
            bg={colors.terappiaOrange.light}
            icon={
              <Ionicons
                color="white"
                name="people-outline"
                size={!web ? 70 : 100}
              />
            }
            label="Terapeutas"
            labelColor="white"
            maxH="35%"
            onPress={() => handleScreenChange('patientTherapists')}
            px={6}
            py={!web ? undefined : 10}
            w="45%"
          />
          <IconTextButton
            bg={colors.terappiaGreen.light}
            icon={
              <SimpleLineIcons
                color="white"
                name="calendar"
                size={!web ? 60 : 80}
              />
            }
            label="Eventos"
            labelColor="white"
            maxH="35%"
            onPress={() => handleScreenChange('events')}
            px={6}
            py={!web ? undefined : 10}
            space={3}
            w="45%"
          />
        </HStack>
      </VStack>
    </UIWrapper>
  );
};
