import { usePlatform } from 'hooks/platform-hooks';
import { IModalProps, Modal, Text } from 'native-base';

import { AcceptDecline } from '../AcceptDecline';
import { IAcceptDeclineProps } from '../types.d';

export type IAcceptDeclineModalProps = IModalProps & IAcceptDeclineProps;

export const AcceptDeclineModal: React.FC<IAcceptDeclineModalProps> = (
  props,
) => {
  const { onAccept, onDecline, invertColors, children, ...rest } = props;
  const { web } = usePlatform();
  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        bg="white"
        borderRadius={!web ? 0 : 10}
        justifyContent="center"
        position="absolute"
        py={6}
        shadow="none"
        w={!web ? '100%' : '30%'}
      >
        <Text mx={2} textAlign="center">
          {children}
        </Text>
        <AcceptDecline
          invertColors={invertColors}
          mt={6}
          onAccept={onAccept}
          onDecline={onDecline}
        />
      </Modal.Content>
    </Modal>
  );
};

AcceptDeclineModal.defaultProps = {
  size: 'full',
};
