import {
  AcceptDeclineModal,
  IAcceptDeclineModalProps,
} from 'components/elements';
import { format } from 'date-fns';
import { getUserFullName } from 'helpers/data-helpers/string-helpers';
import fp from 'lodash/fp';
import { Text } from 'native-base';
import { IAppointmentWithUser } from 'screens/types';

interface IConfirmDisclaimerProps extends IAcceptDeclineModalProps {
  /**
   * Appointment with user from we are retriving data from (state).
   */
  selectedAppointmentWithUser: IAppointmentWithUser;
}

export const ConfirmDisclaimer: React.FC<IConfirmDisclaimerProps> = (
  props,
): JSX.Element => {
  const { selectedAppointmentWithUser, ...rest } = props;
  return (
    <AcceptDeclineModal {...rest}>
      {!fp.isNil(selectedAppointmentWithUser.date) ? (
        <Text fontWeight="bold">
          {`¿Estás seguro de querer confirmar como completada la cita del día ${format(
            new Date(selectedAppointmentWithUser.date),
            'dd-MM-yyyy',
          )} a las ${format(
            new Date(selectedAppointmentWithUser.date),
            'HH:mm',
          )} hrs con ${getUserFullName(
            selectedAppointmentWithUser.user,
          )}? Esta acción no se puede deshacer.`}
        </Text>
      ) : null}
    </AcceptDeclineModal>
  );
};
