import { Entypo } from '@expo/vector-icons';
import { AcceptDecline, AcceptDeclineModal } from 'components/elements';
import { DashboardInputField, DateField } from 'components/inputs';
import { Formik } from 'formik';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import {
  Checkbox,
  HStack,
  IconButton,
  IModalProps,
  Select,
  Text,
  VStack,
} from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IAppointmentInfo } from 'services/resources/appointments-info/types';

import { AppointmentInfoFormValidationSchema } from './helpers/form-helpers';
import { getPlaceHolder } from './helpers/string-helpers';

interface IAppointmentInfoProps extends IModalProps {
  /**
   * Values that are already set, used for editing them.
   */
  previousValues: IAppointmentInfo;
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IAppointmentInfo) => void;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
}

export const AppointmentInfoForm: React.FC<IAppointmentInfoProps> = (props) => {
  const { onSubmit, onCancel, previousValues, ...rest } = props;

  const { web } = usePlatform();
  const themeData = useSelector(getTheme);

  const [disclaimerModalOpen, setDisclaimerModalOpen] =
    useState<boolean>(false);
  const [editCommission, setEditCommission] = useState<boolean>(false);
  const [acquisitionType, setAcquisitionType] = useState<string>(
    !fp.isNil(previousValues.acquisitionType)
      ? previousValues.acquisitionType
      : '',
  );
  const [invoicePeriod, setInvoicePeriod] = useState<string>(
    !fp.isNil(previousValues.invoicePeriod) ? previousValues.invoicePeriod : '',
  );
  const [invoiceRequest, setInvoiceRequest] = useState<boolean>(
    !fp.isNil(previousValues.invoiceRequest)
      ? previousValues.invoiceRequest
      : false,
  );
  const [paidStatus, setPaidStatus] = useState<string>(
    !fp.isNil(previousValues.paidStatus) ? previousValues.paidStatus : '',
  );
  const [paymentDate, setPaymentDate] = useState<Date>(
    !fp.isNil(previousValues.paymentDate)
      ? new Date(previousValues.paymentDate)
      : new Date(),
  );
  const [paymentMethod, setPaymentMethod] = useState<string>(
    !fp.isNil(previousValues.paymentMethod) ? previousValues.paymentMethod : '',
  );

  const noBankDataRequired =
    paymentMethod === '' ||
    paymentMethod === 'cash' ||
    previousValues.paymentMethod === 'cash';
  const isInvoiceRequested = invoiceRequest || previousValues.invoiceRequest;

  return (
    <Formik
      initialValues={previousValues}
      onSubmit={onSubmit}
      validationSchema={AppointmentInfoFormValidationSchema}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isValid,
        setFieldValue,
        values,
      }) => (
        <>
          <IconButton
            alignSelf="flex-end"
            icon={
              <Entypo color={themeData.mainColorDark} name="cross" size={28} />
            }
            mr={3}
            onPress={() => {
              onCancel();
              setAcquisitionType('');
              setEditCommission(false);
              setInvoicePeriod('');
              setInvoiceRequest(false);
              setPaidStatus('');
              setPaymentMethod('');
            }}
          />
          <VStack alignItems="center" {...rest}>
            <Text
              color={themeData.mainColorDark}
              fontSize={24}
              fontWeight="bold"
              pb={3}
              textAlign="center"
            >
              Información Adicional
            </Text>
            <VStack w={!web ? '90%' : '92%'}>
              <Text fontSize={16} fontWeight="bold" mb={1} textAlign="center">
                Tipo de Adquisición:
              </Text>
              <Select
                mb={5}
                mx={4}
                onValueChange={(itemValue) => {
                  setAcquisitionType(itemValue);
                }}
                placeholder={
                  !fp.isNil(previousValues.acquisitionType)
                    ? getPlaceHolder(previousValues.acquisitionType)
                    : 'Selecciona un tipo de adquisición:'
                }
              >
                <Select.Item label="Paquete" value="package" />
                <Select.Item label="Promoción" value="promo" />
                <Select.Item
                  label="Intercambio de servicios"
                  value="serviceExchange"
                />
                <Select.Item label="Donación" value="donation" />
              </Select>
            </VStack>
            <VStack w="90%">
              <Text fontSize={16} fontWeight="bold" mb={1} textAlign="center">
                Costo (ya incluye descuento aplicado):
              </Text>
              <DashboardInputField
                alignSelf="center"
                defaultValue={`${previousValues.cost}`}
                error={errors.cost}
                onChangeText={handleChange('cost')}
              />
            </VStack>
            <VStack mb={5} w="90%">
              <Text fontSize={16} fontWeight="bold" mb={1} textAlign="center">
                Porcentaje de Comisión:
              </Text>
              <DashboardInputField
                alignSelf="center"
                defaultValue={`${previousValues.commissionPercentage}`}
                error={errors.commissionPercentage}
                isDisabled={!editCommission}
                onChangeText={handleChange('commissionPercentage')}
              />
              <Checkbox
                alignSelf="center"
                isChecked={editCommission}
                mt={-3}
                onChange={() => setEditCommission(!editCommission)}
                value="editCommissionCheckbox"
              >
                <Text fontSize={14} mt={3} textAlign="center">
                  {
                    'Acepto modificar el porcentaje \n de comisión del terapeuta.'
                  }
                </Text>
              </Checkbox>
            </VStack>
            <VStack w="90%">
              <Text fontSize={16} fontWeight="bold" mb={1} textAlign="center">
                Pago Recibido:
              </Text>
              <DashboardInputField
                alignSelf="center"
                defaultValue={`${
                  !fp.isNil(previousValues.receivedPayment)
                    ? previousValues.receivedPayment
                    : 0
                }`}
                error={errors.receivedPayment}
                onChangeText={handleChange('receivedPayment')}
              />
            </VStack>
            <VStack w={!web ? '90%' : '92%'}>
              <Text fontSize={16} fontWeight="bold" mb={1} textAlign="center">
                Estatus de Pago:
              </Text>
              <Select
                mb={5}
                mx={4}
                onValueChange={(itemValue) => {
                  setPaidStatus(itemValue);
                }}
                placeholder={
                  !fp.isNil(previousValues.paidStatus)
                    ? previousValues.paidStatus === 'paid'
                      ? 'Pagado'
                      : 'Pendiente'
                    : 'Selecciona un estatus:'
                }
              >
                <Select.Item label="Pagado" value="paid" />
                <Select.Item label="Pendiente" value="pending" />
              </Select>
            </VStack>
            <Text fontSize={16} fontWeight="bold" mb={1} textAlign="center">
              Fecha de Pago:
            </Text>
            <HStack mb={5} w={!web ? '82%' : '87%'}>
              <DateField
                buttonHeight="40px"
                buttonLabel="Seleccionar Fecha:"
                dateValue={paymentDate}
                disableButton={false}
                onDateChange={(d) => {
                  setPaymentDate(d);
                }}
                w="50%"
              />
            </HStack>
            <VStack w={!web ? '90%' : '92%'}>
              <Text fontSize={16} fontWeight="bold" mb={1} textAlign="center">
                Método de Pago:
              </Text>
              <Select
                mb={5}
                mx={4}
                onValueChange={(itemValue) => {
                  setPaymentMethod(itemValue);
                }}
                placeholder={
                  !fp.isNil(previousValues.paymentMethod)
                    ? getPlaceHolder(previousValues.paymentMethod)
                    : 'Selecciona un método:'
                }
              >
                <Select.Item label="Efectivo" value="cash" />
                <Select.Item label="Tarjeta" value="card" />
                <Select.Item label="Transferencia" value="transfer" />
                <Select.Item label="Cheque" value="check" />
              </Select>
            </VStack>
            {!noBankDataRequired ? (
              <VStack w="90%">
                <Text fontSize={16} fontWeight="bold" mb={1} textAlign="center">
                  Nombre del Banco:
                </Text>
                <DashboardInputField
                  alignSelf="center"
                  defaultValue={previousValues.bankName}
                  error={errors.bankName}
                  onChangeText={handleChange('bankName')}
                />
              </VStack>
            ) : null}
            <VStack w={!web ? '90%' : '92%'}>
              <Text fontSize={16} fontWeight="bold" mb={1} textAlign="center">
                Solicitud de Factura
              </Text>
              <Select
                mb={5}
                mx={4}
                onValueChange={(itemValue) => {
                  if (itemValue === 'yes') {
                    setInvoiceRequest(true);
                  } else setInvoiceRequest(false);
                }}
                placeholder={previousValues.invoiceRequest ? 'Sí' : 'No'}
              >
                <Select.Item label="Sí" value="yes" />
                <Select.Item label="No" value="no" />
              </Select>
            </VStack>
            {isInvoiceRequested ? (
              <>
                <VStack w="90%">
                  <Text
                    fontSize={16}
                    fontWeight="bold"
                    mb={1}
                    textAlign="center"
                  >
                    Concepto de Factura:
                  </Text>
                  <DashboardInputField
                    alignSelf="center"
                    defaultValue={previousValues.invoiceConcept}
                    error={errors.invoiceConcept}
                    onChangeText={handleChange('invoiceConcept')}
                  />
                </VStack>
                <VStack w={!web ? '90%' : '92%'}>
                  <Text
                    fontSize={16}
                    fontWeight="bold"
                    mb={1}
                    textAlign="center"
                  >
                    Periodo Facturado:
                  </Text>
                  <Select
                    mb={5}
                    mx={4}
                    onValueChange={(itemValue) => {
                      setInvoicePeriod(itemValue);
                    }}
                    placeholder={
                      !fp.isNil(previousValues.invoicePeriod)
                        ? previousValues.invoicePeriod === 'month'
                          ? 'Mes'
                          : 'Cita individual'
                        : 'Selecciona un estatus:'
                    }
                  >
                    <Select.Item label="Mes" value="month" />
                    <Select.Item label="Cita Individual" value="appointment" />
                  </Select>
                </VStack>
              </>
            ) : null}
          </VStack>
          <AcceptDecline
            alignSelf="center"
            disabled={!isValid}
            my={4}
            onAccept={() => setDisclaimerModalOpen(true)}
            onDecline={() => {
              onCancel();
              setAcquisitionType('');
              setEditCommission(false);
              setInvoicePeriod('');
              setInvoiceRequest(false);
              setPaidStatus('');
              setPaymentDate(new Date());
              setPaymentMethod('');
            }}
          />
          <AcceptDeclineModal
            isOpen={disclaimerModalOpen}
            onAccept={() => {
              setFieldValue('acquisitionType', acquisitionType);
              setFieldValue('invoicePeriod', invoicePeriod);
              setFieldValue('invoiceRequest', invoiceRequest);
              setFieldValue('paidStatus', paidStatus);
              setFieldValue('paymentDate', paymentDate);
              setFieldValue('paymentMethod', paymentMethod);
              setFieldValue('cost', Number(values.cost));
              setFieldValue('receivedPayment', Number(values.receivedPayment));
              handleSubmit();
              setDisclaimerModalOpen(false);
            }}
            onDecline={() => setDisclaimerModalOpen(false)}
          >
            ¿Estás seguro de querer continuar? Confirma que los datos sean
            correctos.
          </AcceptDeclineModal>
        </>
      )}
    </Formik>
  );
};

AppointmentInfoForm.defaultProps = {
  justifyContent: 'center',
  size: 'full',
};
