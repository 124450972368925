import { Ionicons } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import {
  AcceptDeclineModal,
  ExtendedFlatlist,
  LoadingStatusModal,
  UIWrapper,
} from 'components/elements';
import fp from 'lodash/fp';
import { Button, Text, VStack } from 'native-base';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux-service/slices';
import { glossaryResources } from 'services/resources/glossary';
import {
  IGlossaryEntry,
  IGlossaryPayload,
} from 'services/resources/glossary/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { colors } from 'styles/colors';
import { ILoadingData } from 'types';

import { EntryModal } from './components/EntryModal';
import { GlossaryEntryItem } from './components/GlossaryEntryItem';

export const Glossary: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [entryModalOpen, setEntryModalOpen] = useState<boolean>(false);
  const [disclaimerModalOpen, setDisclaimerModalOpen] =
    useState<boolean>(false);
  const [glossaryEntries, setGlossaryEntries] = useState<IGlossaryEntry[]>([]);
  const [selectedEntry, setSelectedEntry] = useState<IGlossaryEntry>(
    {} as IGlossaryEntry,
  );

  const verifiedTherapist =
    !fp.isNil(userData.verifiedTherapist) && userData.verifiedTherapist;

  const toggleDisclaimerModalOpen = (): void => {
    setDisclaimerModalOpen(!disclaimerModalOpen);
  };

  const toggleEntryModalOpen = (): void => {
    setEntryModalOpen(!entryModalOpen);
  };

  /**
   * Function for retrieving all the terms that are currently stored in the
   * database.
   */
  const retrieveGlossaryTerms = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Términos...',
    });
    try {
      const { data: d } = await glossaryResources.get(userData.token);
      const data = d as IGlossaryEntry[];
      if (!fp.isEmpty(data)) {
        setGlossaryEntries(data);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'glossary',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function for deleting a given term.
   */
  const handleOnDeleteTerm = async (): Promise<void> => {
    toggleDisclaimerModalOpen();
    setLoadingData({
      loading: true,
      loadingMessage: 'Eliminando Término...',
    });
    try {
      await glossaryResources.delete(selectedEntry.id, userData.token);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'glossary',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    setSelectedEntry({} as IGlossaryEntry);
    retrieveGlossaryTerms();
  };

  /**
   * Function for updating an existing term.
   * @param entry
   */
  const handleOnUpdateTerm = async (entry: IGlossaryEntry): Promise<void> => {
    toggleEntryModalOpen();
    setLoadingData({
      loading: true,
      loadingMessage: 'Actualizando Término...',
    });
    try {
      await glossaryResources.putAll(entry, entry.id, userData.token);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'glossary',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    setSelectedEntry({} as IGlossaryEntry);
    retrieveGlossaryTerms();
  };

  /**
   * Function for creating a new term.
   * @param entry
   */
  const handleOnCreateTerm = async (entry: IGlossaryPayload): Promise<void> => {
    toggleEntryModalOpen();
    setLoadingData({
      loading: true,
      loadingMessage: 'Creando Término...',
    });
    try {
      await glossaryResources.create(entry, userData.token);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'glossary',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    retrieveGlossaryTerms();
  };

  useFocusEffect(
    useCallback(() => {
      retrieveGlossaryTerms();
    }, []),
  );

  return (
    <UIWrapper title="Glosario">
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      {userData.accountType === 'therapist' ? (
        <EntryModal
          isOpen={entryModalOpen}
          onCancel={() => {
            setSelectedEntry({} as IGlossaryEntry);
            toggleEntryModalOpen();
          }}
          onSubmit={(term) =>
            fp.isEmpty(selectedEntry)
              ? handleOnCreateTerm(term)
              : handleOnUpdateTerm(term as IGlossaryEntry)
          }
          previousValues={fp.isEmpty(selectedEntry) ? undefined : selectedEntry}
        />
      ) : null}
      <AcceptDeclineModal
        invertColors
        isOpen={disclaimerModalOpen}
        onAccept={handleOnDeleteTerm}
        onDecline={() => {
          setSelectedEntry({} as IGlossaryEntry);
          toggleDisclaimerModalOpen();
        }}
      >
        <Text fontWeight="bold">
          {`¿Estás seguro de querer eliminar el término ${selectedEntry.term}? Esta acción no se puede deshacer.`}
        </Text>
      </AcceptDeclineModal>
      <VStack bg="white" flex={17}>
        <ExtendedFlatlist
          data={glossaryEntries}
          noDataMessage="No se encontraron términos de glosario."
          renderItem={
            <GlossaryEntryItem
              onDelete={(term) => {
                setSelectedEntry(term);
                toggleDisclaimerModalOpen();
              }}
              onEdit={(term) => {
                setSelectedEntry(term);
                toggleEntryModalOpen();
              }}
            />
          }
          searchBarPlaceholder="término"
          searchKey={['term', 'description']}
          sort
          sortKey="term"
          useSearchBar
        />
      </VStack>
      {userData.accountType === 'therapist' && verifiedTherapist ? (
        <Button
          _hover={{ bg: colors.success, opacity: 0.8 }}
          _pressed={{ bg: colors.success, opacity: 0.8 }}
          _text={{ fontWeight: 'bold' }}
          bg={colors.success}
          borderRadius={0}
          flex={1}
          leftIcon={<Ionicons color="white" name="add" size={24} />}
          onPress={toggleEntryModalOpen}
          w="100%"
        >
          Agregar Término
        </Button>
      ) : null}
    </UIWrapper>
  );
};
