import { numberRegex } from 'helpers/data-helpers/regex-helpers';
import * as Yup from 'yup';

export const AppointmentInfoFormCopies = {
  acquisitionType: 'Tipo de Adquisición',
  bankName: 'Nombre del Banco',
  commissionPercentage: 'Porcentaje de Comisión',
  cost: 'Costo',
  invoiceConcept: 'Concepto de la Factura',
  invoicePeriod: 'Periodo Facturado',
  invoiceRequest: 'Solicitó Factura',
  paidStatus: 'Estatus de Pago',
  paymentDate: 'Fecha de Pago',
  paymentMethod: 'Método de Pago',
};

export const AppointmentInfoFormValidationSchema = Yup.object().shape({
  acquisitionType: Yup.string().oneOf([
    'donation',
    'package',
    'promo',
    'serviceExchange',
  ]),
  commissionPercentage: Yup.string()
    .matches(numberRegex, 'Debes ingresar un número válido.')
    .required('Este campo es requerido.'),
  cost: Yup.string()
    .matches(numberRegex, 'Debes ingresar un número válido.')
    .required('Este campo es requerido.'),
  invoicePeriod: Yup.string().oneOf(['appointment', 'month']),
  paidStatus: Yup.string().oneOf(['paid', 'pending']),
  paymentMethod: Yup.string().oneOf(['card', 'cash', 'check', 'transfer']),
  receivedPayment: Yup.string().matches(
    numberRegex,
    'Debes ingresar un número válido.',
  ),
});
