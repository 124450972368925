import {
  DEV_ROUTE_LOCAL,
  DEV_URL_BASE,
  ENVIRONMENT,
  PROD_ROUTE_LOCAL,
  PROD_URL_BASE,
} from '@env';
import axios from 'axios';
import { getHostDeviceIP } from 'helpers/network-helpers/ip-helpers';

const URI = `http://${getHostDeviceIP()}${
  ENVIRONMENT === 'PROD' ? PROD_ROUTE_LOCAL : DEV_ROUTE_LOCAL
}`;

export const instance = axios.create({
  baseURL: ENVIRONMENT === 'DEV' ? DEV_URL_BASE : PROD_URL_BASE,
});

export const tmpinstance = axios.create({
  baseURL: URI,
});
