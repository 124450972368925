/* eslint-disable prefer-const */
import fp from 'lodash/fp';
import {
  IPasswordLessSignUpPayload,
  IUser,
} from 'services/resources/users/types.d';
import urlRegexSafe from 'url-regex-safe';

/**
 * Function that returns the label to be placed in a given place by its field
 * name.
 * @param field
 * @param text
 * @returns labelText
 */
export const getLabelByField = (field: string, text?: string): string => {
  if (field === 'accountType') {
    if (text === 'regular') {
      return 'Usuario Regular';
    } else if (text === 'therapist') {
      return 'Terapeuta';
    } else if (text === 'patient') {
      return 'Paciente';
    } else {
      return 'Indefinido';
    }
  } else if (field === 'administrator') {
    return 'Administrador';
  } else if (field === 'secretary') {
    return 'Secretario(a)';
  } else if (field === 'financialAdmin') {
    return 'Financiero';
  } else {
    return 'Indefinido';
  }
};

/**
 * Function for converting all names to PascalCase, even if they are compound
 * names.
 * @param payload
 * @returns loweredCaseUser
 */
export const lowerCasePayload = (
  payload: IPasswordLessSignUpPayload,
): IPasswordLessSignUpPayload => {
  // Deconstruct properties
  const { fatherName, motherName = '', firstName } = payload;

  // Create the output variables
  let lFaN = '';
  let lMN = '';
  let lFiN = '';

  // Create intermediate variables
  let faNF = '';
  let mNF = '';
  let fiNF = '';

  // In case the user has compound names, split the strings
  const cFaN = fatherName.split(' ');
  const cMN = motherName.split(' ');
  const cFiN = firstName.split(' ');

  // Perform operations for father name
  if (cFaN.length > 0) {
    const outputArray: string[] = [];
    cFaN.forEach((word) => {
      const wF = word[0].toUpperCase();
      let lW = word.toLowerCase();
      let splittedString: string[] = lW.split('');
      splittedString[0] = wF;
      lW = splittedString.join('');
      outputArray.push(lW);
    });
    lFaN = outputArray.join(' ');
  } else {
    // Get first letters and convert them to upper case
    faNF = fatherName[0].toUpperCase();
    // Read rest of the string and replace every first by the upper case
    lFaN = fatherName.toLowerCase();
    let splittedString: string[] = lFaN.split('');
    splittedString[0] = faNF;
    lFaN = splittedString.join('');
  }

  // Perform operations for mother name
  if (motherName.length > 0) {
    if (cMN.length > 0) {
      const outputArray: string[] = [];
      cMN.forEach((word) => {
        const wF = word[0].toUpperCase();
        let lW = word.toLowerCase();
        let splittedString: string[] = lW.split('');
        splittedString[0] = wF;
        lW = splittedString.join('');
        outputArray.push(lW);
      });
      lMN = outputArray.join(' ');
    } else {
      // Get first letters and convert them to upper case
      mNF = motherName[0].toUpperCase();
      // Read rest of the string and replace every first by the upper case
      lMN = motherName.toLowerCase();
      let splittedString: string[] = lMN.split('');
      splittedString[0] = mNF;
      lMN = splittedString.join('');
    }
  }

  // Perform operations for first name
  if (cFiN.length > 0) {
    const outputArray: string[] = [];
    cFiN.forEach((word) => {
      const wF = word[0].toUpperCase();
      let lW = word.toLowerCase();
      let splittedString: string[] = lW.split('');
      splittedString[0] = wF;
      lW = splittedString.join('');
      outputArray.push(lW);
    });
    lFiN = outputArray.join(' ');
  } else {
    // Get first letters and convert them to upper case
    fiNF = firstName[0].toUpperCase();
    // Read rest of the string and replace every first by the upper case
    lFiN = firstName.toLowerCase();
    let splittedString: string[] = lFiN.split('');
    splittedString[0] = fiNF;
    lFiN = splittedString.join('');
  }

  // Build the output
  const output = {
    ...payload,
    fatherName: lFaN,
    firstName: lFiN,
    motherName: lMN,
  };
  return output;
};

/**
 * Function that takes a firebase storage file URL and returns the filename.
 * @param fileURL
 * @returns file name with extension
 */
export const getStorageFileName = (fileURL: string): string => {
  let fileName = '';
  let fileExtension = '.jpg';
  let fileExtensionIndex = fileURL.indexOf(fileExtension);
  if (fileExtensionIndex < 0) {
    fileExtension = '.png';
    fileExtensionIndex = fileURL.indexOf(fileExtension);
  }
  fileName = `${fileURL.slice(
    fileExtensionIndex - 36,
    fileExtensionIndex,
  )}${fileExtension}`;
  return fileName;
};

/**
 * Function that extracts the first two initials from the user's names.
 * @param user
 * @returns first two initials.
 */
export const getUserInitials = (user: IUser): string => {
  const { firstName, fatherName } = user;
  const firstInital = firstName[0];
  const secondInitial = fatherName[0];
  return `${firstInital}${secondInitial}`;
};

/**
 * Function that returns all user's names as a single string.
 * @param user
 * @returns user's full name.
 */
export const getUserFullName = (user: IUser): string => {
  const { firstName, fatherName, motherName = '' } = user;
  return `${firstName} ${fatherName} ${motherName}`;
};

/**
 * Function that takes a string that contains urls separeted by comas and
 * return an array of strings with each url.
 * @param text
 * @returns
 */
export const getLinksArrayFromText = (text: string): string[] => {
  let outputArray: string[] = [];
  if (text.length > 0) {
    const splittedText = text.split(',');
    outputArray = splittedText.map((item) => {
      return item.replace(/ /gi, '');
    });
  }
  return outputArray;
};

/**
 * Function that takes a string that may contain URLs inside of it and returns
 * an array of strings containing those URLs.
 * @param text
 * @returns array of urls
 */
export const getURLsFromText = (text: string): string[] => {
  let output: string[] = [];
  const matches = text.match(urlRegexSafe());
  matches?.forEach((match) => {
    output.push(match);
  });
  return output;
};

/**
 * Function that creates a string for being used in an input from an array
 * of strings.
 * @param links
 * @returns
 */
export const stringifyLinks = (links: string[]): string => {
  if (!fp.isEmpty(links) && fp.isArray(links)) {
    return links.join(', ');
  }
  return '';
};
