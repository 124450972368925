import fp from 'lodash/fp';
import { Flex } from 'native-base';
import { useEffect, useState } from 'react';
import { ImageBackground } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { StatusSheet } from '../StatusSheet';
import { IUIWrapperProps } from '../types.d';
import { Footer } from './components/Footer';
import { Navbar } from './components/Navbar';

import { styles } from './styles';

interface UIWrapperProps extends IUIWrapperProps {
  /**
   * Header to be used in the UI Wrapper.
   */
  title?: string;
  /**
   * Flag to indicate weather to hide all those UI elements not related to
   * layout itself (useful for anonymous log-in).
   */
  hideUIAddOns?: boolean;
}

export const UIWrapper: React.FC<UIWrapperProps> = (props): JSX.Element => {
  const {
    hideUIAddOns = false,
    error = false,
    children,
    operationStatus = '',
    onDiscard = () => {},
    title = '',
    rNOpacity,
    ...rest
  } = props;
  const themeData = useSelector(getTheme);

  const [statusOpen, setStatusOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!fp.isEmpty(operationStatus)) setStatusOpen(true);
  }, [error, operationStatus]);

  return (
    <Flex bg={themeData.mainColorLight} {...rest}>
      <ImageBackground
        source={themeData.wallpaperSrc}
        style={[styles.backgroundImage, { opacity: rNOpacity }]}
      />
      <Flex flex={1} h="100%" position="absolute" w="100%">
        <Navbar
          flex={1}
          hideUIAddOns={hideUIAddOns}
          justifyContent="space-around"
          title={title}
        />
        <Flex flex={6}>{children}</Flex>
        <Footer
          bg={themeData.mainColorDark}
          flex={1}
          hideUIAddOns={hideUIAddOns}
          justifyContent="center"
        />
      </Flex>
      <StatusSheet
        error={error}
        isOpen={statusOpen}
        onClose={() => {
          setStatusOpen(false);
          onDiscard();
        }}
      >
        {operationStatus}
      </StatusSheet>
    </Flex>
  );
};

UIWrapper.defaultProps = {
  flex: 1,
  rNOpacity: 0.4,
};
