import { Ionicons } from '@expo/vector-icons';
import DateTimePicker from '@react-native-community/datetimepicker';
import { format, set } from 'date-fns';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { Box, IBoxProps, Modal, Pressable, Text } from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { fontSizes } from 'styles/theme';

import { AcceptDecline } from '../../elements/AcceptDecline';

interface ITimeFieldProps extends IBoxProps {
  /**
   * Handler function triggered when date selected.
   */
  onTimeChange: (date: Date) => void;
  /**
   * Current value of the date.
   */
  timeValue?: Date;
  /**
   * Height to be set in the show/hide button.
   */
  buttonHeight?: string;
  /**
   * Label to be shown inside of the button.
   */
  buttonLabel: string;
  /**
   * Color to be used within the button.
   */
  buttonColor?: string;
}

export const TimeField: React.FC<ITimeFieldProps> = (props) => {
  const {
    onTimeChange,
    timeValue = undefined,
    buttonHeight = undefined,
    buttonColor = '',
    buttonLabel,
    ...rest
  } = props;

  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const [show, setShow] = useState<boolean>(false);
  const [time, setTime] = useState<Date>(new Date());

  const toggleShow = (): void => {
    setShow(!show);
  };

  /**
   * Function for handling the time change when OS is web, since the
   * implementation os way different from mobile OSs.
   * @param t
   */
  const handleWebChange = (t: string): void => {
    const hours = t.substring(0, 2);
    const minutes = t.substring(3, 5);
    const fullDate = set(fp.isNil(timeValue) ? time : timeValue, {
      hours: Number(hours),
      minutes: Number(minutes),
    });
    setTime(fullDate);
    onTimeChange(fullDate);
  };

  return (
    <Box {...rest}>
      <Pressable
        alignItems="center"
        bg={!fp.isEmpty(buttonColor) ? buttonColor : themeData.mainColorDark}
        borderRadius={5}
        flex={1}
        flexDir="row"
        h={!fp.isNil(buttonHeight) ? buttonHeight : '100%'}
        justifyContent="center"
        onPress={toggleShow}
      >
        <Ionicons color="white" name="time-outline" size={24} />
        <Text color="white" pl={2}>{`${buttonLabel} (${format(
          fp.isNil(timeValue) ? time : timeValue,
          'HH:mm',
        )})`}</Text>
      </Pressable>
      {!web ? (
        show ? (
          <DateTimePicker
            mode="time"
            onChange={(e, t) => {
              if (!fp.isNil(t)) {
                setShow(false);
                setTime(t);
                onTimeChange(t);
              }
            }}
            testID="dateTimePicker"
            value={fp.isNil(timeValue) ? time : timeValue}
          />
        ) : null
      ) : (
        <Modal isOpen={show}>
          <Modal.Content
            alignItems="center"
            justifyContent="center"
            p={3}
            w="60%"
          >
            <input
              id="timepicker"
              name="timepicker"
              onChange={(e) => handleWebChange(e.target.value)}
              style={{
                borderRadius: 10,
                fontSize: fontSizes.bigger,
                height: '50px',
                width: '95%',
              }}
              type="time"
              value={
                fp.isNil(timeValue)
                  ? format(time, 'HH:mm')
                  : format(timeValue, 'HH:mm')
              }
            />
            <AcceptDecline
              onAccept={() => setShow(false)}
              onDecline={() => setShow(false)}
              py={2}
            />
          </Modal.Content>
        </Modal>
      )}
    </Box>
  );
};

TimeField.defaultProps = {
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDir: 'row',
  w: '100%',
};
