import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import {
  Flex,
  HStack,
  IconButton,
  IFlexProps,
  Text,
  VStack,
} from 'native-base';

interface IFooterProps extends IFlexProps {
  /**
   * Title to be render below the icon.
   */
  title?: string;
  /**
   * Flag to indicate weather to hide all those UI elements not related to
   * layout itself (useful for anonymous log-in).
   */
  hideUIAddOns?: boolean;
}

export const Footer: React.FC<IFooterProps> = (props): JSX.Element => {
  const { title, hideUIAddOns = false, ...rest } = props;
  const navigation = useNavigation();

  return (
    <Flex {...rest}>
      {!hideUIAddOns ? (
        <HStack justifyContent="space-around">
          <VStack>
            <IconButton
              bg="transparent"
              icon={<Entypo color="white" name="home" size={36} />}
              onPress={() => navigation.navigate('home' as never)}
            />
            <Text color="white" textAlign="center">
              Inicio
            </Text>
          </VStack>
        </HStack>
      ) : null}
    </Flex>
  );
};
