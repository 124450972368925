import { Feather } from '@expo/vector-icons';
import { useSmallScreen } from 'hooks/dimensions-hooks';
import { usePlatform } from 'hooks/platform-hooks';
import { Flex, IFlexProps, Modal, Text } from 'native-base';
import { useState } from 'react';
import { fontSizes } from 'styles/theme';

interface IMenuDescriptorProps extends IFlexProps {
  /**
   * Color to be applied to the tootip text.
   */
  tooltipTextColor?: string;
  /**
   * Info to be displayed in the tootip.
   */
  children: string;
}

export const MenuDescriptor: React.FC<IMenuDescriptorProps> = (props) => {
  const { tooltipTextColor = 'black', children, ...rest } = props;
  const small = useSmallScreen();
  const { web } = usePlatform();

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

  return (
    <Flex {...rest}>
      <Feather
        color="white"
        name="info"
        onPress={() => setTooltipVisible(true)}
        size={!small ? 24 : 16}
      />
      {web ? (
        <Text
          color="white"
          fontSize={fontSizes.small}
          mt={1}
          px={1}
          textAlign="center"
        >
          Presiona el ícono para más detalles.
        </Text>
      ) : null}
      <Modal
        closeOnOverlayClick
        isOpen={tooltipVisible}
        onClose={() => setTooltipVisible(false)}
      >
        <Modal.Content bg="white">
          <Text
            color={tooltipTextColor}
            fontSize={!small ? fontSizes.regular : fontSizes.small}
            p={2}
            textAlign="center"
          >
            {children}
          </Text>
        </Modal.Content>
      </Modal>
    </Flex>
  );
};

MenuDescriptor.defaultProps = {
  alignItems: 'center',
  borderRadius: 10,
  flex: 1,
  justifyContent: 'center',
};
