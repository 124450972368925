import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { getUser } from 'redux-service/slices';
import {
  AccountSettings,
  AdministratorDashboard,
  AdministratorRequests,
  AppointmentManagement,
  AppointmentsStatistics,
  Blog,
  Calendar,
  Commissions,
  ContactRequests,
  Events,
  EventSignUp,
  EventsRegistries,
  EventsStatistics,
  FinancialAdminDashboard,
  Glossary,
  Home,
  LogIn,
  PasswordReset,
  PatientDashboard,
  PatientTherapists,
  RegularDashboard,
  SecretaryDashboard,
  SignUp,
  StatisticsDashboard,
  Tasks,
  TherapistDashboard,
  TherapistFinancial,
  TherapistPatients,
  Welcome,
} from 'screens';

import { navigationRef } from './root-navigation';

const prefix = Linking.createURL('/');

const Stack = createNativeStackNavigator();

export const App: React.FC = () => {
  const { logged, foreignVisit } = useSelector(getUser);

  const fV = foreignVisit as boolean;

  // This is needed for deep linking
  const config = {
    screens: {
      eventSignUp: 'eventSignUp/:event',
    },
  };

  const linking = {
    config,
    prefixes: [prefix],
  };

  // TODO: correct typing issues related to ReactNavigation
  return (
    <NavigationContainer linking={linking} ref={navigationRef}>
      <Stack.Navigator
        initialRouteName={Platform.OS !== 'web' ? 'welcome' : 'logIn'}
        screenOptions={{
          animation: 'none',
          headerShown: false,
        }}
      >
        <Stack.Screen component={LogIn} name="logIn" />
        <Stack.Group navigationKey={logged && !fV ? 'true' : 'false'}>
          <Stack.Screen component={Home} name="home" />
          <Stack.Screen component={SignUp} name="signUp" />
          <Stack.Screen component={PasswordReset} name="passwordReset" />
          <Stack.Screen component={Welcome} name="welcome" />
          <Stack.Screen component={AccountSettings} name="account" />
          <Stack.Screen component={TherapistDashboard} name="therapist" />
          <Stack.Screen
            component={TherapistPatients}
            name="therapistPatients"
          />
          <Stack.Screen component={PatientDashboard} name="patient" />
          <Stack.Screen
            component={PatientTherapists}
            name="patientTherapists"
          />
          <Stack.Screen
            component={AdministratorDashboard}
            name="administrator"
          />
          <Stack.Screen
            component={AdministratorRequests}
            name="administratorRequests"
          />
          <Stack.Screen component={Glossary} name="glossary" />
          <Stack.Screen component={Blog} name="blog" />
          <Stack.Screen component={Tasks} name="tasks" />
          <Stack.Screen component={Calendar} name="calendar" />
          <Stack.Screen component={Events} name="events" />
          <Stack.Screen component={EventSignUp} name="eventSignUp" />
          <Stack.Screen component={EventsRegistries} name="eventsRegistries" />
          <Stack.Screen component={SecretaryDashboard} name="secretary" />
          <Stack.Screen
            component={FinancialAdminDashboard}
            name="financialAdmin"
          />
          <Stack.Screen component={ContactRequests} name="contactRequests" />
          <Stack.Screen component={RegularDashboard} name="regular" />
          <Stack.Screen component={StatisticsDashboard} name="statistics" />
          <Stack.Screen
            component={AppointmentManagement}
            name="appointmentManagement"
          />
          <Stack.Screen
            component={AppointmentsStatistics}
            name="appointmentsStatistics"
          />
          <Stack.Screen component={EventsStatistics} name="eventsStatistics" />
          <Stack.Screen
            component={TherapistFinancial}
            name="therapistFinancial"
          />
          <Stack.Screen component={Commissions} name="commissions" />
        </Stack.Group>
      </Stack.Navigator>
    </NavigationContainer>
  );
};
