import { SimpleLineIcons } from '@expo/vector-icons';
import { BottomPicker } from 'components/elements';
import { IOption } from 'components/elements/types.d';
import { Button, IStackProps, Text, VStack } from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

interface IDateFilterPickerProps extends IStackProps {
  /**
   * State setter for date options.
   */
  setSelectedDateOption: (option: IOption) => void;
  /**
   * Current date state.
   */
  selectedDateOption: IOption;
  /**
   * Date options to be used in the BottomPicker.
   */
  dateOptions: IOption[];
}

export const DateFilterPicker: React.FC<IDateFilterPickerProps> = (
  props,
): JSX.Element => {
  const { dateOptions, setSelectedDateOption, selectedDateOption, ...rest } =
    props;

  const themeData = useSelector(getTheme);

  const [pickerOpen, setPickerOpen] = useState<boolean>(false);

  return (
    <VStack {...rest}>
      <Text
        color={themeData.mainColorDark}
        fontSize={14}
        py={5}
        textAlign="center"
      >
        Por favor, selecciona un período para realizar tu consulta:
      </Text>
      <Button
        bg={themeData.mainColorDark}
        leftIcon={<SimpleLineIcons color="white" name="calendar" size={16} />}
        onPress={() => setPickerOpen(true)}
      >{`Opción Seleccionada: ${selectedDateOption.title}`}</Button>
      <BottomPicker
        isOpen={pickerOpen}
        onSelect={(option) => {
          setSelectedDateOption(option);
          setPickerOpen(false);
        }}
        options={dateOptions}
      />
    </VStack>
  );
};
