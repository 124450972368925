import { Entypo, Ionicons, MaterialIcons } from '@expo/vector-icons';
import {
  HStack,
  IconButton,
  Input,
  IStackProps,
  Text,
  VStack,
} from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { ICustomField } from 'services/resources/events/types.d';
import { colors } from 'styles/colors';

interface ICustomFieldProps extends IStackProps {
  /**
   * Custom fields to be shown in the UI, when defined by user.
   */
  customFields: ICustomField[];
  /**
   * State handler function for setting the custom fields.
   */
  setCustomFields: (customField: ICustomField[]) => void;
}

export const CustomFields: React.FC<ICustomFieldProps> = (props) => {
  const { customFields, setCustomFields, ...rest } = props;
  const themeData = useSelector(getTheme);

  const [fieldName, setFieldName] = useState<string>('');
  const [stackWidth, setStackWidth] = useState<number>(0);

  const handleCustomFieldAddition = (): void => {
    const customFieldsSnapshot = [...customFields];
    customFieldsSnapshot.push({ field: fieldName, value: '' });
    setCustomFields(customFieldsSnapshot);
    setFieldName('');
  };

  const handleCustomFieldDeletion = (index: number): void => {
    const customFieldsSnapshot = [...customFields];
    customFieldsSnapshot.splice(index, 1);
    setCustomFields(customFieldsSnapshot);
  };

  return (
    <VStack {...rest}>
      {customFields.map(({ field }, index) => (
        <HStack
          alignItems="center"
          justifyContent="space-between"
          key={`${field}+${Math.random()}`}
          onLayout={({
            nativeEvent: {
              layout: { width },
            },
          }) => setStackWidth(width)}
          space={3}
        >
          <MaterialIcons
            color={themeData.mainColorDark}
            name="circle"
            size={16}
          />
          <Text color={themeData.mainColorDark} maxW={`${stackWidth / 1.4}px`}>
            {field}
          </Text>
          <IconButton
            bg="transparent"
            icon={<Ionicons color={colors.error} name="md-trash" size={24} />}
            onPress={() => handleCustomFieldDeletion(index)}
          />
        </HStack>
      ))}
      <HStack justifyContent="center" mt={3} w="100%">
        <Input
          onChangeText={(t) => setFieldName(t)}
          placeholder="Nombre del Campo"
          value={fieldName}
          w="85%"
        />
        <IconButton
          bg={themeData.mainColorDark}
          icon={<Entypo color="white" name="plus" size={24} />}
          onPress={handleCustomFieldAddition}
          w="15%"
        />
      </HStack>
    </VStack>
  );
};

CustomFields.defaultProps = {
  justifyContent: 'center',
};
