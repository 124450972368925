import { Dimensions, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    justifyContent: 'center',
    resizeMode: 'cover',
  },
  badgeContainer: {
    left: 26,
    position: 'absolute',
    top: 5,
  },
  button: {
    backgroundColor: 'transparent',
  },
  disabled: {
    backgroundColor: 'transparent',
    opacity: 0.3,
  },
  menuModal: {
    backgroundColor: 'transparent',
    flex: 1,
  },
  menuModalButton: {
    backgroundColor: '#003766',
    marginVertical: 2,
  },
  menuModalOverlay: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
  },
  modal: {
    borderRadius: 10,
    maxHeight: Dimensions.get('screen').height / 1.2,
    width: Dimensions.get('screen').width / 1.2,
  },
  modalTitle: {
    color: '#003766',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 14,
    marginTop: 2,
    textAlign: 'center',
  },
  overContainer: {
    flex: 1,
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
});
