import { Box, Divider, Flex, IFlexProps, Text } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IFirebaseNotification } from 'services/resources/notifications/types.d';
import { colors } from 'styles/colors';

interface INotificationItem extends IFlexProps {
  /**
   * Individual notification retrieved from Firebase.
   */
  notification: IFirebaseNotification;
}

export const NotificationItem: React.FC<INotificationItem> = (props) => {
  const { notification, ...rest } = props;

  const themeData = useSelector(getTheme);

  return (
    <Flex {...rest}>
      <Divider />
      <Box justifyContent="center" py={2}>
        <Text
          color={!notification.read ? themeData.mainColorDark : colors.disabled}
          fontWeight={!notification.read ? 'bold' : 'normal'}
          pt={3}
        >
          {notification.title}
        </Text>
        <Text
          color={!notification.read ? themeData.mainColorDark : colors.disabled}
          fontWeight={!notification.read ? 'bold' : 'normal'}
          pb={3}
        >
          {notification.body}
        </Text>
      </Box>
      <Divider />
    </Flex>
  );
};

NotificationItem.defaultProps = {
  px: 3,
};
