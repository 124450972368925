import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  deleteUser,
  initializeAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut as fbSO,
  User,
  UserCredential,
} from 'firebase/auth';
import { instance } from 'services/instance';

import { appConfig } from '../firebase-service';
import { ISignUpPayload } from '../users/types.d';
import {
  IAuthResponse,
  IBaseAuthentication,
  IBaseAutoAuthentication,
} from './types.d';

const auth = initializeAuth(initializeApp(appConfig));

export const authResources = {
  anonymouslyLogIn: (): Promise<UserCredential> => signInAnonymously(auth),

  auth: (body: IBaseAuthentication): Promise<UserCredential> =>
    signInWithEmailAndPassword(auth, body.email, body.password),

  autoAuth: (token: string): Promise<UserCredential> =>
    signInWithCustomToken(auth, token),

  createCustomToken: (
    session: IBaseAutoAuthentication,
  ): Promise<IAuthResponse> =>
    instance.post('/authenticationAPI/custom', { session }),

  deleteAccount: (user: User): Promise<void> => deleteUser(user),

  passwordReset: (email: string): Promise<void> =>
    sendPasswordResetEmail(auth, email),

  signOut: (): Promise<void> => fbSO(auth),

  signUp: (body: ISignUpPayload): Promise<UserCredential> =>
    createUserWithEmailAndPassword(auth, body.email, body.password),

  verifyEmail: (user: User): Promise<void> => sendEmailVerification(user),
};
