import { Actionsheet, IActionsheetProps } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

import { IOption } from '../types.d';

interface IBottomPickerProps extends IActionsheetProps {
  /**
   * Options to be used in the picker.
   */
  options: IOption[];
  /**
   * Function to handle the new selected value.
   */
  onSelect: (value: IOption) => void;
}

export const BottomPicker: React.FC<IBottomPickerProps> = (
  props,
): JSX.Element => {
  const { onSelect, options, ...rest } = props;

  const themeData = useSelector(getTheme);

  return (
    <Actionsheet hideDragIndicator {...rest}>
      <Actionsheet.Content
        bg={themeData.mainColorDark}
        style={{ alignSelf: 'center' }}
      >
        {options.map((option) => {
          return (
            <Actionsheet.Item
              _hover={{ bg: 'transparent' }}
              _pressed={{ bg: 'transparent' }}
              _text={{ color: 'white' }}
              alignItems="center"
              bg={themeData.mainColorDark}
              key={option.value}
              onPress={() => onSelect(option)}
            >
              {option.title != null ? option.title : option.value}
            </Actionsheet.Item>
          );
        })}
      </Actionsheet.Content>
    </Actionsheet>
  );
};
