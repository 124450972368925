import { AntDesign } from '@expo/vector-icons';
import { UserItem } from 'components/elements';
import { IUserItemProps } from 'components/elements/types.d';
import { IconButton } from 'native-base';
import { IUser } from 'services/resources/users/types.d';
import { colors } from 'styles/colors';

export const RequestingTherapistItem: React.FC<IUserItemProps> = (props) => {
  const { onSelectUser = () => {}, item } = props;

  const user = item as IUser;

  return (
    <UserItem item={user}>
      <IconButton
        bg={colors.success}
        icon={<AntDesign color="white" name="check" size={24} />}
        onPress={() => onSelectUser(item, 'accept')}
        position="absolute"
        right={16}
      />
      <IconButton
        bg={colors.error}
        icon={<AntDesign color="white" name="close" size={24} />}
        onPress={() => onSelectUser(item, 'decline')}
        position="absolute"
        right={4}
      />
    </UserItem>
  );
};
