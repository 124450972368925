import { instance } from 'services/instance';
import {
  IColorsResponse,
  IEventPayload,
  IEventResponse,
  IEventsResponse,
} from './types.d';

export const eventsResources = {
  create: (payload: IEventPayload, token: string): Promise<void> =>
    instance.post(
      '/eventsAPI',
      { event: payload },
      { headers: { authorization: token } },
    ),

  delete: (eventId: string, token: string): Promise<void> =>
    instance.delete('/eventsAPI', {
      headers: { authorization: token },
      params: { event: eventId },
    }),

  get: (token: string): Promise<IEventsResponse> =>
    instance.get('/eventsAPI', { headers: { authorization: token } }),

  getById: (eventId: string, token: string): Promise<IEventResponse> =>
    instance.get('/eventsAPI', {
      headers: { authorization: token },
      params: { event: eventId },
    }),

  getPalette: (token: string, imageURL: string): Promise<IColorsResponse> =>
    instance.get('/eventsAPI/colorPalette', {
      headers: { authorization: token },
      params: { image: imageURL },
    }),

  putAll: (
    payload: IEventPayload,
    event: string,
    token: string,
  ): Promise<void> =>
    instance.put(
      '/eventsAPI',
      { event: payload },
      { headers: { authorization: token }, params: { event } },
    ),
};
