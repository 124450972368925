import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * Function for saving the welcome screen read status.
 */
export const storeWelcome = async (): Promise<void> => {
  await AsyncStorage.setItem('WELCOME', 'true');
};

/**
 * Function for reading the welcome read status.
 * @returns welcome read status
 */
export const readWelcome = async (): Promise<string> => {
  const welcomeRead = await AsyncStorage.getItem('WELCOME');
  return welcomeRead as string;
};
