import { Ionicons } from '@expo/vector-icons';
import { IUserItemProps } from 'components/elements/types';
import {
  getUserFullName,
  getUserInitials,
} from 'helpers/data-helpers/string-helpers';
import { useSmallScreen } from 'hooks/dimensions-hooks';
import { Avatar, Badge, Divider, HStack, Text, VStack } from 'native-base';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IUserWithTasks } from 'services/resources/types';

interface ITasksCustomUserItem extends IUserItemProps {
  /**
   * Function to show the tasks' form modal associated to a given user.
   */
  toggleTasksModalVisible: (id: string) => void;
}

export const TasksCustomUserItem: React.FC<ITasksCustomUserItem> = (props) => {
  const { toggleTasksModalVisible, item, ...rest } = props;
  const themeData = useSelector(getTheme);
  const small = useSmallScreen();

  const userWithTasks = item as IUserWithTasks;

  return (
    <VStack {...rest}>
      <HStack flex={1} justifyContent="space-around" p={2} w="100%">
        <HStack alignItems="center" flex={4}>
          <Avatar
            _text={{ color: 'white' }}
            bg={themeData.mainColorDark}
            source={{
              uri: userWithTasks.user.pictures?.profilePicture,
            }}
          >
            {getUserInitials(userWithTasks.user)}
          </Avatar>

          <Text
            fontWeight="bold"
            maxW="60%"
            mb={-1}
            ml={2}
            noOfLines={!small ? 1 : 2}
            textAlign="left"
          >
            {getUserFullName(userWithTasks.user)}
          </Text>
        </HStack>
        <HStack
          alignItems="center"
          flex={1}
          h="100%"
          justifyContent="flex-end"
          pr={2}
          space={3}
        >
          <Badge
            colorScheme="danger"
            h="32px"
            rounded="full"
            variant="solid"
            w="32px"
          >
            {userWithTasks.tasks.length}
          </Badge>
          <Ionicons
            color={themeData.mainColorDark}
            name="eye"
            onPress={() => toggleTasksModalVisible(userWithTasks.user.id)}
            size={26}
          />
        </HStack>
      </HStack>
      <Divider mt={1} />
    </VStack>
  );
};

TasksCustomUserItem.defaultProps = {
  flex: 1,
  maxH: '80px',
  minH: '60px',
  w: '100%',
};
