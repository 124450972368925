import { IAnyOption, IOption } from 'components/elements/types.d';
import { usePlatform } from 'hooks/platform-hooks';
import { Button, IStackProps, Text, VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

import { IFilterQueryProps } from '../types.d';

interface IFilterProps extends IStackProps {
  /**
   * Filtering options array to be used for displaying the filter name and to
   * later setting the selected value.
   */
  options: IOption[] | IAnyOption[];
  /**
   * Actual name of the filter to be displayed.
   */
  label: string;
  /**
   * State setter for the selected option.
   */
  onOptionSelect: (selectedOption: string | any) => void;
  /**
   * Current filter query state. Used to know if mark filters as selected.
   */
  filterQuery: IFilterQueryProps;
}

export const Filter: React.FC<IFilterProps> = (props) => {
  const { options, label, onOptionSelect, filterQuery, ...rest } = props;

  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const queryKeys = Object.keys(filterQuery);

  return (
    <VStack w={!web ? '100%' : '50%'} {...rest}>
      <Text fontWeight="bold" py={2} textAlign="center">
        {label}
      </Text>
      {options.map((option) => {
        return (
          <Button
            bg={themeData.mainColorDark}
            key={option.value}
            mx={3}
            my={2}
            onPress={() => {
              onOptionSelect(option.value);
            }}
            opacity={
              queryKeys.some(
                (key) =>
                  filterQuery[key as keyof typeof filterQuery] === option.value,
              )
                ? 1
                : 0.7
            }
          >
            {option.title}
          </Button>
        );
      })}
    </VStack>
  );
};
