import { Entypo } from '@expo/vector-icons';
import {
  AcceptDecline,
  AcceptDeclineModal,
  InputScroller,
} from 'components/elements';
import { DashboardInputField } from 'components/inputs';
import { Formik } from 'formik';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import {
  IconButton,
  IModalProps,
  Modal,
  Select,
  Text,
  VStack,
} from 'native-base';
import { useState } from 'react';
import { ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { ITaxInfo } from 'services/resources/users/types';

import { TaxInfoFormValidationSchema } from './helpers/form-helpers';

interface ITaxInfoProps extends IModalProps {
  /**
   * Values that are already set, used for editing them.
   */
  previousValues: ITaxInfo;
  /**
   * Flag to indicate whether to show the form or not.
   */
  isFormVisible: boolean;
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: ITaxInfo) => void;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
}

export const TaxInfoForm: React.FC<ITaxInfoProps> = (props) => {
  const { isFormVisible, onSubmit, onCancel, previousValues, ...rest } = props;

  const [disclaimerModalOpen, setDisclaimerModalOpen] =
    useState<boolean>(false);
  const [expenseType, setExpenseType] = useState<string>('general');
  const { web } = usePlatform();
  const themeData = useSelector(getTheme);

  return (
    <Formik
      initialValues={previousValues}
      onSubmit={onSubmit}
      validationSchema={TaxInfoFormValidationSchema}
    >
      {({ errors, handleChange, handleSubmit, isValid, setFieldValue }) => (
        <>
          <Modal isOpen={isFormVisible} {...rest}>
            <Modal.Content
              alignItems="center"
              bg={themeData.mainColorDark}
              w={!web ? '100%' : '30%'}
            >
              <IconButton
                alignSelf="flex-end"
                icon={<Entypo color="white" name="cross" size={28} />}
                mr={3}
                onPress={() => {
                  onCancel();
                }}
              />
              {!web ? (
                <InputScroller
                  bottomOffset
                  contentContainerStyle={{
                    width: '100%',
                  }}
                  disableScrollToStart
                >
                  <VStack alignItems="center">
                    <Text
                      color="white"
                      fontSize={24}
                      fontWeight="bold"
                      pb={3}
                      textAlign="center"
                    >
                      {fp.isEmpty(previousValues)
                        ? 'Añadir datos fiscales'
                        : 'Editar datos fiscales'}
                    </Text>
                    <VStack w="90%">
                      <Text
                        color="white"
                        fontSize={16}
                        fontWeight="bold"
                        mb={1}
                        textAlign="center"
                      >
                        RFC:
                      </Text>
                      <DashboardInputField
                        alignSelf="center"
                        bgColor="white"
                        defaultValue={previousValues.RFC}
                        error={errors.RFC}
                        onChangeText={handleChange('RFC')}
                      />
                    </VStack>
                    <VStack w="90%">
                      <Text
                        color="white"
                        fontSize={16}
                        fontWeight="bold"
                        mb={1}
                        textAlign="center"
                      >
                        Razón Social:
                      </Text>
                      <DashboardInputField
                        alignSelf="center"
                        bgColor="white"
                        defaultValue={previousValues.legalName}
                        error={errors.legalName}
                        onChangeText={handleChange('legalName')}
                      />
                    </VStack>
                    <VStack w="90%">
                      <Text
                        color="white"
                        fontSize={16}
                        fontWeight="bold"
                        mb={1}
                        textAlign="center"
                      >
                        Régimen Fiscal:
                      </Text>
                      <DashboardInputField
                        alignSelf="center"
                        bgColor="white"
                        defaultValue={previousValues.taxRegime}
                        error={errors.taxRegime}
                        onChangeText={handleChange('taxRegime')}
                      />
                    </VStack>
                    <VStack w="90%">
                      <Text
                        color="white"
                        fontSize={16}
                        fontWeight="bold"
                        mb={1}
                        textAlign="center"
                      >
                        Código Postal:
                      </Text>
                      <DashboardInputField
                        alignSelf="center"
                        bgColor="white"
                        defaultValue={previousValues.CP}
                        error={errors.CP}
                        onChangeText={handleChange('CP')}
                      />
                    </VStack>
                    <VStack w="90%">
                      <Text
                        color="white"
                        fontSize={16}
                        fontWeight="bold"
                        mb={1}
                        textAlign="center"
                      >
                        Tipo de Gasto:
                      </Text>
                      <Select
                        bgColor="white"
                        mb={5}
                        mx={4}
                        onValueChange={(itemValue) => {
                          setExpenseType(itemValue);
                        }}
                        placeholder="Selecciona un tipo de gasto:"
                      >
                        <Select.Item label="Gastos Médicos" value="medical" />
                        <Select.Item
                          label="Gastos en General"
                          value="general"
                        />
                      </Select>
                    </VStack>
                    <VStack w="90%">
                      <Text
                        color="white"
                        fontSize={16}
                        fontWeight="bold"
                        mb={1}
                        textAlign="center"
                      >
                        Correo Electrónico:
                      </Text>
                      <DashboardInputField
                        alignSelf="center"
                        bgColor="white"
                        defaultValue={previousValues.email}
                        error={errors.email}
                        onChangeText={handleChange('email')}
                      />
                    </VStack>
                  </VStack>
                  <AcceptDecline
                    alignSelf="center"
                    disabled={!isValid}
                    my={4}
                    onAccept={() => setDisclaimerModalOpen(true)}
                    onDecline={onCancel}
                  />
                </InputScroller>
              ) : (
                <ScrollView
                  showsVerticalScrollIndicator
                  style={{ width: '100%' }}
                >
                  <VStack alignItems="center">
                    <Text
                      color="white"
                      fontSize={24}
                      fontWeight="bold"
                      pb={3}
                      textAlign="center"
                    >
                      {fp.isEmpty(previousValues)
                        ? 'Añadir datos fiscales'
                        : 'Editar datos fiscales'}
                    </Text>
                    <VStack w="90%">
                      <Text
                        color="white"
                        fontSize={16}
                        fontWeight="bold"
                        mb={1}
                        textAlign="center"
                      >
                        RFC:
                      </Text>
                      <DashboardInputField
                        alignSelf="center"
                        bgColor="white"
                        defaultValue={previousValues.RFC}
                        error={errors.RFC}
                        onChangeText={handleChange('RFC')}
                      />
                    </VStack>
                    <VStack w="90%">
                      <Text
                        color="white"
                        fontSize={16}
                        fontWeight="bold"
                        mb={1}
                        textAlign="center"
                      >
                        Razón Social:
                      </Text>
                      <DashboardInputField
                        alignSelf="center"
                        bgColor="white"
                        defaultValue={previousValues.legalName}
                        error={errors.legalName}
                        onChangeText={handleChange('legalName')}
                      />
                    </VStack>
                    <VStack w="90%">
                      <Text
                        color="white"
                        fontSize={16}
                        fontWeight="bold"
                        mb={1}
                        textAlign="center"
                      >
                        Régimen Fiscal:
                      </Text>
                      <DashboardInputField
                        alignSelf="center"
                        bgColor="white"
                        defaultValue={previousValues.taxRegime}
                        error={errors.taxRegime}
                        onChangeText={handleChange('taxRegime')}
                      />
                    </VStack>
                    <VStack w="90%">
                      <Text
                        color="white"
                        fontSize={16}
                        fontWeight="bold"
                        mb={1}
                        textAlign="center"
                      >
                        Código Postal:
                      </Text>
                      <DashboardInputField
                        alignSelf="center"
                        bgColor="white"
                        defaultValue={previousValues.CP}
                        error={errors.CP}
                        onChangeText={handleChange('CP')}
                      />
                    </VStack>
                    <VStack w="90%">
                      <Text
                        color="white"
                        fontSize={16}
                        fontWeight="bold"
                        mb={1}
                        textAlign="center"
                      >
                        Tipo de Gasto:
                      </Text>
                      <Select
                        bgColor="white"
                        mb={5}
                        mx={3}
                        onValueChange={(itemValue) => {
                          setExpenseType(itemValue);
                        }}
                        placeholder="Selecciona un tipo de gasto:"
                      >
                        <Select.Item label="Gastos Médicos" value="medical" />
                        <Select.Item
                          label="Gastos en General"
                          value="general"
                        />
                      </Select>
                    </VStack>
                    <VStack w="90%">
                      <Text
                        color="white"
                        fontSize={16}
                        fontWeight="bold"
                        mb={1}
                        textAlign="center"
                      >
                        Correo Electrónico:
                      </Text>
                      <DashboardInputField
                        alignSelf="center"
                        bgColor="white"
                        defaultValue={previousValues.email}
                        error={errors.email}
                        onChangeText={handleChange('email')}
                      />
                    </VStack>
                  </VStack>
                  <AcceptDecline
                    alignSelf="center"
                    disabled={!isValid}
                    my={4}
                    onAccept={() => setDisclaimerModalOpen(true)}
                    onDecline={onCancel}
                  />
                </ScrollView>
              )}
            </Modal.Content>
          </Modal>
          <AcceptDeclineModal
            isOpen={disclaimerModalOpen}
            onAccept={() => {
              setFieldValue('expenseType', expenseType);
              handleSubmit();
              setDisclaimerModalOpen(false);
            }}
            onDecline={() => setDisclaimerModalOpen(false)}
          >
            ¿Estás seguro de querer continuar? (confirma que tus datos fiscales
            sean correctos).
          </AcceptDeclineModal>
        </>
      )}
    </Formik>
  );
};

TaxInfoForm.defaultProps = {
  justifyContent: 'center',
  size: 'full',
};
