import { Dimensions, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  accountText: {
    color: '#003766',
    fontSize: 20,
    fontWeight: '600',
    textAlign: 'center',
  },
  backButton: {
    alignSelf: 'flex-start',
    marginTop: (Dimensions.get('window').height * 6.25) / 100,
  },
  button: {
    backgroundColor: '#003766',
    borderColor: 'white',
    borderRadius: 10,
    borderWidth: 1,
    marginBottom: 10,
  },
  buttonContainer: {
    paddingHorizontal: 80,
  },
  input: {
    backgroundColor: 'white',
    borderRadius: 10,
    textAlign: 'center',
  },
  inputContainer: {
    borderColor: 'transparent',
    marginBottom: -10,
    paddingHorizontal: 10,
  },
  inputPlaceholder: {
    color: '#003766',
  },
  link: {
    color: '#003766',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  logo: {
    height: (Dimensions.get('window').height * 15.625) / 100,
    marginBottom: 30,
    marginTop: 5,
    resizeMode: 'contain',
  },
  returnButton: {
    color: '#003766',
    fontWeight: 'bold',
    left: 10,
  },
  termsText: {
    color: '#003766',
    marginTop: 12,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
});
