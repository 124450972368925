import { emailRegex } from 'helpers/data-helpers/regex-helpers';
import * as Yup from 'yup';

export const SearchUserFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      emailRegex,
      'Debes usar un correo válido, es probable que hayas usado autocompletar y se haya añadido un espacio adicional.',
    )
    .required('Este campo es requerido.'),
});

export const INITIAL_VALUES = {
  email: '',
};
