import { instance } from 'services/instance';

import {
  IEventRegistryPayload,
  IEventsRegistriesResponse,
  IEventsWithRegistriesResponse,
} from './types.d';

export const eventsRegistriesResources = {
  create: (payload: IEventRegistryPayload, token: string): Promise<void> =>
    instance.post(
      '/eventsRegistriesAPI',
      { eventRegistry: payload },
      { headers: { authorization: token } },
    ),

  getByEventId: (
    eventId: string,
    token: string,
  ): Promise<IEventsRegistriesResponse> =>
    instance.get('/eventsRegistriesAPI/byEvent', {
      headers: { authorization: token },
      params: { event: eventId },
    }),

  getEventsWithRegistriesByFixedDate: (
    date: string,
    dateOption: string,
    token: string,
  ): Promise<IEventsWithRegistriesResponse> =>
    instance.get('/eventsRegistriesAPI/eventsWithRegistriesByFixedDate', {
      headers: { authorization: token },
      params: { date, dateOption },
    }),

  putConfirmation: (eventRegistry: string, token: string): Promise<void> =>
    instance.put(
      '/eventsRegistriesAPI',
      {},
      {
        headers: { authorization: token },
        params: { eventRegistry, updateField: 'assistedConfirmation' },
      },
    ),

  putPayment: (eventRegistry: string, token: string): Promise<void> =>
    instance.put(
      '/eventsRegistriesAPI',
      {},
      {
        headers: { authorization: token },
        params: { eventRegistry, updateField: 'paid' },
      },
    ),
};
