import { useSmallScreen } from 'hooks/dimensions-hooks';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { FormControl, Input, WarningOutlineIcon } from 'native-base';
import React, { useState } from 'react';
import { fontSizes, inputSizes } from 'styles/theme';

import { IInputFieldProps } from '../types.d';

export const InputField: React.FC<IInputFieldProps> = (props): JSX.Element => {
  const {
    label = '',
    error = '',
    onChangeText,
    secureText = false,
    ...rest
  } = props;

  const small = useSmallScreen();
  const { web } = usePlatform();

  const [errorHeight, setErrorHeight] = useState<number>(5);

  return (
    <FormControl
      h={
        !small
          ? `${inputSizes.regular + errorHeight}px`
          : `${inputSizes.small + errorHeight}px`
      }
      isInvalid={!fp.isEmpty(error)}
      mb={4}
      w="100%"
    >
      <Input
        h={!small ? undefined : `${inputSizes.small}px`}
        onChangeText={(t) => {
          onChangeText(t);
          if (fp.isEmpty(error)) setErrorHeight(0);
        }}
        placeholder={label}
        py={!web ? undefined : 3}
        style={{ fontSize: !small ? fontSizes.regular : fontSizes.smaller }}
        type={secureText ? 'password' : 'text'}
        {...rest}
      />
      <FormControl.ErrorMessage
        alignSelf="center"
        leftIcon={<WarningOutlineIcon mr={1} size="xs" />}
        onLayout={({
          nativeEvent: {
            layout: { height },
          },
        }) => setErrorHeight(height)}
        px={2}
        textAlign="center"
        top={!small ? 0 : -5}
      >
        {fp.isEmpty(error) ? '' : error}
      </FormControl.ErrorMessage>
    </FormControl>
  );
};
