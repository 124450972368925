import fp from 'lodash/fp';
import { Box, IModalProps, Modal, ScrollView, Text } from 'native-base';
import { RichEditor } from 'react-native-pell-rich-editor';
import { colors } from 'styles/colors';

import { IListItem } from '../types.d';

interface ILinkSelectorProps extends IModalProps {
  selectorVisible: boolean;
  setSelectorVisible: (value: boolean) => void;
  /**
   * List of links to be used in order to insert links while using the editor.
   */
  links: IListItem[];
  richText: React.RefObject<RichEditor>;
}

export const LinkSelector: React.FC<ILinkSelectorProps> = (
  props,
): JSX.Element => {
  const { selectorVisible, setSelectorVisible, links, richText, ...rest } =
    props;

  const handleOnInsertLink = (link: IListItem): void => {
    if (!fp.isNil(richText.current)) {
      richText.current.insertLink(link.title, link.value);
      setSelectorVisible(false);
    }
  };

  return (
    <Modal isOpen={selectorVisible} {...rest}>
      <Modal.Content h="50%">
        <Modal.CloseButton onPress={() => setSelectorVisible(false)} />
        <Box pt="60px">
          {links.length > 0 ? (
            <ScrollView>
              <Box justifyContent="center">
                <Text fontSize={18} mb={2} textAlign="center">
                  Selecciona el hipervínculo a añadir:
                </Text>
                {links.map((link) => (
                  <Text
                    color={colors.terappiaBlue.dark}
                    fontWeight="bold"
                    key={`${link.value}_${Math.random()}`}
                    my={2}
                    onPress={() => handleOnInsertLink(link)}
                    textAlign="center"
                    underline
                  >
                    {link.value}
                  </Text>
                ))}
              </Box>
            </ScrollView>
          ) : (
            <Text fontSize={18} px={4} textAlign="center">
              Aún no has añadido hipervínculos para usar en el editor.
            </Text>
          )}
        </Box>
      </Modal.Content>
    </Modal>
  );
};

LinkSelector.defaultProps = {
  size: 'full',
};
