import { usePlatform } from 'hooks/platform-hooks';
import { HStack, IStackProps, Text } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

interface IStatisticsAmountItemProps extends IStackProps {
  /**
   * Actual text to be displayed that indicates what is going to be shown.
   */
  label: string;
  /**
   * Actual data to be displayed next to the label.
   */
  amount: number;
  /**
   * Color to be used in the displayed data.
   */
  amountColor: string | undefined;
}

export const StatisticsItemAmount: React.FC<IStatisticsAmountItemProps> = (
  props,
) => {
  const { amountColor = undefined, label, amount, ...rest } = props;

  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  return (
    <HStack px={5} py={!web ? 1 : 5} {...rest}>
      <Text color={themeData.mainColorDark} fontSize={18} fontWeight="bold">
        {label}
      </Text>
      <Text color={amountColor} fontSize={18} fontWeight="bold">
        {`${amount}`}
      </Text>
    </HStack>
  );
};
