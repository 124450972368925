import { Dimensions, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  button: {
    backgroundColor: '#003766',
    borderRadius: 10,
  },
  buttonContainer: {
    paddingHorizontal: 80,
  },
  divider: {
    alignSelf: 'center',
    borderColor: '#003766',
    borderWidth: 2,
    marginBottom: 1,
    marginTop: 6,
    width: 220,
  },
  forgottenPassword: {
    color: '#003766',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 60,
    textAlign: 'center',
  },
  input: {
    backgroundColor: 'white',
    borderRadius: 10,
    textAlign: 'center',
  },
  inputContainer: {
    borderColor: 'transparent',
    paddingHorizontal: 10,
  },
  inputPlaceholder: {
    color: '#003766',
  },
  logo: {
    height: (Dimensions.get('window').height * 15.625) / 100,
    marginTop: Dimensions.get('window').height / 4,
  },
  signUp: {
    color: '#003766',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
