import * as Yup from 'yup';

export const TherapyProgressFormCopies = {
  patientProgress: '',
  therapistProgress: '',
};

export const TherapyProgresFormValidationSchema = Yup.object().shape({
  patientProgress: Yup.string().required('Este campo es requerido.'),
  therapistProgress: Yup.string().required('Este campo es requerido.'),
});
