import { AcceptDecline } from 'components/elements';
import { DashboardInputField, DateField, TimeField } from 'components/inputs';
import { getDate, getMonth, getYear, set } from 'date-fns';
import { Formik } from 'formik';
import { integerOrDecimalNumberRegex } from 'helpers/data-helpers/regex-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { IStackProps, ScrollView, Text, VStack } from 'native-base';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IAppointmentFormPayload } from 'services/resources/appointments/types.d';

import {
  AppointmentFormValidationSchema,
  INITIAL_VALUES,
} from './helpers/form-helpers';

interface ILoginFormProps extends IStackProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IAppointmentFormPayload) => void;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Values that are already set, used for editing them.
   */
  previousValues: IAppointmentFormPayload;
  /**
   * Flag that indicates if we are performing an edition operation.
   */
  isEditing?: boolean;
  /**
   * Date that was selected from the calendar to be used in the form.
   */
  selectedDate: Date;
}

export const AppointmentForm: React.FC<ILoginFormProps> = (props) => {
  const {
    selectedDate,
    onSubmit,
    onCancel,
    previousValues,
    isEditing = false,
    ...rest
  } = props;

  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const previousValuesEnabled = !fp.isNil(previousValues.created);
  const [marginsExceeded, setMarginsExceeded] = useState<boolean>(false);
  const [discountInPesos, setDiscountInPesos] = useState<string>(
    !previousValuesEnabled
      ? `${INITIAL_VALUES.discountInPesos}`
      : `${previousValues.discountInPesos}`,
  );
  const [discountInPercentage, setDiscountInPercentage] = useState<string>(
    !previousValuesEnabled
      ? `${INITIAL_VALUES.discount}`
      : `${previousValues.discount}`,
  );

  /**
   * Function that build an initial values object with the selected date from
   * the calendar.
   * @returns initial values with the originally selected date
   */
  const setInitialValues = (): IAppointmentFormPayload => {
    const output: IAppointmentFormPayload = {
      ...INITIAL_VALUES,
      date: selectedDate,
    };
    return output;
  };

  /**
   * Handler function for setting the full date (date and time).
   * @param previousTime
   * @param newDate
   */
  const handleOnDateChange = (previousTime: Date, newDate: Date): Date => {
    return set(previousTime, {
      date: getDate(newDate),
      month: getMonth(newDate),
      year: getYear(newDate),
    });
  };

  const checkPercentageError = (percentage: string): string => {
    let error = '';
    const passesTest = new RegExp(integerOrDecimalNumberRegex).test(percentage);
    if (!passesTest) {
      error = 'Debes ingresar un número válido.';
    } else if (Number(percentage) > 100) {
      error = 'El descuento no puede exceder el 100%.';
    } else {
      setMarginsExceeded(false);
    }
    return error;
  };

  const checkPesosError = (pesos: string, cost: Number): string => {
    let error = '';
    const passesTest = new RegExp(integerOrDecimalNumberRegex).test(pesos);
    if (!passesTest) {
      error = 'Debes ingresar un número válido.';
      setMarginsExceeded(true);
    } else if (Number(pesos) > Number(cost)) {
      error = 'El descuento no puede exceder el costo de la consulta';
      setMarginsExceeded(true);
    }
    return error;
  };

  return (
    <Formik
      initialValues={
        !previousValuesEnabled ? setInitialValues() : previousValues
      }
      onSubmit={onSubmit}
      validationSchema={AppointmentFormValidationSchema}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        errors,
        handleChange,
        isValid,
      }) => (
        <VStack {...rest}>
          <ScrollView
            contentContainerStyle={{ width: '100%' }}
            maxH="80%"
            w="100%"
          >
            <TimeField
              buttonHeight="40px"
              buttonLabel="Seleccionar Horario"
              flex={1}
              mb={1}
              onTimeChange={(t) => {
                setFieldValue('date', t);
              }}
              timeValue={new Date(values.date)}
            />
            <DateField
              buttonHeight="40px"
              buttonLabel={
                !isEditing ? 'Fecha Seleccionada' : 'Seleccionar Fecha'
              }
              dateValue={new Date(values.date)}
              disableButton={!isEditing}
              flex={1}
              onDateChange={(d) => {
                const outputDate = handleOnDateChange(new Date(values.date), d);
                setFieldValue('date', outputDate);
              }}
            />
            <VStack flex={1} mt={1}>
              <Text
                color={themeData.mainColorDark}
                fontSize={16}
                fontWeight="bold"
                textAlign="center"
              >
                Costo:
              </Text>
              <DashboardInputField
                defaultValue={`${values.cost}`}
                error={errors.cost}
                onChangeText={handleChange('cost')}
              />
            </VStack>
            {values.cost !== 0 ? (
              <VStack flex={1}>
                <Text
                  color={themeData.mainColorDark}
                  fontSize={16}
                  fontWeight="bold"
                  textAlign="center"
                >
                  Descuento en Porcentaje:
                </Text>
                <DashboardInputField
                  error={checkPercentageError(discountInPercentage)}
                  isDisabled={fp.isEmpty(values.cost) && !isEditing}
                  onChangeText={(text) => {
                    setDiscountInPercentage(text);
                    setDiscountInPesos(
                      ((Number(text) / 100) * values.cost).toFixed(2),
                    );
                  }}
                  value={discountInPercentage}
                />
                <Text
                  color={themeData.mainColorDark}
                  fontSize={16}
                  fontWeight="bold"
                  textAlign="center"
                >
                  Descuento en Pesos:
                </Text>
                <DashboardInputField
                  error={checkPesosError(discountInPesos, values.cost)}
                  isDisabled={fp.isEmpty(values.cost) && !isEditing}
                  onChangeText={(text) => {
                    setDiscountInPesos(text);
                    setDiscountInPercentage(
                      ((Number(text) * 100) / values.cost).toFixed(2),
                    );
                  }}
                  value={discountInPesos}
                />
              </VStack>
            ) : null}
          </ScrollView>
          <AcceptDecline
            disabled={!isValid || marginsExceeded}
            flex={1}
            maxH={web ? '80px' : '50px'}
            my={4}
            onAccept={() => {
              setFieldValue('discount', Number(discountInPercentage));
              setFieldValue('discountInPesos', Number(discountInPesos));
              setFieldValue('cost', Number(values.cost));
              handleSubmit();
            }}
            onDecline={onCancel}
          />
        </VStack>
      )}
    </Formik>
  );
};

AppointmentForm.defaultProps = {
  alignItems: 'center',
  flex: 1,
  h: '100%',
  justifyContent: 'center',
  w: '100%',
};
