import { Entypo, MaterialIcons } from '@expo/vector-icons';
import {
  AcceptDeclineModal,
  ExtendedVirtualizedList,
} from 'components/elements';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import {
  Button,
  Flex,
  IconButton,
  IModalProps,
  Modal,
  Text,
} from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import {
  IPredefinedTask,
  IPredefinedTaskPayload,
} from 'services/resources/tasks/types.d';
import { colors } from 'styles/colors';

import { PredefinedTaskItem } from './PredefinedTaskItem';
import { PredefinedTaskModal } from './PredefinedTaskModal';

interface ITaskModalProps extends IModalProps {
  /**
   * Handler function for hiding the modal.
   */
  toggleTasksModalVisible: () => void;
  /**
   * Function for handling an item deletion on database.
   */
  onDelete: (task: IPredefinedTask) => void;
  /**
   * Function for handling an item edition on database.
   */
  onEdit: (task: IPredefinedTask) => void;
  /**
   * Function for setting the selected predefined task as the task to be
   * assigned to patient.
   */
  onSelect: (task: IPredefinedTask) => void;
  /**
   * Function for handling item creation in database.
   */
  onCreate: (task: IPredefinedTaskPayload) => void;
  /**
   * Tasks that exist already in the database.
   */
  predefinedTasks: IPredefinedTask[];
  /**
   * State for the selected predefined task.
   */
  selectedPredefined: IPredefinedTask;
  /**
   * State setter for selected predefined task.
   */
  setSelectedPredefined: (predefined: IPredefinedTask) => void;
  /**
   * Function for showing/hiding tasks' modal.
   */
  togglePredefinedTasksModalVisible: () => void;
  /**
   * Flag to indicate weather to show or hide the modal.
   */
  predefinedTasksModalVisible: boolean;
}

export const PredefinedTasksModal: React.FC<ITaskModalProps> = (
  props,
): JSX.Element => {
  const {
    predefinedTasks,
    onEdit,
    onCreate,
    onSelect,
    onDelete,
    toggleTasksModalVisible,
    selectedPredefined,
    setSelectedPredefined,
    predefinedTasksModalVisible,
    togglePredefinedTasksModalVisible,
    ...rest
  } = props;
  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const [previousValues, setPreviousValues] = useState<IPredefinedTask>(
    {} as IPredefinedTask,
  );
  const [disclaimerVisible, setDisclaimerVisible] = useState<boolean>(false);
  const [predefinedTaskModalVisible, setPredefinedTaskModalVisible] =
    useState<boolean>(false);

  const togglePredefinedTaskModalVisible = (): void => {
    setPredefinedTaskModalVisible(!predefinedTaskModalVisible);
  };

  const toggleDisclaimerVisible = (): void => {
    setDisclaimerVisible(!disclaimerVisible);
  };

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        bg="white"
        borderRadius={!web ? 0 : 10}
        flex={1}
        w={!web ? '100%' : '30%'}
      >
        <AcceptDeclineModal
          invertColors
          isOpen={disclaimerVisible}
          onAccept={() => {
            toggleDisclaimerVisible();
            onDelete(selectedPredefined);
          }}
          onDecline={() => {
            setSelectedPredefined({} as IPredefinedTask);
            toggleDisclaimerVisible();
          }}
        >
          <Text fontWeight="bold">
            {`¿Estás seguro de querer eliminar la tarea predefinida '${selectedPredefined.name}', '${selectedPredefined.description}'? Esta acción no se puede deshacer.`}
          </Text>
        </AcceptDeclineModal>
        <PredefinedTaskModal
          isOpen={predefinedTaskModalVisible}
          onCancel={() => {
            setPreviousValues({} as IPredefinedTask);
            togglePredefinedTaskModalVisible();
          }}
          onSubmit={(payload) => {
            if (fp.isEmpty(previousValues)) {
              onCreate(payload);
            } else {
              onEdit(payload as IPredefinedTask);
              setPreviousValues({} as IPredefinedTask);
            }
            togglePredefinedTaskModalVisible();
          }}
          previousValues={previousValues}
        />
        <IconButton
          alignSelf="flex-end"
          flex={1}
          icon={
            <Entypo color={themeData.mainColorDark} name="cross" size={28} />
          }
          mr={3}
          mt={3}
          onPress={toggleTasksModalVisible}
        />
        <Flex flex={16} w="100%">
          <ExtendedVirtualizedList
            data={predefinedTasks}
            noDataMessage="No se encontraron tareas predefinidas."
            renderItem={
              <PredefinedTaskItem
                onDelete={(payload) => {
                  toggleDisclaimerVisible();
                  setSelectedPredefined(payload);
                }}
                onEdit={(payload) => {
                  setPreviousValues(payload);
                  togglePredefinedTaskModalVisible();
                }}
                onSelect={onSelect}
              />
            }
            searchBarPlaceholder="tarea"
            searchKey={['description', 'name']}
            sort
            sortKey="name"
            useSearchBar
          />
        </Flex>
        <Button
          _hover={{ bg: colors.success, opacity: 0.8 }}
          _pressed={{ bg: colors.success, opacity: 0.8 }}
          _text={{ fontWeight: 'bold' }}
          bg={colors.success}
          borderRadius={0}
          flex={1}
          leftIcon={
            <MaterialIcons
              color="white"
              name="add"
              size={24}
              style={{ marginRight: 10 }}
            />
          }
          onPress={togglePredefinedTaskModalVisible}
          w="100%"
        >
          Añadir Tarea Predefinida
        </Button>
      </Modal.Content>
    </Modal>
  );
};

PredefinedTasksModal.defaultProps = {
  avoidKeyboard: true,
  size: 'full',
};
