import { useLinkTo, useNavigation } from '@react-navigation/native';
import {
  ExternalUIWrapper,
  InputScroller,
  LoadingStatusModal,
} from 'components/elements';
import { LogInForm } from 'components/forms';
import { authErrorHandler } from 'helpers/error-helpers/auth-helpers';
import { Logo } from 'helpers/source-helpers/img-src-helpers';
import {
  readSession,
  storeSession,
} from 'helpers/storage-helpers/session-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { Center, Image } from 'native-base';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setToken, setUserData } from 'redux-service/slices';
import { IUserState } from 'redux-service/types.d';
import { IScreenProps } from 'screens/types.d';
import { authResources } from 'services/resources/auth';
import { IBaseAuthentication } from 'services/resources/auth/types.d';
import { userResources } from 'services/resources/users';
import { IUser } from 'services/resources/users/types.d';
import { ILoadingData, IOperationError } from 'types.d';

import { styles } from './styles';

export const LogIn: React.FC<IScreenProps> = ({ route }) => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const dispatch = useDispatch();
  const { web } = usePlatform();
  // We determine this origin to the cases where we are making a user to log-in
  // after it decided to use non-anonymous authentication for event signing-up
  const origin = !fp.isNil(route.params) ? route.params.origin : undefined;

  const [error, setError] = useState<IOperationError>({
    detected: false,
    errorMessage: '',
  });
  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });

  /**
   * Function for handling the submission of a log-in trial.
   * @param payload
   */
  const handleOnSubmit = async (
    payload: IBaseAuthentication,
  ): Promise<void> => {
    setLoadingData({ loading: true, loadingMessage: 'Iniciando Sesión...' });
    let data = {} as IUser;
    try {
      const { user } = await authResources.auth(payload);
      if (!user.emailVerified) {
        throw new Error('auth/not-verified');
      }
      await storeSession(user);
      const token = await user.getIdToken();
      dispatch(setToken(token));
      const { data: d } = await userResources.getByMail(payload.email, token);
      data = d as IUser;
    } catch (e: any) {
      if (!fp.isNil(e.code)) {
        setError({
          detected: true,
          errorMessage: authErrorHandler.logInError(e.code),
        });
      } else if (!fp.isNil(e.message)) {
        setError({
          detected: true,
          errorMessage: authErrorHandler.logInError(e.message),
        });
      } else {
        setError({
          detected: true,
          errorMessage: authErrorHandler.logInError(e),
        });
      }
    }
    const loggedUser = {
      ...data,
      logged: true,
    };
    setLoadingData({ loading: false, loadingMessage: '' });
    if (!fp.isEmpty(data)) {
      dispatch(setUserData(loggedUser as IUserState));
      // If the user came from eventSignUp, return him to such screen
      if (!fp.isNil(origin) && origin.includes('eventSignUp')) {
        linkTo(route.params.origin as never);
      } else {
        setTimeout(() => {
          navigation.navigate('home' as never);
        }, 30);
      }
    }
  };

  /**
   * Function that will try to restore a previous session.
   */
  const tryAutoLogIn = async (): Promise<void> => {
    let userData = {} as IUser;
    try {
      // Read stored data
      const restoredSession = await readSession();
      if (!fp.isEmpty(restoredSession)) {
        setLoadingData({
          loading: true,
          loadingMessage: 'Sesión anterior encontrada. Restaurando...',
        });
        // Generate custom token if recognized session
        const {
          data: { token },
        } = await authResources.createCustomToken({
          token: restoredSession.stsTokenManager.accessToken,
          uid: restoredSession.uid,
        });
        // Sign-in with custom token
        const { user } = await authResources.autoAuth(token);
        const authToken = await user.getIdToken();
        dispatch(setToken(authToken));
        // Retrieve user data from database
        const { data: d } = await userResources.getByMail(
          restoredSession.email,
          authToken,
        );
        userData = d as IUser;
      }
    } catch (e) {
      setError({
        detected: true,
        errorMessage: 'Error inesperado en la autenticación.',
      });
    }
    setLoadingData({
      loading: false,
      loadingMessage: '',
    });
    const loggedUser = {
      ...userData,
      foreignVisit: false,
      logged: true,
    };
    if (!fp.isEmpty(userData)) {
      dispatch(setUserData(loggedUser as IUserState));
      // If the user came from eventSignUp, return him to such screen
      if (!fp.isNil(origin) && origin.includes('eventSignUp')) {
        linkTo(route.params.origin as never);
      } else {
        setTimeout(() => {
          navigation.navigate('home' as never);
        }, 30);
      }
    }
  };

  useEffect(() => {
    // Try to auto-log-in when component rendered
    tryAutoLogIn();
  }, []);

  return (
    <ExternalUIWrapper
      error={error.detected}
      onDiscard={() => setError({ detected: false, errorMessage: '' })}
      operationStatus={error.errorMessage}
    >
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      {!web ? (
        <InputScroller bottomOffset>
          <Image
            alt="logo"
            h={styles.logo.height}
            mb={30}
            mt={styles.logo.marginTop}
            resizeMode="contain"
            source={Logo}
          />
          <LogInForm onSubmit={handleOnSubmit} />
        </InputScroller>
      ) : (
        <Center>
          <img
            alt="logo"
            height="25%"
            src={Logo}
            style={{ marginBottom: 30, marginTop: 0 }}
          />
          <LogInForm onSubmit={handleOnSubmit} w="20%" />
        </Center>
      )}
    </ExternalUIWrapper>
  );
};
