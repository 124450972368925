import { Entypo } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { DisclaimerModal } from 'components/elements';
import { BarCodeEvent, BarCodeScanner } from 'expo-barcode-scanner';
import fp from 'lodash/fp';
import { IconButton, IModalProps, Modal, Skeleton, Text } from 'native-base';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IEventInfo } from 'services/resources/events/types.d';

interface IQRScannerModalProps extends IModalProps {
  /**
   * Handler function for setting the isOpen state to false.
   */
  onCloseModal: () => void;
}

export const QRScannerModal: React.FC<IQRScannerModalProps> = (props) => {
  const { onCloseModal, ...rest } = props;
  const themeData = useSelector(getTheme);
  const navigation = useNavigation();

  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [disclaimerVisible, setDisclaimerVisible] = useState<boolean>(false);

  const handleBarCodeScanned = (event: BarCodeEvent): void => {
    let eventInfo = {} as IEventInfo;
    try {
      eventInfo = JSON.parse(event.data);
      if (!fp.isNil(eventInfo.app) && eventInfo.app === 'terappia') {
        onCloseModal();
        navigation.navigate(
          'eventsRegistries' as never,
          { eventInfo } as never,
        );
      }
    } catch (e) {
      setDisclaimerVisible(true);
    }
  };

  // As in Expo docs, we firstly need to require permissions to use the camera
  // https://docs.expo.dev/versions/v47.0.0/sdk/bar-code-scanner/#usage
  useEffect(() => {
    const getBarCodeScannerPermissions = async (): Promise<void> => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    };
    getBarCodeScannerPermissions();
  }, []);

  return (
    <Modal {...rest}>
      <Modal.Content justifyContent="center">
        <DisclaimerModal
          isOpen={disclaimerVisible}
          onClose={() => setDisclaimerVisible(false)}
        >
          Este código QR no es un código QR de TerAppia.
        </DisclaimerModal>
        <IconButton
          alignSelf="flex-end"
          icon={
            <Entypo color={themeData.mainColorDark} name="cross" size={28} />
          }
          mr={3}
          onPress={() => onCloseModal()}
        />
        <Text
          color={themeData.mainColorDark}
          fontWeight="bold"
          pb={3}
          textAlign="center"
        >
          Coloca el código en la cámara:
        </Text>
        {hasPermission ? (
          <BarCodeScanner
            barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
            onBarCodeScanned={(barCodeEvent) =>
              handleBarCodeScanned(barCodeEvent)
            }
            style={{
              alignSelf: 'center',
              height: '80%',
              width: '90%',
            }}
          />
        ) : (
          <Skeleton bg="gray" borderRadius={5} h="90%" w="90%" />
        )}
      </Modal.Content>
    </Modal>
  );
};

QRScannerModal.defaultProps = {
  size: 'full',
};
