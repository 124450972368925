import { instance } from 'services';

import { IBlogPayload, IBlogResponse } from './types';

export const blogResources = {
  create: (payload: IBlogPayload, token: string): Promise<void> =>
    instance.post(
      '/blogAPI',
      { blogEntry: payload },
      { headers: { authorization: token } },
    ),

  delete: (blogEntry: string, token: string): Promise<void> =>
    instance.delete('/blogAPI', {
      headers: { authorization: token },
      params: { blogEntry },
    }),

  get: (token: string): Promise<IBlogResponse> =>
    instance.get('/blogAPI', { headers: { authorization: token } }),

  putAll: (
    payload: IBlogPayload,
    blogEntry: string,
    token: string,
  ): Promise<void> =>
    instance.put(
      '/blogAPI',
      { blogEntry: payload },
      { headers: { authorization: token }, params: { blogEntry } },
    ),
};
