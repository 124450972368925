import { useFocusEffect } from '@react-navigation/native';
import {
  ExtendedFlatlist,
  LoadingStatusModal,
  UIWrapper,
} from 'components/elements';
import fp from 'lodash/fp';
import { VStack } from 'native-base';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux-service/slices';
import { contactRequestsResources } from 'services/resources/contact-requests';
import { IContactRequest } from 'services/resources/contact-requests/types';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types';
import { ILoadingData } from 'types';

import { ContactRequestItem } from './components/ContactRequestItem';

export const ContactRequests: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [contactRequests, setContactRequests] = useState<IContactRequest[]>([]);

  /**
   * function for retrieving existing contact requests.
   */
  const retrieveContactRequests = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando solicitudes...',
    });
    try {
      const { data: d } = await contactRequestsResources.get(userData.token);
      const data = d as IContactRequest[];
      if (!fp.isEmpty(data)) {
        setContactRequests(data);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'contactRequests-retrieving',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * function for editing a contact request, specifically marking it as read
   * and setting its readBy prop to the user id.
   */
  const handlePutContactRequest = async (
    contactRequest: IContactRequest,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Actualizando solicitud...',
    });
    const updatedContactRequest = {
      contactReason: contactRequest.contactReason,
      email: contactRequest.email,
      fatherName: contactRequest.fatherName,
      firstName: contactRequest.firstName,
      id: contactRequest.id,
      phone: contactRequest.phone,
      readBy: userData.id,
    } as IContactRequest;
    try {
      await contactRequestsResources.put(updatedContactRequest, userData.token);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'contactRequests-updating',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    retrieveContactRequests();
  };

  useFocusEffect(
    useCallback(() => {
      retrieveContactRequests();
    }, []),
  );

  return (
    <UIWrapper title="Contacto">
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      <VStack bg="white" flex={1}>
        <ExtendedFlatlist
          data={contactRequests}
          noDataMessage="No se encontraron solicitudes de contacto."
          renderItem={
            <ContactRequestItem onMarkAsRead={handlePutContactRequest} />
          }
          searchBarPlaceholder="solicitudes"
          searchKey={['firstName', 'fatherName']}
          sort
          sortDateByTimeStamp={true}
          sortDatesInverted
          sortKey="date"
          useSearchBar
        />
      </VStack>
    </UIWrapper>
  );
};
