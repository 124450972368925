import { instance } from 'services/instance';
import {
  IContactRequest,
  IContactRequestPayload,
  IContactRequestsResponse
} from './types.d';

export const contactRequestsResources = {
  create: (payload: IContactRequestPayload, token: string): Promise<void> =>
    instance.post(
      '/contactRequestsAPI',
      { contactRequest: payload },
      { headers: { authorization: token } },
    ),

  get: (token: string): Promise<IContactRequestsResponse> =>
    instance.get('/contactRequestsAPI', {
      headers: { authorization: token },
    }),
  put: (payload: IContactRequest, token: string): Promise<void> =>
    instance.put(
      '/contactRequestsAPI',
      { contactRequest: payload },
      { headers: { authorization: token }, params: { updateField: 'all' } },
    ),
};
