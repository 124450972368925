import { AcceptDecline, AcceptDeclineModal } from 'components/elements';
import { DashboardInputField } from 'components/inputs';
import { Formik } from 'formik';
import { IStackProps, Text, VStack } from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { ICommission } from 'services/resources/users/types';

import { CommissionFormValidationSchema } from './helpers/form-helpers';

interface CommissionFormProps extends IStackProps {
  /**
   * Existing commission of the therapist.
   */
  previousCommission?: number;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: ICommission) => void;
}

export const CommissionForm: React.FC<CommissionFormProps> = (props) => {
  const { onCancel, onSubmit, previousCommission, ...rest } = props;

  const [disclaimerModalOpen, setDisclaimerModalOpen] =
    useState<boolean>(false);
  const themeData = useSelector(getTheme);

  return (
    <Formik
      initialValues={{} as ICommission}
      onSubmit={onSubmit}
      validationSchema={CommissionFormValidationSchema}
    >
      {({ handleChange, handleSubmit, errors, isValid }) => (
        <VStack {...rest}>
          <Text
            color={themeData.mainColorDark}
            fontSize={24}
            fontWeight="bold"
            pb={3}
            textAlign="center"
          >
            Comisión de Terapeuta
          </Text>
          <VStack>
            <Text
              color="black"
              fontSize={16}
              fontWeight="bold"
              mb={2}
              textAlign="center"
            >
              Ingresa el nuevo porcentaje de comisión:
            </Text>
            <DashboardInputField
              alignSelf="center"
              defaultValue={
                previousCommission === 0 ? '' : String(previousCommission)
              }
              error={errors.commission}
              onChangeText={handleChange('commission')}
            />
          </VStack>
          <AcceptDecline
            disabled={!isValid}
            mt={6}
            onAccept={() => setDisclaimerModalOpen(true)}
            onDecline={onCancel}
          />
          <AcceptDeclineModal
            isOpen={disclaimerModalOpen}
            onAccept={() => {
              setDisclaimerModalOpen(false);
              handleSubmit();
            }}
            onDecline={() => setDisclaimerModalOpen(false)}
          >
            ¿Estás seguro de querer continuar? (confirma que la comisión sea la
            correspondiente).
          </AcceptDeclineModal>
        </VStack>
      )}
    </Formik>
  );
};

CommissionForm.defaultProps = {
  w: '100%',
};
