import { usePlatform } from 'hooks/platform-hooks';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

import { InputField } from '../InputField';
import { IInputFieldProps } from '../types.d';

export const DashboardInputField: React.FC<IInputFieldProps> = (
  props,
): JSX.Element => {
  const { ...rest } = props;
  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  return (
    <InputField
      focusOutlineColor={themeData.mainColorDark}
      placeholderTextColor={themeData.mainColorDark}
      w={!web ? '90%' : '96%'}
      {...rest}
    />
  );
};

DashboardInputField.defaultProps = {
  _focus: { bg: 'white' },
  mx: 2,
};
