/* eslint-disable prettier/prettier */
import {
  alphaSpaceRegex,
  emailRegex,
  stringNumRegex,
} from 'helpers/data-helpers/regex-helpers';
import { IEventRegistryPayload } from 'services/resources/events-registries/types.d';
import * as Yup from 'yup';

export const eventSignUpFormCopies = {
  diseases: 'Enfermedades',
  email: 'Correo Electrónico',
  fullName: 'Nombre Completo',
  phone: 'Número con Whatsapp',
};

export const INITIAL_VALUES: IEventRegistryPayload = {
  assistedConfirmation: false,
  customFields: [],
  diseases: '',
  email: '',
  event: '',
  fullName: '',
  paid: false,
  phone: '',
};

export const EventSignUpFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, 'Debes ingresar un correo válido.')
    .required('Este campo es requerido.'),
  fullName: Yup.string()
    .matches(
      alphaSpaceRegex,
      'Tu nombre no puede contener caracteres especiales ni números.',
    )
    .required('Este campo es requerido.'),
  phone: Yup.string()
    .matches(
      stringNumRegex,
      'Tu número no puede contener caracteres especiales ni letras.',
    )
    .min(10, 'Tu número debe tener 10 dígitos.')
    .max(10, 'Tu número debe tener 10 dígitos.')
    .required('Este campo es requerido.'),
});
