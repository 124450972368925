import { MaterialIcons } from '@expo/vector-icons';
import { format } from 'date-fns';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import {
  Flex,
  HStack,
  IconButton,
  IStackProps,
  Text,
  VStack,
} from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IAppointmentWithInfoTaxDataAndNames } from 'services/resources/appointments-info/types.d';
import { colors } from 'styles/colors';

import { fieldOptions } from '../helpers/constant-helpers';
import { getInfoItemData } from '../helpers/data-helpers';
import { AppointmentDataItem } from './AppointmentDataItem';
import { AppointmentInfoItem } from './AppointmentInfoItem';

interface IAppointmentStatisticItemProps extends IStackProps {
  /**
   * Item to be used in ExtendedFlatlist. Should be explicitely read as a given
   * type.
   */
  item?: any;
}

export const AppointmentStatisticItem: React.FC<
  IAppointmentStatisticItemProps
> = (props) => {
  const { item, ...rest } = props;

  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const statisticItem = item as IAppointmentWithInfoTaxDataAndNames;

  const [isItemExpanded, setIsItemExpanded] = useState<boolean>(false);

  const toggleIsItemExpanded = (): void => {
    setIsItemExpanded(!isItemExpanded);
  };

  const verifyIsNotDiscardedField = (value: string): boolean => {
    if (value !== 'created' && value !== 'updated' && value !== 'cost') {
      return true;
    } else return false;
  };

  return (
    <VStack {...rest}>
      <HStack alignItems="center" justifyContent="space-around" p={3}>
        <VStack flex={7}>
          <Text
            fontSize={16}
            fontWeight="bold"
            maxW={!web ? '90%' : undefined}
            noOfLines={1}
          >{`Cita de ${statisticItem.therapistFullName} con ${statisticItem.patientFullName} `}</Text>
          <Text fontSize={16}>{`${format(
            new Date(statisticItem.appointment.date),
            'dd-MM-yyyy',
          )}`}</Text>
        </VStack>
        <Flex flex={1}>
          <IconButton
            alignSelf="flex-end"
            bg={themeData.mainColorDark}
            icon={
              <MaterialIcons
                color="white"
                name={`expand-${!isItemExpanded ? 'more' : 'less'}`}
                size={18}
              />
            }
            onPress={toggleIsItemExpanded}
            w="40px"
          />
        </Flex>
      </HStack>
      {isItemExpanded ? (
        <VStack bg={themeData.mainColorDark} py={2}>
          {statisticItem.appointment.cancelled ? (
            <Text color={colors.error} fontWeight="bold" pl={2} py={2}>
              Cita Cancelada
            </Text>
          ) : null}
          <AppointmentDataItem
            appointmentData={statisticItem.appointment}
            commission={statisticItem.info.commissionPercentage}
            receivedPayment={statisticItem.info.receivedPayment}
          />
          {Object.entries(statisticItem.info).map((k) => {
            // Check if the field exists in our mapped properties and if the
            // value is not empty
            const fieldExists =
              fieldOptions.some((fieldOption) => fieldOption.value === k[0]) &&
              !fp.isEmpty(k[1]);
            // If we passed the previous condition and are not dealing with
            // dev-only fields, return the corresponding data
            if (verifyIsNotDiscardedField(k[0]) && fieldExists) {
              return (
                <AppointmentInfoItem
                  info={getInfoItemData(k[0], k[1] as string)}
                  key={k[0]}
                />
              );
            } else return null;
          })}
        </VStack>
      ) : null}
    </VStack>
  );
};
