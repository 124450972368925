import { AcceptDecline, LoadingStatusModal } from 'components/elements';
import {
  ColorsField,
  DashboardInputField,
  DateField,
  LinksField,
  MultipleFilesField,
  SingleFileField,
  TimeField,
} from 'components/inputs';
import { getDate, getMonth, getYear, parseISO, set } from 'date-fns';
import { Formik } from 'formik';
import {
  getLinksArrayFromText,
  stringifyLinks,
} from 'helpers/data-helpers/string-helpers';
import fp from 'lodash/fp';
import { Divider, IStackProps, Text, VStack } from 'native-base';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import {
  ICustomField,
  IEvent,
  IEventPayload,
} from 'services/resources/events/types.d';
import { ILoadingData } from 'types.d';

import { CustomFields } from './components/CustomFields';
import {
  EventFormValidationSchema,
  INITIAL_VALUES,
} from './helpers/form-helpers';

interface EventFormProps extends IStackProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IEvent | IEventPayload) => void;
  /**
   * Values that are already set, used for editing them.
   */
  previousValues: IEvent;
  /**
   * Flag that indicates if we are performing an edition operation.
   */
  isEditing?: boolean;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Function that will connect to the backend to retrieve the cover colors.
   */
  handleStoreColors: (imageURI: string) => Promise<void>;
  /**
   * Function that will handle the temp files created by handleStoreColors
   * function.
   */
  handleTempFilesDeletion: () => Promise<void>;
  /**
   * Colors to be used as the palette for the form.
   */
  coverColors: string[];
  /**
   * The color that has been selected from the palette.
   */
  selectedColor: string;
  /**
   * State handler function for the selectedColor.
   */
  setSelectedColor: (color: string) => void;
  /**
   * Colors palette loading status from backend operations.
   */
  colorsLoading: ILoadingData;
}

export const EventForm: React.FC<EventFormProps> = (props) => {
  const {
    colorsLoading,
    onSubmit,
    previousValues,
    onCancel,
    isEditing = false,
    coverColors,
    handleStoreColors,
    handleTempFilesDeletion,
    setSelectedColor,
    selectedColor,
    ...rest
  } = props;

  const themeData = useSelector(getTheme);

  const previousValuesEnabled =
    !fp.isNil(previousValues) && !fp.isEmpty(previousValues);

  const [coverURI, setCoverURI] = useState<string>(
    previousValuesEnabled ? previousValues.cover : '',
  );
  const [mediaURIs, setMediaURIs] = useState<string[]>(
    previousValuesEnabled ? (previousValues.media as string[]) : [],
  );
  const [selectedDate, setSelectedDate] = useState<Date>(
    previousValuesEnabled ? new Date(previousValues.date) : new Date(),
  );
  const [customFields, setCustomFields] = useState<ICustomField[]>(
    previousValuesEnabled
      ? (previousValues.customFields as ICustomField[])
      : [],
  );

  const previousWithParsedDate = { ...previousValues };
  previousWithParsedDate.date = parseISO(`${previousValues.date}`);

  /**
   * Handler function for setting the full date (date and time).
   * @param previousTime
   * @param newDate
   */
  const handleOnDateChange = (previousTime: Date, newDate: Date): Date => {
    return set(previousTime, {
      date: getDate(newDate),
      month: getMonth(newDate),
      year: getYear(newDate),
    });
  };

  /**
   * Function that displays a message in the title of the form, depending on
   * the operation being performed (creation/edition).
   * @returns output form title message
   */
  const setTitleMessage = (): string => {
    if (fp.isEmpty(previousValues)) {
      return 'Nuevo Evento';
    } else if (!fp.isEmpty(previousValues)) {
      return 'Actualizar Evento';
    }
    return 'Nuevo Evento';
  };

  /**
   * Function that displays a message at the bottom of the form, depending on
   * the operation being performed (creation/edition).
   * @returns output form submit message
   */
  const setAcceptMessage = (): string => {
    if (fp.isEmpty(previousValues)) {
      return 'Añadir';
    } else if (!fp.isEmpty(previousValues)) {
      return 'Actualizar';
    }
    return 'Añadir';
  };

  return (
    <Formik
      initialValues={
        previousValuesEnabled ? previousWithParsedDate : INITIAL_VALUES
      }
      onSubmit={onSubmit}
      validationSchema={EventFormValidationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        setFieldValue,
        errors,
        isValid,
      }) => (
        <VStack {...rest}>
          <LoadingStatusModal loading={colorsLoading.loading}>
            {colorsLoading.loadingMessage}
          </LoadingStatusModal>
          <Text
            color={themeData.mainColorDark}
            fontSize={24}
            fontWeight="bold"
            pb={3}
            textAlign="center"
          >
            {setTitleMessage()}
          </Text>
          <VStack w="90%">
            <Text
              color={themeData.mainColorDark}
              fontSize={16}
              fontWeight="bold"
              textAlign="center"
            >
              Título:
            </Text>
            <DashboardInputField
              alignSelf="center"
              defaultValue={
                !fp.isNil(previousValues) ? previousValues.title : values.title
              }
              error={errors.title}
              onChangeText={handleChange('title')}
            />
          </VStack>
          <VStack justifyContent="center" pb={3} space={6} w="90%">
            <Text
              color={themeData.mainColorDark}
              fontSize={16}
              fontWeight="bold"
              textAlign="center"
            >
              Fecha y Horario:
            </Text>
            <DateField
              buttonHeight="40px"
              buttonLabel={
                !isEditing ? 'Fecha Seleccionada' : 'Seleccionar Fecha'
              }
              dateValue={selectedDate}
              disableButton={false}
              onDateChange={(d) => {
                const outputDate = handleOnDateChange(values.date, d);
                setSelectedDate(outputDate);
                setFieldValue('date', outputDate);
              }}
            />
            <TimeField
              buttonHeight="40px"
              buttonLabel={
                !isEditing ? 'Horario Seleccionado' : 'Seleccionar Horario'
              }
              onTimeChange={(t) => {
                setSelectedDate(t);
                setFieldValue('date', t);
              }}
              timeValue={selectedDate}
            />
          </VStack>
          <VStack>
            <Text
              color={themeData.mainColorDark}
              fontSize={16}
              fontWeight="bold"
              textAlign="center"
            >
              Costo:
            </Text>
            <DashboardInputField
              defaultValue={
                previousValuesEnabled
                  ? `${previousValues.cost}`
                  : `${values.cost}`
              }
              error={errors.cost}
              onChangeText={handleChange('cost')}
            />
          </VStack>
          <VStack w="90%">
            <Text
              color={themeData.mainColorDark}
              fontSize={16}
              fontWeight="bold"
              pt={1}
              textAlign="center"
            >
              Descripción:
            </Text>
            <DashboardInputField
              alignSelf="center"
              defaultValue={
                previousValuesEnabled
                  ? previousValues.description
                  : values.description
              }
              error={errors.description}
              onChangeText={handleChange('description')}
            />
          </VStack>
          <VStack w="90%">
            <Text
              color={themeData.mainColorDark}
              fontSize={16}
              fontWeight="bold"
              pt={1}
              textAlign="center"
            >
              Portada:
            </Text>
            <SingleFileField
              disabled={!fp.isEmpty(coverURI)}
              error={errors.cover}
              setSingleFileURI={(uri) => {
                setCoverURI(uri);
                setFieldValue('cover', uri);
                setFieldValue('media', mediaURIs);
                handleStoreColors(uri);
              }}
              singleFileURI={values.cover}
            >
              Elegir Archivo
            </SingleFileField>
          </VStack>
          <VStack pb={3} w="90%">
            <Text
              color={themeData.mainColorDark}
              fontSize={16}
              fontWeight="bold"
              pt={1}
              textAlign="center"
            >
              Medios:
            </Text>
            <MultipleFilesField
              disabled={!fp.isEmpty(mediaURIs)}
              multipleFilesURIs={values.media}
              setMultipleFilesURIs={(files) => {
                setMediaURIs(files);
                setFieldValue('cover', coverURI);
                setFieldValue('media', files);
              }}
            >
              Elegir Archivos
            </MultipleFilesField>
          </VStack>
          <VStack pb={2} w="90%">
            <Text
              color={themeData.mainColorDark}
              fontSize={16}
              fontWeight="bold"
              pt={3}
              textAlign="center"
            >
              Enlaces:
            </Text>
            <LinksField
              defaultValue={
                previousValuesEnabled
                  ? stringifyLinks(previousValues.videoLinks as string[])
                  : stringifyLinks(values.videoLinks as string[])
              }
              onChangeText={(text) => {
                setFieldValue('videoLinks', getLinksArrayFromText(text));
              }}
              title="Añade los links, separándolos por comas"
            />
          </VStack>
          <VStack justifyContent="center" pb={6}>
            <Text
              color={themeData.mainColorDark}
              fontSize={16}
              fontWeight="bold"
              mb={2}
              textAlign="center"
            >
              Color de acentuación:
            </Text>
            <ColorsField
              colorsData={coverColors}
              onColorSelect={(color) => {
                setSelectedColor(color);
                setFieldValue('accentColor', color);
              }}
              selectedColor={selectedColor}
            />
          </VStack>
          <VStack justifyContent="center" space={3} w="90%">
            <Divider bg={themeData.mainColorDark} h="2px" w="100%" />
            <Text
              color={themeData.mainColorDark}
              fontSize={16}
              fontWeight="bold"
              textAlign="center"
            >
              Campos Personalizados:
            </Text>
            <Text color={themeData.mainColorDark} px={3} textAlign="center">
              {!isEditing
                ? 'En esta sección, puedes solicitar campos de información personalizados para los formularios que llenarán los usuarios que asistan a los eventos (discapacidades, edad, género, etc).'
                : 'Recuerda que si modificas los campos personalizados, los registros a eventos que se hayan hecho no podrán cambiarse.'}
            </Text>
            <CustomFields
              customFields={customFields}
              setCustomFields={setCustomFields}
              w="100%"
            />
          </VStack>
          <AcceptDecline
            customAcceptMessage={setAcceptMessage()}
            disabled={!isValid}
            maxH="80px"
            mt={6}
            onAccept={() => {
              // If we are not editing an existing event
              if (fp.isEmpty(previousValues)) {
                setFieldValue('colorPalette', coverColors);
                setFieldValue('accentColor', selectedColor);
                setFieldValue('customFields', customFields);
                handleSubmit();
              } else {
                setFieldValue('colorPalette', coverColors);
                setFieldValue('accentColor', selectedColor);
                setFieldValue('customFields', customFields);
                setFieldValue('id', previousValues.id);
                handleSubmit();
              }
            }}
            onDecline={onCancel}
            py={4}
          />
        </VStack>
      )}
    </Formik>
  );
};

EventForm.defaultProps = {
  w: '100%',
};
