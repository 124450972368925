import {
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { IconTextButton, UIWrapper } from 'components/elements';
import { usePlatform } from 'hooks/platform-hooks';
import { HStack, Text, VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { colors } from 'styles/colors';

export const FinancialAdminDashboard: React.FC = (): JSX.Element => {
  const themeData = useSelector(getTheme);
  const navigation = useNavigation();
  const { web } = usePlatform();

  const handleScreenChange = (screen: string): void => {
    navigation.navigate(screen as never);
  };

  return (
    <UIWrapper title="Financiero">
      <Text
        color={themeData.mainColorDark}
        flex={1}
        fontSize={22}
        fontWeight="bold"
        pt={3}
        textAlign="center"
      >
        Selecciona un dashboard:
      </Text>
      <VStack alignItems="center" flex={9} mx={4} my={3}>
        <HStack
          alignItems="center"
          flex={1}
          justifyContent="space-around"
          space={2}
          w={!web ? '100%' : '50%'}
        >
          <IconTextButton
            bg={colors.terappiaPurple.dark}
            flex={1}
            icon={
              <MaterialIcons
                color="white"
                name="connect-without-contact"
                size={80}
              />
            }
            label={web ? 'Solicitudes de Contacto' : 'Solicitudes\nde Contacto'}
            labelColor="white"
            minH="80%"
            onPress={() => handleScreenChange('contactRequests')}
          />
          <IconTextButton
            bg={colors.terappiaBlue.dark}
            flex={1}
            icon={
              <Ionicons color="white" name="ios-stats-chart-sharp" size={80} />
            }
            label="Estadísticas"
            labelColor="white"
            minH="80%"
            onPress={() => handleScreenChange('statistics')}
          />
        </HStack>
        <HStack
          alignItems="center"
          flex={1}
          justifyContent="space-around"
          space={2}
          w={!web ? '100%' : '50%'}
        >
          <IconTextButton
            bg={colors.terappiaOrange.light}
            flex={1}
            icon={
              <FontAwesome5 color="white" name="calendar-check" size={80} />
            }
            label={web ? 'Administrador de Citas' : 'Administrador\nde Citas'}
            labelColor="white"
            minH="80%"
            onPress={() => handleScreenChange('appointmentManagement')}
          />
          <IconTextButton
            bg={colors.terappiaGreen.light}
            flex={1}
            icon={
              <MaterialCommunityIcons
                color="white"
                name="circle-slice-5"
                size={80}
              />
            }
            label={
              web
                ? 'Administrador de Comisiones'
                : 'Administrador\nde Comisiones'
            }
            labelColor="white"
            minH="80%"
            onPress={() => handleScreenChange('commissions')}
          />
        </HStack>
      </VStack>
    </UIWrapper>
  );
};
