import { IUser } from 'services/resources/users/types.d';

/**
 * Fuction that determines if a user has been deleted.
 * @param user
 * @returns
 */
export const isUserDeleted = (user: IUser): boolean => {
  let isDeleted: boolean = false;
  const { fatherName } = user;
  if (fatherName.includes('eliminado')) {
    isDeleted = true;
  }
  return isDeleted;
};
