import {
  API_KEY_DEV,
  API_KEY_PROD,
  AUTH_DOMAIN_DEV,
  AUTH_DOMAIN_PROD,
  ENVIRONMENT,
  PROJECT_ID_DEV,
  PROJECT_ID_PROD,
  STORAGE_BUCKET_DEV,
  STORAGE_BUCKET_PROD,
} from '@env';

const prodEnvironment: boolean = ENVIRONMENT === 'PROD';

export const appConfig = {
  apiKey: prodEnvironment ? API_KEY_PROD : API_KEY_DEV,
  authDomain: prodEnvironment ? AUTH_DOMAIN_PROD : AUTH_DOMAIN_DEV,
  projectId: prodEnvironment ? PROJECT_ID_PROD : PROJECT_ID_DEV,
  storageBucket: prodEnvironment ? STORAGE_BUCKET_PROD : STORAGE_BUCKET_DEV,
};
