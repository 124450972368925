import { MaterialIcons } from '@expo/vector-icons';
import { EditionButtons } from 'components/elements';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import {
  Divider,
  HStack,
  IconButton,
  IStackProps,
  Link,
  Text,
  VStack,
} from 'native-base';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import { IGlossaryEntry } from 'services/resources/glossary/types.d';

interface IGlossaryEntryProps extends IStackProps {
  /**
   * Item to be inherited from the cloneElement function.
   */
  item?: any;
  /**
   * Function for handling an item deletion on database.
   */
  onDelete: (term: IGlossaryEntry) => void;
  /**
   * Function for handling an item edition on database.
   */
  onEdit: (term: IGlossaryEntry) => void;
}

export const GlossaryEntryItem: React.FC<IGlossaryEntryProps> = (
  props,
): JSX.Element => {
  const { onDelete, onEdit, children, item, ...rest } = props;
  const themeData = useSelector(getTheme);
  const userData = useSelector(getUser);
  const { android } = usePlatform();

  const [childrenVisible, setChildrenVisible] = useState<boolean>(false);

  const toggleChildrenVisible = (): void => {
    setChildrenVisible(!childrenVisible);
  };

  const entry = item as IGlossaryEntry;

  const verifiedTherapist =
    !fp.isNil(userData.verifiedTherapist) && userData.verifiedTherapist;

  return (
    <VStack {...rest}>
      <HStack alignItems="center" flex={1} m={2} w="100%">
        <Text flex={3} fontWeight="bold" pl={2}>
          {entry.term}
        </Text>
        <HStack flex={5} justifyContent="flex-end" space={3}>
          {userData.accountType === 'therapist' && verifiedTherapist ? (
            <EditionButtons
              onDelete={() => onDelete(entry)}
              onEdit={() => onEdit(entry)}
            />
          ) : null}
          <IconButton
            icon={
              <MaterialIcons
                color={themeData.mainColorDark}
                name={
                  !childrenVisible ? 'keyboard-arrow-down' : 'keyboard-arrow-up'
                }
                size={24}
              />
            }
            mr={3}
            onPress={toggleChildrenVisible}
          />
        </HStack>
      </HStack>
      {childrenVisible ? (
        <VStack bg={themeData.mainColorDark} p={4}>
          <Text color="white" textAlign={!android ? 'justify' : 'center'}>
            {entry.description}
          </Text>
          {!fp.isEmpty(entry.links) ? (
            <>
              <Text color="white" fontWeight="bold" mt={2} textAlign="center">
                Recursos Útiles:
              </Text>
              {fp.isArray(entry.links) &&
                entry.links.map((link: string) => {
                  return (
                    <Link
                      _text={{ color: 'white', fontWeight: 'bold' }}
                      alignSelf="center"
                      href={link}
                      isExternal
                      key={`${entry.id}+${link}`}
                      py={2}
                    >
                      {link}
                    </Link>
                  );
                })}
            </>
          ) : null}
        </VStack>
      ) : null}
      <Divider />
    </VStack>
  );
};

GlossaryEntryItem.defaultProps = {
  flex: 1,
  w: '100%',
};
