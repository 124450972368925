import { numberRegex } from 'helpers/data-helpers/regex-helpers';
import * as Yup from 'yup';

export const EventFormValidationSchema = Yup.object().shape({
  cost: Yup.string()
    .matches(numberRegex, 'Debes ingresar un número válido.')
    .required('Este campo es requerido.'),
  cover: Yup.string().required(
    'Debes subir una portada para el evento. Solo se aceptan imágenes con formato .png y .jpg. Archivos superiores a 3 MB pueden ocasionar errores inesperados.',
  ),
  description: Yup.string().required('Este campo es requerido.'),
  title: Yup.string().required('Este campo es requerido.'),
});

export const INITIAL_VALUES = {
  accentColor: '#1d3761',
  colorPalette: [],
  cost: 0,
  cover: '',
  customFields: [],
  date: new Date(),
  description: '',
  id: '',
  media: [],
  title: '',
  videoLinks: [],
};
