import fp from 'lodash/fp';
import { IUserWithTasks } from 'services/resources/types';

export const sortByFirstName = (data: IUserWithTasks[]): IUserWithTasks[] => {
  return data.sort((a, b) => {
    return a.user.firstName.localeCompare(b.user.firstName);
  });
};

export const filterByName = (
  data: IUserWithTasks[],
  search: string,
): IUserWithTasks[] => {
  if (!fp.isEmpty(search)) {
    return data.filter((item) => {
      const loweredFullName =
        `${item.user.firstName} ${item.user.fatherName} ${item.user.fatherName}`.toLocaleLowerCase();
      const loweredSearch = search.toLocaleLowerCase();
      if (loweredFullName.includes(loweredSearch)) {
        return item;
      } else return null;
    });
  } else {
    return data;
  }
};
