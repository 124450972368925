import { MaterialIcons } from '@expo/vector-icons';
import { format } from 'date-fns';
import {
  Flex,
  HStack,
  IconButton,
  IStackProps,
  Text,
  VStack,
} from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IEventWithRegistries } from 'services/resources/events-registries/types';

import { EventDataItem } from './EventDataItem';

interface IEventStatisticItemProps extends IStackProps {
  /**
   * Item to be used in ExtendedFlatlist. Should be explicitely read as a given
   * type.
   */
  item?: any;
}

export const EventStatisticItem: React.FC<IEventStatisticItemProps> = (
  props,
) => {
  const { item, ...rest } = props;

  const themeData = useSelector(getTheme);

  const statisticItem = item as IEventWithRegistries;

  const [isItemExpanded, setIsItemExpanded] = useState<boolean>(false);

  const toggleIsItemExpanded = (): void => {
    setIsItemExpanded(!isItemExpanded);
  };

  return (
    <VStack {...rest}>
      <HStack alignItems="center" justifyContent="space-around" p={3}>
        <VStack flex={7}>
          <Text fontSize={16} fontWeight="bold">
            {` ${statisticItem.event.title} `}
          </Text>
          <Text fontSize={16}>{`${format(
            new Date(statisticItem.event.date),
            'dd-MM-yyyy',
          )}`}</Text>
        </VStack>
        <Flex flex={1}>
          <IconButton
            alignSelf="flex-end"
            bg={themeData.mainColorDark}
            icon={
              <MaterialIcons
                color="white"
                name={`expand-${!isItemExpanded ? 'more' : 'less'}`}
                size={18}
              />
            }
            onPress={toggleIsItemExpanded}
            w="40px"
          />
        </Flex>
      </HStack>
      {isItemExpanded ? (
        <VStack bg={themeData.mainColorDark} py={2}>
          <EventDataItem
            cost={statisticItem.event.cost}
            registries={statisticItem.registries}
          />
        </VStack>
      ) : null}
    </VStack>
  );
};
