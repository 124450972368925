import { IBlogEntryFormPayload } from 'services/resources/blog/types';
import * as Yup from 'yup';

export const BlogEntryValidationSchema = Yup.object().shape({
  content: Yup.string().required('Este campo es requerido.'),
  title: Yup.string().required('Este campo es requerido.'),
});

export const INITIAL_VALUES: IBlogEntryFormPayload = {
  content: '',
  title: '',
};
