import { InputScroller } from 'components/elements';
import { GlossaryTermForm } from 'components/forms';
import { usePlatform } from 'hooks/platform-hooks';
import { IModalProps, Modal } from 'native-base';
import {
  IGlossaryEntry,
  IGlossaryPayload,
} from 'services/resources/glossary/types.d';

interface IEntryModalProps extends IModalProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IGlossaryPayload | IGlossaryEntry) => Promise<void>;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Values that are already set, used for editing them.
   */
  previousValues?: IGlossaryEntry;
}

export const EntryModal: React.FC<IEntryModalProps> = (props): JSX.Element => {
  const { onSubmit, onCancel, previousValues = undefined, ...rest } = props;
  const { web } = usePlatform();

  return (
    <Modal {...rest}>
      {!web ? (
        <InputScroller
          bothOffsets
          contentContainerStyle={{
            width: '100%',
          }}
        >
          <Modal.Content alignItems="center" bg="white" borderRadius={0}>
            <GlossaryTermForm
              onCancel={onCancel}
              onSubmit={onSubmit}
              previousValues={previousValues}
            />
          </Modal.Content>
        </InputScroller>
      ) : (
        <Modal.Content alignItems="center" bg="white" borderRadius={10} w="30%">
          <GlossaryTermForm
            onCancel={onCancel}
            onSubmit={onSubmit}
            previousValues={previousValues}
          />
        </Modal.Content>
      )}
    </Modal>
  );
};

EntryModal.defaultProps = {
  avoidKeyboard: true,
  size: 'full',
};
