import { DashboardInputField } from 'components/inputs';
import { IUncontrolledInputProps } from 'components/inputs/types';
import { Formik } from 'formik';
import { getUserFullName } from 'helpers/data-helpers/string-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { Button, IStackProps, Text, VStack } from 'native-base';
import { createRef, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import {
  ICustomField,
  IEventRegistry,
  IEventRegistryPayload,
} from 'services/resources/events-registries/types.d';

import { UncontrolledDashboardInputField } from './components/UncontrolledDashboardInput';
import {
  eventSignUpFormCopies,
  EventSignUpFormValidationSchema,
  INITIAL_VALUES,
} from './helpers/form-helpers';

interface IEventSignUpFormProps extends IStackProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IEventRegistry | IEventRegistryPayload) => void;
  /**
   * Id corresponding to the event from which the user is trying to sign-up.
   */
  eventId: string;
  /**
   * Custom fields defined by ET staff, to be used in this event registry.
   */
  customFields: ICustomField[];
  /**
   * Flag to indicate weather the authentication was anonymous or not.
   */
  anonymous: boolean;
}

export const EventSignUpForm: React.FC<IEventSignUpFormProps> = (
  props,
): JSX.Element => {
  const { anonymous, onSubmit, eventId, customFields, ...rest } = props;
  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const [customFieldsState, setCustomFieldsState] =
    useState<ICustomField[]>(customFields);
  const [customFieldErrors, setCustomFieldErrors] = useState<boolean[]>([]);
  const [customFieldsValues, setCustomFieldsValues] = useState<ICustomField[]>(
    [],
  );

  // As the custom fields are rendered dynamically based on its iterability
  // property, using controlled inputs represented a rough user experience.
  // Therefore, to use uncontrolled inputs was decided.
  const inputRefs = useMemo(() => {
    return customFields.map(() => {
      return createRef<IUncontrolledInputProps>();
    });
  }, [customFields]);

  /**
   * Function that creates an ICustomField array based on the corresponding
   * values. It behaves differently depending on the platform. If it is web,
   * uses the uncontrolled inputs refs. Otherwise uses the customFieldsValues
   * state.
   * @returns ICustomField[] array
   */
  const getCustomFieldsValues = (): ICustomField[] => {
    if (web) {
      if (!fp.isEmpty(inputRefs)) {
        return inputRefs.map((ref, index) => {
          return {
            field: customFields[index].field,
            value: ref.current?.value as string,
          };
        });
      }
    } else {
      return customFieldsValues;
    }
    return [];
  };

  /**
   * Function that confirms if there is an error on a custom field by checking
   * weather is empty or not, since the custom fields if present, are supposed
   * to be required. The error presence is stored in the state.
   * @param index
   */
  const handleCustomFieldError = (index: number): void => {
    const customFieldsErrorsOutput = [...customFieldErrors];
    if (!fp.isEmpty(inputRefs[index].current?.value)) {
      customFieldsErrorsOutput[index] = false;
    } else {
      customFieldsErrorsOutput[index] = true;
    }
    setCustomFieldErrors(customFieldsErrorsOutput);
  };

  /**
   * Function required to handle mobile devices' inputs. It stores the text
   * in a state and also checks if the stored data is empty or not and therfore
   * raises an error.
   * @param t
   * @param index
   */
  const handleNativeChange = (t: string, index: number): void => {
    const fieldsSnapshot = [...customFieldsValues];
    const customFieldsErrorsOutput = [...customFieldErrors];
    fieldsSnapshot[index].value = t;
    if (!fp.isEmpty(fieldsSnapshot[index].value)) {
      customFieldsErrorsOutput[index] = false;
    } else {
      customFieldsErrorsOutput[index] = true;
    }
    setCustomFieldErrors(customFieldsErrorsOutput);
    setCustomFieldsValues(fieldsSnapshot);
  };

  /**
   * Function that checks the custom fields errors state and if detects at
   * least one returns the error status.
   * @returns
   */
  const checkCustomFieldsErrors = (): boolean => {
    let errorDetected = false;
    customFieldErrors.forEach((error) => {
      if (error) {
        errorDetected = true;
      }
    });
    return errorDetected;
  };

  /**
   * Function that uses Formik's isValid and values values for determining
   * if the criteria is met to allow form submission or not.
   * @param valid
   * @param values
   * @returns button disabled status
   */
  const getDisabled = (
    valid: boolean,
    values: IEventRegistryPayload,
  ): boolean => {
    if (
      !valid ||
      (!anonymous && fp.isEmpty(values.phone) && checkCustomFieldsErrors())
    ) {
      return true;
    }
    if (
      !valid ||
      (anonymous && fp.isEmpty(values.fullName)) ||
      fp.isEmpty(values.email) ||
      fp.isEmpty(values.phone) ||
      checkCustomFieldsErrors()
    ) {
      return true;
    }
    return false;
  };

  /**
   * Function that constructs initial values based on some stored user data.
   * Later this initial values are used in the form.
   * @returns initial values
   */
  const getExistingUserInitialValues = (): IEventRegistryPayload => {
    const InitialValuesSnapshot = { ...INITIAL_VALUES };
    InitialValuesSnapshot.email = userData.email;
    InitialValuesSnapshot.fullName = getUserFullName(userData);
    return InitialValuesSnapshot;
  };

  useEffect(() => {
    setCustomFieldsState(customFields);
    setCustomFieldErrors(new Array(customFields.length).fill(true));
    setCustomFieldsValues(customFields);
  }, [customFields]);

  return (
    <Formik
      initialValues={
        anonymous ? INITIAL_VALUES : getExistingUserInitialValues()
      }
      onSubmit={onSubmit}
      validationSchema={EventSignUpFormValidationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        setFieldValue,
        errors,
        isValid,
      }) => (
        <VStack {...rest}>
          <Text color={themeData.mainColorDark} mb={6} textAlign="center">
            Por favor, compártenos tus siguientes datos para poder registrarte:
          </Text>
          <DashboardInputField
            defaultValue={values.fullName}
            error={errors.fullName}
            isDisabled={!anonymous}
            onChangeText={handleChange('fullName')}
            placeholder={eventSignUpFormCopies.fullName}
          />
          <DashboardInputField
            defaultValue={values.email}
            error={errors.email}
            isDisabled={!anonymous}
            onChangeText={handleChange('email')}
            placeholder={eventSignUpFormCopies.email}
          />
          <DashboardInputField
            defaultValue={values.phone}
            error={errors.phone}
            onChangeText={handleChange('phone')}
            placeholder={eventSignUpFormCopies.phone}
          />
          <DashboardInputField
            defaultValue={values.diseases}
            error={errors.diseases}
            onChangeText={handleChange('diseases')}
            placeholder={eventSignUpFormCopies.diseases}
          />
          {customFieldsState.map((customField, index) => (
            <UncontrolledDashboardInputField
              error={
                customFieldErrors[index] ? 'Este campo es requerido' : undefined
              }
              innerRef={inputRefs[index]}
              key={`${customField.field}+${Math.random}`}
              onChangeText={(t) =>
                // When working with mobile devices, RN has issues with
                // uncontrolled inputs, so when that's the case, we actually
                // use controlled inputs.
                web
                  ? handleCustomFieldError(index)
                  : handleNativeChange(t, index)
              }
              placeholder={customField.field}
            />
          ))}
          <Button
            alignSelf="center"
            bg={themeData.mainColorDark}
            isDisabled={getDisabled(isValid, values)}
            onPress={() => {
              setFieldValue('event', eventId);
              setFieldValue('customFields', getCustomFieldsValues());
              handleSubmit();
            }}
            w="30%"
          >
            Registrarse
          </Button>
        </VStack>
      )}
    </Formik>
  );
};

EventSignUpForm.defaultProps = {
  bg: 'white',
  justifyContent: 'center',
};
