import { IAppointment } from 'services/resources/appointments/types.d';

/**
 * Function that filter the appointments returning only those that have not
 * been cancelled.
 * @param allAppointments
 * @returns non-cancelled appointments.
 */
export const getNonCancelledAppointments = (
  allAppointments: IAppointment[],
): IAppointment[] => {
  return allAppointments.filter((appointment) => {
    if (!appointment.cancelled) {
      return appointment;
    } else {
      return null;
    }
  });
};

/**
 * Function that filter the appointments returning only those that have already
 * been confirmed by the therapists.
 * @param allAppointments
 * @returns confirmed by therapist appointments.
 */
export const getTherapistConfirmedAppointments = (
  allAppointments: IAppointment[],
): IAppointment[] => {
  return allAppointments.filter((appointment) => {
    if (appointment.confirmedByTherapist) {
      return appointment;
    } else {
      return null;
    }
  });
};
