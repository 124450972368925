import { TherapyProgressForm } from 'components/forms';
import { usePlatform } from 'hooks/platform-hooks';
import { IModalProps, Modal } from 'native-base';
import {
  ITherapyProgress,
  ITherapyProgressPayload,
} from 'services/resources/therapy-progress/types';

interface IPutTherapyProgressModalProps extends IModalProps {
  /**
   * Function to update the therapy progress data.
   */
  onSubmitTherapyProgress: (
    payload: ITherapyProgress | ITherapyProgressPayload,
  ) => void;
  /**
   * Function to close the PutTherapyProgressModal.
   */
  onClosePutTherapyProgressModal: () => void;
  /**
   * Existing values of the the selected appointment with therapy progress.
   */
  previousValues: ITherapyProgress;
}

export const PutTherapyProgressModal: React.FC<
  IPutTherapyProgressModalProps
> = (props): JSX.Element => {
  const {
    onSubmitTherapyProgress,
    onClosePutTherapyProgressModal,
    previousValues,
    ...rest
  } = props;

  const { web } = usePlatform();

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        borderRadius={!web ? 0 : 10}
        justifyContent="center"
        p={6}
        w={!web ? '100%' : '30%'}
      >
        <TherapyProgressForm
          onCancel={onClosePutTherapyProgressModal}
          onSubmit={onSubmitTherapyProgress}
          previousValues={previousValues}
        />
      </Modal.Content>
    </Modal>
  );
};

PutTherapyProgressModal.defaultProps = {
  size: 'full',
};
