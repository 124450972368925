import { NativeBaseProvider } from 'native-base';
import { StatusBar } from 'react-native';
import { Provider } from 'react-redux';
import { reduxStore } from 'redux-service/store';
import { App } from './app';

export const TerAppia: React.FC = () => {
  return (
    <Provider store={reduxStore}>
      <NativeBaseProvider>
        <StatusBar backgroundColor="transparent" translucent />
        <App />
      </NativeBaseProvider>
    </Provider>
  );
};
