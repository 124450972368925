import fp from 'lodash/fp';

import { IAppointmentWithTherapyProgress } from '../types.d';

/**
 * Function that takes away all appointments with therapy progress where the
 * therapy progress is undefined.
 * @param appointmentsWithPotentialUndefinedTherapyProgress
 * @returns appointments with actual defined therapy progress
 */
export const getAppointmentsWithDefinedTherapyProgress = (
  appointmentsWithPotentialUndefinedTherapyProgress: IAppointmentWithTherapyProgress[],
): IAppointmentWithTherapyProgress[] => {
  return appointmentsWithPotentialUndefinedTherapyProgress.filter((aWPUTP) => {
    if (!fp.isNil(aWPUTP.therapyProgress)) {
      return aWPUTP;
    } else return null;
  });
};
