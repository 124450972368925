export const tasksDescription = {
  patient:
    'Consulta y marca como completadas las tareas asignadas por tus terapeutas.',
  therapist: 'Consulta, añade o edita nuevas tareas para tus pacientes.',
};

export const calendarDescription = {
  patient:
    'Consulta o cancela las citas que tienes con tus terapeutas. Consulta los eventos a los que te suscribiste.',
  therapist:
    'Crea, consulta, edita o cancela nuevas citas con tus pacientes. Consulta eventos.',
};

export const glossaryDescription = {
  patient:
    'Consulta las entradas de glosario y visita sitios relevantes relacionados con estas.',
  therapist: 'Crea, consulta o edita entradas para el glosario.',
};
