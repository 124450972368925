import { SearchBarField } from 'components/inputs';
import { FlatList, IStackProps, VStack } from 'native-base';
import { useState } from 'react';
import { IUserWithTasks } from 'services/resources/types';

import { filterByName, sortByFirstName } from '../helpers/data-helpers';
import { TasksCustomUserItem } from './TasksCustomUserItem';

interface ICustomFlatListProps extends IStackProps {
  /**
   * Data to be used in the flatlist.
   */
  data: any[];
  /**
   * Function to show the tasks' form modal associated to a given user.
   */
  toggleTasksModalVisible: (id: string) => void;
  /**
   * Account type of the currently logged user.
   */
  accountType: string;
}

export const CustomFlatList: React.FC<ICustomFlatListProps> = (
  props,
): JSX.Element => {
  const { accountType, data, toggleTasksModalVisible, ...rest } = props;

  const castedData = data as IUserWithTasks[];

  const [search, setSearch] = useState<string>('');

  return (
    <VStack {...rest}>
      <SearchBarField
        alignSelf="center"
        my={2}
        onChangeText={(text) => setSearch(text)}
        placeholder={accountType === 'therapist' ? 'paciente' : 'terapeuta'}
        w="95%"
      />
      <FlatList
        data={sortByFirstName(filterByName(castedData, search))}
        keyExtractor={(item) => item.user.id}
        renderItem={({ item }) => (
          <TasksCustomUserItem
            item={item}
            toggleTasksModalVisible={() =>
              toggleTasksModalVisible(item.user.id)
            }
          />
        )}
      />
    </VStack>
  );
};

CustomFlatList.defaultProps = {
  flex: 1,
};
