import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import {
  Box,
  Button,
  Circle,
  FlatList,
  HStack,
  Image,
  IStackProps,
  Link,
  Text,
  VStack,
} from 'native-base';
import { useCallback, useState } from 'react';
import QRCode from 'react-native-qrcode-svg';
import ViewShot from 'react-native-view-shot';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IEvent, IEventInfo } from 'services/resources/events/types.d';

interface ISelectedEventModalContentProps extends IStackProps {
  /**
   * Function that handles the QR save (store in Firebase).
   */
  saveQR: (event: string, snapshotURI: string) => Promise<void>;
  /**
   * Data of the selected Event.
   */
  eventData: IEvent;
}

export const SelectedEventModalContent: React.FC<
  ISelectedEventModalContentProps
> = (props) => {
  const { saveQR, eventData, ...rest } = props;

  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const [snapshotURI, setSnapshotURI] = useState<string>('');

  const eventInfo: IEventInfo = {
    app: 'terappia',
    id: eventData.id,
    path: `/eventSignUp/${eventData.id}`,
    section: 'eventSignUp',
  };

  const onCapture = useCallback((uri: string) => {
    setSnapshotURI(uri);
  }, []);

  return (
    <VStack {...rest}>
      <Text
        color={themeData.mainColorDark}
        fontSize={24}
        fontWeight="bold"
        pb={5}
        textAlign="center"
      >
        {eventData.title}
      </Text>
      <HStack alignSelf="center" px={5} space="120px">
        <VStack>
          <Text
            color={themeData.mainColorDark}
            fontSize={16}
            fontWeight="bold"
            textAlign="center"
          >
            Fecha:
          </Text>
          <Text>
            {`${format(new Date(eventData.date), 'EEE dd MMM yyyy', {
              locale: es,
            })} \n a las ${format(new Date(eventData.date), 'HH:mm')} hrs.`}
          </Text>
        </VStack>
        <VStack>
          <Text
            color={themeData.mainColorDark}
            fontSize={16}
            fontWeight="bold"
            textAlign="center"
          >
            Costo:
          </Text>
          <Text textAlign="center">{`$${eventData.cost}`}</Text>
        </VStack>
      </HStack>
      <Text
        color={themeData.mainColorDark}
        fontSize={16}
        fontWeight="bold"
        pt={5}
        textAlign="center"
      >
        Descripción:
      </Text>
      <Text px="25px" textAlign="center">
        {eventData.description}
      </Text>
      <Text
        color={themeData.mainColorDark}
        fontSize={16}
        fontWeight="bold"
        pb={2}
        pt={5}
        textAlign="center"
      >
        Portada:
      </Text>
      <Image
        alignSelf="center"
        alt="cover"
        size="xl"
        source={{
          uri: eventData.cover,
        }}
        w="100%"
      />
      {!fp.isEmpty(eventData.media) ? (
        <>
          <Text
            color={themeData.mainColorDark}
            fontSize={16}
            fontWeight="bold"
            pb={2}
            pt={5}
            textAlign="center"
          >
            Medios:
          </Text>
          {fp.isArray(eventData.media) ? (
            <FlatList
              data={eventData.media}
              horizontal
              keyExtractor={(item) => `${item}+${Math.random()}`}
              renderItem={({ item }) => (
                <Image
                  alt="media-image"
                  mr={2}
                  size="xl"
                  source={{
                    uri: item,
                  }}
                />
              )}
              showsHorizontalScrollIndicator
              style={{ marginHorizontal: 6 }}
            />
          ) : null}
        </>
      ) : null}
      {!fp.isEmpty(eventData.videoLinks) ? (
        <>
          <Text
            color={themeData.mainColorDark}
            fontSize={16}
            fontWeight="bold"
            pt={3}
            textAlign="center"
          >
            Enlaces:
          </Text>
          {!fp.isNil(eventData.videoLinks) &&
            eventData.videoLinks.map((link: string) => {
              return (
                <Link
                  _text={{
                    color: themeData.mainColorDark,
                    fontWeight: 'bold',
                  }}
                  alignSelf="center"
                  href={link}
                  isExternal
                  key={link}
                  py={2}
                >
                  {link}
                </Link>
              );
            })}
        </>
      ) : null}
      <HStack alignItems="center" justifyContent="center" py={2}>
        <Text
          color={themeData.mainColorDark}
          fontSize={16}
          fontWeight="bold"
          textAlign="center"
        >
          Color de acentuación:
        </Text>
        <Circle bg={eventData.accentColor} ml={2} size={5} />
      </HStack>
      <Text
        color={themeData.mainColorDark}
        fontSize={16}
        fontWeight="bold"
        pb={2}
        pt={2}
        textAlign="center"
      >
        QR del evento:
      </Text>
      <Box alignSelf="center" p={2}>
        {!web ? (
          <ViewShot
            captureMode="mount"
            onCapture={onCapture}
            options={{ format: 'jpg' }}
          >
            <QRCode size={200} value={JSON.stringify(eventInfo)} />
          </ViewShot>
        ) : (
          <div id="et-qr-container">
            <QRCode size={200} value={JSON.stringify(eventInfo)} />
          </div>
        )}
      </Box>
      <Button
        alignSelf="center"
        bg={themeData.mainColorDark}
        borderRadius={4}
        mb={5}
        onPress={() => saveQR(eventData.id, snapshotURI)}
        pt={2}
        w="60%"
      >
        Descargar QR
      </Button>
    </VStack>
  );
};

SelectedEventModalContent.defaultProps = { flex: 10 };
