import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { getLabelByField } from 'helpers/data-helpers/string-helpers';
import { useSmallScreen } from 'hooks/dimensions-hooks';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { Button, IStackProps, Text, VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import { fontSizes } from 'styles/theme';

export const StatusButtonGroup: React.FC<IStackProps> = (props) => {
  const { ...rest } = props;

  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);
  const navigation = useNavigation();
  const small = useSmallScreen();
  const { web } = usePlatform();

  const handleScreenChange = (screen: string): void => {
    // If user is patient
    if (
      userData.accountType === 'regular' ||
      userData.accountType === 'patient'
    ) {
      navigation.navigate(screen as never);
      // If user is therapist and is verified as therapist
    } else if (
      !fp.isNil(userData.verifiedTherapist) &&
      userData.verifiedTherapist
    ) {
      navigation.navigate(screen as never);
    }
  };

  return (
    <VStack {...rest}>
      <Text
        color={themeData.mainColorDark}
        fontSize={!small ? fontSizes.regular : fontSizes.smaller}
        fontWeight="bold"
        textAlign="center"
      >{`¡Hola ${userData.firstName}!\nAccede a alguno de tus dashboards:`}</Text>
      <Button
        _text={{
          color: themeData.mainColorDark,
          fontSize: !small ? fontSizes.regular : fontSizes.small,
          fontWeight: 'bold',
        }}
        alignSelf="center"
        bg="white"
        leftIcon={
          <AntDesign
            color={themeData.mainColorDark}
            name="user"
            size={!small ? 24 : 16}
          />
        }
        onPress={() => handleScreenChange(userData.accountType)}
        w={web ? '50%' : '100%'}
      >
        {getLabelByField('accountType', userData.accountType)}
      </Button>
      {!fp.isNil(userData.secretary) && userData.secretary ? (
        <Button
          _text={{
            color: themeData.mainColorDark,
            fontSize: !small ? fontSizes.regular : fontSizes.small,
            fontWeight: 'bold',
          }}
          alignSelf="center"
          bg="white"
          leftIcon={
            <AntDesign
              color={themeData.mainColorDark}
              name="contacts"
              size={!small ? 24 : 16}
            />
          }
          onPress={() => navigation.navigate('secretary' as never)}
          w={web ? '50%' : '100%'}
        >
          {getLabelByField('secretary')}
        </Button>
      ) : null}
      {!fp.isNil(userData.financialAdmin) && userData.financialAdmin ? (
        <Button
          _text={{
            color: themeData.mainColorDark,
            fontSize: !small ? fontSizes.regular : fontSizes.small,
            fontWeight: 'bold',
          }}
          alignSelf="center"
          bg="white"
          leftIcon={
            <MaterialIcons
              color={themeData.mainColorDark}
              name="attach-money"
              size={!small ? 24 : 16}
            />
          }
          onPress={() => navigation.navigate('financialAdmin' as never)}
          w={web ? '50%' : '100%'}
        >
          {getLabelByField('financialAdmin')}
        </Button>
      ) : null}
      {!fp.isNil(userData.administrator) && userData.administrator ? (
        <Button
          _text={{
            color: themeData.mainColorDark,
            fontSize: !small ? fontSizes.regular : fontSizes.small,
            fontWeight: 'bold',
          }}
          alignSelf="center"
          bg="white"
          leftIcon={
            <MaterialIcons
              color={themeData.mainColorDark}
              name="admin-panel-settings"
              size={!small ? 24 : 16}
            />
          }
          onPress={() => navigation.navigate('administrator' as never)}
          w={web ? '50%' : '100%'}
        >
          {getLabelByField('administrator')}
        </Button>
      ) : null}
    </VStack>
  );
};

StatusButtonGroup.defaultProps = {
  mr: 3,
  space: 2,
};
