import { AcceptDecline } from 'components/elements';
import { DashboardInputField } from 'components/inputs';
import { Formik } from 'formik';
import { IStackProps, Text, VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import {
  ITherapyProgress,
  ITherapyProgressPayload,
} from 'services/resources/therapy-progress/types';
import { TherapyProgresFormValidationSchema } from './helpers/form-helpers';

interface TherapyProgressFormProps extends IStackProps {
  /**
   * Values that are already set, used for editing them.
   */
  previousValues: ITherapyProgress;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: ITherapyProgress | ITherapyProgressPayload) => void;
}

export const TherapyProgressForm: React.FC<TherapyProgressFormProps> = (
  props,
) => {
  const { onCancel, onSubmit, previousValues, ...rest } = props;

  const themeData = useSelector(getTheme);

  return (
    <Formik
      initialValues={previousValues}
      onSubmit={onSubmit}
      validationSchema={TherapyProgresFormValidationSchema}
    >
      {({ handleChange, handleSubmit, errors, isValid }) => (
        <VStack {...rest}>
          <Text
            color={themeData.mainColorDark}
            fontSize={24}
            fontWeight="bold"
            pb={3}
            textAlign="center"
          >
            Progreso de Terapia
          </Text>
          <VStack>
            <Text
              color="black"
              fontSize={16}
              fontWeight="bold"
              mb={4}
              textAlign="center"
            >
              Progreso del Paciente:
            </Text>
            <DashboardInputField
              alignSelf="center"
              defaultValue={previousValues.patientProgress}
              error={errors.patientProgress}
              onChangeText={handleChange('patientProgress')}
            />
          </VStack>
          <VStack>
            <Text
              color="black"
              fontSize={16}
              fontWeight="bold"
              mb={4}
              textAlign="center"
            >
              Progreso como Terapeuta:
            </Text>
            <DashboardInputField
              alignSelf="center"
              defaultValue={previousValues.therapistProgress}
              error={errors.therapistProgress}
              onChangeText={handleChange('therapistProgress')}
            />
          </VStack>
          <AcceptDecline
            disabled={!isValid}
            mt={6}
            onAccept={handleSubmit}
            onDecline={onCancel}
          />
        </VStack>
      )}
    </Formik>
  );
};

TherapyProgressForm.defaultProps = {
  w: '100%',
};
