import { useNavigation } from '@react-navigation/native';
import {
  PeacefulPeak,
  PeacefulPeakWithLogo,
} from 'helpers/source-helpers/img-src-helpers';
import { storeWelcome } from 'helpers/storage-helpers/welcome-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import { useWelcomeRead } from 'hooks/welcome-hooks';
import { Button, Flex } from 'native-base';
import React, { useEffect, useState } from 'react';
import { ImageBackground } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

import { NextStepUI } from './components/NextStepUI';
import { styles } from './styles';

export const Welcome: React.FC = (props): JSX.Element => {
  const { ...rest } = props;
  const themeData = useSelector(getTheme);
  const navigation = useNavigation();
  const previousSession = useWelcomeRead();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const { ios } = usePlatform();

  const navigateLogIn = (): void => {
    storeWelcome();
    navigation.navigate('logIn' as never);
  };

  useEffect(() => {
    if (previousSession) navigation.navigate('logIn' as never);
  }, [previousSession]);

  return (
    <Flex {...rest}>
      <ImageBackground
        source={currentStep === 0 ? PeacefulPeakWithLogo : PeacefulPeak}
        style={styles.backgroundImage}
      />
      <Flex
        flex={1}
        h="100%"
        justifyContent={currentStep === 0 ? 'flex-end' : 'center'}
        position="absolute"
        w="100%"
      >
        {currentStep === 0 ? (
          <Button
            _text={{ color: themeData.mainColorDark, fontWeight: 'bold' }}
            bg="white"
            mb={!ios ? 4 : 12}
            ml={6}
            onPress={() => setCurrentStep(1)}
            w="50%"
          >
            Continuar
          </Button>
        ) : (
          <NextStepUI onContinue={navigateLogIn} />
        )}
      </Flex>
    </Flex>
  );
};

Welcome.defaultProps = {
  flex: 1,
};
