import { AntDesign } from '@expo/vector-icons';
import { format } from 'date-fns';
import fp from 'lodash/fp';
import {
  Button,
  Divider,
  HStack,
  IStackProps,
  Text,
  VStack,
} from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import {
  IAppointmentInfo,
  IAppointmentWithInfoAndNames,
} from 'services/resources/appointments-info/types';
import { colors } from 'styles/colors';

interface IAppointmentInfoItemProps extends IStackProps {
  /**
   * Item to be inherited from the ExtendedFlatList.
   */
  item?: any;
  /**
   * Function to toggle the appointment info form.
   */
  onOpenAppointmentInfoForm: (appointment: IAppointmentInfo) => void;
}

export const AppointmentInfoItem: React.FC<IAppointmentInfoItemProps> = (
  props,
): JSX.Element => {
  const { item, onOpenAppointmentInfoForm, ...rest } = props;
  const themeData = useSelector(getTheme);
  const appointment = item as IAppointmentWithInfoAndNames;
  const formFilled: boolean = !fp.isNil(appointment.info.acquisitionType);

  return (
    <VStack {...rest}>
      <HStack
        alignItems="center"
        justifyContent="space-between"
        pr={5}
        py={3}
        w="95%"
      >
        <VStack>
          <Text color={themeData.mainColorDark}>
            {`Cita de ${appointment.patientFullName} con ${appointment.therapistFullName}`}
          </Text>
          <Text color={themeData.mainColorDark}>
            {`${format(
              new Date(appointment.appointment.date),
              // eslint-disable-next-line quotes
              "dd-MM-yyyy, HH:mm 'hrs'",
            )}`}
          </Text>
        </VStack>
        <AntDesign
          color={formFilled ? colors.success : 'gray'}
          name="checkcircleo"
          size={24}
        />
      </HStack>
      <Button
        alignSelf="center"
        bg={formFilled ? colors.warning : colors.success}
        onPress={() => onOpenAppointmentInfoForm(appointment.info)}
        w="95%"
      >
        {formFilled
          ? 'Editar información adicional'
          : 'Añadir información adicional'}
      </Button>
      <Divider mt={3} />
    </VStack>
  );
};

AppointmentInfoItem.defaultProps = {
  alignItems: 'center',
};
