import { ITextProps, Text } from 'native-base';
import { useCallback } from 'react';
import { Linking } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

interface ILinkProps extends ITextProps {
  /**
   * Actual url from which is made reference.
   */
  href: string;
}

export const RNLink: React.FC<ILinkProps> = (props): JSX.Element => {
  const { href, children, ...rest } = props;

  const themeData = useSelector(getTheme);

  const handlePress = useCallback(async () => {
    const supported = await Linking.canOpenURL(href);

    if (supported) {
      await Linking.openURL(href);
    }
  }, [href]);

  return (
    <Text color={themeData.mainColorDark} onPress={handlePress} {...rest}>
      {children}
    </Text>
  );
};

RNLink.defaultProps = {
  fontWeight: 'bold',
  textDecorationLine: 'underline',
};
