export const authErrorHandler = {
  logInError(error: string) {
    switch (error) {
      case 'auth/wrong-password':
        return 'Contraseña Incorrecta.';
      case 'auth/user-not-found':
        return 'Usuario no encontrado.';
      case 'auth/not-verified':
        return 'Usuario no verificado.';
      default:
        return error;
    }
  },

  passwordResetError(error: string) {
    switch (error) {
      case 'auth/invalid-email':
        return 'Correo no registrado.';
      case 'auth/user-not-found':
        return 'Usuario no encontrado.';
      default:
        return error;
    }
  },

  signUpError(error: string) {
    switch (error) {
      case 'auth/invalid-email':
        return 'Correo no válido.';
      case 'auth/weak-password':
        return 'Contraseña muy débil.';
      case 'auth/email-already-in-use':
        return 'Este correo ya está en uso.';
      default:
        return error;
    }
  },
};
