import fp from 'lodash/fp';
import { IAppointmentWithInfoTaxDataAndNames } from 'services/resources/appointments-info/types.d';

/**
 * Function that counts how many appointments have not been cancelled from the
 * given input.
 * @param data
 * @returns
 */
export const getNonCancelledAppointmentsAmount = (
  data: IAppointmentWithInfoTaxDataAndNames[],
): number => {
  return data.filter((aWIAN) => {
    if (!aWIAN.appointment.cancelled) {
      return aWIAN;
    } else return null;
  }).length;
};

/**
 * Function that counts how many appointments have been cancelled from the
 * given input.
 * @param data
 * @returns
 */
export const getCancelledAppointmentsAmount = (
  data: IAppointmentWithInfoTaxDataAndNames[],
): number => {
  return data.filter((aWIAN) => {
    if (aWIAN.appointment.cancelled) {
      return aWIAN;
    } else return null;
  }).length;
};

/**
 * Function that counts how many appointments have been confirmed by the
 * corresponding therapists from the given input.
 * @param data
 * @returns
 */
export const getConfirmedByTherapistsAmount = (
  data: IAppointmentWithInfoTaxDataAndNames[],
): number => {
  return data.filter((aWIAN) => {
    if (aWIAN.appointment.confirmedByTherapist) {
      return aWIAN;
    } else return null;
  }).length;
};

/**
 * Function that counts how many appointments have been confirmed by the
 * corresponding registered third parties from the given input.
 * @param data
 * @returns
 */
export const getConfirmedByThirdPartiesAmount = (
  data: IAppointmentWithInfoTaxDataAndNames[],
): number => {
  return data.filter((aWIAN) => {
    if (aWIAN.appointment.confirmedByThird) {
      return aWIAN;
    } else return null;
  }).length;
};

/**
 * Function that does both: counts how much profits has ET made from the
 * given input and also calculates how much would the therapist gain from the
 * input.
 * @param data
 * @returns ETProfit and Therapist profit
 */
export const getProfitsAmounts = (
  data: IAppointmentWithInfoTaxDataAndNames[],
): { ETProfit: number; therapistProfit: number } => {
  let ETProfit = 0;
  let therapistProfit = 0;
  data.forEach(({ info: { commissionPercentage, receivedPayment } }) => {
    let appointmentProfit = 0;
    if (commissionPercentage !== 0 && !fp.isNil(receivedPayment)) {
      appointmentProfit =
        Number(receivedPayment) -
        (Number(receivedPayment) * commissionPercentage) / 100;
    } else if (!fp.isNil(receivedPayment)) {
      appointmentProfit = Number(receivedPayment);
    }
    ETProfit += appointmentProfit;
    if (
      receivedPayment !== 0 &&
      commissionPercentage !== 0 &&
      !fp.isNil(receivedPayment)
    ) {
      therapistProfit += (Number(receivedPayment) * commissionPercentage) / 100;
    }
  });
  return { ETProfit, therapistProfit };
};

/**
 * Function that filters all the appointments that match the given therapist.
 * @param data
 * @param therapist
 * @returns filtered by therapist appointments
 */
export const getAllFilteredAppointmentInfo = (
  data: IAppointmentWithInfoTaxDataAndNames[],
  therapist: string,
): IAppointmentWithInfoTaxDataAndNames[] => {
  if (fp.isEmpty(data)) {
    return data;
  }
  return data.filter(
    (appointment) => appointment.appointment.therapist === therapist,
  );
};
