import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { IconTextButton, UIWrapper } from 'components/elements';
import { usePlatform } from 'hooks/platform-hooks';
import { Text, VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { colors } from 'styles/colors';

export const AdministratorDashboard: React.FC = (): JSX.Element => {
  const themeData = useSelector(getTheme);
  const navigation = useNavigation();
  const { web } = usePlatform();

  const handleScreenChange = (screen: string): void => {
    navigation.navigate(screen as never);
  };

  return (
    <UIWrapper title="Administrador">
      <Text
        color={themeData.mainColorDark}
        flex={1}
        fontSize={22}
        fontWeight="bold"
        pt={3}
        textAlign="center"
      >
        Selecciona un dashboard:
      </Text>
      <VStack alignItems="center" flex={7} my={3} space={3}>
        <VStack
          alignItems="center"
          flex={1}
          justifyContent="space-around"
          space={3}
        >
          <IconTextButton
            bg={colors.terappiaOrange.light}
            icon={<Feather color="white" name="user-check" size={90} />}
            label="Solicitudes de Terapeutas"
            labelColor="white"
            minH={!web ? '40%' : undefined}
            onPress={() => handleScreenChange('administratorRequests')}
            p={5}
          />
        </VStack>
      </VStack>
    </UIWrapper>
  );
};
