import { FontAwesome } from '@expo/vector-icons';
import { AcceptDecline } from 'components/elements';
import { DashboardInputField } from 'components/inputs';
import { Formik } from 'formik';
import fp from 'lodash/fp';
import { Button, Checkbox, Text, VStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import {
  IPredefinedTask,
  ITask,
  ITaskPayload,
} from 'services/resources/tasks/types.d';
import { fontSizes } from 'styles/theme';

import { INITIAL_VALUES } from './helpers/form-helpers';

interface ITaskFormProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: ITaskPayload | ITask) => void;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Function for showing/hiding the predefined tasks modal.
   */
  togglePredefinedTasksModal: () => void;
  /**
   * Function for giving a name to to the predefined task in case we want
   * to add one.
   */
  onPredefinedNameChange: (name: string) => void;
  /**
   * Values that are already set, used for editing them.
   */
  previousValues?: ITask;
  /**
   * Predefined task to be used for the form if needed.
   */
  selectedPredefined?: IPredefinedTask;
}

export const TaskForm: React.FC<ITaskFormProps> = (props) => {
  const {
    onSubmit,
    onCancel,
    previousValues = undefined,
    selectedPredefined = undefined,
    togglePredefinedTasksModal,
    onPredefinedNameChange,
  } = props;
  const themeData = useSelector(getTheme);

  const previousEnabled =
    !fp.isNil(previousValues) && !fp.isEmpty(previousValues);
  const predefinedEnabled =
    !fp.isNil(selectedPredefined) && !fp.isEmpty(selectedPredefined);

  const [predefined, setPredefined] = useState<boolean>(false);
  const [newPreviousPayload, setNewPreviousPayload] = useState<ITask>({
    ...(previousValues as ITask),
    description: !predefinedEnabled
      ? (previousValues?.description as string)
      : selectedPredefined.description,
  });

  const togglePredefined = (): void => {
    setPredefined(!predefined);
  };

  const setTitleMessage = (): string => {
    if (!previousEnabled) {
      return 'Nueva Tarea';
    } else {
      return 'Actualizar Tarea';
    }
  };

  const setAcceptMessage = (): string => {
    if (!previousEnabled) {
      return 'Añadir';
    } else {
      return 'Actualizar';
    }
  };

  const getRightValue = (formValue: string): string => {
    if (!previousEnabled && predefinedEnabled) {
      return selectedPredefined.description;
    } else if (previousEnabled) {
      return previousValues.description;
    } else {
      return formValue;
    }
  };

  const getDisabledCases = (values: ITask | ITaskPayload): boolean => {
    // When we don't have predefined but the description remains the same
    // as in the previous values
    if (
      previousEnabled &&
      !predefinedEnabled &&
      values.description === newPreviousPayload.description
    ) {
      return true;
    }
    // When we don't have predefined, we are creating a new task and the
    // description remains empty
    if (
      !previousEnabled &&
      !predefinedEnabled &&
      fp.isEmpty(values.description)
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (predefinedEnabled) {
      setNewPreviousPayload({
        ...newPreviousPayload,
        description: selectedPredefined.description,
      });
    }
  }, [selectedPredefined]);

  return (
    <Formik
      initialValues={!previousEnabled ? INITIAL_VALUES : newPreviousPayload}
      key={JSON.stringify(newPreviousPayload)}
      onSubmit={onSubmit}
    >
      {({ handleChange, handleSubmit, values, setFieldValue }) => (
        <VStack alignItems="center" justifyContent="center" w="100%">
          <Text
            color={themeData.mainColorDark}
            fontSize={fontSizes.regular}
            fontWeight="bold"
            py={4}
            textAlign="center"
          >
            {setTitleMessage()}
          </Text>
          <Text color={themeData.mainColorDark} py={2} textAlign="center">
            Descripción
          </Text>
          <DashboardInputField
            defaultValue={getRightValue(values.description)}
            onChangeText={handleChange('description')}
          />
          <Button
            leftIcon={
              <FontAwesome color="white" name="folder-open-o" size={24} />
            }
            mt={4}
            onPress={togglePredefinedTasksModal}
          >
            Seleccionar Tarea Predefinida
          </Button>
          <Checkbox my={4} onChange={togglePredefined} value="predefined">
            Agregar a tareas predefinidas
          </Checkbox>
          {predefined ? (
            <>
              <Text color={themeData.mainColorDark} py={2} textAlign="center">
                Nombre de la Tarea Predefinida
              </Text>
              <DashboardInputField onChangeText={onPredefinedNameChange} />
            </>
          ) : null}
          <AcceptDecline
            customAcceptMessage={setAcceptMessage()}
            disabled={getDisabledCases(values)}
            mt={6}
            onAccept={() => {
              // For the case where we have predefined task and want to set a
              // new task
              if (predefinedEnabled && fp.isEmpty(values.description)) {
                setFieldValue('description', selectedPredefined.description);
              }
              setPredefined(false);
              handleSubmit();
            }}
            onDecline={onCancel}
            py={4}
          />
        </VStack>
      )}
    </Formik>
  );
};
