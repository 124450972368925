import { InputScroller } from 'components/elements';
import { AppointmentInfoForm } from 'components/forms/AppointmentInfoForm';
import { usePlatform } from 'hooks/platform-hooks';
import { IModalProps, Modal } from 'native-base';
import { ScrollView } from 'react-native';
import { IAppointmentInfo } from 'services/resources/appointments-info/types';

interface IAppointmentInfoFormModalProps extends IModalProps {
  /**
   * Values that are already set, used for editing them.
   */
  previousValues: IAppointmentInfo;
  /**
   * Flag to indicate whether to show the form or not.
   */
  isFormVisible: boolean;
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IAppointmentInfo) => void;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
}

export const AppointmentInfoFormModal: React.FC<
  IAppointmentInfoFormModalProps
> = (props) => {
  const { isFormVisible, onSubmit, onCancel, previousValues, ...rest } = props;
  const { web } = usePlatform();
  return (
    <Modal isOpen={isFormVisible} {...rest}>
      <Modal.Content alignItems="center" w={!web ? '100%' : '30%'}>
        {!web ? (
          <InputScroller
            bottomOffset
            contentContainerStyle={{
              width: '100%',
            }}
            disableScrollToStart
          >
            <AppointmentInfoForm
              onCancel={onCancel}
              onSubmit={onSubmit}
              previousValues={previousValues}
            />
          </InputScroller>
        ) : (
          <ScrollView showsVerticalScrollIndicator style={{ width: '100%' }}>
            <AppointmentInfoForm
              onCancel={onCancel}
              onSubmit={onSubmit}
              previousValues={previousValues}
            />
          </ScrollView>
        )}
      </Modal.Content>
    </Modal>
  );
};

AppointmentInfoFormModal.defaultProps = {
  justifyContent: 'center',
  size: 'full',
};
