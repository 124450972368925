import { Ionicons } from '@expo/vector-icons';
import { useNotifications } from 'hooks/notifications-hooks';
import { Badge, Flex, IconButton, IFlexProps } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { notificationsResources } from 'services/resources/notifications';
import { IFirebaseNotification } from 'services/resources/notifications/types.d';

import { NotificationModal } from './NotificationModal';

export const NotificationBadge: React.FC<IFlexProps> = (props): JSX.Element => {
  const { ...rest } = props;
  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);

  const [visible, setVisible] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<IFirebaseNotification[]>(
    [],
  );
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);

  let newNotification: any | null | undefined;
  // Only use hook if is not web as currently is not supported
  if (Platform.OS !== 'web') newNotification = useNotifications();

  const toggleVisible = (): void => {
    setVisible(!visible);
  };

  /**
   * Check if there are notifications that are not read by the user yet.
   */
  const getUnreadNotifications = (
    notificationsToCheck: IFirebaseNotification[],
  ): void => {
    let unreadCount = 0;
    notificationsToCheck.length > 0 &&
      notificationsToCheck.forEach((notification) => {
        if (!notification.read) {
          unreadCount += 1;
        }
      });
    setUnreadNotifications(unreadCount);
  };

  /**
   * Retrieve notifications for the current user.
   */
  const getUserNotifications = async (): Promise<void> => {
    try {
      const { data: retrievedNotifications } = await notificationsResources.get(
        userData.id,
        userData.token,
      );
      setNotifications(retrievedNotifications as IFirebaseNotification[]);
      getUnreadNotifications(retrievedNotifications as IFirebaseNotification[]);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'notifications',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
      setNotifications([]);
    }
  };

  // Retrieve notifications if notification event triggered for mobile devices
  useEffect(() => {
    getUserNotifications();
  }, [newNotification, visible]);

  useEffect(() => {
    if (Platform.OS === 'web' && userData.logged) {
      setTimeout(() => {
        getUserNotifications();
      }, 10000);
    }
  });

  return (
    <Flex {...rest}>
      <NotificationModal
        notifications={notifications}
        toggleVisible={toggleVisible}
        visible={visible}
      />
      <IconButton
        _hover={{ bg: 'transparent' }}
        _pressed={{ bg: 'transparent' }}
        icon={
          <Ionicons
            color={themeData.mainColorDark}
            name="notifications"
            size={28}
          />
        }
        onPress={toggleVisible}
      />
      {unreadNotifications > 0 ? (
        <Badge
          _text={{
            fontSize: 12,
          }}
          colorScheme="danger"
          left={Platform.OS !== 'web' ? 12 : 40}
          position="absolute"
          rounded="full"
          variant="solid"
        >
          {unreadNotifications}
        </Badge>
      ) : null}
    </Flex>
  );
};
