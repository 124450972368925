import { Ionicons } from '@expo/vector-icons';
import { compareDesc, secondsToMilliseconds as sTM } from 'date-fns';
import { usePlatform } from 'hooks/platform-hooks';
import { Button, IModalProps, Modal, Text } from 'native-base';
import React, { useEffect } from 'react';
import { Dimensions, FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { notificationsResources } from 'services/resources/notifications';
import {
  IDateObject,
  IFirebaseNotification,
} from 'services/resources/notifications/types.d';

import { NotificationItem } from './NotificationItem';

interface INotificationModalProps extends IModalProps {
  /**
   * Function for toggling the visible status of the modal.
   */
  toggleVisible: () => void;
  /**
   * Notifications to be shown as the content of the modal.
   */
  notifications: IFirebaseNotification[];
  /**
   * Visible status of the modal.
   */
  visible: boolean;
}

export const NotificationModal: React.FC<INotificationModalProps> = (props) => {
  const { toggleVisible, notifications, visible, ...rest } = props;

  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  /**
   * Once the notifications modal is displayed, set the pending notifications
   * as read.
   */
  const setReadNotifications = async (): Promise<void> => {
    await Promise.all(
      notifications.map(async (notification) => {
        if (!notification.read) {
          try {
            await notificationsResources.putRead(
              notification.id,
              userData.token,
            );
          } catch (e) {
            const newLog: ILogEntry = {
              date: new Date(),
              message: JSON.stringify(e),
              service: 'notifications',
              user: userData.email,
            };
            logsResources.create(newLog, userData.token);
          }
        }
      }),
    );
  };

  useEffect(() => {
    if (visible) setReadNotifications();
  }, [notifications]);

  return (
    <Modal isOpen={visible} {...rest}>
      <Modal.Content
        alignItems="center"
        bg="white"
        borderRadius={10}
        justifyContent="center"
        maxH={Dimensions.get('screen').height / 1.2}
        position="absolute"
        shadow="none"
        w={!web ? Dimensions.get('screen').width / 1.2 : '30%'}
      >
        <Text
          color={themeData.mainColorDark}
          fontSize={16}
          fontWeight="bold"
          py={3}
          textAlign="center"
        >
          Notificaciones
        </Text>
        {notifications.length > 0 ? (
          <FlatList
            data={notifications.sort((a, b) => {
              const aCreated = a.created as IDateObject;
              const bCreated = b.created as IDateObject;
              const dateA = new Date(sTM(aCreated._seconds));
              const dateB = new Date(sTM(bCreated._seconds));
              return compareDesc(dateA, dateB);
            })}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => <NotificationItem notification={item} />}
          />
        ) : (
          <Text
            fontSize={12}
            fontWeight="bold"
            mb="14px"
            mt="2px"
            textAlign="center"
          >
            Aún no tienes notificaciones
          </Text>
        )}
        <Button
          _text={{ fontWeight: 'bold', ml: '6px' }}
          bg={themeData.mainColorDark}
          justifyContent="center"
          leftIcon={
            <Ionicons
              color="white"
              name="arrow-back-circle-outline"
              size={24}
            />
          }
          my={3}
          onPress={toggleVisible}
          w="95%"
        >
          Volver
        </Button>
      </Modal.Content>
    </Modal>
  );
};

NotificationModal.defaultProps = {
  size: 'full',
};
