import fp from 'lodash/fp';
import { Flex } from 'native-base';
import { useEffect, useState } from 'react';
import { ImageBackground } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { StatusSheet } from '../StatusSheet';
import { IUIWrapperProps } from '../types.d';

import { styles } from './styles';

export const ExternalUIWrapper: React.FC<IUIWrapperProps> = (
  props,
): JSX.Element => {
  const {
    error = false,
    children,
    rNOpacity = 0.2,
    operationStatus = '',
    onDiscard = () => {},
    ...rest
  } = props;
  const themeData = useSelector(getTheme);

  const [statusOpen, setStatusOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!fp.isEmpty(operationStatus)) setStatusOpen(true);
  }, [error, operationStatus]);

  return (
    <Flex bg={themeData.mainColorLight} {...rest}>
      <ImageBackground
        source={themeData.wallpaperSrc}
        style={[styles.backgroundImage, { opacity: rNOpacity }]}
      />
      <Flex
        flex={1}
        h="100%"
        justifyContent="center"
        position="absolute"
        w="100%"
      >
        {children}
      </Flex>
      <StatusSheet
        error={error}
        isOpen={statusOpen}
        onClose={() => {
          setStatusOpen(false);
          onDiscard();
        }}
      >
        {operationStatus}
      </StatusSheet>
    </Flex>
  );
};

ExternalUIWrapper.defaultProps = {
  flex: 1,
};
