import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { usePlatform } from 'hooks/platform-hooks';
import { Button, IButtonProps } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

export const BackButton: React.FC<IButtonProps> = (props): JSX.Element => {
  const { ...rest } = props;
  const themeData = useSelector(getTheme);
  const navigation = useNavigation();
  const { web } = usePlatform();
  return (
    <Button
      _text={{ color: themeData.mainColorDark }}
      leftIcon={
        <Feather
          color={themeData.mainColorDark}
          name="arrow-left-circle"
          size={24}
        />
      }
      mt={web ? -4 : 6}
      onPress={() => navigation.navigate('logIn' as never)}
      {...rest}
    >
      Inicia Sesión
    </Button>
  );
};

BackButton.defaultProps = {
  _hover: { bg: 'transparent' },
  alignSelf: 'flex-start',
  bg: 'transparent',
  justifyContent: 'center',
  ml: 9,
};
