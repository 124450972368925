/* eslint-disable prefer-const */
import fp from 'lodash/fp';
import { IUserState } from 'redux-service/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { userResources } from 'services/resources/users';
import { IUser, IUserDevices } from 'services/resources/users/types.d';

/**
 * Function for retrieving and storaging the administrators devices ids
 * @param user
 * @param token
 * @returns administrator' devices ids and administrators' ids
 */
export const getAdministratorsDevices = async (
  user: string,
  token: string,
): Promise<{
  administrators: IUserDevices[];
}> => {
  let administrators: IUserDevices[] = [] as IUserDevices[];
  try {
    // Get administrators' ids
    const { data: administatorsIds } = await userResources.getAdmins(token);
    if (!fp.isNil(administatorsIds)) {
      // Get administrators' full data
      const retrievedAdministrators = await Promise.all(
        administatorsIds.map(async (administratorId) => {
          try {
            const { data: administrator } = await userResources.getById(
              administratorId,
              token,
            );
            return administrator;
          } catch (e) {
            const newLog: ILogEntry = {
              date: new Date(),
              message: JSON.stringify({
                e,
                message: 'Retrieve administrator error',
              }),
              service: 'recipes-getAdministratorsDevices',
              user,
            };
            logsResources.create(newLog, token);
          }
        }),
      );
      if (
        retrievedAdministrators.length > 0 &&
        !fp.isNil(retrievedAdministrators[0])
      ) {
        retrievedAdministrators.forEach((a) => {
          const administrator = a as IUser;
          administrators.push({
            deviceId: administrator.deviceId,
            id: administrator.id,
          });
        });
      }
    }
  } catch (e) {
    const newLog: ILogEntry = {
      date: new Date(),
      message: JSON.stringify({ e, message: 'Retrieve administrator error' }),
      service: 'recipes-getAdministratorsDevices',
      user,
    };
    logsResources.create(newLog, token);
  }
  return { administrators };
};

/**
 * Function that retrieves full data of the patients for a given therapist.
 * @param therapist
 * @returns therapist patients
 */
export const handleGetPatients = async (
  therapist: IUserState,
): Promise<{ patients: IUser[]; patientsIds: string[] }> => {
  let patients: IUser[] = [];
  let patientsIds: string[] = [];
  try {
    const { data: d } = await userResources.getPatients(
      therapist.id,
      therapist.token,
    );
    const data = d as string[];
    // We verify that there is at least one patient
    if (data.length > 0) {
      patientsIds = data;
      // We wait until all the promises are resolved or one is rejected
      patients = await Promise.all(
        data.map(async (patient) => {
          const { data } = await userResources.getById(
            patient,
            therapist.token,
          );
          return data as IUser;
        }),
      );
    }
  } catch (e) {
    const newLog: ILogEntry = {
      date: new Date(),
      message: JSON.stringify(e),
      service: 'recipes-handleGetPatients',
      user: therapist.email,
    };
    logsResources.create(newLog, therapist.token);
  }
  return { patients, patientsIds };
};

/**
 * Function that retrieves full data of the therapists for a given patient.
 * @param patient
 * @returns therapists
 */
export const handleGetTherapists = async (
  patient: IUserState,
): Promise<{ therapists: IUser[]; therapistsIds: string[] }> => {
  let therapists: IUser[] = [];
  let therapistsIds: string[] = [];
  try {
    const { data: d } = await userResources.getTherapists(
      patient.id,
      patient.token,
    );
    const data = d as string[];
    // We verify that there is at least one therapist
    if (data.length > 0) {
      therapistsIds = data;
      // We wait until all the promises are resolved or one is rejected
      therapists = await Promise.all(
        data.map(async (therapist) => {
          const { data } = await userResources.getById(
            therapist,
            patient.token,
          );
          return data as IUser;
        }),
      );
    }
  } catch (e) {
    const newLog: ILogEntry = {
      date: new Date(),
      message: JSON.stringify(e),
      service: 'recipes-handleGetTherapists',
      user: patient.email,
    };
    logsResources.create(newLog, patient.token);
  }
  return { therapists, therapistsIds };
};
