import * as RootNavigation from 'app/root-navigation';
import { FormattedEmailField, PasswordField } from 'components/inputs';
import { Formik } from 'formik';
import { useSmallScreen } from 'hooks/dimensions-hooks';
import fp from 'lodash/fp';
import { Button, Divider, IStackProps, Text, VStack } from 'native-base';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IBaseAuthentication } from 'services/resources/auth/types.d';
import { buttonSizes, fontSizes } from 'styles/theme';

import {
  INITIAL_VALUES,
  LoginFormValidationSchema,
} from './helpers/form-helpers';

interface ILoginFormProps extends IStackProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IBaseAuthentication) => Promise<void>;
  /**
   * Function triggered when a screen change was required.
   */
  onScreenChange?: () => void;
  /**
   * Route from which the form was called.
   */
  origin?: string;
}

export const LogInForm: React.FC<ILoginFormProps> = (props) => {
  const { onSubmit, onScreenChange = () => {}, origin = '', ...rest } = props;
  const themeData = useSelector(getTheme);
  const small = useSmallScreen();
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={LoginFormValidationSchema}
    >
      {({
        errors,
        handleChange,
        isValid,
        handleSubmit,
        values,
        setFieldValue,
      }) => (
        <VStack {...rest}>
          <FormattedEmailField
            error={errors.email}
            onChangeText={(text) => setFieldValue('email', text.trim())}
            overflow="hidden"
            value={values.email}
            w="100%"
          />
          <PasswordField
            _focus={{ bg: 'white' }}
            bg="white"
            borderColor="transparent"
            borderRadius={15}
            color={themeData.mainColorDark}
            customShowHideColor={themeData.mainColorDark}
            customShowHideSize={18}
            error={errors.password}
            focusOutlineColor="transparent"
            fontSize={fontSizes.regular}
            onChangeText={handleChange('password')}
            overflow="hidden"
            placeholderTextColor={themeData.mainColorDark}
            textAlign="center"
            w="100%"
          />
          <Button
            _text={{
              color: 'white',
              fontSize: !small ? fontSizes.regular : fontSizes.smaller,
              fontWeight: 'bold',
            }}
            bg={themeData.mainColorDark}
            borderRadius={15}
            h={!small ? undefined : buttonSizes.small}
            isDisabled={!isValid || values.email === ''}
            mb={20}
            mt={8}
            mx={10}
            onPress={() => handleSubmit()}
          >
            Iniciar Sesión
          </Button>
          <Text
            color={themeData.mainColorDark}
            fontSize={!small ? fontSizes.regular : fontSizes.small}
            fontWeight="bold"
            onPress={() => {
              onScreenChange();
              RootNavigation.navigate('passwordReset');
            }}
            textAlign="center"
          >
            ¿Olvidaste tu contraseña?
          </Text>
          <Divider
            alignSelf="center"
            bg={themeData.mainColorDark}
            my={2}
            w="50%"
          />
          <Text
            color={themeData.mainColorDark}
            fontSize={!small ? fontSizes.regular : fontSizes.small}
            fontWeight="bold"
            onPress={() => {
              onScreenChange();
              // This was needed in order to allow users to sign-in from
              // the eventSignUp feature. If the origin is empty, take user
              // to signUp screen regularly. Otherwise, send parameters.
              if (fp.isEmpty(origin)) {
                RootNavigation.navigate('signUp');
              } else {
                RootNavigation.navigate('signUp', { origin });
              }
            }}
            textAlign="center"
          >
            Crear una cuenta
          </Text>
        </VStack>
      )}
    </Formik>
  );
};

LogInForm.defaultProps = { justifyContent: 'center', w: '80%' };
