import { useSmallScreen } from 'hooks/dimensions-hooks';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { HStack, IStackProps, Text } from 'native-base';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { fontSizes } from 'styles/theme';

interface IInfoFieldProps extends IStackProps {
  /**
   * Icon to be rendered in the info field.
   */
  icon?: JSX.Element;
  /**
   * Info field name.
   */
  fieldName: string;
  /**
   * Info field value.
   */
  fieldValue: string;
}

export const InfoField: React.FC<IInfoFieldProps> = (props) => {
  const { icon = null, fieldName, fieldValue, ...rest } = props;

  const themeData = useSelector(getTheme);
  const small = useSmallScreen();
  const { web } = usePlatform();

  return (
    <HStack bg={themeData.mainColorDark} {...rest}>
      {!fp.isNil(icon) ? icon : null}
      {!web ? (
        <Text
          color="white"
          flex={1}
          fontSize={small ? fontSizes.small : fontSizes.regular}
          fontWeight="bold"
          textAlign="center"
        >
          {`${fieldName}:\n${fieldValue}`}
        </Text>
      ) : (
        <Text
          color="white"
          flex={1}
          fontSize={fontSizes.regular}
          fontWeight="bold"
          py={2}
          textAlign="center"
        >
          {`${fieldName}: ${fieldValue}`}
        </Text>
      )}
    </HStack>
  );
};

InfoField.defaultProps = {
  alignItems: 'center',
  borderRadius: 10,
  flex: 1,
  justifyContent: 'center',
  my: 1,
  px: 2,
  space: 2,
  w: '100%',
};
