import { Ionicons } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import { ExtendedFlatlist, UIWrapper } from 'components/elements';
import { IOption } from 'components/elements/types.d';
import { formatISO } from 'date-fns';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { IconButton, VStack } from 'native-base';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import { appointmentsInfoResources } from 'services/resources/appointments-info';
import { IAppointmentWithInfoTaxDataAndNames } from 'services/resources/appointments-info/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { ILoadingData } from 'types.d';

import { AppointmentStatisticItem } from './components/AppointmentStatisticItem';
import { FilteringModal } from './components/FilteringModal';
import { Statistics } from './components/Statistics';
import { dateOptions } from './helpers/constant-helpers';
import { sortAppointmentsByDate } from './helpers/data-helpers';
import { getAllFilteredAppointmentInfo } from './helpers/filter-helpers';

export const TherapistFinancial: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [appointmentsWithInfoAndNames, setAppointmentsWithInfoAndNames] =
    useState<IAppointmentWithInfoTaxDataAndNames[]>(
      [] as IAppointmentWithInfoTaxDataAndNames[],
    );
  const [queriedDate, setQueriedDate] = useState<Date>(new Date());
  const [filteringModalVisible, setFilteringModalVisible] =
    useState<boolean>(true);
  const [selectedDateOption, setSelectedDateOption] = useState<IOption>(
    dateOptions[0],
  );

  const toggleFilteringModalVisible = (): void => {
    setFilteringModalVisible(!filteringModalVisible);
  };

  /**
   * Function that retrieves appointments with their info and names, with a given
   * date and a date option, gaining optimization with this approach.
   * @param date
   * @param selectedOption
   */
  const retrieveAppointmentsWithInfoAndNames = async (
    date: Date,
    selectedOption: string,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Citas...',
    });
    let d: IAppointmentWithInfoTaxDataAndNames[] = [];
    try {
      const { data } =
        await appointmentsInfoResources.getCompletedWithNamesByFixedDate(
          formatISO(date),
          selectedOption,
          userData.token,
        );
      d = data as IAppointmentWithInfoTaxDataAndNames[];
      if (!fp.isNil(d)) {
        setAppointmentsWithInfoAndNames(d);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'AppointmentsStatistics-retrieveAppointmentsWithInfoAndNames',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that handles both: date option state set and retrieves
   * data based on the new given date.
   * @param newDate
   * @param selectedOption
   */
  const handleDateChange = (newDate: Date, selectedOption: string): void => {
    setQueriedDate(newDate);
    retrieveAppointmentsWithInfoAndNames(newDate, selectedOption);
  };

  useFocusEffect(
    useCallback(() => {
      retrieveAppointmentsWithInfoAndNames(queriedDate, 'month');
    }, []),
  );

  const filteredData = getAllFilteredAppointmentInfo(
    appointmentsWithInfoAndNames,
    userData.id,
  );

  return (
    <UIWrapper title="Finanzas (terapeuta)">
      <VStack alignItems="center" backgroundColor="white" h="100%" w="100%">
        <FilteringModal
          dateOptionChange={(newOption) =>
            handleDateChange(queriedDate, newOption)
          }
          isOpen={filteringModalVisible}
          loadingData={loadingData}
          onClose={toggleFilteringModalVisible}
          selectedDateOption={selectedDateOption}
          selectedQueryDate={queriedDate}
          setSelectedDateOption={setSelectedDateOption}
          setSelectedQueryDate={(d, o) => handleDateChange(d, o)}
        />
        {!fp.isEmpty(filteredData) ? (
          <Statistics data={filteredData} flex={1} w="100%" />
        ) : null}

        <ExtendedFlatlist
          data={
            !fp.isEmpty(filteredData)
              ? sortAppointmentsByDate(filteredData)
              : []
          }
          flex={!web ? 1 : 3}
          noDataMessage="No se encontraron citas con los criterios seleccionados."
          renderItem={<AppointmentStatisticItem />}
          useSearchBar={false}
          w="100%"
        />

        <IconButton
          alignItems="center"
          bg={themeData.mainColorDark}
          borderColor="white"
          borderWidth="1px"
          bottom={6}
          icon={<Ionicons color="white" name="filter" size={24} />}
          onPress={() => toggleFilteringModalVisible()}
          position="absolute"
        />
      </VStack>
    </UIWrapper>
  );
};
