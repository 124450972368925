import AsyncStorage from '@react-native-async-storage/async-storage';
import { User } from 'firebase/auth';
import { IStoredFirebaseUser } from 'services/resources/users/types.d';

/**
 * Function for saving the current user's session in the storage for future
 * session restorations.
 * @param user
 */
export const storeSession = async (user: User): Promise<void> => {
  await AsyncStorage.setItem('USER', JSON.stringify(user));
};

/**
 * Function for restoring a previously saved session.
 * @returns user
 */
export const readSession = async (): Promise<IStoredFirebaseUser> => {
  const user = await AsyncStorage.getItem('USER');
  const parsedUser = JSON.parse(user as string);
  return parsedUser;
};

/**
 * Function for removing a previously saved session.
 */
export const removeSession = async (): Promise<void> => {
  await AsyncStorage.removeItem('USER');
};
