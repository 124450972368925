import { useEffect, useState } from 'react';
import { Keyboard, Platform, useWindowDimensions } from 'react-native';

// Hook retrieved from https://stackoverflow.com/a/60682069
export const useKeyboardHeight = (): number => {
  const [keyboardHeight, setKeyboardHeight] = useState<number>(0);

  const onKeyboardDidShow = (e: any): void => {
    setKeyboardHeight(e.endCoordinates.height);
  };

  const onKeyboardDidHide = (): void => {
    setKeyboardHeight(0);
  };

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      onKeyboardDidShow,
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      onKeyboardDidHide,
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  return keyboardHeight;
};

export const useSmallScreen = (): boolean => {
  const [smallScreen, setSmallScreen] = useState(false);

  // React native recommends using this hook instead of Dimensions() since
  // it updates as the screen changes.
  const { height, scale } = useWindowDimensions();
  const heightInPx = height * scale;
  useEffect(() => {
    if (Platform.OS !== 'web' && heightInPx < 1335) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, []);

  return smallScreen;
};
