import fp from 'lodash/fp';
import {
  Badge,
  Button,
  FlatList,
  HStack,
  IStackProps,
  Text,
  VStack,
} from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

interface IColorsFieldProps extends IStackProps {
  /**
   * Array of colors for picking one of them.
   */
  colorsData: string[];
  /**
   * Handler function for selecting a color.
   */
  onColorSelect: (color: string) => void;
  /**
   * Color that has already been selected.
   */
  selectedColor: string;
}

export const ColorsField: React.FC<IColorsFieldProps> = (
  props,
): JSX.Element => {
  const { colorsData, onColorSelect, selectedColor, ...rest } = props;

  const themeData = useSelector(getTheme);

  return (
    <VStack {...rest}>
      <Text color={themeData.mainColorDark} mb={1} textAlign="center">
        Colores Disponibles:
      </Text>
      <FlatList
        contentContainerStyle={{
          justifyContent: 'center',
          maxHeight: 25,
          width: '100%',
        }}
        data={!fp.isEmpty(colorsData) ? colorsData : ['#032357', '#000000']}
        h="50%"
        horizontal
        keyExtractor={(item) => item}
        renderItem={({ item }) => (
          <Button
            bg={item}
            h={6}
            mx={1}
            onPress={() => onColorSelect(item)}
            rounded="full"
            w={6}
          />
        )}
        showsHorizontalScrollIndicator
      />
      <HStack mt={3}>
        <Text color={themeData.mainColorDark}>Color Seleccionado: </Text>
        <Badge
          bg={!fp.isEmpty(selectedColor) ? selectedColor : '#032357'}
          h={6}
          mx={1}
          rounded="full"
          w={6}
        />
      </HStack>
    </VStack>
  );
};

ColorsField.defaultProps = {
  alignItems: 'center',
  h: '100px',
  justifyContent: 'center',
};
