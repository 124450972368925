import { SearchBarField } from 'components/inputs';
import fp from 'lodash/fp';
import { Divider, FlatList, IStackProps, Text, VStack } from 'native-base';
import React, { useState } from 'react';
import { ListRenderItemInfo, ViewStyle } from 'react-native';

import { includesSearchText, sortListData } from './helpers/data-helpers';

interface IExtendedFlatlist extends IStackProps {
  /**
   * Array containing the information to be used in the flatlist.
   */
  data: any[];
  /**
   * Custom style to be applied to the flatlist.
   */
  flatListStyle?: ViewStyle;
  /**
   * Custom message to be shown when data array is an empty array.
   */
  noDataMessage?: string;
  /**
   * Item to be rendered as list element.
   */
  renderItem: JSX.Element;
  /**
   * Placeholder to be used in the search bar.
   */
  searchBarPlaceholder?: string;
  /**
   * Key to be used for finding in the search function.
   */
  searchKey?: string | string[];
  /**
   * Custom key for being used as a custom search criteria for a nested
   * property in an object. Can only be used if the search key is not an array.
   */
  searchSubKey?: string | string[];
  /**
   * Flag for indicating either to sort the FlatList data or not.
   */
  sort?: boolean;
  /**
   * Flag that indicates if we want to sort by date or created property in
   * a timestamp format.
   */
  sortDateByTimeStamp?: boolean;
  /**
   * Custom key to be used for sorting the array data.
   */
  sortKey?: string;
  /**
   * Custom key for being used as a custom sorting criteria for a nested
   * property in an object.
   */
  sortSubKey?: string;
  /**
   * Custom key to be used for sorting cases where there is a shared nested
   * property that can come from different objects.
   */
  alternativeSortKey?: string;
  /**
   * Flag that indicates if we want to sort by date property nested in an
   * object.
   */
  sortByDateInObject?: boolean;
  /**
   * Flag for indicating if want to display the searchbar in the flatlist.
   */
  useSearchBar?: boolean;
  /**
   * Flag for inverting the sorted array when set by dates.
   */
  sortDatesInverted?: boolean;
}

export const ExtendedFlatlist: React.FC<IExtendedFlatlist> = (props) => {
  const {
    alternativeSortKey = '',
    data,
    renderItem,
    flatListStyle = {},
    noDataMessage = 'No se encontró información',
    sort = false,
    searchBarPlaceholder = '',
    sortByDateInObject = false,
    sortDateByTimeStamp = false,
    searchKey = '',
    sortKey = '',
    sortSubKey = '',
    useSearchBar = true,
    searchSubKey = '',
    sortDatesInverted = false,
    ...rest
  } = props;

  const [search, setSearch] = useState<string>('');

  /**
   * Function for rendering list elements based on a search criteria if defined.
   * @param item
   * @returns FlatList item.
   */
  const renderChildren = (
    item: ListRenderItemInfo<unknown>,
  ): React.FunctionComponentElement<any> => {
    if (!useSearchBar) {
      return React.cloneElement(renderItem, { item });
    } else if (
      includesSearchText(JSON.stringify(item), searchKey, searchSubKey, search)
    ) {
      return React.cloneElement(renderItem, { item });
    }
    return <></>;
  };

  return (
    <VStack {...rest}>
      {!fp.isNil(data) && data.length > 0 ? (
        <VStack h="100%">
          {useSearchBar ? (
            <SearchBarField
              alignSelf="center"
              my={2}
              onChangeText={(text) => setSearch(text)}
              placeholder={searchBarPlaceholder}
              w="95%"
            />
          ) : null}
          <Divider />
          <FlatList
            data={
              !sort
                ? data
                : sortListData(
                    data,
                    sortKey,
                    alternativeSortKey,
                    sortSubKey,
                    sortByDateInObject,
                    sortDatesInverted,
                    sortDateByTimeStamp,
                  )
            }
            keyExtractor={(item) => JSON.stringify(item)}
            renderItem={({ item }) => renderChildren(item)}
            style={!fp.isEmpty(flatListStyle) ? flatListStyle : undefined}
          />
        </VStack>
      ) : (
        <Text fontWeight="bold" px={2} py={6} textAlign="center">
          {noDataMessage}
        </Text>
      )}
    </VStack>
  );
};

ExtendedFlatlist.defaultProps = {
  flex: 1,
};
