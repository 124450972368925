import { LoadingStatusModal } from 'components/elements';
import { IOption } from 'components/elements/types.d';
import { usePlatform } from 'hooks/platform-hooks';
import { IModalProps, Modal, Text, View, VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { ILoadingData } from 'types.d';

import { dateOptions } from '../helpers/constant-helpers';
import { DateFilterPicker } from './DateFilterPicker';
import { DateSelector } from './DateSelector';

interface IFilteringModalProps extends IModalProps {
  /**
   * Current date value to be used for retrieving the target data.
   */
  selectedQueryDate: Date;
  /**
   * Function that will help to both: set the new query date state and to
   * set current selected period (for retrieving by date, month or year).
   */
  setSelectedQueryDate: (newDate: Date, selectedOption: string) => void;
  /**
   * Data loading status, for being used with corresponding component.
   */
  loadingData: ILoadingData;
  /**
   * Function to be triggered every time the date option has changed.
   */
  dateOptionChange: (selectedOption: string) => void;
  /**
   * State ot the selected date option.
   */
  selectedDateOption: IOption;
  /**
   * State setter for the selected date option.
   */
  setSelectedDateOption: (newOption: IOption) => void;
}

export const FilteringModal: React.FC<IFilteringModalProps> = (
  props,
): JSX.Element => {
  const {
    selectedQueryDate,
    setSelectedQueryDate,
    loadingData,
    dateOptionChange,
    selectedDateOption,
    setSelectedDateOption,
    ...rest
  } = props;

  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        bg="white"
        borderRadius={!web ? 0 : 10}
        maxH="90%"
        maxWidth={!web ? '100%' : '40%'}
        w={!web ? '100%' : undefined}
      >
        <Modal.CloseButton />
        <View w="100%">
          <VStack alignItems="center" pb={3}>
            <LoadingStatusModal loading={loadingData.loading}>
              {loadingData.loadingMessage}
            </LoadingStatusModal>
            <Text
              color={themeData.mainColorDark}
              fontSize={18}
              fontWeight="bold"
              pt={5}
              textAlign="center"
            >
              Filtros
            </Text>
            <DateFilterPicker
              dateOptions={dateOptions}
              selectedDateOption={selectedDateOption}
              setSelectedDateOption={(dateOption) => {
                setSelectedDateOption(dateOption);
                dateOptionChange(dateOption.value);
              }}
              w="60%"
            />
            <DateSelector
              mt={2}
              selectedDateOption={selectedDateOption.value}
              selectedQueryDate={selectedQueryDate}
              setSelectedQueryDate={(d) =>
                setSelectedQueryDate(d, selectedDateOption.value)
              }
            />
          </VStack>
        </View>
      </Modal.Content>
    </Modal>
  );
};
