import { EditionButtons } from 'components/elements';
import { Divider, HStack, IStackProps, Text, VStack } from 'native-base';
import React from 'react';
import { IBlogEntry } from 'services/resources/blog/types.d';

interface IBlogEntryProps extends IStackProps {
  /**
   * Item to be inherited from the cloneElement function.
   */
  item?: any;
  /**
   * Function for handling an item deletion on database.
   */
  onDelete: (entry: IBlogEntry) => void;
  /**
   * Function for handling an item edition on database.
   */
  onEdit: (entry: IBlogEntry) => void;
}

export const BlogEntryItem: React.FC<IBlogEntryProps> = (
  props,
): JSX.Element => {
  const { onDelete, onEdit, children, item, ...rest } = props;

  const entry = item as IBlogEntry;

  return (
    <VStack {...rest}>
      <HStack alignItems="center" flex={1} m={2} w="100%">
        <Text flex={3} fontWeight="bold" pl={2}>
          {entry.title}
        </Text>
        <EditionButtons
          onDelete={() => onDelete(entry)}
          onEdit={() => onEdit(entry)}
          pr={6}
        />
      </HStack>
      <Divider />
    </VStack>
  );
};

BlogEntryItem.defaultProps = {
  flex: 1,
  w: '100%',
};
