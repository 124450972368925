import { useFocusEffect } from '@react-navigation/native';
import {
  AcceptDeclineModal,
  ExtendedFlatlist,
  LoadingStatusModal,
  UIWrapper,
} from 'components/elements';
import { getUserFullName } from 'helpers/data-helpers/string-helpers';
import { handleNotificate } from 'helpers/notifications-helpers/notifications-recipes';
import { extractNotificationSection } from 'helpers/notifications-helpers/property-helpers';
import { useNotifications } from 'hooks/notifications-hooks';
import fp from 'lodash/fp';
import { Text, VStack } from 'native-base';
import { useCallback, useState } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { getUser } from 'redux-service/slices';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { userResources } from 'services/resources/users';
import { IUser } from 'services/resources/users/types.d';
import { ILoadingData } from 'types.d';

import { RequestingTherapistItem } from './components/RequestingTherapistItem';

export const AdministratorRequests: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);

  let newNotification: any | null | undefined;
  // Only use hook if is not web as currently is not supported
  if (Platform.OS !== 'web') newNotification = useNotifications();

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [modalInfo, setModalInfo] = useState<{
    isOpen: boolean;
    message: string;
  }>({
    isOpen: false,
    message: '',
  });
  const [selectedUser, setSelectedUser] = useState<IUser>({} as IUser);
  const [requestingTherapists, setRequestingTherapists] = useState<IUser[]>([]);

  const toggleModalOpen = (): void => {
    setModalInfo({ isOpen: !modalInfo.isOpen, message: modalInfo.message });
  };

  /**
   * Function for retrieving the users that have requested to be accepted as
   * therapists.
   */
  const retrieveTherapistsRequests = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Solicitudes...',
    });
    try {
      const { data: d } = await userResources.getTherapistsRequests(
        userData.token,
      );
      const data = d as string[];
      let retrievedRequestingTherapists: IUser[] = [];
      // We verify that there is at least one user
      if (data.length > 0) {
        // We wait until all the promises are resolved or one is rejected
        retrievedRequestingTherapists = await Promise.all(
          data.map(async (requestingTherapist) => {
            const { data } = await userResources.getById(
              requestingTherapist,
              userData.token,
            );
            return data as IUser;
          }),
        );
      }
      // Only write states if requesting therapists changed
      if (!fp.isEmpty(retrievedRequestingTherapists)) {
        setRequestingTherapists(retrievedRequestingTherapists);
      } else {
        // Otherwise overwrite with empty array
        setRequestingTherapists([]);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'administrator-dashboard',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function for handling the therapist request acceptance and notificate
   * about it.
   */
  const handleOnAcceptRequest = async (
    requestingTherapist: IUser,
  ): Promise<void> => {
    setLoadingData({ loading: true, loadingMessage: 'Aceptando Solicitud...' });
    try {
      await userResources.resolveTherapistRequest(
        requestingTherapist.id,
        'accept',
        userData.token,
      );
      // Send notification
      const notification = {
        body: 'Tu solicitud para darte de alta como terapeuta ha sido aceptada',
        title: 'Actualización de cuenta',
      };
      handleNotificate(notification, 'administrator-dashboard', {
        ...requestingTherapist,
        logged: false,
        token: userData.token,
      });
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'administrator-dashboard',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ loading: false, loadingMessage: '' });
    retrieveTherapistsRequests();
  };

  /**
   * Function for handling the therapist request declination and notificate
   * about it.
   */
  const handleOnDeclineRequest = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Rechazando Solicitud...',
    });
    try {
      await userResources.resolveTherapistRequest(
        selectedUser.id,
        'decline',
        userData.token,
      );
      // Send notification
      const notification = {
        body: 'Tu solicitud para darte de alta como terapeuta ha sido rechazada',
        title: 'Actualización de cuenta',
      };
      handleNotificate(notification, 'administrator-dashboard', {
        ...selectedUser,
        logged: false,
        token: userData.token,
      });
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'administrator-dashboard',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    toggleModalOpen();
    setLoadingData({ loading: false, loadingMessage: '' });
    retrieveTherapistsRequests();
  };

  /**
   * Function for choosing what to do with the request.
   * @param requestingTherapist
   * @param operation
   */
  const handleRequestOperation = (
    requestingTherapist: IUser,
    operation: string,
  ): void => {
    if (operation === 'decline') {
      toggleModalOpen();
      setModalInfo({
        isOpen: true,
        message: `¿Estás seguro de rechazar la solicitud de ${getUserFullName(
          requestingTherapist,
        )}?`,
      });
    }
    if (operation === 'accept') {
      handleOnAcceptRequest(requestingTherapist);
    }
  };

  useFocusEffect(
    useCallback(() => {
      retrieveTherapistsRequests();
    }, [
      Platform.OS !== 'web'
        ? extractNotificationSection(newNotification.notification)
        : undefined,
    ]),
  );

  return (
    <UIWrapper title="Administrador">
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      <AcceptDeclineModal
        invertColors
        isOpen={modalInfo.isOpen}
        onAccept={handleOnDeclineRequest}
        onDecline={toggleModalOpen}
      >
        <Text fontWeight="bold">{modalInfo.message}</Text>
      </AcceptDeclineModal>
      <VStack bg="white" flex={17}>
        <ExtendedFlatlist
          data={requestingTherapists}
          noDataMessage="No se encontraron solicitudes de terapeutas."
          renderItem={
            <RequestingTherapistItem
              onSelectUser={(requestingTherapist, operation) => {
                setSelectedUser(requestingTherapist);
                handleRequestOperation(
                  requestingTherapist,
                  operation as string,
                );
              }}
            />
          }
          searchBarPlaceholder="usuarios solicitantes"
          searchKey={['firstName', 'fatherName', 'motherName']}
          sort
          sortKey="firstName"
          useSearchBar
        />
      </VStack>
    </UIWrapper>
  );
};
