import fp from 'lodash/fp';
import {
  Box,
  FlatList,
  Image,
  IModalProps,
  Modal,
  Pressable,
  Text,
} from 'native-base';
import { RichEditor } from 'react-native-pell-rich-editor';

import { IListItem } from '../types.d';

interface IImageSelectorProps extends IModalProps {
  selectorVisible: boolean;
  setSelectorVisible: (value: boolean) => void;
  /**
   * List of images to be used in order to insert images while using the editor.
   */
  imageURLs: IListItem[];
  richText: React.RefObject<RichEditor>;
}

export const ImageSelector: React.FC<IImageSelectorProps> = (
  props,
): JSX.Element => {
  const { selectorVisible, setSelectorVisible, imageURLs, richText, ...rest } =
    props;

  const handleOnInsertImage = (src: string): void => {
    if (!fp.isNil(richText.current)) {
      richText.current.insertImage(src);
      setSelectorVisible(false);
    }
  };

  return (
    <Modal isOpen={selectorVisible} {...rest}>
      <Modal.Content h="50%">
        <Modal.CloseButton onPress={() => setSelectorVisible(false)} />
        <Box pt="100px">
          {imageURLs.length > 0 ? (
            <Box justifyContent="center">
              <Text fontSize={18} textAlign="center">
                Selecciona la imagen a insertar:
              </Text>
              <FlatList
                contentContainerStyle={{
                  alignItems: 'center',
                }}
                data={imageURLs}
                h="70%"
                horizontal
                keyExtractor={(i) => i.value}
                renderItem={({ item }) => (
                  <Pressable onPress={() => handleOnInsertImage(item.value)}>
                    <Image alt={item.title} mx={2} size="xl" src={item.value} />
                  </Pressable>
                )}
                style={{ alignSelf: 'center' }}
              />
            </Box>
          ) : (
            <Text fontSize={18} px={4} textAlign="center">
              Aún no has añadido imágenes para usar en el editor.
            </Text>
          )}
        </Box>
      </Modal.Content>
    </Modal>
  );
};

ImageSelector.defaultProps = {
  size: 'full',
};
