import { AcceptDecline } from 'components/elements';
import { DashboardInputField } from 'components/inputs';
import { Formik } from 'formik';
import fp from 'lodash/fp';
import { Text, VStack } from 'native-base';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import {
  IPredefinedTask,
  IPredefinedTaskPayload,
} from 'services/resources/tasks/types.d';
import { fontSizes } from 'styles/theme';

import { INITIAL_VALUES } from './helpers/form-helpers';

interface IPredefinedTaskFormProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IPredefinedTaskPayload | IPredefinedTask) => void;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Values that are already set, used for editing them.
   */
  previousValues?: IPredefinedTask;
}

export const PredefinedTaskForm: React.FC<IPredefinedTaskFormProps> = (
  props,
) => {
  const { onSubmit, onCancel, previousValues = undefined } = props;
  const themeData = useSelector(getTheme);

  const previousEnabled =
    !fp.isNil(previousValues) && !fp.isEmpty(previousValues);

  return (
    <Formik
      initialValues={!previousEnabled ? INITIAL_VALUES : previousValues}
      key={JSON.stringify(previousValues)}
      onSubmit={onSubmit}
    >
      {({ handleChange, handleSubmit, values }) => (
        <VStack alignItems="center" justifyContent="center" w="100%">
          <Text
            color={themeData.mainColorDark}
            fontSize={fontSizes.regular}
            fontWeight="bold"
            py={4}
            textAlign="center"
          >
            {!fp.isEmpty(previousValues)
              ? 'Actualizar Tarea Predefinida'
              : 'Nueva Tarea Predefinida'}
          </Text>
          <Text color={themeData.mainColorDark} py={2} textAlign="center">
            Nombre de la tarea predefinida
          </Text>
          <DashboardInputField
            onChangeText={handleChange('name')}
            value={values.name}
          />
          <Text color={themeData.mainColorDark} py={2} textAlign="center">
            Descripción
          </Text>
          <DashboardInputField
            onChangeText={handleChange('description')}
            value={values.description}
          />
          <AcceptDecline
            customAcceptMessage={
              fp.isEmpty(previousValues) ? 'Añadir' : 'Actualizar'
            }
            disabled={
              (fp.isEmpty(previousValues) && values.description === '') ||
              (!fp.isEmpty(previousValues) &&
                previousValues.description === values.description &&
                previousValues.name === values.name)
            }
            mt={6}
            onAccept={handleSubmit}
            onDecline={onCancel}
            py={4}
          />
        </VStack>
      )}
    </Formik>
  );
};
