import { useKeyboardHeight } from 'hooks/dimensions-hooks';
import { useKeyboardShow } from 'hooks/event-hooks';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  ScrollView,
  ScrollViewProps,
  useWindowDimensions,
  View,
} from 'react-native';

interface IInputScrollerProps extends ScrollViewProps {
  /**
   * Content to be rendered inside of the scroller.
   */
  children: JSX.Element | JSX.Element[];
  /**
   * Flag to indicate to use a bottom offset.
   */
  bottomOffset?: boolean;
  /**
   * Flag to indicate to use a top offset.
   */
  topOffset?: boolean;
  /**
   * Flag to indicate to use a bottom and top offsets.
   */
  bothOffsets?: boolean;
  /**
   * Flag to indicate if the input is multiline.
   */
  multiline?: boolean;
  /**
   * Flag that indicates if disabling the scrollTo start behavior.
   */
  disableScrollToStart?: boolean;
}

export const InputScroller: React.FC<IInputScrollerProps> = (
  props,
): JSX.Element => {
  const {
    children,
    disableScrollToStart = false,
    bottomOffset = false,
    topOffset = false,
    bothOffsets = false,
    multiline = false,
    ...rest
  } = props;
  const { visible: keyboardShowed, hidden: keybordHid } = useKeyboardShow();
  const [scrollRef, setScrollRef] = useState<ScrollView | null>(null);
  const keyboardHeight = useKeyboardHeight();
  const screenHeight = useWindowDimensions().height;
  const scrollHeight = screenHeight;

  const setScrollBehaviour = (position: string): void => {
    if (position === 'input' && !disableScrollToStart) {
      scrollRef?.scrollTo({ y: scrollHeight / 3 });
    } else if (bothOffsets && !disableScrollToStart) {
      scrollRef?.scrollTo({ y: screenHeight / 5 });
    } else if (!disableScrollToStart) {
      scrollRef?.scrollTo({ y: 0 });
    }
  };

  useLayoutEffect(() => {
    if (keyboardShowed) setScrollBehaviour('input');
    if (keybordHid && !multiline && !disableScrollToStart) {
      setScrollBehaviour('start');
    }
  });

  useEffect(() => {
    if (multiline) scrollRef?.scrollTo({ y: scrollHeight / 2.5 });
  });

  return (
    <ScrollView
      ref={(ref) => setScrollRef(ref)}
      scrollEnabled={keyboardHeight > 0 || multiline || disableScrollToStart}
      style={{
        height: scrollHeight,
      }}
      {...rest}
    >
      {bothOffsets ? (
        <>
          <View style={{ height: screenHeight / 2 }} />
          {children}
          <View style={{ height: screenHeight / 2 }} />
        </>
      ) : (
        <>
          {topOffset && <View style={{ height: screenHeight / 2 }} />}
          {children}
          {bottomOffset && <View style={{ height: screenHeight / 2 }} />}
        </>
      )}
    </ScrollView>
  );
};

InputScroller.defaultProps = {
  contentContainerStyle: {
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
};
