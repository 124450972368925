import { useFocusEffect } from '@react-navigation/native';
import {
  ExtendedFlatlist,
  LoadingStatusModal,
  UIWrapper,
} from 'components/elements';
import fp from 'lodash/fp';
import { VStack } from 'native-base';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setUserData } from 'redux-service/slices';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { userResources } from 'services/resources/users';
import { IUser } from 'services/resources/users/types.d';
import { ILoadingData } from 'types.d';

import { PutCommissionModal } from './components/PutCommissionModal';
import { TherapistItem } from './components/TherapistItem';

export const Commissions: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);
  const dispatch = useDispatch();

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [verifiedTherapists, setVerifiedTherapists] = useState<IUser[]>([]);
  const [commissionFormVisible, setCommissionFormVisible] =
    useState<boolean>(false);
  const [selectedTherapist, setSelectedTherapist] = useState<IUser>(
    {} as IUser,
  );

  /**
   * Function for retrieving all verified therapists and writing them to state.
   */
  const retrieveVerifiedTherapists = async (): Promise<void> => {
    setLoadingData({ loading: true, loadingMessage: 'Cargando Terapeutas...' });
    try {
      const { data: d } = await userResources.getVerifiedTherapists(
        userData.token,
      );
      const data = d as string[];
      let retrievedTherapists: IUser[] = [];
      // We verify that there is at least one therapist
      if (data.length > 0) {
        // We wait until all the promises are resolved or one is rejected
        retrievedTherapists = await Promise.all(
          data.map(async (therapistId) => {
            const { data } = await userResources.getById(
              therapistId,
              userData.token,
            );
            return data as IUser;
          }),
        );
      }
      // Only write states if therapists changed
      if (!fp.isEmpty(retrievedTherapists)) {
        // Write redux state
        dispatch(setUserData({ ...userData, therapists: userData.therapists }));
        setVerifiedTherapists(retrievedTherapists);
      } else {
        // Otherwise ovewrite with empty array
        setVerifiedTherapists([]);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'commissions-retrieveVerifiedTherapists',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function for retrieving all verified therapists and writing them to state.
   */
  const handleSubmitCommission = async (
    newCommission: number,
  ): Promise<void> => {
    setCommissionFormVisible(false);
    setLoadingData({
      loading: true,
      loadingMessage: 'Actualizando Comisión...',
    });
    try {
      await userResources.putCommission(
        selectedTherapist.email,
        String(newCommission),
        userData.token,
      );
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'commissions-handleSubmitCommission',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    setSelectedTherapist({} as IUser);
    retrieveVerifiedTherapists();
  };

  useFocusEffect(
    useCallback(() => {
      retrieveVerifiedTherapists();
    }, []),
  );

  return (
    <UIWrapper title="Comisiones">
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      <VStack bg="white" flex={1}>
        <PutCommissionModal
          isOpen={commissionFormVisible}
          onClosePutCommissionModal={() => {
            setCommissionFormVisible(!commissionFormVisible);
            setSelectedTherapist({} as IUser);
          }}
          onSubmitCommission={(payload) =>
            handleSubmitCommission(payload.commission)
          }
          previousCommission={
            fp.isNil(selectedTherapist.commissionPercentage)
              ? 0
              : selectedTherapist.commissionPercentage
          }
        />
        <ExtendedFlatlist
          data={verifiedTherapists}
          noDataMessage="No se encontraron terapeutas verificados."
          renderItem={
            <TherapistItem
              onToggleCommissionForm={(selectedUser) => {
                setSelectedTherapist(selectedUser);
                setCommissionFormVisible(!commissionFormVisible);
              }}
            />
          }
          searchBarPlaceholder="terapeutas"
          searchKey={['firstName', 'fatherName', 'motherName']}
          sort
          sortKey="firstName"
          useSearchBar
        />
      </VStack>
    </UIWrapper>
  );
};
