import { Ionicons } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import { ExtendedFlatlist, UIWrapper } from 'components/elements';
import { IOption } from 'components/elements/types.d';
import { formatISO } from 'date-fns';
import { sortObjectArrayByNestedDate } from 'helpers/data-helpers/sorting-helpers';
import fp from 'lodash/fp';
import { IconButton, VStack } from 'native-base';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import { eventsRegistriesResources } from 'services/resources/events-registries';
import { IEventWithRegistries } from 'services/resources/events-registries/types';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { ILoadingData } from 'types.d';

import { EventStatisticItem } from './components/EventStatisticItem';
import { FilteringModal } from './components/FilteringModal';
import { Statistics } from './components/Statistics';
import { dateOptions } from './helpers/constant-helpers';

export const EventsStatistics: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [eventsWithRegistries, setEventsWithRegistries] = useState<
    IEventWithRegistries[]
  >([] as IEventWithRegistries[]);
  const [queriedDate, setQueriedDate] = useState<Date>(new Date());
  const [filteringModalVisible, setFilteringModalVisible] =
    useState<boolean>(true);
  const [selectedDateOption, setSelectedDateOption] = useState<IOption>(
    dateOptions[0],
  );

  const toggleFilteringModalVisible = (): void => {
    setFilteringModalVisible(!filteringModalVisible);
  };

  /**
   * Function that retrieves events with their registries, with a given
   * date and a date option, gaining optimization with this approach.
   * @param date
   * @param selectedOption
   */
  const retrieveEventsWithRegistries = async (
    date: Date,
    selectedOption: string,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Eventos...',
    });
    try {
      const { data } =
        await eventsRegistriesResources.getEventsWithRegistriesByFixedDate(
          formatISO(date),
          selectedOption,
          userData.token,
        );
      const d = data as IEventWithRegistries[];
      if (!fp.isNil(d)) {
        setEventsWithRegistries(d);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'EventsStatistics-retrieveEventsWithRegistries',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that handles both: date option state set and retrieves
   * data based on the new given date.
   * @param newDate
   * @param selectedOption
   */
  const handleDateChange = (newDate: Date, selectedOption: string): void => {
    setQueriedDate(newDate);
    retrieveEventsWithRegistries(newDate, selectedOption);
  };

  useFocusEffect(
    useCallback(() => {
      retrieveEventsWithRegistries(queriedDate, 'day');
    }, []),
  );

  const filteredData = !fp.isEmpty(eventsWithRegistries)
    ? sortObjectArrayByNestedDate(
        eventsWithRegistries,
        'event',
        undefined,
        'date',
        true,
      )
    : eventsWithRegistries;

  return (
    <UIWrapper title="Estadísticas de Eventos">
      <VStack alignItems="center" backgroundColor="white" h="100%" w="100%">
        <FilteringModal
          dateOptionChange={(newOption) =>
            handleDateChange(queriedDate, newOption)
          }
          isOpen={filteringModalVisible}
          loadingData={loadingData}
          onClose={toggleFilteringModalVisible}
          selectedDateOption={selectedDateOption}
          selectedQueryDate={queriedDate}
          setSelectedDateOption={setSelectedDateOption}
          setSelectedQueryDate={(d, o) => handleDateChange(d, o)}
        />
        {!fp.isEmpty(eventsWithRegistries) ? (
          <Statistics
            data={filteredData as IEventWithRegistries[]}
            flex={1}
            w="100%"
          />
        ) : null}
        <ExtendedFlatlist
          data={filteredData}
          flex={3}
          noDataMessage="No se encontraron eventos con los criterios seleccionados."
          renderItem={<EventStatisticItem />}
          useSearchBar={false}
          w="100%"
        />
        <IconButton
          alignItems="center"
          bg={themeData.mainColorDark}
          borderColor="white"
          borderWidth="1px"
          bottom={6}
          icon={<Ionicons color="white" name="filter" size={24} />}
          onPress={() => toggleFilteringModalVisible()}
          position="absolute"
        />
      </VStack>
    </UIWrapper>
  );
};
