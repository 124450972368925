import { Ionicons } from '@expo/vector-icons';
import fp from 'lodash/fp';
import {
  Divider,
  Flex,
  HStack,
  IconButton,
  IFlexProps,
  Text,
} from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { MenuModal } from './MenuModal';
import { NotificationBadge } from './NotificationBadge';

interface INavbarProps extends IFlexProps {
  /**
   * Header to be used in the UI Wrapper.
   */
  title?: string;
  /**
   * Flag to indicate weather to hide all those UI elements not related to
   * layout itself (useful for anonymous log-in).
   */
  hideUIAddOns?: boolean;
}

export const Navbar: React.FC<INavbarProps> = (props): JSX.Element => {
  const { title, hideUIAddOns = false, ...rest } = props;

  const themeData = useSelector(getTheme);

  const [menuModalVisible, setMenuModalVisible] = useState<boolean>(false);

  const toggleMenuModalVisible = (): void => {
    setMenuModalVisible(!menuModalVisible);
  };

  return (
    <Flex {...rest}>
      <HStack alignItems="center" flex={1} justifyContent="space-around">
        {!hideUIAddOns ? <NotificationBadge flex={1} /> : null}
        {!fp.isEmpty(title) ? (
          <Text
            color={themeData.mainColorDark}
            flex={3}
            fontSize={22}
            fontWeight="bold"
            textAlign="center"
          >
            {title}
          </Text>
        ) : null}
        <MenuModal
          isOpen={menuModalVisible}
          toggleMenuVisible={toggleMenuModalVisible}
        />
        {!hideUIAddOns ? (
          <IconButton
            _hover={{ bg: 'transparent' }}
            _pressed={{ bg: 'transparent' }}
            bg="transparent"
            flex={1}
            icon={
              <Ionicons color={themeData.mainColorDark} name="menu" size={30} />
            }
            onPress={toggleMenuModalVisible}
          />
        ) : null}
      </HStack>
      <Divider
        bg={themeData.mainColorDark}
        bottom={0}
        height={1}
        position="absolute"
        w="100%"
      />
    </Flex>
  );
};
