import { AcceptDecline, LoadingStatusModal } from 'components/elements';
import {
  DashboardInputField,
  LinksField,
  MultipleFilesField,
} from 'components/inputs';
import { Formik } from 'formik';
import {
  getLinksArrayFromText,
  stringifyLinks,
} from 'helpers/data-helpers/string-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { IStackProps, Text, VStack } from 'native-base';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import {
  IBlogEntry,
  IBlogEntryFormPayload,
} from 'services/resources/blog/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { handleUploadBlogMedia } from 'services/resources-recipes/storage-recipes';
import { fontSizes } from 'styles/theme';
import { ILoadingData } from 'types.d';

import { MobileEditor } from './components/MobileEditor';
import { WebEditor } from './components/WebEditor';
import {
  BlogEntryValidationSchema,
  INITIAL_VALUES,
} from './helpers/form-helpers';
import {
  areImagesPresent,
  removeImageFromTemplate,
} from './helpers/string-helpers';
import { IListItem } from './types.d';

interface IBlogEntryFormProps extends IStackProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IBlogEntryFormPayload | IBlogEntry) => void;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Values that are already set, used for editing them.
   */
  previousValues?: IBlogEntry;
  /**
   * Temporary ID created to handle Firestore files upload and deletion.
   */
  tempNewEntryID: string;
}

export const BlogEntryForm: React.FC<IBlogEntryFormProps> = (props) => {
  const {
    onSubmit,
    onCancel,
    previousValues = undefined,
    tempNewEntryID,
    ...rest
  } = props;

  const themeData = useSelector(getTheme);
  const userData = useSelector(getUser);
  const { web } = usePlatform();

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [editorState, setEditorState] = useState<string>(
    !fp.isNil(previousValues) ? previousValues.content : '',
  );
  const [mediaURIs, setMediaURIs] = useState<string[]>([]);
  const [mediaURLs, setMediaURLs] = useState<string[]>([]);
  const [links, setLinks] = useState<string[]>([]);

  const previousValuesEnabled =
    !fp.isNil(previousValues) && !fp.isEmpty(previousValues);

  const handleMediaUpload = async (files: string[]): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage:
        files.length > 1 ? 'Subiendo imágenes...' : 'Subiendo imagen...',
    });
    try {
      const retrievedMediaURLs = await handleUploadBlogMedia(
        files,
        tempNewEntryID,
      );
      if (!fp.isEmpty(retrievedMediaURLs)) setMediaURLs(retrievedMediaURLs);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'BlogEntryForm-handleMediaUpload',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that in one hand removes an image from the editor state and
   * in the other, removes the unused image urls from the state.
   * @param index
   */
  const handleOnFileRemove = (index: number): void => {
    const fileName = `uploaded_image_${index + 1}`;
    setEditorState(removeImageFromTemplate(editorState, fileName));
    // eslint-disable-next-line prefer-const
    let outputURLs: string[] = [];
    mediaURLs.forEach((url) => {
      if (!url.includes(fileName)) {
        outputURLs.push(url);
      }
    });
    setMediaURLs(outputURLs);
  };

  /**
   * Function that from the current links URIs, create the list scheme required
   * to be used with tinyMCE.
   * @param links
   * @returns links_list
   */
  const getLinksScheme = (links: string[]): IListItem[] => {
    return links.map((link) => {
      const newListItem: IListItem = {} as IListItem;
      newListItem.title = link;
      newListItem.value = link;
      return newListItem;
    });
  };

  /**
   * Function that from the current images URLs, create the list scheme required
   * to be used with tinyMCE.
   * @param images
   * @returns image_list
   */
  const getImageScheme = (images: string[]): IListItem[] => {
    return images.map((image, index) => {
      const newListItem: IListItem = {} as IListItem;
      newListItem.title = `uploaded_image_${index + 1}`;
      newListItem.value = image;
      return newListItem;
    });
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={BlogEntryValidationSchema}
    >
      {({ handleChange, values, handleSubmit, setFieldValue, errors }) => (
        <VStack
          onLayout={() => {
            if (previousValuesEnabled) {
              setFieldValue('title', previousValues.title);
            }
          }}
          {...rest}
        >
          <LoadingStatusModal loading={loadingData.loading}>
            {loadingData.loadingMessage}
          </LoadingStatusModal>
          <Text
            color={themeData.mainColorDark}
            fontSize={fontSizes.regular}
            fontWeight="bold"
            py={4}
            textAlign="center"
          >
            {previousValuesEnabled ? 'Actualizar Entrada' : 'Nueva Entrada'}
          </Text>
          <Text
            alignSelf={web ? 'center' : undefined}
            color={themeData.mainColorDark}
            py={2}
            textAlign="center"
            w={web ? '90%' : undefined}
          >
            Título
          </Text>
          <DashboardInputField
            alignSelf="center"
            defaultValue={values.title}
            error={errors.title}
            onChangeText={handleChange('title')}
            w="90%"
          />
          <Text
            color={themeData.mainColorDark}
            pb={4}
            pt={2}
            textAlign="center"
          >
            Contenido
          </Text>
          <MultipleFilesField
            disabled={!fp.isEmpty(mediaURIs) || areImagesPresent(editorState)}
            minH={mediaURIs.length > 0 ? '120px' : '50px'}
            minW={!web ? '90%' : undefined}
            multipleFilesURIs={mediaURIs}
            onRemoveFile={(index: number) => handleOnFileRemove(index)}
            setMultipleFilesURIs={(files) => {
              setMediaURIs(files);
              if (mediaURLs.length === 0) handleMediaUpload(files);
            }}
            w={web ? '90%' : undefined}
          >
            Elegir Imágenes
          </MultipleFilesField>
          <Text
            color={themeData.mainColorDark}
            opacity={0.8}
            px={2}
            py={2}
            textAlign="center"
          >
            {
              '(Las imágenes subidas podrán ser usadas más adelante en el editor de texto)'
            }
          </Text>
          <LinksField
            alignSelf="center"
            defaultValue={stringifyLinks(links)}
            h="40px"
            onChangeText={(text) => setLinks(getLinksArrayFromText(text))}
            pt={4}
            title="Añade los links que quieras usar en el editor de texto, separándolos por comas"
            w="90%"
          />
          {!web ? (
            <MobileEditor
              editorContent={editorState}
              imageURLs={getImageScheme(mediaURLs)}
              links={getLinksScheme(links)}
              previousEnabled={previousValuesEnabled}
              setEditorContent={setEditorState}
            />
          ) : (
            <WebEditor
              editorContent={editorState}
              imageURLs={getImageScheme(mediaURLs)}
              links={getLinksScheme(links)}
              setEditorContent={setEditorState}
            />
          )}
          <AcceptDecline
            customAcceptMessage={
              !previousValuesEnabled ? 'Añadir' : 'Actualizar'
            }
            disabled={
              (!previousValuesEnabled &&
                (values.title === '' || editorState === '')) ||
              (previousValuesEnabled &&
                previousValues.content === editorState &&
                previousValues.title === values.title)
            }
            mt={6}
            onAccept={() => {
              setFieldValue('content', editorState);
              setTimeout(() => handleSubmit(), 50);
            }}
            onDecline={onCancel}
            pb={8}
          />
        </VStack>
      )}
    </Formik>
  );
};

BlogEntryForm.defaultProps = {
  alignItems: 'center',
  bg: '#FFFFFF',
  mt: '20px',
  pt: 8,
  w: '100%',
};
