import { Entypo } from '@expo/vector-icons';
import { InputScroller } from 'components/elements';
import { EventForm } from 'components/forms';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { IconButton, IModalProps, Modal } from 'native-base';
import { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IEvent, IEventPayload } from 'services/resources/events/types.d';
import { ILoadingData } from 'types.d';

interface IEventModalProps extends IModalProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IEvent | IEventPayload) => void;
  /**
   * Function that only calls the toggle modal at Events.tsx, but may be used
   * in the future for calling other functions.
   */
  onCloseModal: () => void;
  /**
   * Flag for being aware about edition status.
   */
  isEditing: boolean;
  /**
   * Values that are already set, used for editing them.
   */
  previousValues: IEvent;
  /**
   * Function that will connect to the backend to retrieve the cover colors.
   */
  handleStoreColors: (imageURI: string) => Promise<void>;
  /**
   * Function that will handle the temp files created by handleStoreColors
   * function.
   */
  handleTempFilesDeletion: () => Promise<void>;
  /**
   * Colors to be used as the palette for the form.
   */
  coverColors: string[];
  /**
   * The color that has been selected from the palette.
   */
  selectedColor: string;
  /**
   * State handler function for the selectedColor.
   */
  setSelectedColor: (color: string) => void;
  /**
   * Colors palette loading status from backend operations.
   */
  colorsLoading: ILoadingData;
}

export const EventModal: React.FC<IEventModalProps> = (props): JSX.Element => {
  const {
    colorsLoading,
    coverColors,
    onSubmit,
    isEditing,
    previousValues,
    onCloseModal,
    handleStoreColors,
    handleTempFilesDeletion,
    setSelectedColor,
    selectedColor,
    ...rest
  } = props;
  const themeData = useSelector(getTheme);

  const { web } = usePlatform();

  const [scrollRef, setScrollRef] = useState<ScrollView | null>(null);

  useEffect(() => {
    if (!fp.isNil(scrollRef)) scrollRef.flashScrollIndicators();
  }, [isEditing]);

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        borderRadius={!web ? 0 : 10}
        flex={1}
        h="100%"
        justifyContent="center"
        w={!web ? '100%' : '30%'}
      >
        <IconButton
          alignSelf="flex-end"
          icon={
            <Entypo color={themeData.mainColorDark} name="cross" size={28} />
          }
          mr={3}
          onPress={() => onCloseModal()}
        />
        {!web ? (
          <InputScroller
            bottomOffset
            contentContainerStyle={{
              width: '100%',
            }}
            disableScrollToStart
          >
            <EventForm
              alignItems="center"
              colorsLoading={colorsLoading}
              coverColors={coverColors}
              handleStoreColors={handleStoreColors}
              handleTempFilesDeletion={handleTempFilesDeletion}
              isEditing={isEditing}
              onCancel={() => onCloseModal()}
              onSubmit={onSubmit}
              previousValues={previousValues}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          </InputScroller>
        ) : (
          <ScrollView
            ref={(scrollViewRef) => setScrollRef(scrollViewRef)}
            showsVerticalScrollIndicator
            style={{ width: '100%' }}
          >
            <EventForm
              alignItems="center"
              colorsLoading={colorsLoading}
              coverColors={coverColors}
              handleStoreColors={handleStoreColors}
              handleTempFilesDeletion={handleTempFilesDeletion}
              isEditing={isEditing}
              onCancel={() => onCloseModal()}
              onSubmit={onSubmit}
              previousValues={previousValues}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          </ScrollView>
        )}
      </Modal.Content>
    </Modal>
  );
};

EventModal.defaultProps = {
  size: 'full',
};
