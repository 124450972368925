import { differenceInDays, parseISO } from 'date-fns';

/**
 * Function that compares if the current hour is the same registered in the
 * selected event or if have only elapsed three hours since the event began.
 * It also checks if are minutes pending for the event to start.
 * @param selectedEventDate
 * @returns dateTestPass
 */
export const compareDates = (selectedEventDate: Date): boolean => {
  let dateTestPass = false;
  const parsedDate = parseISO(`${selectedEventDate}`);
  const currentDate = new Date();
  if (differenceInDays(currentDate, parsedDate) < -7) {
    dateTestPass = true;
  }
  return dateTestPass;
};
