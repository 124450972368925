import fp from 'lodash/fp';
import { IEventRegistry } from 'services/resources/events-registries/types.d';

/**
 * Function that checks if a user has signed-up before to an event and
 * therefore, avoiding unwanted behavior.
 * @param registries
 * @param user
 * @returns a boolean that indicates if user has signed before or not
 */
export const checkIfUserAlreadySignedUpToEvent = (
  registries: IEventRegistry[],
  user: string,
): boolean => {
  let alreadySignedUp: boolean = false;
  if (!fp.isEmpty(registries)) {
    registries.forEach((registry) => {
      if (registry.email === user) {
        alreadySignedUp = true;
      }
    });
  }
  return alreadySignedUp;
};
