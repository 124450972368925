import { MaterialIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  AcceptDeclineModal,
  DisclaimerModal,
  LoadingStatusModal,
  UIWrapper,
} from 'components/elements';
import { TaxInfoForm } from 'components/forms';
import { getStorageFileName } from 'helpers/data-helpers/string-helpers';
import {
  readSession,
  removeSession,
} from 'helpers/storage-helpers/session-helpers';
import fp from 'lodash/fp';
import { Button, Text } from 'native-base';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setLogged, setUserData } from 'redux-service/slices';
import { authResources } from 'services/resources/auth';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { notificationsResources } from 'services/resources/notifications';
import { storageResources } from 'services/resources/storage';
import { userResources } from 'services/resources/users';
import { ITaxInfo, IUser } from 'services/resources/users/types.d';
import { handleUploadProfilePicture } from 'services/resources-recipes/storage-recipes';
import { colors } from 'styles/colors';

import { UserInfo } from './components/UserInfo';

export const AccountSettings: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [disclaimerVisible, setDisclaimerVisible] = useState<boolean>(false);
  const [taxInfoFormVisible, setTaxInfoFormVisible] = useState<boolean>(false);

  const toggleModalOpen = (): void => {
    setModalOpen(!modalOpen);
  };

  const handleLogOut = async (): Promise<void> => {
    setLoadingData(false);
    toggleModalOpen();
    await authResources.signOut();
    removeSession();
    dispatch(setLogged(false));
    navigation.navigate('logIn' as never);
  };

  const handleImageUpload = async (): Promise<void> => {
    setLoadingData(true);
    try {
      const imageURL = await handleUploadProfilePicture(
        userData.email,
        'profilePicture',
      );
      if (!fp.isEmpty(imageURL)) {
        await userResources.putProfilePicture(
          imageURL,
          userData.id,
          userData.token,
        );
        const { data: d } = await userResources.getByMail(
          userData.email,
          userData.token,
        );
        const data = d as IUser;
        if (!fp.isEmpty(data)) {
          dispatch(setUserData({ ...userData, pictures: data.pictures }));
        }
      } else {
        setDisclaimerVisible(true);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'image-upload',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData(false);
  };

  const handleOnDelete = async (): Promise<void> => {
    setLoadingData(true);
    try {
      // When the user is a patient we delete the patient entries for all the
      // therapists
      if (userData.accountType === 'patient') {
        // We verify if the field exists on the database
        const therapists: string[] = !fp.isNil(userData.therapists)
          ? userData.therapists
          : [];
        // Wait until all relationships are undone
        await Promise.all(
          therapists.map(async (therapist) => {
            const therapyRelationshipPayload = {
              requestingUserId: therapist,
              targetUserId: userData.id,
            };
            return await userResources.deleteTherapyRelationship(
              therapyRelationshipPayload,
              userData.token,
            );
          }),
        );
        // When the user is a therapist, we delete its id from all related
        // patients.
      } else if (userData.accountType === 'therapist') {
        const patients: string[] = !fp.isNil(userData.patients)
          ? userData.patients
          : [];
        await Promise.all(
          patients.map(async (patient) => {
            const therapyRelationshipPayload = {
              requestingUserId: userData.id,
              targetUserId: patient,
            };
            return await userResources.deleteTherapyRelationship(
              therapyRelationshipPayload,
              userData.token,
            );
          }),
        );
      }
      // Delete al the notifications related to the user
      await notificationsResources.deleteAll(userData.id, userData.token);
      // Delete storaged pictures from the user
      if (!fp.isNil(userData.pictures?.profilePicture)) {
        await storageResources.deletePicture(
          userData.email,
          `profilePicture/${getStorageFileName(
            userData.pictures?.profilePicture as string,
          )}`,
        );
      }
      // Delete the user from database
      await userResources.delete(userData.id, userData.token);
      // Read stored data to get the user Object since Redux does not allow
      // us to store the User interface.
      const restoredSession = await readSession();
      if (!fp.isEmpty(restoredSession)) {
        // Generate custom token if recognized session
        const {
          data: { token },
        } = await authResources.createCustomToken({
          token: restoredSession.stsTokenManager.accessToken,
          uid: restoredSession.uid,
        });
        // Sign-in with custom token
        const { user } = await authResources.autoAuth(token);
        // Delete the user from Firebase Auth
        await authResources.deleteAccount(user);
      }
      await handleLogOut();
    } catch (e) {
      setLoadingData(false);
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'account-deletion',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
  };

  /**
   * Function that updates the tax info of the user.
   * @param taxInfoPayload
   */
  const handleOnUpdateTaxInfo = async (
    taxInfoPayload: ITaxInfo,
  ): Promise<void> => {
    setTaxInfoFormVisible(false);
    setLoadingData(true);
    try {
      // update the user's tax info with the given payload.
      await userResources.putTaxInfo(
        taxInfoPayload,
        userData.email,
        userData.token,
      );
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'account-putTaxInfo',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData(false);
  };

  // Re-render screen if user data changed
  useFocusEffect(useCallback(() => {}, [userData.pictures]));

  return (
    <UIWrapper title="Cuenta">
      <LoadingStatusModal loading={loadingData}>
        Actualizando Información...
      </LoadingStatusModal>
      <DisclaimerModal
        isOpen={disclaimerVisible}
        onClose={() => setDisclaimerVisible(false)}
      >
        Cancelaste la operación, usaste un tipo de archivo no admitido o hubo
        algún problema con tu archivo.
      </DisclaimerModal>
      <AcceptDeclineModal
        invertColors
        isOpen={modalOpen}
        onAccept={handleOnDelete}
        onDecline={toggleModalOpen}
      >
        <Text fontWeight="bold">
          {
            '¿Estás seguro de querer eliminar tu cuenta? \n\nEsta acción no se puede deshacer y tus datos serán permanentemente eliminados.'
          }
        </Text>
      </AcceptDeclineModal>
      <TaxInfoForm
        isFormVisible={taxInfoFormVisible}
        onCancel={() => setTaxInfoFormVisible(false)}
        onSubmit={(p) => handleOnUpdateTaxInfo(p)}
        previousValues={
          !fp.isNil(userData.taxInfo) ? userData.taxInfo : ({} as ITaxInfo)
        }
      />
      <UserInfo
        flex={17}
        onImageUpdate={handleImageUpload}
        onTaxInfoUpdate={() => setTaxInfoFormVisible(true)}
      />
      <Button
        _hover={{ bg: colors.error, opacity: 0.8 }}
        _pressed={{ bg: colors.error, opacity: 0.8 }}
        _text={{ fontWeight: 'bold' }}
        bg={colors.error}
        borderRadius={0}
        flex={1}
        leftIcon={
          <MaterialIcons
            color="white"
            name="delete"
            size={24}
            style={{ marginRight: 10 }}
          />
        }
        onPress={toggleModalOpen}
        w="100%"
      >
        Eliminar Cuenta
      </Button>
    </UIWrapper>
  );
};
