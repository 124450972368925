import { Entypo } from '@expo/vector-icons';
import { HStack, IconButton, IStackProps } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IUser } from 'services/resources/users/types.d';
import { colors } from 'styles/colors';

import { CalendarUserItem } from './CalendarUserItem';

interface ISelectedTherapistProps extends IStackProps {
  onUnselect: () => void;
  selectedTherapist: IUser;
}

export const SelectedTherapist: React.FC<ISelectedTherapistProps> = (
  props,
): JSX.Element => {
  const { onUnselect, selectedTherapist, ...rest } = props;
  const themeData = useSelector(getTheme);
  return (
    <HStack bg={colors.terappiaYellow.light} {...rest}>
      <CalendarUserItem
        isSelectRendered={false}
        item={selectedTherapist}
        minH="90px"
        pt={1}
      />
      <IconButton
        icon={<Entypo color={themeData.mainColorDark} name="cross" size={28} />}
        onPress={onUnselect}
      />
    </HStack>
  );
};

SelectedTherapist.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
};
