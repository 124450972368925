import { AntDesign } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { FormattedInputField } from '../FormattedInputField';
import { IInputFieldProps } from '../types.d';

export const SearchBarField: React.FC<IInputFieldProps> = (props) => {
  const { ...rest } = props;
  const themeData = useSelector(getTheme);
  return (
    <FormattedInputField
      InputLeftElement={
        <AntDesign
          color="white"
          name="search1"
          size={24}
          style={{ marginLeft: 10 }}
        />
      }
      _focus={{
        bg: themeData.mainColorDark,
        borderWidth: 0,
      }}
      bg={themeData.mainColorDark}
      {...rest}
    />
  );
};

FormattedInputField.defaultProps = {
  color: 'white',
  placeholderTextColor: 'white',
  textAlign: 'left',
  w: '100%',
};
