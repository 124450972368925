import { Editor } from '@tinymce/tinymce-react';
import fp from 'lodash/fp';
import { Dimensions } from 'react-native';

import { IListItem } from '../types.d';

interface IWebEditorProps {
  /**
   * List of links to be used in order to insert links while using the editor.
   */
  links: IListItem[];
  /**
   * List of images to be used in order to insert images while using the editor.
   */
  imageURLs: IListItem[];
  /**
   * State that contains the editor content.
   */
  editorContent: string;
  /**
   * State setter for the editor content.
   */
  setEditorContent: (content: string) => void;
}

const TINY_KEY = 'tbubjgb4i84a8e1qplrvmko9x5mjyr76216m86blp1apo1xl';
const ES_URL =
  'https://firebasestorage.googleapis.com/v0/b/terappia-v2.appspot.com/o/lang%2Fes_MX.js?alt=media&token=fdbb1924-f6f7-45d4-8bbc-e44bd10e5ed7';
const LOGO_CLASS =
  '#root > div > div > div > div:nth-child(20) > div:nth-child(2) > div > div > div > div > div > div.tox.tox-tinymce > div.tox-editor-container > div.tox-statusbar > div.tox-statusbar__text-container > span';

export const WebEditor: React.FC<IWebEditorProps> = (props): JSX.Element => {
  const { editorContent, setEditorContent, links = [], imageURLs = [] } = props;

  const tinyMCEInit = {
    automatic_uploads: true,
    content_style:
      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    contextmenu: 'link image imagetools',
    height: 600,
    image_list: imageURLs,
    image_uploadtab: false,
    importcss_append: true,
    language: 'es_MX',
    language_url: ES_URL,
    link_list: links,
    menubar: 'edit insert format',
    plugins:
      'importcss searchreplace autolink visualblocks visualchars image link advlist lists quickbars emoticons',
    quickbars_insert_toolbar: false,
    quickbars_selection_toolbar: false,
    removed_menuitems:
      'hr pagebreak anchor toc nonbreaking codeformat backcolor blocks styles lineheight',
    toolbar:
      'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor removeformat | emoticons',
    toolbar_sticky: true,
    width: Dimensions.get('screen').width / 2 - 80,
  };

  return (
    <Editor
      apiKey={TINY_KEY}
      init={tinyMCEInit}
      key={JSON.stringify({ imageURLs, links })}
      onEditorChange={setEditorContent}
      onLoadContent={() => {
        // Remove TinyMCE logo
        const logo = document.querySelector(LOGO_CLASS);
        if (!fp.isNil(logo)) logo.setAttribute('style', 'display: none');
      }}
      value={editorContent}
    />
  );
};
