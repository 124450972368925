import { Entypo } from '@expo/vector-icons';
import { LogInForm } from 'components/forms';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import {
  Button,
  IconButton,
  IModalProps,
  Modal,
  Text,
  VStack,
} from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IBaseAuthentication } from 'services/resources/auth/types.d';

interface INewSessionModalProps extends IModalProps {
  /**
   * Function triggered when pressing on the cross icon.
   */
  onModalClose: () => void;
  /**
   * Handler function for the case when the user wants to log-in with its
   * existing account.
   */
  onLogIn: (payload: IBaseAuthentication) => Promise<void>;
  /**
   * Function to be used when a user wants to log-in as guest.
   */
  onAnonymousLogIn: () => Promise<void>;
  /**
   * Route to be used to re-take the user to the desired event when creating
   * a new TerAppia account.
   */
  eventRoute?: string;
}

export const NewSessionModal: React.FC<INewSessionModalProps> = (
  props,
): JSX.Element => {
  const {
    onModalClose,
    onLogIn,
    onAnonymousLogIn,
    eventRoute = '',
    ...rest
  } = props;
  const themeData = useSelector(getTheme);
  const { web } = usePlatform();
  const [logInFormVisible, setLogInFormVisible] = useState<boolean>(false);
  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        bg={themeData.mainColorLight}
        borderRadius={!web ? 0 : 10}
        flex={1}
        h="100%"
        justifyContent="center"
        w={!web ? '100%' : '30%'}
      >
        {logInFormVisible && !fp.isEmpty(eventRoute) ? (
          <LogInForm
            onScreenChange={onModalClose}
            onSubmit={onLogIn}
            origin={eventRoute}
          />
        ) : (
          <>
            <IconButton
              alignSelf="flex-end"
              flex={1}
              icon={
                <Entypo
                  color={themeData.mainColorDark}
                  name="cross"
                  size={28}
                />
              }
              mr={3}
              onPress={onModalClose}
            />
            <VStack
              alignItems="center"
              flex={9}
              justifyContent="center"
              space={5}
            >
              <Text px={5} textAlign="center">
                No se encontró ninguna sesión previa. Puedes iniciar sesión con
                tu cuenta de TerAppia o continuar como invitado.
              </Text>
              <Button
                bg={themeData.mainColorDark}
                onPress={() => setLogInFormVisible(true)}
                w="60%"
              >
                Iniciar Sesión
              </Button>
              <Text
                color={themeData.mainColorDark}
                fontWeight="bold"
                onPress={() => {
                  onModalClose();
                  onAnonymousLogIn();
                }}
              >
                Continuar como invitado
              </Text>
            </VStack>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

NewSessionModal.defaultProps = {
  size: 'full',
};
