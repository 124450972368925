import { Ionicons } from '@expo/vector-icons';
import { usePlatform } from 'hooks/platform-hooks';
import { Box, IStackProps, VStack } from 'native-base';
import { useState } from 'react';

interface IStatisticsIconProps extends IStackProps {
  /**
   * Icon to be added to the stadistics icon.
   */
  icon: JSX.Element;
}

export const StatisticsIcon: React.FC<IStatisticsIconProps> = (
  props,
): JSX.Element => {
  const { icon, ...rest } = props;

  const { web } = usePlatform();
  const [iconWidth, setIconWidth] = useState<number>(0);

  return (
    <VStack
      onLayout={(e) => setIconWidth(e.nativeEvent.layout.width)}
      {...rest}
    >
      <Box
        alignSelf="flex-start"
        minH={!web ? '18px' : '40px'}
        ml={`-${iconWidth / 2}px`}
      >
        <Ionicons
          color="white"
          name="ios-stats-chart-sharp"
          size={!web ? 18 : 40}
        />
      </Box>
      <Box justifyContent="center" minH="70px">
        {icon}
      </Box>
    </VStack>
  );
};

StatisticsIcon.defaultProps = {
  alignItems: 'center',
  flex: 1,
  w: '80%',
};
