import Constants from 'expo-constants';
import fp from 'lodash/fp';
import { Platform } from 'react-native';

/**
 * Function that based on the platform returns a url that can be used to
 * connect to test APIs in debug mode.
 * @returns app server ip
 */
export const getHostDeviceIP = (): string => {
  let ip = '0.0.0.0';
  const { manifest } = Constants;

  // Algorithm retrieved from https://stackoverflow.com/a/56943681/11123993
  if (Platform.OS !== 'web') {
    ip = `${
      !fp.isNil(manifest) && !fp.isNil(manifest.debuggerHost)
        ? manifest.debuggerHost.split(':').shift()
        : '0.0.0.0'
    }`;
  } else {
    ip = '127.0.0.1';
  }
  return ip;
};
