import { Entypo, MaterialIcons } from '@expo/vector-icons';
import { getSingleImagePickerURI } from 'helpers/data-helpers/picker-helpers';
import fp from 'lodash/fp';
import {
  Button,
  HStack,
  IconButton,
  Image,
  IStackProps,
  Text,
  VStack,
  WarningOutlineIcon,
} from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { colors } from 'styles/colors';

interface ISingleFileFieldProps extends IStackProps {
  /**
   * State for single file.
   */
  singleFileURI?: string;
  /**
   * State setter for single files.
   * @param file
   * @returns
   */
  setSingleFileURI?: (file: string) => void;
  /**
   * Error detected if validation schema is provided.
   */
  error?: string;
  /**
   * Flag to indicate weather disabling the button or not.
   */
  disabled?: boolean;
}

export const SingleFileField: React.FC<ISingleFileFieldProps> = (
  props,
): JSX.Element => {
  const {
    disabled = false,
    error = '',
    children,
    singleFileURI = '',
    setSingleFileURI = () => {},
    ...rest
  } = props;

  const themeData = useSelector(getTheme);

  const validSingleFileURI =
    !fp.isNil(singleFileURI) && !fp.isEmpty(singleFileURI);

  const handleSingleFileSelect = async (): Promise<void> => {
    const imageURI = await getSingleImagePickerURI();
    setSingleFileURI(imageURI);
  };

  const handleSingleFileUnattach = (): void => {
    setSingleFileURI('');
  };

  return (
    <VStack {...rest}>
      <Button
        bg={themeData.mainColorDark}
        disabled={disabled}
        leftIcon={<MaterialIcons color="white" name="attach-file" size={24} />}
        onPress={() => handleSingleFileSelect()}
        opacity={disabled ? 0.5 : 1}
      >
        {children}
      </Button>
      {!fp.isEmpty(error) ? (
        <HStack alignSelf="center" mt={2}>
          <WarningOutlineIcon color={colors.error} mt={1} size="xs" />
          <Text color={colors.error} fontSize={12} ml={1} textAlign="center">
            {error}
          </Text>
        </HStack>
      ) : null}
      {validSingleFileURI && fp.isEmpty(error) ? (
        <VStack alignItems="center">
          <Image
            alt="cover"
            borderRadius={8}
            mt={2}
            size="sm"
            src={singleFileURI}
          />
          <IconButton
            icon={<Entypo color="red" name="circle-with-cross" size={24} />}
            onPress={() => handleSingleFileUnattach()}
          />
        </VStack>
      ) : null}
    </VStack>
  );
};

SingleFileField.defaultProps = {
  flex: 1,
  textAlign: 'center',
  w: '100%',
};
