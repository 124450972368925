import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { fontSizes } from 'styles/theme';
import { IPasswordFieldProps, PasswordField } from '../PasswordField';

export const FormattedPasswordField: React.FC<IPasswordFieldProps> = (
  props,
): JSX.Element => {
  const { ...rest } = props;
  const themeData = useSelector(getTheme);
  return (
    <PasswordField
      color={themeData.mainColorDark}
      customShowHideColor={themeData.mainColorDark}
      fontSize={fontSizes.regular}
      placeholderTextColor={themeData.mainColorDark}
      {...rest}
    />
  );
};

FormattedPasswordField.defaultProps = {
  _focus: { bg: 'white' },
  bg: 'white',
  borderColor: 'transparent',
  borderRadius: 15,
  customShowHideSize: 18,
  focusOutlineColor: 'transparent',
  textAlign: 'center',
};
