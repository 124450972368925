import { FontAwesome } from '@expo/vector-icons';
import { useSmallScreen } from 'hooks/dimensions-hooks';
import { usePlatform } from 'hooks/platform-hooks';
import { Flex, IFlexProps, Pressable, Text } from 'native-base';
import { Dimensions } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { fontSizes } from 'styles/theme';

interface INoPictureProps extends IFlexProps {
  /**
   * Pressable component event handler.
   */
  onPress: () => void;
}

export const NoPicture: React.FC<INoPictureProps> = (props) => {
  const { onPress, ...rest } = props;

  const themeData = useSelector(getTheme);
  const small = useSmallScreen();
  const { web } = usePlatform();

  return (
    <Flex
      w={
        !web
          ? `${Dimensions.get('screen').width / 2.352941}px`
          : `${Dimensions.get('screen').width / 6.305549}px`
      }
      {...rest}
    >
      <Pressable
        alignItems="center"
        bg={themeData.mainColorDark}
        borderRadius={10}
        justifyContent="center"
        maxH={`${Dimensions.get('screen').height / 3.152774699}px`}
        onPress={onPress}
        py={10}
      >
        <FontAwesome color="white" name="user-circle-o" size={120} />
        <Text
          color="white"
          fontSize={!small ? fontSizes.regular : fontSizes.smaller}
          mt={4}
          mx={3}
          textAlign="center"
        >
          Presiona aquí para subir una foto de perfil
        </Text>
      </Pressable>
    </Flex>
  );
};

NoPicture.defaultProps = {
  alignItems: 'center',
  flex: 1,
  mt: 3,
  mx: 3,
};
