import { AntDesign, FontAwesome5, Ionicons } from '@expo/vector-icons';
import { usePlatform } from 'hooks/platform-hooks';
import {
  Button,
  Flex,
  HStack,
  IconButton,
  IFlexProps,
  ScrollView,
  Text,
  VStack,
} from 'native-base';
import { useState } from 'react';
import { IAppointmentWithInfoTaxDataAndNames } from 'services/resources/appointments-info/types.d';
import { IAppointmentsStatsPayload } from 'services/resources/report-generation/types.d';
import { IReportFileType } from 'services/types.d';
import { colors } from 'styles/colors';

import {
  getCancelledAppointmentsAmount,
  getConfirmedByTherapistsAmount,
  getConfirmedByThirdPartiesAmount,
  getETProfitsAmount,
  getNonCancelledAppointmentsAmount,
} from '../helpers/filter-helpers';
import { StatisticsItemAmount } from './StatisticsAmountItem';

interface IStatisticsProps extends IFlexProps {
  /**
   * All retrieved and filtered data to be used for calculating stats.
   */
  data: IAppointmentWithInfoTaxDataAndNames[];
  /**
   * Handler function to calling report generation API (administrative user).
   */
  onGenerateAdministrativeReport: (
    appointmentsStats: IAppointmentsStatsPayload,
    fileType: IReportFileType,
  ) => void;
  /**
   * Handler function to calling report generation API (accountant).
   */
  onGenerateAccountantReport: (fileType: IReportFileType) => void;
  /**
   * Flag that indicates if we can actually generate accountant reports.
   */
  accountantDataAvailable: boolean;
}

export const Statistics: React.FC<IStatisticsProps> = (props): JSX.Element => {
  const {
    data,
    onGenerateAdministrativeReport,
    onGenerateAccountantReport,
    accountantDataAvailable,
    ...rest
  } = props;

  const { web } = usePlatform();

  const [wantsAdministrativeReport, setWantsAdministrativeReport] =
    useState<boolean>(false);
  const [wantsAccountantReport, setWantsAccountantReport] =
    useState<boolean>(false);

  const toggleWantsAdministrativeReport = (): void => {
    setWantsAdministrativeReport(!wantsAdministrativeReport);
  };

  const toggleWantsAccountantReport = (): void => {
    setWantsAccountantReport(!wantsAccountantReport);
  };

  const listData = [
    {
      amount: getNonCancelledAppointmentsAmount(data),
      label: 'Citas Completadas: ',
      value: 'completed',
    },
    {
      amount: getCancelledAppointmentsAmount(data),
      label: 'Citas Canceladas: ',
      value: 'cancelled',
    },
    {
      amount: getConfirmedByTherapistsAmount(data),
      label: 'Citas Confirmadas Por Terapeutas: ',
      value: 'confirmedByTherapist',
    },
    {
      amount: getConfirmedByThirdPartiesAmount(data),
      label: 'Citas Confirmadas Por Terceros: ',
      value: 'confirmedByThird',
    },
    {
      amount: getETProfitsAmount(data),
      label: 'Ganancias de ET: ',
      value: 'ETProfitsAmount',
    },
  ];

  /**
   * Function that for a given value, returns the color that has to be displayed
   * accordingly.
   * @param value
   * @returns
   */
  const getDisplayColor = (value: string): string | undefined => {
    if (value === 'completed') return colors.success;
    if (value === 'ETProfitsAmount') return colors.success;
    if (value === 'cancelled') return colors.error;
    return undefined;
  };

  /**
   * Function that prepares the required payload for administrative report
   * generation.
   * @param fileType
   */
  const handleOnAdministrativeReportGenerationPress = (
    fileType: IReportFileType,
  ): void => {
    // Build the payload with corresponding information
    const payload: IAppointmentsStatsPayload = {
      appointmentsData: { ...data },
      appointmentsStats: {
        ETProfitsAmount: getETProfitsAmount(data),
        cancelled: getCancelledAppointmentsAmount(data),
        completed: getNonCancelledAppointmentsAmount(data),
        confirmedByTherapist: getConfirmedByTherapistsAmount(data),
        confirmedByThird: getConfirmedByThirdPartiesAmount(data),
      },
    };
    // Call parent's handler function
    onGenerateAdministrativeReport(payload, fileType);
  };

  return (
    <Flex direction={web ? 'row' : 'column'} {...rest}>
      <Flex flex={1} w="100%">
        {web ? (
          <VStack alignItems="center">
            <HStack>
              <StatisticsItemAmount
                amount={listData[0].amount}
                amountColor={getDisplayColor(listData[0].value)}
                label={listData[0].label}
              />
              <StatisticsItemAmount
                amount={listData[1].amount}
                amountColor={getDisplayColor(listData[1].value)}
                label={listData[1].label}
              />
            </HStack>
            <HStack>
              <StatisticsItemAmount
                amount={listData[2].amount}
                amountColor={getDisplayColor(listData[2].value)}
                label={listData[2].label}
              />
              <StatisticsItemAmount
                amount={listData[3].amount}
                amountColor={getDisplayColor(listData[3].value)}
                label={listData[3].label}
              />
            </HStack>
            <StatisticsItemAmount
              amount={listData[4].amount}
              amountColor={getDisplayColor(listData[4].value)}
              label={listData[4].label}
            />
          </VStack>
        ) : (
          <ScrollView width="100%">
            <VStack w="100%">
              <StatisticsItemAmount
                amount={listData[0].amount}
                amountColor={getDisplayColor(listData[0].value)}
                label={listData[0].label}
              />
              <StatisticsItemAmount
                amount={listData[1].amount}
                amountColor={getDisplayColor(listData[1].value)}
                label={listData[1].label}
              />
              <StatisticsItemAmount
                amount={listData[2].amount}
                amountColor={getDisplayColor(listData[2].value)}
                label={listData[2].label}
              />
              <StatisticsItemAmount
                amount={listData[3].amount}
                amountColor={getDisplayColor(listData[3].value)}
                label={listData[3].label}
              />
              <StatisticsItemAmount
                amount={listData[4].amount}
                amountColor={getDisplayColor(listData[4].value)}
                label={listData[4].label}
              />
            </VStack>
          </ScrollView>
        )}
      </Flex>
      <ScrollView
        contentContainerStyle={{
          alignContent: 'center',
          justifyContent: 'center',
          maxWidth: '100%',
          width: '100%',
        }}
        flex={1}
        style={{ width: '100%' }}
      >
        <Flex
          alignItems="center"
          direction={web ? 'row' : 'column'}
          flex={1}
          justifyContent="flex-start"
          px={4}
          w="100%"
        >
          <VStack alignItems="center" flex={1} mt={3} w="100%">
            <Text fontWeight="bold" pb={3} textAlign="center">
              Administrativo
            </Text>
            <Button
              bg={colors.terappiaOrange.dark}
              h="40px"
              mb={!web ? 4 : undefined}
              onPress={() => toggleWantsAdministrativeReport()}
              rightIcon={
                <Ionicons color="white" name="stats-chart" size={16} />
              }
              w="80%"
            >
              Generar Reporte
            </Button>
            {wantsAdministrativeReport ? (
              <HStack
                justifyContent="center"
                mb={!web ? 5 : undefined}
                mt={3}
                space={3}
              >
                <IconButton
                  bg={colors.error}
                  icon={<AntDesign color="white" name="pdffile1" size={20} />}
                  onPress={() =>
                    handleOnAdministrativeReportGenerationPress('pdf')
                  }
                  size="md"
                />
                <IconButton
                  bg={colors.excelGreen}
                  icon={
                    <FontAwesome5 color="white" name="file-csv" size={20} />
                  }
                  onPress={() =>
                    handleOnAdministrativeReportGenerationPress('csv')
                  }
                  size="md"
                />
              </HStack>
            ) : null}
          </VStack>
          <VStack alignItems="center" flex={1} justifyContent="center" w="100%">
            <Text fontWeight="bold" pb={3} textAlign="center">
              Contador
            </Text>
            {accountantDataAvailable ? (
              <VStack alignItems="center" w="100%">
                <Button
                  bg={colors.terappiaPurple.dark}
                  h="40px"
                  mb={!web ? 4 : undefined}
                  onPress={() => toggleWantsAccountantReport()}
                  rightIcon={
                    <Ionicons color="white" name="stats-chart" size={16} />
                  }
                  w="80%"
                >
                  Generar Reporte
                </Button>
                {wantsAccountantReport ? (
                  <HStack
                    justifyContent="center"
                    mb={!web ? 5 : undefined}
                    mt={3}
                    space={3}
                  >
                    <IconButton
                      bg={colors.excelGreen}
                      icon={
                        <FontAwesome5 color="white" name="file-csv" size={20} />
                      }
                      onPress={() => onGenerateAccountantReport('csv')}
                      size="md"
                    />
                  </HStack>
                ) : null}
              </VStack>
            ) : null}
            {!accountantDataAvailable ? (
              <Text pb={2} textAlign="center" w="100%">
                Para generar reporte de contabilidad, primero utiliza el filtro
                de solicititud de factura. Si seleccionado el filtro no se
                muestra el botón de generación de reporte, entonces no se
                encontraron solicitudes de factura para el período seleccionado.
              </Text>
            ) : null}
          </VStack>
        </Flex>
      </ScrollView>
    </Flex>
  );
};

Statistics.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
};
