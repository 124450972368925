import { AntDesign, Ionicons } from '@expo/vector-icons';
import { format, parseISO } from 'date-fns';
import { esLocales } from 'helpers/data-helpers/locale-helpers';
import fp from 'lodash/fp';
import { Box, IBoxProps, Modal, Pressable, Text } from 'native-base';
import { useState } from 'react';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

LocaleConfig.locales.es = esLocales;
LocaleConfig.defaultLocale = 'es';

interface IDateFieldProps extends IBoxProps {
  /**
   * Handler function triggered when date selected.
   */
  onDateChange: (date: Date) => void;
  /**
   * Current value of the date.
   */
  dateValue?: Date;
  /**
   * Height to be set in the show/hide button.
   */
  buttonHeight?: string;
  /**
   * Label to be shown inside of the button.
   */
  buttonLabel: string;
  /**
   * Flag to indicate weather disabling the button or not.
   */
  disableButton?: boolean;
  /**
   * Color to be used within the button.
   */
  buttonColor?: string;
}

export const DateField: React.FC<IDateFieldProps> = (props) => {
  const {
    onDateChange,
    dateValue = undefined,
    buttonHeight = undefined,
    buttonColor = '',
    buttonLabel,
    disableButton = true,
    ...rest
  } = props;

  const themeData = useSelector(getTheme);

  const [show, setShow] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());

  const toggleShow = (): void => {
    setShow(!show);
  };

  return (
    <Box {...rest}>
      <Pressable
        _disabled={{ opacity: 0.8 }}
        alignItems="center"
        bg={!fp.isEmpty(buttonColor) ? buttonColor : themeData.mainColorDark}
        borderRadius={5}
        disabled={disableButton}
        flex={1}
        flexDir="row"
        h={!fp.isNil(buttonHeight) ? buttonHeight : '100%'}
        justifyContent="center"
        onPress={toggleShow}
        w="100%"
      >
        <Ionicons color="white" name="calendar-outline" size={24} />
        <Text color="white" pl={2}>{`${buttonLabel} (${format(
          fp.isNil(dateValue) ? date : dateValue,
          'dd-MM-yyyy',
        )})`}</Text>
      </Pressable>
      <Modal isOpen={show}>
        <Modal.Content>
          <Calendar
            markingType="custom"
            onDayPress={({ dateString }) => {
              const parsedDate = parseISO(dateString);
              setDate(new Date(parsedDate));
              onDateChange(new Date(parsedDate));
              setShow(false);
            }}
            renderArrow={(direction) => (
              <AntDesign
                color={themeData.mainColorDark}
                name={direction === 'right' ? 'caretright' : 'caretleft'}
                size={18}
              />
            )}
          />
        </Modal.Content>
      </Modal>
    </Box>
  );
};

DateField.defaultProps = {
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDir: 'row',
  w: '100%',
};
