import fp from 'lodash/fp';
import { Button, HStack } from 'native-base';
import { colors } from 'styles/colors';

import { IAcceptDeclineProps } from '../types.d';

export const AcceptDecline: React.FC<IAcceptDeclineProps> = (props) => {
  const {
    customAcceptMessage = '',
    customDeclineMessage = '',
    customAcceptColor = '',
    disabled = false,
    onAccept,
    onDecline,
    invertColors = false,
    ...rest
  } = props;

  return (
    <HStack {...rest}>
      <Button
        _hover={{
          bg: !invertColors ? colors.success : colors.error,
          opacity: 0.8,
        }}
        _pressed={{
          bg: !invertColors ? colors.success : colors.error,
          opacity: 0.8,
        }}
        bg={
          !invertColors
            ? fp.isEmpty(customAcceptColor)
              ? colors.success
              : customAcceptColor
            : colors.error
        }
        flex={1}
        isDisabled={disabled}
        onPress={onAccept}
      >
        {!fp.isEmpty(customAcceptMessage) ? customAcceptMessage : 'Aceptar'}
      </Button>
      <Button
        _hover={{
          bg: !invertColors ? colors.error : colors.success,
          opacity: 0.8,
        }}
        _pressed={{
          bg: !invertColors ? colors.error : colors.success,
          opacity: 0.8,
        }}
        bg={!invertColors ? colors.error : colors.success}
        flex={1}
        onPress={onDecline}
      >
        {!fp.isEmpty(customDeclineMessage) ? customDeclineMessage : 'Cancelar'}
      </Button>
    </HStack>
  );
};

AcceptDecline.defaultProps = {
  px: 2,
  space: 2,
  w: '100%',
};
