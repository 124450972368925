import {
  alphaSpaceRegex,
  emailRegex,
} from 'helpers/data-helpers/regex-helpers';
import * as Yup from 'yup';

export const SignupFormCopies = {
  email: 'Correo Electrónico',
  fatherName: 'Apellido Paterno',
  firstName: 'Nombre',
  motherName: 'Apellido Materno',
};

export const SignupFormValidationSchema = Yup.object().shape({
  accountType: Yup.string().oneOf(['regular', 'therapist']),
  email: Yup.string()
    .matches(emailRegex, 'Debes ingresar un correo válido.')
    .required('Este campo es requerido.'),
  fatherName: Yup.string()
    .matches(
      alphaSpaceRegex,
      'Tus apellidos no pueden contener caracteres especiales ni números.',
    )
    .required('Este campo es requerido.'),
  firstName: Yup.string()
    .matches(
      alphaSpaceRegex,
      'Tu nombre no puede contener caracteres especiales ni números.',
    )
    .required('Este campo es requerido.'),
  motherName: Yup.string().matches(
    alphaSpaceRegex,
    'Tus apellidos no pueden contener caracteres especiales ni números.',
  ),
});

export const INITIAL_VALUES = {
  accountType: 'regular',
  email: '',
  fatherName: '',
  firstName: '',
  motherName: '',
};
