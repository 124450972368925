import LogoAsset from 'images/general/logo.png';
import PeacefulPeakAssetWithLogo from 'images/general/splash_with_et.png';
import ETLogoAsset from 'images/general/TA_logo.png';
import PeacefulPeakAsset from 'images/wallpapers/PeacefulPeak.png';
import PeacefulPeakWebAsset from 'images/wallpapers/PeacefulPeakWeb.png';

export const PeacefulPeak = PeacefulPeakAsset;
export const PeacefulPeakWithLogo = PeacefulPeakAssetWithLogo;
export const ETLogo = ETLogoAsset;
export const PeacefulPeakWeb = PeacefulPeakWebAsset;
export const Logo = LogoAsset;
export const LogoOnline =
  'https://firebasestorage.googleapis.com/v0/b/terappia-v2.appspot.com/o/images%2FTerAppia%2Flogo.png?alt=media&token=55a993d3-ef47-4b31-b2f3-133e24e4a0aa';
