import { createNavigationContainerRef } from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef();

// Implementation retrieved from https://reactnavigation.org/docs/navigating-without-navigation-prop/

/**
 * React navigation function to be used when issues with no NavigationContainer
 * error message is found.
 * @param name
 * @param params
 */
export const navigate = (name: string, params: any = {}): void => {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name as never, params as never);
  }
};
