import { usePlatform } from 'hooks/platform-hooks';
import { Flex, IFlexProps, Image } from 'native-base';
import { Dimensions } from 'react-native';

interface IMenuProfilePictureProps extends IFlexProps {
  /**
   * Image source to be used, independently from the platform.
   */
  src: string;
}

export const MenuProfilePicture: React.FC<IMenuProfilePictureProps> = (
  props,
) => {
  const { src, ...rest } = props;
  const { web } = usePlatform();
  return (
    <Flex {...rest}>
      {web ? (
        <img
          alt="profilepicture"
          height={`${Dimensions.get('screen').height / 3.152774699}px`}
          src={src}
          style={{ borderRadius: 10, objectFit: 'cover' }}
          width={`${Dimensions.get('screen').width / 6.305549}px`}
        />
      ) : (
        <Image
          alt="profilepicture"
          borderRadius={10}
          h={`${Dimensions.get('screen').height / 3.152774699}px`}
          src={src}
          w={`${Dimensions.get('screen').width / 2.352941}px`}
        />
      )}
    </Flex>
  );
};

MenuProfilePicture.defaultProps = {
  alignItems: 'center',
  flex: 1,
  mt: 3,
};
