import { instance } from 'services/instance';

import {
  IAppointmentInfo,
  IAppointmentInfoPayload,
  IAppointmentInfoResponse,
  IAppointmentInfoWithNamesResponse,
} from './types.d';

export const appointmentsInfoResources = {
  create: (payload: IAppointmentInfoPayload, token: string): Promise<void> =>
    instance.post(
      '/appointmentsInfoAPI',
      { appointmentInfo: payload },
      { headers: { authorization: token } },
    ),

  get: (token: string): Promise<IAppointmentInfoResponse> =>
    instance.get('/appointmentsInfoAPI', {
      headers: { authorization: token },
    }),

  getByAppointmentId: (
    id: string,
    token: string,
  ): Promise<IAppointmentInfoResponse> =>
    instance.get('/appointmentsInfoAPI/byAppointmentId', {
      headers: { authorization: token },
      params: { appointmentId: id },
    }),

  getCompletedWithNames: (
    token: string,
  ): Promise<IAppointmentInfoWithNamesResponse> =>
    instance.get('/appointmentsInfoAPI/completedWithNames', {
      headers: { authorization: token },
    }),

  getCompletedWithNamesByDate: (
    date: string,
    token: string,
  ): Promise<IAppointmentInfoWithNamesResponse> =>
    instance.get('/appointmentsInfoAPI/completedWithNamesByDate', {
      headers: { authorization: token },
      params: { date },
    }),

  getCompletedWithNamesByFixedDate: (
    date: string,
    dateOption: string,
    token: string,
  ): Promise<IAppointmentInfoWithNamesResponse> =>
    instance.get('/appointmentsInfoAPI/completedWithNamesByFixedDate', {
      headers: { authorization: token },
      params: { date, dateOption },
    }),

  put: (payload: IAppointmentInfo, id: string, token: string): Promise<void> =>
    instance.put(
      '/appointmentsInfoAPI',
      { appointmentInfo: payload },
      { headers: { authorization: token }, params: { appointmentId: id } },
    ),
};
