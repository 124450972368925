import { IUserState } from 'redux-service/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { mailingResources } from 'services/resources/mailing';
import { notificationsResources } from 'services/resources/notifications';

/**
 * Function for notifying the users with both, push notifications and
 * notification emails from a relevant action on the app.
 * @param notification
 * @param appSection
 * @param user
 */
export const handleNotificate = async (
  notification: { body: string; title: string },
  appSection: string,
  user: IUserState,
): Promise<void> => {
  try {
    // Send the regular notification
    notificationsResources.create(
      {
        body: notification.body,
        data: {
          section: appSection,
        },
        sound: 'default',
        title: notification.title,
        to: user.deviceId,
      },
      user.id,
      user.token,
    );
    // Send the mail
    mailingResources.create(
      {
        body: notification.body,
        subject: notification.title,
        title: notification.title,
        to: user.email,
      },
      user.token,
    );
  } catch (e) {
    const newLog: ILogEntry = {
      date: new Date(),
      message: JSON.stringify(e),
      service: 'notification-recipe',
      user: user.email,
    };
    logsResources.create(newLog, user.token);
  }
};
