import {
  sortObjectArrayByDate,
  sortObjectArrayByKeys,
} from 'helpers/data-helpers/sorting-helpers';
import fp from 'lodash/fp';

/**
 * Function for returning sorted data to a flatlist.
 * @param data
 * @param sortKey
 * @param sortSubKey
 * @returns sorted array that meets the given conditions
 */
export const sortListData = (
  data: any[],
  sortKey: string,
  sortSubKey?: string,
): any[] => {
  const originalArray = [...data];
  let sortedArray: any[] = [];
  // We verify that the key is not an empty string
  if (!fp.isEmpty(sortKey)) {
    // If we are not trying to sort by date, continue with the usual flow
    if (sortKey !== 'date') {
      // Perform this sorting only if key but no subkey given
      if (!fp.isEmpty(sortKey) && fp.isEmpty(sortSubKey)) {
        sortedArray = sortObjectArrayByKeys(data, sortKey);
      } else {
        sortedArray = sortObjectArrayByKeys(data, sortKey, sortSubKey);
      }
    } else {
      // Otherwise, sort by date
      sortedArray = sortObjectArrayByDate(data);
    }
    return sortedArray;
  }
  return originalArray;
};

/**
 * Function that verfies if a given object includes a given search word in
 * some of its properties, either by one key or an array of keys.
 * @param item
 * @param searchKey
 * @param searchWord
 * @returns includes result
 */
export const includesSearchText = (
  item: string,
  searchKey: string | string[],
  searchSubKey: string | string[],
  searchWord: string,
): boolean => {
  // Convert the string into an object
  const itemObject = JSON.parse(item);
  let result: boolean = false;
  // We first verify we are not dealing with an array of strings
  if (typeof searchKey === 'string') {
    // We save the object property, interpreting as a string
    const keyString = itemObject[
      searchKey as keyof typeof itemObject
    ] as string;
    // We verify that we don't have a search sub key
    if (fp.isEmpty(searchSubKey)) {
      // Verify the incidence
      if (keyString.includes(searchWord)) result = true;
    } else {
      // Check that we are not dealing with an array
      if (typeof searchSubKey === 'string') {
        // We save the object nested property, interpreting as a string
        const keySubString = itemObject[searchKey as keyof typeof itemObject][
          searchSubKey as keyof typeof searchKey
        ] as string;
        if (keySubString.includes(searchWord)) result = true;
      } else {
        // If searchKeys it is an array of strings, get an array of values that
        // matches those keys.
        const subkeysValues = searchSubKey.map((subKey) => {
          return itemObject[searchKey as keyof typeof itemObject][
            subKey as keyof typeof searchKey
          ];
        });
        // Create a unique string with that array
        const outputString = subkeysValues.join(' ');
        // Verify the incidence
        if (outputString.includes(searchWord)) result = true;
      }
    }
  } else {
    // If searchKeys it is an array of strings, get an array of values that
    // matches those keys.
    const keysValues = searchKey.map((key) => {
      return itemObject[key as keyof typeof itemObject];
    });
    // Create a unique string with that array
    const outputString = keysValues.join(' ');
    // Verify the incidence
    if (outputString.includes(searchWord)) result = true;
  }
  return result;
};
