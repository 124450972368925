import { createSlice } from '@reduxjs/toolkit';
import {
  PeacefulPeak,
  PeacefulPeakWeb,
} from 'helpers/source-helpers/img-src-helpers';
import { Platform } from 'react-native';
import { RootState } from 'redux-service/store';
import { IThemeState, ThemesEnum } from 'redux-service/types.d';
import { colors } from 'styles/colors';

const availableThemes: ThemesEnum[] = [
  ThemesEnum.PEACEFUL_PEAK,
  ThemesEnum.MELANCHOLY_HILL,
  ThemesEnum.QUIET_PORT,
  ThemesEnum.SILENT_COAST,
  ThemesEnum.SUNSET_OASIS,
];

const initialState: IThemeState = {
  currentTheme: availableThemes[0],
  mainColorDark: colors.terappiaBlue.dark,
  mainColorLight: colors.terappiaBlue.light,
  themeNumber: 0,
  wallpaperSrc: Platform.OS !== 'web' ? PeacefulPeak : PeacefulPeakWeb,
};

export const themeSlice = createSlice({
  initialState,
  name: 'theme',
  reducers: {
    changeTheme: (state) => {
      if (state.themeNumber < availableThemes.length) {
        state.themeNumber += 1;
        state.currentTheme = availableThemes[state.themeNumber];
      }
    },
  },
});

export const { changeTheme } = themeSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getTheme = (state: RootState): IThemeState => state.theme;

export const themeReducer = themeSlice.reducer;
