import fp from 'lodash/fp';
import { IAppointmentWithInfoTaxDataAndNames } from 'services/resources/appointments-info/types.d';

import { IFilterQueryProps } from '../types.d';

/**
 * Function that counts how many appointments have not been cancelled from the
 * given input.
 * @param data
 * @returns
 */
export const getNonCancelledAppointmentsAmount = (
  data: IAppointmentWithInfoTaxDataAndNames[],
): number => {
  return data.filter((aWIAN) => {
    if (!aWIAN.appointment.cancelled) {
      return aWIAN;
    } else return null;
  }).length;
};

/**
 * Function that counts how many appointments have been cancelled from the
 * given input.
 * @param data
 * @returns
 */
export const getCancelledAppointmentsAmount = (
  data: IAppointmentWithInfoTaxDataAndNames[],
): number => {
  return data.filter((aWIAN) => {
    if (aWIAN.appointment.cancelled) {
      return aWIAN;
    } else return null;
  }).length;
};

/**
 * Function that counts how many appointments have been confirmed by the
 * corresponding therapists from the given input.
 * @param data
 * @returns
 */
export const getConfirmedByTherapistsAmount = (
  data: IAppointmentWithInfoTaxDataAndNames[],
): number => {
  return data.filter((aWIAN) => {
    if (aWIAN.appointment.confirmedByTherapist) {
      return aWIAN;
    } else return null;
  }).length;
};

/**
 * Function that counts how many appointments have been confirmed by the
 * corresponding registered third parties from the given input.
 * @param data
 * @returns
 */
export const getConfirmedByThirdPartiesAmount = (
  data: IAppointmentWithInfoTaxDataAndNames[],
): number => {
  return data.filter((aWIAN) => {
    if (aWIAN.appointment.confirmedByThird) {
      return aWIAN;
    } else return null;
  }).length;
};

/**
 * Function that counts how much profits has ET made from the given input.
 * @param data
 * @returns
 */
export const getETProfitsAmount = (
  data: IAppointmentWithInfoTaxDataAndNames[],
): number => {
  let fullProfit = 0;
  data.forEach(({ info: { commissionPercentage, receivedPayment } }) => {
    let appointmentProfit = 0;
    if (commissionPercentage !== 0 && !fp.isNil(receivedPayment)) {
      appointmentProfit =
        Number(receivedPayment) -
        (Number(receivedPayment) * commissionPercentage) / 100;
    } else if (!fp.isNil(receivedPayment)) {
      appointmentProfit = Number(receivedPayment);
    }
    fullProfit += appointmentProfit;
  });
  return fullProfit;
};

// Algorithm provided by ChatGPT May 3 Version
/**
 * Function that retrieves only those entries that match the given query filter
 * input.
 * @param data
 * @param selectedQuery
 * @returns
 */
export const getAllFilteredAppointmentInfo = (
  data: IAppointmentWithInfoTaxDataAndNames[],
  selectedQuery: IFilterQueryProps,
): IAppointmentWithInfoTaxDataAndNames[] => {
  if (fp.isEmpty(data)) {
    return data;
  }
  return data.filter((appointment) => {
    const matches = Object.entries(selectedQuery).every(([key, value]) => {
      if (fp.isNil(value)) {
        return true;
      }
      switch (key) {
        case 'therapistFullName':
          return appointment.therapistFullName === value;
        case 'paymentMethod':
          return appointment.info.paymentMethod === value;
        case 'paidStatus':
          return appointment.info.paidStatus === value;
        case 'invoiceRequest':
          return appointment.info.invoiceRequest === value;
        case 'invoicePeriod':
          return appointment.info.invoicePeriod === value;
        default:
          return true;
      }
    });
    return matches;
  });
};
