import { IAppointmentWithTherapyProgress } from '../types.d';

/**
 * Function to return only the appointments (with therapy progress) with the
 * selected patient.
 * @param inputAppointmentsWithTherapyProgresses
 * @param user
 * @returns appointmentsWithTherapyProgresses
 */
export const getSelectedUserAppointmentsProgresses = (
  inputAppointmentsWithTherapyProgresses: IAppointmentWithTherapyProgress[],
  user: string,
): IAppointmentWithTherapyProgress[] => {
  return inputAppointmentsWithTherapyProgresses.filter(
    (appointmentWithProgress) => {
      if (user === appointmentWithProgress.appointment.patient) {
        return appointmentWithProgress;
      } else return null;
    },
  );
};
