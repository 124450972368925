import { HStack, IStackProps, Text, VStack } from 'native-base';
import { IEventRegistry } from 'services/resources/events-registries/types';

interface IEventDataItemProps extends IStackProps {
  /**
   * Cost of the event.
   */
  cost: number;
  /**
   * Registries of the event.
   */
  registries: IEventRegistry[];
}

export const EventDataItem: React.FC<IEventDataItemProps> = (props) => {
  const { cost, registries, ...rest } = props;

  /**
   * Function that indicates us how many people assisted to the event.
   */
  const getAssistance = (): number => {
    let eventAssistance = 0;
    // for each registry, if assistedConfirmation is true, add 1.
    registries.forEach((registry) => {
      if (registry.assistedConfirmation) {
        eventAssistance += 1;
      }
    });
    return eventAssistance;
  };

  /**
   * Function that indicates us how many people registered have paid.
   */
  const getCompletedPayments = (): number => {
    let completedPayments = 0;
    // for each registry, if paid is true, add 1.
    registries.forEach((registry) => {
      if (registry.paid) {
        completedPayments += 1;
      }
    });
    return completedPayments;
  };

  /**
   * Function that indicates us how many people registered have not paid.
   */
  const getPendingPayments = (): number => {
    let pendingPayments = 0;
    // for each registry, if paid is false, add 1.
    registries.forEach((registry) => {
      if (!registry.paid) {
        pendingPayments += 1;
      }
    });
    return pendingPayments;
  };

  /**
   * Function that indicates us how much would ET actually receives from an
   * event.
   */
  const getETProfit = (): number => {
    let eventProfit = 0;
    // for each registry, if paid is true, add event cost.
    registries.forEach((registry) => {
      if (registry.paid) {
        eventProfit += Number(cost);
      }
    });
    return eventProfit;
  };

  return (
    <VStack {...rest}>
      <HStack>
        <Text color="white" fontWeight="bold">
          Personas Registradas:{' '}
        </Text>
        <Text color="white">{registries.length}</Text>
      </HStack>
      <HStack>
        <Text color="white" fontWeight="bold">
          Personas que Asistieron:{' '}
        </Text>
        <Text color="white">{getAssistance()}</Text>
      </HStack>
      <HStack>
        <Text color="white" fontWeight="bold">
          Pagos Completados:{' '}
        </Text>
        <Text color="white">{getCompletedPayments()}</Text>
      </HStack>
      <HStack>
        <Text color="white" fontWeight="bold">
          Pagos Pendientes:{' '}
        </Text>
        <Text color="white">{getPendingPayments()}</Text>
      </HStack>
      <HStack>
        <Text color="white" fontWeight="bold">
          Dinero Recaudado:{' '}
        </Text>
        <Text color="white">{getETProfit()}</Text>
      </HStack>
    </VStack>
  );
};

EventDataItem.defaultProps = {
  pl: 2,
  py: 2,
  space: 4,
};
