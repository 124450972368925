import fp from 'lodash/fp';
import * as Yup from 'yup';

export const PasswordFormCopies = {
  password: 'Contraseña',
  passwordConfirmation: 'Confirma Tu Contraseña',
};

export const PasswordFormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Este campo es requerido.')
    .test(
      'len',
      'La contraseña debe tener al menos 6 caracteres.',
      (val) => !fp.isEmpty(val) && val.length >= 6,
    ),
  passwordConfirmation: Yup.string()
    .required('Es necesario confirmar tu contraseña.')
    .when('password', {
      is: (val: string) => !!(!fp.isEmpty(val) && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Las contraseñas deben ser iguales',
      ),
    }),
});

export const INITIAL_VALUES = {
  password: '',
  passwordConfirmation: '',
};
