import { Entypo } from '@expo/vector-icons';
import { BottomPicker, DisclaimerModal } from 'components/elements';
import { IOption } from 'components/elements/types.d';
import { FormattedEmailField } from 'components/inputs';
import { Formik } from 'formik';
import { getLabelByField } from 'helpers/data-helpers/string-helpers';
import { useSmallScreen } from 'hooks/dimensions-hooks';
import { usePlatform } from 'hooks/platform-hooks';
import { Button, VStack } from 'native-base';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IPasswordLessSignUpPayload } from 'services/resources/users/types.d';
import { buttonSizes, fontSizes } from 'styles/theme';

import { FormattedInputField } from './components/FormattedInputField';
import {
  INITIAL_VALUES,
  SignupFormCopies,
  SignupFormValidationSchema,
} from './helpers/form-helpers';

interface ILoginForm {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IPasswordLessSignUpPayload) => void;
}

export const SignUpForm: React.FC<ILoginForm> = (props) => {
  const { onSubmit } = props;
  const themeData = useSelector(getTheme);
  const small = useSmallScreen();
  const { web } = usePlatform();

  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [disclaimerVisible, setDisclaimerVisible] = useState<boolean>(false);

  const accountTypes: IOption[] = [
    { title: 'Usuario Regular', value: 'regular' },
    { title: 'Terapeuta', value: 'therapist' },
  ];

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={SignupFormValidationSchema}
    >
      {({
        errors,
        handleChange,
        isValid,
        handleSubmit,
        values,
        setFieldValue,
      }) => (
        <VStack
          alignItems="center"
          justifyContent="center"
          w={!web ? '80%' : '20%'}
        >
          <FormattedInputField
            error={errors.firstName}
            label={SignupFormCopies.firstName}
            onChangeText={handleChange('firstName')}
          />
          <FormattedInputField
            error={errors.fatherName}
            label={SignupFormCopies.fatherName}
            onChangeText={handleChange('fatherName')}
          />
          <FormattedInputField
            error={errors.motherName}
            label={SignupFormCopies.motherName}
            onChangeText={handleChange('motherName')}
          />
          <FormattedEmailField
            error={errors.email}
            onChangeText={handleChange('email')}
          />
          <Button
            _text={{
              fontSize: !small ? fontSizes.regular : fontSizes.smaller,
            }}
            bg={themeData.mainColorDark}
            borderRadius={15}
            h={!small ? undefined : buttonSizes.small}
            justifyContent="center"
            mt={small && !isValid ? -2 : 0}
            onPress={() => setPickerOpen(true)}
            rightIcon={
              <Entypo
                color="white"
                name="triangle-down"
                size={24}
                style={{ position: 'absolute', right: -20 }}
              />
            }
            w="100%"
          >
            {`Tipo De Cuenta: ${getLabelByField(
              'accountType',
              values.accountType,
            )}`}
          </Button>
          <BottomPicker
            isOpen={pickerOpen}
            onSelect={(a) => {
              setFieldValue('accountType', a.value);
              setPickerOpen(false);
              if (a.value === 'therapist') {
                setDisclaimerVisible(true);
              }
            }}
            options={accountTypes}
          />
          <DisclaimerModal
            isOpen={disclaimerVisible}
            onClose={() => setDisclaimerVisible(false)}
          >
            Recuerda que un administrador debe concederte permisos de terapeuta.
            Mientras tu solicitud se resuelve, permanecerás como usuario
            regular.
          </DisclaimerModal>
          <Button
            _text={{
              color: 'white',
              fontSize: !small ? fontSizes.regular : fontSizes.smaller,
              fontWeight: 'bold',
            }}
            bg={themeData.mainColorDark}
            borderRadius={15}
            h={!small ? undefined : `${buttonSizes.small}px`}
            isDisabled={!isValid || values.email === ''}
            mb={15}
            mt={!small ? 4 : 2}
            onPress={() => handleSubmit()}
            w="60%"
          >
            Crear Contraseña
          </Button>
        </VStack>
      )}
    </Formik>
  );
};
