import { AntDesign } from '@expo/vector-icons';
import { LoadingStatusModal } from 'components/elements';
import { IOption } from 'components/elements/types.d';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import {
  Button,
  IModalProps,
  Modal,
  ScrollView,
  Text,
  View,
  VStack,
} from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { colors } from 'styles/colors';
import { ILoadingData } from 'types.d';

import {
  dateOptions,
  invoicePeriodOptions,
  invoiceRequestOptions,
  paidStatusOptions,
  paymentMethodOptions,
} from '../helpers/constant-helpers';
import { IFilterQueryProps } from '../types.d';
import { DateFilterPicker } from './DateFilterPicker';
import { DateSelector } from './DateSelector';
import { Filter } from './Filter';

interface IFilteringModalProps extends IModalProps {
  /**
   * Therapists that have been tracked on the retrieved appointments, to be used
   * as data filter.
   */
  appointmentsTherapists: string[];
  /**
   * Current date value to be used for retrieving the target data.
   */
  selectedQueryDate: Date;
  /**
   * Function that will help to both: set the new query date state and to
   * set current selected period (for retrieving by date, month or year).
   */
  setSelectedQueryDate: (newDate: Date, selectedOption: string) => void;
  /**
   * Data loading status, for being used with corresponding component.
   */
  loadingData: ILoadingData;
  /**
   * State setter function to be used for the filtering query.
   */
  setFilterQuery: (filterQuery: IFilterQueryProps) => void;
  /**
   * Actual value of the filtering query state.
   */
  filterQuery: IFilterQueryProps;
  /**
   * Function to be triggered every time the date option has changed.
   */
  dateOptionChange: (selectedOption: string) => void;
  /**
   * State ot the selected date option.
   */
  selectedDateOption: IOption;
  /**
   * State setter for the selected date option.
   */
  setSelectedDateOption: (newOption: IOption) => void;
}

export const FilteringModal: React.FC<IFilteringModalProps> = (
  props,
): JSX.Element => {
  const {
    selectedQueryDate,
    setSelectedQueryDate,
    appointmentsTherapists,
    loadingData,
    filterQuery,
    setFilterQuery,
    dateOptionChange,
    selectedDateOption,
    setSelectedDateOption,
    ...rest
  } = props;

  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const therapistsOptions: IOption[] = appointmentsTherapists.map(
    (therapist) => {
      return { title: therapist, value: therapist };
    },
  );

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        bg="white"
        borderRadius={!web ? 0 : 10}
        maxH="90%"
        maxWidth={!web ? '100%' : '40%'}
        w={!web ? '100%' : undefined}
      >
        <Modal.CloseButton />
        <View w="100%">
          <VStack alignItems="center" pb={3}>
            <LoadingStatusModal loading={loadingData.loading}>
              {loadingData.loadingMessage}
            </LoadingStatusModal>
            <Text
              color={themeData.mainColorDark}
              fontSize={18}
              fontWeight="bold"
              pt={5}
              textAlign="center"
            >
              Filtros
            </Text>
            <DateFilterPicker
              dateOptions={dateOptions}
              selectedDateOption={selectedDateOption}
              setSelectedDateOption={(dateOption) => {
                setSelectedDateOption(dateOption);
                dateOptionChange(dateOption.value);
              }}
              w="60%"
            />
            <DateSelector
              mt={2}
              selectedDateOption={selectedDateOption.value}
              selectedQueryDate={selectedQueryDate}
              setSelectedQueryDate={(d) =>
                setSelectedQueryDate(d, selectedDateOption.value)
              }
            />
            {!fp.isEmpty(filterQuery) ? (
              <Button
                bg={colors.error}
                leftIcon={<AntDesign color="white" name="delete" size={16} />}
                my={2}
                onPress={() => setFilterQuery({} as IFilterQueryProps)}
              >
                Limpiar Filtros
              </Button>
            ) : null}
          </VStack>
        </View>
        <ScrollView style={{ width: '100%' }}>
          <VStack alignItems="center" maxW="100%">
            {!fp.isEmpty(appointmentsTherapists) ? (
              <Filter
                filterQuery={filterQuery}
                label="Terapeuta:"
                onOptionSelect={(tFN) =>
                  setFilterQuery({ ...filterQuery, therapistFullName: tFN })
                }
                options={therapistsOptions}
              />
            ) : null}
            <Filter
              filterQuery={filterQuery}
              label="Tipo de Pago:"
              onOptionSelect={(pM) =>
                setFilterQuery({ ...filterQuery, paymentMethod: pM })
              }
              options={paymentMethodOptions}
            />
            <Filter
              filterQuery={filterQuery}
              label="Estatus de Pago:"
              onOptionSelect={(pS) =>
                setFilterQuery({ ...filterQuery, paidStatus: pS })
              }
              options={paidStatusOptions}
            />
            <Filter
              filterQuery={filterQuery}
              label="Solicitud de factura:"
              onOptionSelect={(iR) =>
                setFilterQuery({ ...filterQuery, invoiceRequest: iR })
              }
              options={invoiceRequestOptions}
            />
            {!fp.isNil(filterQuery.invoiceRequest) &&
            filterQuery.invoiceRequest ? (
              <Filter
                filterQuery={filterQuery}
                label="Período de Factura:"
                onOptionSelect={(iP) =>
                  setFilterQuery({ ...filterQuery, invoicePeriod: iP })
                }
                options={invoicePeriodOptions}
              />
            ) : null}
          </VStack>
        </ScrollView>
      </Modal.Content>
    </Modal>
  );
};
