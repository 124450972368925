import { usePlatform } from 'hooks/platform-hooks';
import { Flex, HStack, IFlexProps, ScrollView, VStack } from 'native-base';
import { IAppointmentWithInfoTaxDataAndNames } from 'services/resources/appointments-info/types.d';
import { colors } from 'styles/colors';

import {
  getCancelledAppointmentsAmount,
  getConfirmedByTherapistsAmount,
  getConfirmedByThirdPartiesAmount,
  getNonCancelledAppointmentsAmount,
  getProfitsAmounts,
} from '../helpers/filter-helpers';
import { StatisticsItemAmount } from './StatisticsAmountItem';

interface IStatisticsProps extends IFlexProps {
  /**
   * All retrieved and filtered data to be used for calculating stats.
   */
  data: IAppointmentWithInfoTaxDataAndNames[];
}

export const Statistics: React.FC<IStatisticsProps> = (props): JSX.Element => {
  const { data, ...rest } = props;

  const { web } = usePlatform();

  const listData = [
    {
      amount: getNonCancelledAppointmentsAmount(data),
      label: 'Citas Completadas: ',
      value: 'completed',
    },
    {
      amount: getCancelledAppointmentsAmount(data),
      label: 'Citas Canceladas: ',
      value: 'cancelled',
    },
    {
      amount: getConfirmedByTherapistsAmount(data),
      label: 'Citas Confirmadas Por Terapeutas: ',
      value: 'confirmedByTherapist',
    },
    {
      amount: getConfirmedByThirdPartiesAmount(data),
      label: 'Citas Confirmadas Por Terceros: ',
      value: 'confirmedByThird',
    },
    {
      amount: getProfitsAmounts(data).ETProfit,
      label: 'Ganancias de ET: ',
      value: 'ETProfitsAmount',
    },
    {
      amount: getProfitsAmounts(data).therapistProfit,
      label: 'Mis Ganancias: ',
      value: 'therapistProfitsAmount',
    },
  ];

  /**
   * Function that for a given value, returns the color that has to be displayed
   * accordingly.
   * @param value
   * @returns
   */
  const getDisplayColor = (value: string): string | undefined => {
    if (value === 'completed') return colors.success;
    if (value === 'ETProfitsAmount') return colors.success;
    if (value === 'therapistProfitsAmount') return colors.success;
    if (value === 'cancelled') return colors.error;
    return undefined;
  };

  return (
    <Flex direction={web ? 'row' : 'column'} {...rest}>
      <Flex flex={1} w="100%">
        {web ? (
          <VStack alignItems="center">
            <HStack>
              <StatisticsItemAmount
                amount={listData[0].amount}
                amountColor={getDisplayColor(listData[0].value)}
                label={listData[0].label}
              />
              <StatisticsItemAmount
                amount={listData[1].amount}
                amountColor={getDisplayColor(listData[1].value)}
                label={listData[1].label}
              />
            </HStack>
            <HStack>
              <StatisticsItemAmount
                amount={listData[2].amount}
                amountColor={getDisplayColor(listData[2].value)}
                label={listData[2].label}
              />
              <StatisticsItemAmount
                amount={listData[3].amount}
                amountColor={getDisplayColor(listData[3].value)}
                label={listData[3].label}
              />
            </HStack>
            <HStack>
              <StatisticsItemAmount
                amount={listData[4].amount}
                amountColor={getDisplayColor(listData[4].value)}
                label={listData[4].label}
              />
              <StatisticsItemAmount
                amount={listData[5].amount}
                amountColor={getDisplayColor(listData[5].value)}
                label={listData[5].label}
              />
            </HStack>
          </VStack>
        ) : (
          <ScrollView width="100%">
            <VStack w="100%">
              <StatisticsItemAmount
                amount={listData[0].amount}
                amountColor={getDisplayColor(listData[0].value)}
                label={listData[0].label}
              />
              <StatisticsItemAmount
                amount={listData[1].amount}
                amountColor={getDisplayColor(listData[1].value)}
                label={listData[1].label}
              />
              <StatisticsItemAmount
                amount={listData[2].amount}
                amountColor={getDisplayColor(listData[2].value)}
                label={listData[2].label}
              />
              <StatisticsItemAmount
                amount={listData[3].amount}
                amountColor={getDisplayColor(listData[3].value)}
                label={listData[3].label}
              />
              <StatisticsItemAmount
                amount={listData[4].amount}
                amountColor={getDisplayColor(listData[4].value)}
                label={listData[4].label}
              />
              <StatisticsItemAmount
                amount={listData[5].amount}
                amountColor={getDisplayColor(listData[5].value)}
                label={listData[5].label}
              />
            </VStack>
          </ScrollView>
        )}
      </Flex>
    </Flex>
  );
};

Statistics.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
};
