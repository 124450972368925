import { MaterialIcons } from '@expo/vector-icons';
import { ETLogo } from 'helpers/source-helpers/img-src-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import {
  Box,
  Button,
  HStack,
  Image,
  Pressable,
  Text,
  VStack,
} from 'native-base';
import React from 'react';
import { Dimensions, Linking } from 'react-native';
import Swiper from 'react-native-swiper';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

import { eTTitle, hurtTitle, liveTitle } from '../helpers/content-helpers';
import { ET_URL } from '../helpers/url-helpers';
import { SwipperItem } from './SwipperItem';

interface NextStepUIProps {
  /**
   * Event handler for proceeding to Log-in screen.
   */
  onContinue: () => void;
}

export const NextStepUI: React.FC<NextStepUIProps> = (props): JSX.Element => {
  const { onContinue } = props;
  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const handleOnETPress = async (): Promise<void> => {
    await Linking.canOpenURL(ET_URL);
    Linking.openURL(ET_URL);
  };

  return (
    <VStack alignItems="center" flex={1} justifyContent="center">
      <Box pb={20} />
      {!web ? (
        <Swiper autoplay autoplayTimeout={8}>
          <SwipperItem>{liveTitle}</SwipperItem>
          <SwipperItem>{eTTitle}</SwipperItem>
          <SwipperItem>{hurtTitle}</SwipperItem>
        </Swiper>
      ) : null}
      <Button
        _text={{ color: themeData.mainColorDark, fontWeight: 'bold' }}
        bg="white"
        onPress={onContinue}
        w="90%"
      >
        Continuar
      </Button>
      <Pressable
        alignItems="center"
        bg={themeData.mainColorDark}
        borderRadius={5}
        my={4}
        onPress={handleOnETPress}
        py={4}
        w="90%"
      >
        <Text color="white" fontWeight="bold">
          Visítanos en nuestro sitio:
        </Text>
        <HStack alignItems="center">
          <Image alt="etlogo" h="80px" resizeMode="contain" source={ETLogo} />
          <MaterialIcons
            color="white"
            name="touch-app"
            size={36}
            style={{
              left: Dimensions.get('screen').width / 1.55,
              position: 'absolute',
            }}
          />
        </HStack>
      </Pressable>
    </VStack>
  );
};
