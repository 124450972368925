import { Platform } from 'react-native';
import { MarkingProps } from 'react-native-calendars/src/calendar/day/marking';
import { IThemeState } from 'redux-service/types';
import { fontSizes } from 'styles/theme';

export const selectedDayStyle: MarkingProps = {
  customStyles: {
    container: {
      alignItems: 'center',
      borderColor: 'white',
      borderRadius: 20,
      borderWidth: 2,
      height: Platform.OS !== 'web' ? 24 : 20,
      justifyContent: 'center',
      width: 26,
    },
    text: {
      fontWeight: 'bold',
    },
  },
};

export const getTodayStyle = (themeData: IThemeState): MarkingProps => {
  return {
    customStyles: {
      container: {
        backgroundColor: 'white',
      },
      text: {
        color: themeData.mainColorDark,
        fontWeight: 'bold',
      },
    },
  };
};

export const getRegularTheme = (themeData: IThemeState): any => {
  return {
    arrowColor: 'white',
    calendarBackground: themeData.mainColorDark,
    dayTextColor: 'white',
    monthTextColor: 'white',
    'stylesheet.day.basic': {
      base: {
        alignItems: 'center',
        height: Platform.OS !== 'web' ? 24 : 20,
        justifyContent: 'center',
        width: 26,
      },
    },
    textDayFontSize: 14,
    textDayHeaderFontWeight: 'bold',
    textDisabledColor: 'gray',
    textMonthFontWeight: 'bold',
    textSectionTitleColor: 'white',
  };
};

export const getMediumTheme = (themeData: IThemeState): any => {
  return {
    arrowColor: 'white',
    calendarBackground: themeData.mainColorDark,
    dayTextColor: 'white',
    monthTextColor: 'white',
    'stylesheet.calendar.header': {
      dayHeader: {
        color: 'white',
        fontSize: fontSizes.small,
        marginBottom: 2,
        marginTop: 2,
      },
      header: {
        alignItems: 'center',
        color: 'white',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        paddingLeft: 5,
        paddingRight: 5,
      },
      monthText: {
        color: 'white',
        fontSize: fontSizes.small,
        margin: 2,
      },
      week: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 2,
      },
    },
    'stylesheet.day.basic': {
      base: {
        alignItems: 'center',
        height: 22,
        justifyContent: 'center',
        width: 24,
      },
    },
    textDayFontSize: fontSizes.small,
    textDayHeaderFontWeight: 'bold',
    textDisabledColor: 'gray',
    textMonthFontWeight: 'bold',
    textSectionTitleColor: 'white',
  };
};

export const getSmallTheme = (themeData: IThemeState): any => {
  return {
    arrowColor: 'white',
    calendarBackground: themeData.mainColorDark,
    dayTextColor: 'white',
    monthTextColor: 'white',
    'stylesheet.calendar.header': {
      dayHeader: {
        color: 'white',
        fontSize: fontSizes.smaller,
        marginBottom: 0,
        marginTop: 0,
      },
      header: {
        alignItems: 'center',
        color: 'white',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 0,
        paddingLeft: 5,
        paddingRight: 5,
      },
      monthText: {
        color: 'white',
        fontSize: fontSizes.smaller,
        margin: 0,
      },
      week: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: 0,
      },
    },
    'stylesheet.day.basic': {
      base: {
        alignItems: 'center',
        height: 19,
        justifyContent: 'center',
        margin: -2,
        width: 24,
      },
    },
    textDayFontSize: fontSizes.smaller,
    textDayHeaderFontWeight: 'bold',
    textDisabledColor: 'gray',
    textMonthFontWeight: 'bold',
    textSectionTitleColor: 'white',
  };
};
