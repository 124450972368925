import { AntDesign, FontAwesome5 } from '@expo/vector-icons';
import { getUserInitials } from 'helpers/data-helpers/string-helpers';
import { useSmallScreen } from 'hooks/dimensions-hooks';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import {
  Avatar,
  Button,
  Divider,
  Flex,
  HStack,
  IStackProps,
  Text,
  VStack,
} from 'native-base';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import { fontSizes } from 'styles/theme';

import { InfoField } from './InfoField';

interface IUserInfoProps extends IStackProps {
  /**
   * Handler for image update.
   */
  onImageUpdate: () => void;
  /**
   * Handler for tax info update.
   */
  onTaxInfoUpdate: () => void;
}

export const UserInfo: React.FC<IUserInfoProps> = (props): JSX.Element => {
  const { onImageUpdate, onTaxInfoUpdate, ...rest } = props;

  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);
  const small = useSmallScreen();
  const { web } = usePlatform();

  return (
    <VStack {...rest}>
      <HStack flex={1} w="100%">
        <Flex alignItems="center" flex={2} justifyContent="center" pl={3}>
          <Avatar
            _text={{ color: 'white' }}
            bg={themeData.mainColorDark}
            h={!web ? '60%' : '80%'}
            source={{
              uri: userData.pictures?.profilePicture,
            }}
            w={!web ? '90%' : '35%'}
          >
            {getUserInitials(userData)}
          </Avatar>
        </Flex>
        <VStack alignItems="center" flex={3} justifyContent="center" space={3}>
          <Text
            color={themeData.mainColorDark}
            fontSize={fontSizes.big}
            fontWeight="bold"
            textAlign="center"
          >{`Modifica aquí tu perfil ${userData.firstName}`}</Text>
          <Button
            _stack={{ px: !web ? 3 : undefined }}
            _text={{ textAlign: 'center' }}
            bg={themeData.mainColorDark}
            borderRadius={10}
            leftIcon={<AntDesign color="white" name="picture" size={24} />}
            onPress={onImageUpdate}
            w="90%"
          >
            Actualizar Foto De Perfil
          </Button>
          <Button
            _stack={{ px: !web ? 3 : undefined }}
            _text={{ textAlign: 'center' }}
            bg={themeData.mainColorDark}
            borderRadius={10}
            leftIcon={
              <FontAwesome5 color="white" name="money-check-alt" size={18} />
            }
            onPress={onTaxInfoUpdate}
            w="90%"
          >
            Actualizar Datos Fiscales
          </Button>
        </VStack>
      </HStack>
      <VStack alignItems="center" flex={1} w="100%">
        <Divider bg={themeData.mainColorDark} h={0.5} />
        <Text
          color={themeData.mainColorDark}
          flex={1}
          fontSize={
            !web ? (small ? fontSizes.small : fontSizes.big) : fontSizes.big
          }
          fontWeight="bold"
          my={2}
        >
          Información:
        </Text>
        <VStack alignItems="center" flex={8} h="100%" w="94%">
          <InfoField
            fieldName="Nombre"
            fieldValue={`${userData.firstName} ${userData.fatherName} ${
              !fp.isEmpty(userData.motherName) ? userData.motherName : ''
            }`}
            icon={
              <AntDesign
                color="white"
                name="user"
                size={24}
                style={{ marginLeft: 5 }}
              />
            }
          />
          <InfoField
            fieldName="Correo Electrónico"
            fieldValue={userData.email}
            icon={
              <AntDesign
                color="white"
                name="mail"
                size={24}
                style={{ marginLeft: 5 }}
              />
            }
          />
          {userData.administrator === true ? (
            <InfoField
              fieldName="Administrador"
              fieldValue={!fp.isNil(userData.administrator) ? 'Sí' : 'No'}
              icon={
                <AntDesign
                  color="white"
                  name="unlock"
                  size={24}
                  style={{ marginLeft: 5 }}
                />
              }
            />
          ) : null}
        </VStack>
      </VStack>
    </VStack>
  );
};

UserInfo.defaultProps = {
  alignItems: 'center',
};
