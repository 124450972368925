import { IMarkedDate } from 'components/elements/CalendarSelector/types.d';
import { getUserFullName } from 'helpers/data-helpers/string-helpers';
import fp from 'lodash/fp';
import { IAppointmentWithUser } from 'screens/types.d';
import { IEvent } from 'services/resources/events/types.d';
import { IUser } from 'services/resources/users/types.d';

import { ICalendarItem } from '../types.d';

/**
 * Function that creates the array data structure needed for populating the
 * calendar info. It uses both: appointments with users and events for doing
 * so.
 * @param appointmentsWithUsers
 * @param events
 * @returns ICalendarItem[]
 */
export const createCalendarItems = (
  appointmentsWithUsers: IAppointmentWithUser[],
  events: IEvent[],
  allTherapists: IUser[] = [],
): ICalendarItem[] => {
  // eslint-disable-next-line prefer-const
  let output: ICalendarItem[] = [];
  // In the case where there are appointments and events
  if (!fp.isEmpty(appointmentsWithUsers) && !fp.isEmpty(events)) {
    const totalLength = appointmentsWithUsers.length + events.length;
    for (let index = 0; index < totalLength; index++) {
      if (!fp.isNil(events[index])) {
        output.push({
          appointmentWithUser: {
            ...({} as IAppointmentWithUser),
            user: {} as IUser,
          },
          event: events[index],
        });
      }
      if (!fp.isNil(appointmentsWithUsers[index])) {
        const therapistName = !fp.isEmpty(allTherapists)
          ? getUserFullName(
              allTherapists.find(
                (therapist) =>
                  therapist.id === appointmentsWithUsers[index].therapist,
              ) as IUser,
            )
          : '';
        output.push({
          appointmentWithUser: appointmentsWithUsers[index],
          event: {} as IEvent,
          therapistName,
        });
      }
    }
  }
  // In the case where there are only appointments
  if (!fp.isEmpty(appointmentsWithUsers) && fp.isEmpty(events)) {
    appointmentsWithUsers.forEach((appointmentWithUser, index) => {
      const therapistName = !fp.isEmpty(allTherapists)
        ? getUserFullName(
            allTherapists.find(
              (therapist) =>
                therapist.id === appointmentsWithUsers[index].therapist,
            ) as IUser,
          )
        : '';
      output.push({ appointmentWithUser, event: {} as IEvent, therapistName });
    });
  }
  // In the case where there are only events
  if (fp.isEmpty(appointmentsWithUsers) && !fp.isEmpty(events)) {
    events.forEach((event) => {
      output.push({
        appointmentWithUser: {
          ...({} as IAppointmentWithUser),
          user: {} as IUser,
        },
        event,
      });
    });
  }
  return output;
};

/**
 * Function that from appointments and events retrieve the dates and assign
 * a style option index.
 * @param appointmentsWithUser
 * @param events
 * @returns dates to mark
 */
export const createDatesToMark = (
  appointmentsWithUser: IAppointmentWithUser[],
  events: IEvent[],
): IMarkedDate[] => {
  const appointmentsToMark: IMarkedDate[] = appointmentsWithUser.map(
    (appointmentWithUser) => {
      return { date: new Date(appointmentWithUser.date), styleOption: 0 };
    },
  );
  const eventsToMark: IMarkedDate[] = events.map((event) => {
    return { date: event.date, styleOption: 1 };
  });
  return appointmentsToMark.concat(eventsToMark);
};

/**
 * Function that from the list of all patients, retrieves only those entries
 * that correspond to the currently selected therapist.
 * @param therapist
 * @param patients
 * @returns
 */
export const getSelectedTherapistPatients = (
  therapist: IUser,
  patients: IUser[],
): IUser[] => {
  return patients.filter((patient) => {
    const parsedArray = patient.therapists?.join(' ');
    if (!fp.isNil(parsedArray) && parsedArray.includes(therapist.id)) {
      return patient;
    } else {
      return null;
    }
  });
};
