import { usePlatform } from 'hooks/platform-hooks';
import { IModalProps, Modal, Text } from 'native-base';

export const DisclaimerModal: React.FC<IModalProps> = (props): JSX.Element => {
  const { children, ...rest } = props;
  const { web } = usePlatform();
  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        bg="white"
        borderRadius={!web ? 0 : 10}
        py={6}
        w={!web ? '100%' : '30%'}
      >
        <Text textAlign="center" w="60%">
          {children}
        </Text>
        <Text fontWeight="bold" textAlign="center" w="60%">
          Presiona fuera de esta ventana para continuar.
        </Text>
      </Modal.Content>
    </Modal>
  );
};

DisclaimerModal.defaultProps = {
  closeOnOverlayClick: true,
  size: 'full',
  w: '100%',
};
