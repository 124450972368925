import { InputScroller } from 'components/elements';
import { BlogEntryForm } from 'components/forms';
import { usePlatform } from 'hooks/platform-hooks';
import { IModalProps, Modal } from 'native-base';
import { Dimensions, ScrollView } from 'react-native';
import {
  IBlogEntry,
  IBlogEntryFormPayload,
} from 'services/resources/blog/types.d';

interface IEntryModalProps extends IModalProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IBlogEntryFormPayload | IBlogEntry) => void;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Values that are already set, used for editing them.
   */
  previousValues?: IBlogEntry;
  /**
   * Temporary ID created to handle Firestore files upload and deletion.
   */
  tempNewEntryID: string;
}

export const EntryModal: React.FC<IEntryModalProps> = (props): JSX.Element => {
  const {
    onSubmit,
    onCancel,
    previousValues = undefined,
    tempNewEntryID,
    ...rest
  } = props;

  const { web } = usePlatform();

  return (
    <Modal {...rest}>
      {!web ? (
        <Modal.Content
          alignItems="center"
          bg="transparent"
          borderRadius={0}
          h={`${Dimensions.get('screen').height}px`}
          shadow="none"
        >
          <InputScroller
            bottomOffset
            contentContainerStyle={{
              width: '100%',
            }}
            disableScrollToStart
          >
            <BlogEntryForm
              onCancel={onCancel}
              onSubmit={onSubmit}
              previousValues={previousValues}
              tempNewEntryID={tempNewEntryID}
            />
          </InputScroller>
        </Modal.Content>
      ) : (
        <Modal.Content alignItems="center" bg="white" borderRadius={10} w="50%">
          <ScrollView style={{ width: '100%' }}>
            <BlogEntryForm
              onCancel={onCancel}
              onSubmit={onSubmit}
              previousValues={previousValues}
              tempNewEntryID={tempNewEntryID}
            />
          </ScrollView>
        </Modal.Content>
      )}
    </Modal>
  );
};

EntryModal.defaultProps = {
  avoidKeyboard: true,
  size: 'full',
};
