import { instance } from 'services';
import { IGlossaryPayload, IGlossaryResponse } from './types.d';

export const glossaryResources = {
  create: (payload: IGlossaryPayload, token: string): Promise<void> =>
    instance.post(
      '/glossaryAPI',
      { glossaryEntry: payload },
      { headers: { authorization: token } },
    ),

  delete: (term: string, token: string): Promise<void> =>
    instance.delete('/glossaryAPI', {
      headers: { authorization: token },
      params: { id: term },
    }),

  get: (token: string): Promise<IGlossaryResponse> =>
    instance.get('/glossaryAPI', { headers: { authorization: token } }),

  putAll: (
    payload: IGlossaryPayload,
    term: string,
    token: string,
  ): Promise<void> =>
    instance.put(
      '/glossaryAPI',
      { glossaryEntry: payload },
      { headers: { authorization: token }, params: { field: 'all', id: term } },
    ),
};
