import { useFocusEffect } from '@react-navigation/native';
import {
  AcceptDeclineModal,
  LoadingStatus,
  LoadingStatusModal,
  UIWrapper,
} from 'components/elements';
import { handleNotificate } from 'helpers/notifications-helpers/notifications-recipes';
import fp from 'lodash/fp';
import { Text, VStack } from 'native-base';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux-service/slices';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { tasksResources } from 'services/resources/tasks';
import {
  IPredefinedTask,
  IPredefinedTaskPayload,
  ITask,
  ITaskPayload,
  IUpdateTaskPayload,
} from 'services/resources/tasks/types.d';
import { IUserWithTasks } from 'services/resources/types.d';
import { userResources } from 'services/resources/users';
import { ILoadingData } from 'types.d';

import { CustomFlatList } from './components/CustomFlatlist';
import { UserTasksModal } from './components/UserTasksModal';

export const Tasks: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [disclaimerModalOpen, setDislaimerModalOpen] = useState<boolean>(false);
  const [userTasks, setUserTasks] = useState<IUserWithTasks[]>([]);
  const [selectedTask, setSelectedTask] = useState<ITask>({} as ITask);
  const [selectedUserWithTasks, setSelectedUserWithTasks] =
    useState<IUserWithTasks>({} as IUserWithTasks);
  const [selectedPredefinedTask, setSelectedPredefinedTask] =
    useState<IPredefinedTask>({} as IPredefinedTask);
  const [predefinedTasks, setPredefinedTasks] = useState<IPredefinedTask[]>([]);
  const [loadingUserTasks, setLoadingUserTasks] = useState<boolean>(false);
  const [tasksModalVisible, setTasksModalVisible] = useState<boolean>(false);
  const [predefinedName, setPredefinedName] = useState<string>('');
  const [predefinedTasksModalVisible, setPredefinedTasksModalVisible] =
    useState<boolean>(false);

  const togglePredefinedTasksModalVisible = (): void => {
    setPredefinedTasksModalVisible(!predefinedTasksModalVisible);
  };

  const toggleTasksModalVisible = (): void => {
    setTasksModalVisible(!tasksModalVisible);
  };

  /**
   * Function that based on a given id, selects from the corresponsing array
   * and stores into a state the desired user with tasks.
   * @param userId
   */
  const handleSelectUser = (userId: string): void => {
    const selected = userTasks.find(
      (userWithTask) => userWithTask.user.id === userId,
    );
    if (!fp.isNil(selected)) {
      setSelectedUserWithTasks(selected);
    }
  };

  const toggleDisclaimerModalOpen = (): void => {
    setDislaimerModalOpen(!disclaimerModalOpen);
  };

  /**
   * Function for retrieving the predefined tasks that already available in the
   * database.
   */
  const retrievePredefinedTasks = async (
    ommitLoadingAnimation: boolean = false,
  ): Promise<void> => {
    if (!ommitLoadingAnimation) {
      setLoadingData({
        loading: true,
        loadingMessage: 'Cargando Tareas Predefinidas...',
      });
    }
    try {
      const { data } = await tasksResources.getPredefinedTasks(userData.token);
      if (!fp.isNil(data)) {
        setPredefinedTasks(data);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'dashboard-tasks-retrievePredefinedTasks',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function for setting the state with the patient's tasks for a given
   * therapist.
   */
  const retrievePatientsTasks = async (): Promise<void> => {
    setLoadingUserTasks(true);
    try {
      // Retrieve therapist's patients
      const { data: retrievedPatientsTasks } =
        await userResources.getPatientsTasks(userData.id, userData.token);
      if (!fp.isNil(retrievedPatientsTasks)) {
        setUserTasks(retrievedPatientsTasks);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'dashboard-tasks-retrievePatientsTasks',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingUserTasks(false);
    retrievePredefinedTasks(true);
  };

  /**
   * Function for setting the state with the tasks assigned by each therapist
   *  for a given patient.
   */
  const retrieveTasksAssignedByTherapists = async (): Promise<void> => {
    setLoadingData({ loading: true, loadingMessage: 'Cargando Tareas...' });
    try {
      const { data: retrievedTherapistsTasks } =
        await userResources.getTherapistsTasks(userData.id, userData.token);
      if (
        !fp.isNil(retrievedTherapistsTasks) &&
        !fp.isNil(retrievedTherapistsTasks[0].user)
      ) {
        setUserTasks(retrievedTherapistsTasks);
      } else {
        // Otherwise overwrite with empty array
        setUserTasks([]);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'dashboard-tasks-retrieveTasksAssignedByTherapists',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function for deleting a given predefined task.
   */
  const handleOnDeletePredefinedTask = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Eliminando Tarea Predefinida...',
    });
    try {
      await tasksResources.deletePredefined(
        selectedPredefinedTask.id,
        userData.token,
      );
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'dashboard-tasks-handleOnDeletePredefinedTask',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    retrievePredefinedTasks();
  };

  /**
   * Function for deleting a given task.
   */
  const handleOnDeleteTask = async (): Promise<void> => {
    toggleDisclaimerModalOpen();
    setLoadingData({
      loading: true,
      loadingMessage: 'Eliminando Tarea...',
    });
    try {
      await tasksResources.delete(selectedTask.id, userData.token);
      // Send notification
      const notification = {
        body: `Tu terapeuta ${userData.firstName} ha eliminado una tarea que te asignó.`,
        title: 'Tarea eliminada',
      };
      // Get user from the user tasks
      const notificationUser = userTasks.filter((userWithTasks) => {
        return userWithTasks.user.id === selectedTask.patient
          ? userWithTasks.user
          : null;
      });
      handleNotificate(notification, 'tasks-dashboard', {
        ...notificationUser[0].user,
        logged: false,
        token: userData.token,
      });
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'dashboard-tasks-handleOnDeleteTask',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    retrievePredefinedTasks();
    retrievePatientsTasks();
  };

  /**
   * Function for updating an existing predefined task.
   * @param predefinedTask
   */
  const handleOnUpdatePredefinedTask = async (
    predefinedTask: IPredefinedTask,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Actualizando Tarea Predefinida...',
    });
    try {
      await tasksResources.putPredefined(
        predefinedTask,
        predefinedTask.id,
        userData.token,
      );
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'dashboard-tasks-handleOnUpdatePredefinedTask',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setSelectedPredefinedTask({} as IPredefinedTask);
    setLoadingData({ ...loadingData, loading: false });
    retrievePredefinedTasks();
  };

  /**
   * Function for updating an existing task.
   * @param task
   */
  const handleOnUpdateTask = async (task: ITask): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Actualizando Tarea...',
    });
    try {
      // We have not added the account type to the payload yet, we do it here.
      // Behavior that will be deprecated.
      const finalTaskPayload: IUpdateTaskPayload = {
        ...task,
        accountType: userData.accountType,
        patient: selectedUserWithTasks.user.id,
        therapist: userData.id,
      };
      await tasksResources.putAll(finalTaskPayload, userData.token);
      if (userData.accountType === 'therapist') {
        // Send notification
        const notification = {
          body: `Tu terapeuta ${userData.firstName} ha actualizado una tarea que te asignó.`,
          title: 'Tarea actualizada',
        };
        handleNotificate(notification, 'tasks-dashboard', {
          ...selectedUserWithTasks.user,
          logged: false,
          token: userData.token,
        });
      } else {
        // Send notification
        const notification = {
          body: `Tu paciente ${userData.firstName} ha finalizado una tarea que le asignaste.`,
          title: 'Tarea completada',
        };
        handleNotificate(notification, 'tasks-dashboard', {
          ...selectedUserWithTasks.user,
          logged: false,
          token: userData.token,
        });
      }
      // We handle the predefined task case
      if (!fp.isEmpty(predefinedName)) {
        const predefinedPayload: IPredefinedTaskPayload = {
          description: task.description,
          name: predefinedName,
        };
        handleOnCreatePredefinedTask(predefinedPayload);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'dashboard-tasks-handleOnUpdateTask',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    toggleTasksModalVisible();
    setLoadingData({ ...loadingData, loading: false });
    setPredefinedName('');
    setSelectedUserWithTasks({} as IUserWithTasks);
    retrievePredefinedTasks();
    userData.accountType === 'therapist'
      ? retrievePatientsTasks()
      : retrieveTasksAssignedByTherapists();
  };

  /**
   * Function for creating a new predefined task.
   * @param predefinedTask
   */
  const handleOnCreatePredefinedTask = async (
    predefinedTask: IPredefinedTaskPayload,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Creando Tarea Predefinida...',
    });
    try {
      await tasksResources.createPredefined(predefinedTask, userData.token);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'dashboard-tasks-handleOnCreatePredefinedTask',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setSelectedPredefinedTask({} as IPredefinedTask);
    setLoadingData({ ...loadingData, loading: false });
    retrievePredefinedTasks();
  };

  /**
   * Function for creating a new task.
   * @param task
   */
  const handleOnCreateTask = async (task: ITaskPayload): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Creando Tarea...',
    });
    try {
      // We have not added the therapist id to the payload yet, we do it here.
      const updatedPayload: ITaskPayload = {
        ...task,
        finished: false,
        patient: selectedUserWithTasks.user.id,
        therapist: userData.id,
      };
      await tasksResources.create(updatedPayload, userData.token);
      // Send notification
      const notification = {
        body: `Tu terapeuta ${userData.firstName} te ha asignado una nueva tarea.`,
        title: 'Nueva tarea',
      };
      handleNotificate(notification, 'tasks-dashboard', {
        ...selectedUserWithTasks.user,
        logged: false,
        token: userData.token,
      });
      // We handle the predefined task case
      if (!fp.isEmpty(predefinedName)) {
        const predefinedPayload: IPredefinedTaskPayload = {
          description: task.description,
          name: predefinedName,
        };
        handleOnCreatePredefinedTask(predefinedPayload);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'dashboard-tasks-handleOnCreateTask',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    toggleTasksModalVisible();
    setPredefinedName('');
    setSelectedUserWithTasks({} as IUserWithTasks);
    setLoadingData({ ...loadingData, loading: false });
    retrievePredefinedTasks();
    retrievePatientsTasks();
  };

  useFocusEffect(
    useCallback(() => {
      if (userData.accountType === 'therapist') {
        retrievePatientsTasks();
      } else if (userData.accountType === 'patient') {
        retrieveTasksAssignedByTherapists();
      }
    }, []),
  );

  return (
    <UIWrapper title="Tareas">
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      <AcceptDeclineModal
        invertColors
        isOpen={disclaimerModalOpen}
        onAccept={() => handleOnDeleteTask()}
        onDecline={() => {
          setSelectedTask({} as ITask);
          setSelectedPredefinedTask({} as IPredefinedTask);
          toggleDisclaimerModalOpen();
        }}
      >
        <Text fontWeight="bold">
          {`¿Estás seguro de querer eliminar la tarea "${selectedTask.description}"? Esta acción no se puede deshacer.`}
        </Text>
      </AcceptDeclineModal>
      <UserTasksModal
        isOpen={tasksModalVisible}
        onComplete={handleOnUpdateTask}
        onCreate={(p) => {
          toggleTasksModalVisible();
          handleOnCreateTask(p);
        }}
        onDelete={(payload) => {
          setSelectedTask(payload);
          toggleDisclaimerModalOpen();
        }}
        onEdit={(p) => {
          toggleTasksModalVisible();
          handleOnUpdateTask(p);
          setSelectedPredefinedTask({} as IPredefinedTask);
        }}
        onPredefinedCreate={handleOnCreatePredefinedTask}
        onPredefinedDelete={handleOnDeletePredefinedTask}
        onPredefinedEdit={handleOnUpdatePredefinedTask}
        onPredefinedNameChange={setPredefinedName}
        predefinedTasks={predefinedTasks}
        predefinedTasksModalVisible={predefinedTasksModalVisible}
        selectedPredefined={selectedPredefinedTask}
        setSelectedPredefined={setSelectedPredefinedTask}
        tasks={selectedUserWithTasks.tasks}
        togglePredefinedTasksModalVisible={togglePredefinedTasksModalVisible}
        toggleTasksModalVisible={toggleTasksModalVisible}
      />
      <VStack bg="white" flex={17}>
        {loadingUserTasks ? (
          <LoadingStatus alignSelf="center">Cargando tareas...</LoadingStatus>
        ) : (
          <CustomFlatList
            accountType={userData.accountType}
            data={userTasks}
            toggleTasksModalVisible={(id) => {
              toggleTasksModalVisible();
              handleSelectUser(id);
            }}
          />
        )}
      </VStack>
    </UIWrapper>
  );
};
