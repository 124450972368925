/* eslint-disable prettier/prettier */
import * as Notifications from 'expo-notifications';
import { useEffect, useRef, useState } from 'react';

export const useNotifications = (): {
  notification: any;
  response?: any;
} => {
  // eslint-disable-next-line prettier/prettier
  const [notificationState, setNotificationState] = useState<{
    notification: any;
    response?: any;
  }>({ notification: {}, response: {} });

  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldPlaySound: true,
      shouldSetBadge: false,
      shouldShowAlert: true,
    }),
  });

  const notificationListener = useRef<any>();
  const responseListener = useRef<any>();

  useEffect(() => {
    notificationListener.current = Notifications.addNotificationReceivedListener(
      (notification) => {
        setNotificationState({
          ...notificationState,
          notification,
        });
      },
    );

    responseListener.current = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        setNotificationState({
          ...notificationState,
          response,
        });
      },
    );
  });

  return notificationState;
};
