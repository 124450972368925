import { AntDesign } from '@expo/vector-icons';
import { ExtendedFlatlist } from 'components/elements';
import fp from 'lodash/fp';
import { Button, IStackProps, Stack, Text, VStack } from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IEventRegistry } from 'services/resources/events-registries/types.d';
import { IEvent } from 'services/resources/events/types.d';
import { Event } from './Event';
import { EventRegistry } from './EventRegistry';

interface IStaffEventProps extends IStackProps {
  /**
   * Item to be inherited from the cloneElement function.
   */
  item?: any;
  /**
   * Matching registries for selected event.
   */
  eventsRegistries: IEventRegistry[];
  /**
   * Handler function for retrieving events registries for selected event.
   */
  onRetrieveEventsRegistries: (event: string) => Promise<void>;
  /**
   * Handler function for confirming user assitance to an event.
   */
  onAssistanceConfirm: (eventRegistry: string) => Promise<void>;
  /**
   * Handler function for confirming user payment to an event.
   */
  onPaymentConfirm: (eventRegistry: string) => Promise<void>;
}

export const StaffEvent: React.FC<IStaffEventProps> = (props) => {
  const {
    onAssistanceConfirm,
    onPaymentConfirm,
    onRetrieveEventsRegistries,
    eventsRegistries,
    item,
    ...rest
  } = props;
  const themeData = useSelector(getTheme);
  const event = item as IEvent;

  const [registriesVisible, setRegistriesVisible] = useState<boolean>(false);

  const toggleRegistriesVisible = (): void => {
    setRegistriesVisible(!registriesVisible);
  };

  return (
    <VStack bg={event.accentColor} {...rest}>
      <Event pb={10} selectedEvent={event} />
      <Button
        alignItems="center"
        bg={themeData.mainColorDark}
        borderRadius={0}
        onPress={() => {
          if (!registriesVisible) {
            onRetrieveEventsRegistries(event.id);
          }
          toggleRegistriesVisible();
        }}
        rightIcon={
          <AntDesign
            color="white"
            name={!registriesVisible ? 'caretdown' : 'caretup'}
            size={24}
            style={{ marginLeft: 10 }}
          />
        }
        w="100%"
      >
        Consultar registros
      </Button>
      {registriesVisible && !fp.isEmpty(eventsRegistries) ? (
        <Stack bg="white">
          <ExtendedFlatlist
            data={eventsRegistries}
            noDataMessage="No se encontraron registros."
            renderItem={
              <EventRegistry
                onAssistanceConfirm={onAssistanceConfirm}
                onPaymentConfirm={onPaymentConfirm}
                originId={event.id}
              />
            }
            searchBarPlaceholder="registro"
            searchKey="fullName"
            sort
            sortKey="fullName"
            useSearchBar
          />
        </Stack>
      ) : null}
      {fp.isEmpty(eventsRegistries) && registriesVisible ? (
        <Text bg="white" px={1} py={3} textAlign="center">
          No se encontraron registros para el evento seleccionado.
        </Text>
      ) : null}
    </VStack>
  );
};

StaffEvent.defaultProps = {
  flex: 1,
  h: '100%',
  justifyContent: 'center',
  pt: 3,
};
