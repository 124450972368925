/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import { RootState } from 'redux-service/store';
import { IUserState } from 'redux-service/types.d';

const initialState: IUserState = {
  accountType: '',
  administrator: false,
  commissionPercentage: 0,
  deviceId: '',
  email: '',
  fatherName: '',
  financialAdmin: false,
  firstName: '',
  foreignVisit: false,
  id: '',
  logged: false,
  motherName: '',
  patients: [],
  pictures: { profilePicture: '' },
  secretary: false,
  therapists: [],
  token: '',
  verifiedTherapist: false,
};

export const userSlice = createSlice({
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  name: 'user',
  reducers: {
    setLogged: (state, action: PayloadAction<boolean>) => {
      state.logged = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      if (!fp.isEmpty(action.payload)) {
        state.token = action.payload;
      }
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUserData: (state, action: PayloadAction<IUserState>) => {
      if (!fp.isEmpty(action.payload)) {
        state.secretary = action.payload.secretary;
        state.financialAdmin = action.payload.financialAdmin;
        state.administrator = action.payload.administrator;
        state.fatherName = action.payload.fatherName;
        state.logged = action.payload.logged;
        state.foreignVisit = action.payload.foreignVisit;
        state.firstName = action.payload.firstName;
        state.email = action.payload.email;
        state.motherName = action.payload.motherName;
        state.accountType = action.payload.accountType;
        state.pictures = action.payload.pictures;
        state.id = action.payload.id;
        state.patients = action.payload.patients;
        state.therapists = action.payload.therapists;
        state.deviceId = action.payload.deviceId;
        state.verifiedTherapist = action.payload.verifiedTherapist;
        state.commissionPercentage = action.payload.commissionPercentage;
      }
    },
  },
});

export const { setUserData, setLogged, setToken } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getUser = (state: RootState): IUserState => state.user;

export const userReducer = userSlice.reducer;
