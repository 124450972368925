import { useFocusEffect } from '@react-navigation/native';
import {
  CalendarSelector,
  ExtendedFlatlist,
  LoadingStatusModal,
  UIWrapper,
} from 'components/elements';
import { format, formatISO } from 'date-fns';
import fp from 'lodash/fp';
import { Text, VStack } from 'native-base';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import { appointmentsResources } from 'services/resources/appointments';
import { IAppointmentWithFullNames } from 'services/resources/appointments/types.d';
import { appointmentsInfoResources } from 'services/resources/appointments-info';
import {
  IAppointmentInfo,
  IAppointmentWithInfoTaxDataAndNames,
} from 'services/resources/appointments-info/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { ILoadingData } from 'types.d';

import { AppointmentInfoFormModal } from './components/AppointmentInfoFormModal';
import { ManagedAppointmentItem } from './components/ManagedAppointmentItem';
import { getCalendarMarkedDatesStyle } from './helpers/calendar-style-helpers';
import {
  createDatesToMark,
  getManagedAppointmentsToSet,
} from './helpers/data-helpers';
import {
  filterAppointmentsWithFullNamesIncidences,
  filterManagedAppointments,
} from './helpers/filter-helpers';
import { IManagedAppointment } from './types.d';

export const AppointmentManagement: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [appointmentsWithFullNames, setAppointmentsWithFullNames] = useState<
    IAppointmentWithFullNames[]
  >([]);
  const [managedAppointments, setManagedAppointments] = useState<
    IManagedAppointment[]
  >([]);
  const [currentMonthAndYear, setCurrentMonthAndYear] = useState<{
    month: number;
    year: number;
  }>({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [appointmentsInfoData, setAppointmentsInfoData] = useState<
    IAppointmentWithInfoTaxDataAndNames[]
  >([]);
  const [selectedAppointmentInfo, setSelectedAppointmentInfo] =
    useState<IAppointmentInfo>({} as IAppointmentInfo);
  const [appointmentInfoFormVisible, setAppointmentInfoFormVisible] =
    useState<boolean>(false);

  /**
   * Function that retrieves the appointments' info from the selected date
   * and merges the result in the managed appointments' state.
   * @param appointments
   */
  const retrieveAppointmentsInfo = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Información de Citas...',
    });
    try {
      const { data } =
        await appointmentsInfoResources.getCompletedWithNamesByDate(
          formatISO(selectedDate),
          userData.token,
        );
      const d = data as IAppointmentWithInfoTaxDataAndNames[];
      if (!fp.isNil(d)) {
        setAppointmentsInfoData(d);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'AppointmentManagement-retrieveAppointmentsInfo',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that retrieves all the appointments that their date field matches
   * the given month and year, for matters of efficiency.
   */
  const retrieveAppointmentsByMonthAndYear = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Citas...',
    });
    try {
      // Retrieve all the appointments using current year and month from the
      // calendar as query and retrieve users' names as well.
      const { data } =
        await appointmentsResources.getByMonthAndYearWithFullNames(
          formatISO(
            new Date(currentMonthAndYear.year, currentMonthAndYear.month),
          ),
          userData.token,
        );
      const d = data as IAppointmentWithFullNames[];
      if (!fp.isEmpty(d) && !fp.isNil(d)) {
        // Retrieve all those appointments that have either been only confirmed
        // by therapists or by therapists and third parties, for showing in the
        // actual calendar marked dates for both cases.
        const managedAppointments = filterManagedAppointments(d);
        setAppointmentsWithFullNames(managedAppointments);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'AppointmentManagement-retrieveAppointmentsByMonthAndYear',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that updated the confirmedByThird property for the given
   * appointment.
   * @param appointment
   */
  const handleOnConfirmByThird = async (appointment: string): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Confirmando Cita Completada...',
    });
    try {
      await appointmentsResources.putConfirmedByThird(
        appointment,
        userData.id,
        userData.token,
      );
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'AppointmentManagement-handleOnConfirmByThird',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    retrieveAppointmentsByMonthAndYear();
  };

  /**
   * Function to update the additional info of an appointment.
   * @param appointmentInfoPayload
   */
  const putAppointmentInfo = async (
    appointmentInfoPayload: IAppointmentInfo,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Guardando Información...',
    });
    try {
      await appointmentsInfoResources.put(
        appointmentInfoPayload,
        appointmentInfoPayload.id,
        userData.token,
      );
      await appointmentsResources.putCost(
        appointmentInfoPayload.appointment,
        appointmentInfoPayload.cost,
        userData.token,
      );
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'AppointmentManagement-putAppointmentInfo',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ loading: false, loadingMessage: '' });
    setAppointmentInfoFormVisible(false);
    setSelectedAppointmentInfo({} as IAppointmentInfo);
    retrieveAppointmentsByMonthAndYear();
  };

  // Retrieve appointments by month and year when screen focused, re-do it
  // if current month and year changed.
  useFocusEffect(
    useCallback(() => {
      retrieveAppointmentsByMonthAndYear();
    }, [currentMonthAndYear]),
  );

  // Retrieve appointments' info when screen focused, re-do it if selected
  // date or appointments changed.
  useFocusEffect(
    useCallback(() => {
      retrieveAppointmentsInfo();
    }, [selectedDate, appointmentsWithFullNames]),
  );

  // Store managed appointments when screen focused. Re-do it if appointments'
  // info, appointments or selected date changed.
  useFocusEffect(
    useCallback(() => {
      setManagedAppointments(
        // Merge actual appointments and appointments' info entries
        getManagedAppointmentsToSet(
          // Get only those appointments that match the currently selected date
          // for showing them in the flatlist.
          filterAppointmentsWithFullNamesIncidences(
            selectedDate,
            appointmentsWithFullNames,
          ),
          appointmentsInfoData,
        ),
      );
    }, [appointmentsInfoData, appointmentsWithFullNames, selectedDate]),
  );

  return (
    <UIWrapper title="Administrador de Citas">
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      {!fp.isEmpty(selectedAppointmentInfo) ? (
        <AppointmentInfoFormModal
          isFormVisible={appointmentInfoFormVisible}
          onCancel={() => {
            setAppointmentInfoFormVisible(false);
            setSelectedAppointmentInfo({} as IAppointmentInfo);
          }}
          onSubmit={(payload) => putAppointmentInfo(payload)}
          previousValues={
            !fp.isNil(selectedAppointmentInfo)
              ? selectedAppointmentInfo
              : ({} as IAppointmentInfo)
          }
        />
      ) : null}
      <CalendarSelector
        datesToMark={createDatesToMark(appointmentsWithFullNames)}
        flex={1}
        markedDatesStyles={getCalendarMarkedDatesStyle(themeData)}
        onNextMonthSelect={(newMonth, newYear) =>
          setCurrentMonthAndYear({
            month: newMonth,
            year: newYear,
          })
        }
        onPreviousMonthSelect={(newMonth, newYear) =>
          setCurrentMonthAndYear({
            month: newMonth,
            year: newYear,
          })
        }
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <VStack bg="#FFF" flex={1}>
        <Text
          color={themeData.mainColorDark}
          fontWeight="bold"
          py={4}
          textAlign="center"
        >{`Fecha seleccionada: ${format(selectedDate, 'dd-MM-yyyy')}`}</Text>
        <ExtendedFlatlist
          data={managedAppointments}
          noDataMessage={`No se encontraron citas completadas por terapeutas para el día ${format(
            selectedDate,
            'dd-MM-yyyy',
          )}`}
          renderItem={
            <ManagedAppointmentItem
              onConfirmByThird={handleOnConfirmByThird}
              onOpenAppointmentInfoForm={(thisAppointment) => {
                setSelectedAppointmentInfo(thisAppointment);
                setAppointmentInfoFormVisible(true);
              }}
            />
          }
          useSearchBar={false}
        />
      </VStack>
    </UIWrapper>
  );
};
