import fp from 'lodash/fp';
import { HStack, IStackProps, Text, VStack } from 'native-base';
import { IAppointment } from 'services/resources/appointments/types.d';

interface IAppointmentDataItemProps extends IStackProps {
  /**
   * Appointment data to be displayed along with the other component that
   * displays appointment info data.
   */
  appointmentData: IAppointment;
  /**
   * Info data property required to show extra information.
   */
  receivedPayment?: number;
  /**
   * Info data property required to show extra information.
   */
  commission?: number;
}

export const AppointmentDataItem: React.FC<IAppointmentDataItemProps> = (
  props,
) => {
  const {
    appointmentData,
    receivedPayment = 0,
    commission = 0,
    ...rest
  } = props;

  const appliedDiscount = !fp.isNil(appointmentData.discountInPesos)
    ? appointmentData.discountInPesos
    : 0;

  /**
   * Function taht calculates how much would we expect to receive from the
   * appointment, considering the discount.
   */
  const getExpectedPayment = (): number => {
    return appointmentData.cost - appliedDiscount;
  };

  /**
   * Function that indicates us how much would ET actually receive from an
   * appointment. A portion if is a comission-based therapist or full if is a
   * ET partner.
   */
  const getETProfit = (): number => {
    if (commission !== 0) {
      return receivedPayment - (receivedPayment * commission) / 100;
    } else {
      return receivedPayment;
    }
  };

  /**
   * Function that calculates individual therapist profits for an elapsed
   * appointment.
   */
  const getTherapistProfit = (): number => {
    if (receivedPayment !== 0 && commission !== 0) {
      return (receivedPayment * commission) / 100;
    } else {
      return 0;
    }
  };

  return (
    <VStack {...rest}>
      <HStack>
        <Text color="white" fontWeight="bold">
          Costo Base:{' '}
        </Text>
        <Text color="white">{appointmentData.cost}</Text>
      </HStack>
      <HStack>
        <Text color="white" fontWeight="bold">
          Descuento (porcentaje):{' '}
        </Text>
        <Text color="white">{appointmentData.discount}</Text>
      </HStack>
      <HStack>
        <Text color="white" fontWeight="bold">
          Pago Esperado:{' '}
        </Text>
        <Text color="white">{getExpectedPayment()}</Text>
      </HStack>
      <HStack>
        <Text color="white" fontWeight="bold">
          Pago Recibido:{' '}
        </Text>
        <Text color="white">{receivedPayment}</Text>
      </HStack>
      <HStack>
        <Text color="white" fontWeight="bold">
          Ganancia de ET:{' '}
        </Text>
        <Text color="white">{getETProfit()}</Text>
      </HStack>
      {commission !== 0 ? (
        <HStack>
          <Text color="white" fontWeight="bold">
            Ganancia del terapeuta:{' '}
          </Text>
          <Text color="white">{getTherapistProfit()}</Text>
        </HStack>
      ) : null}
    </VStack>
  );
};

AppointmentDataItem.defaultProps = {
  pl: 2,
  py: 2,
  space: 4,
};
