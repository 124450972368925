import { MaterialIcons, SimpleLineIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { IconTextButton, UIWrapper } from 'components/elements';
import { usePlatform } from 'hooks/platform-hooks';
import { HStack, Text, VStack } from 'native-base';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { colors } from 'styles/colors';

import { StatisticsIcon } from './components/StatisticsIcon';

export const StatisticsDashboard: React.FC = (): JSX.Element => {
  const themeData = useSelector(getTheme);
  const navigation = useNavigation();
  const { web } = usePlatform();

  const handleScreenChange = (screen: string): void => {
    navigation.navigate(screen as never);
  };

  return (
    <UIWrapper title="Estadísticas">
      <Text
        color={themeData.mainColorDark}
        flex={1}
        fontSize={22}
        fontWeight="bold"
        pt={3}
        textAlign="center"
      >
        Selecciona un dashboard:
      </Text>
      <VStack alignItems="center" flex={10} mx={4} my={0}>
        <HStack
          alignItems="center"
          flex={1}
          justifyContent="space-around"
          space={2}
          w={!web ? '100%' : '50%'}
        >
          <IconTextButton
            bg={colors.terappiaOrange.light}
            flex={1}
            icon={
              <StatisticsIcon
                icon={
                  <SimpleLineIcons
                    color="white"
                    name="calendar"
                    size={!web ? 36 : 60}
                  />
                }
              />
            }
            label={!web ? 'Estadísticas\nde Citas' : 'Estadísticas de Citas'}
            labelColor="white"
            minH="40%"
            onPress={() => handleScreenChange('appointmentsStatistics')}
            pt={6}
          />
          <IconTextButton
            _disabled={{ opacity: 0.7 }}
            bg={colors.terappiaGreen.light}
            flex={1}
            icon={
              <StatisticsIcon
                icon={
                  <MaterialIcons
                    color="white"
                    name="event-seat"
                    size={!web ? 44 : 70}
                  />
                }
              />
            }
            label={
              !web ? 'Estadísticas\nde Eventos' : 'Estadísticas de Eventos'
            }
            labelColor="white"
            minH="40%"
            onPress={() => handleScreenChange('eventsStatistics')}
            pt={6}
          />
        </HStack>
      </VStack>
    </UIWrapper>
  );
};
