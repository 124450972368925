import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { LoadingStatusModal, UIWrapper } from 'components/elements';
import { EventSignUpForm } from 'components/forms';
import { authErrorHandler } from 'helpers/error-helpers/auth-helpers';
import { checkIfUserAlreadySignedUpToEvent } from 'helpers/event-helpers/registries-helpers';
import {
  readSession,
  removeSession,
  storeSession,
} from 'helpers/storage-helpers/session-helpers';
import fp from 'lodash/fp';
import { HStack, ScrollView } from 'native-base';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUser,
  setLogged,
  setToken,
  setUserData,
} from 'redux-service/slices';
import { IUserState } from 'redux-service/types.d';
import { IRouteProps } from 'screens/types.d';
import { authResources } from 'services/resources/auth';
import { IBaseAuthentication } from 'services/resources/auth/types.d';
import { eventsResources } from 'services/resources/events';
import { IEvent } from 'services/resources/events/types.d';
import { eventsRegistriesResources } from 'services/resources/events-registries';
import {
  IEventRegistry,
  IEventRegistryPayload,
} from 'services/resources/events-registries/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { userResources } from 'services/resources/users';
import { IUser } from 'services/resources/users/types.d';
import { ILoadingData, IOperationError } from 'types.d';

import { Event } from './components/Event';
import { NewSessionModal } from './components/NewSessionModal';

export const EventSignUp: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { routes } = navigation.getState();

  // We ensure that we link the right route with the right screen, firstly by
  // filtering the array
  const filteredRoutes = routes.filter((route) => {
    if (route.name === 'eventSignUp') return route;
    return null;
  });
  // And then, we get the desired object from the array
  const currentRoute = filteredRoutes[0] as IRouteProps;

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [sessionModalOpen, setSessionModalOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<IEvent>({} as IEvent);
  const [error, setError] = useState<IOperationError>({
    code: '',
    detected: false,
    errorMessage: '',
  });
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [anonymous, setAnonymous] = useState<boolean>(false);
  const [anonymousToken, setAnonymousToken] = useState<string>('');
  const [eventRegistries, setEventsRegistries] = useState<IEventRegistry[]>([]);

  const toggleSessionModalOpen = (): void => {
    setSessionModalOpen(!sessionModalOpen);
  };

  /**
   * Function that will retrieve existing event registries for confirming if
   * a user has signed-up before.
   * @param event
   * @param token
   * @returns the event registries or undefined, depending on the flow
   */
  const retrieveRegistriesForSelectedEvent = async (
    event: string,
    token: string = '',
  ): Promise<IEventRegistry[] | undefined> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Consultando registros existentes...',
    });
    try {
      const { data } = await eventsRegistriesResources.getByEventId(
        event,
        userData.logged ? userData.token : token,
      );
      const d = data as IEventRegistry[];
      if (!fp.isEmpty(d)) {
        return d;
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'EventSignUp-retrieveSelectedEvent',
        user: userData.logged ? userData.email : 'anonymous',
      };
      logsResources.create(newLog, userData.logged ? userData.token : token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function for retrieving the selected event data.
   * @param token
   */
  const retrieveSelectedEvent = async (token: string = ''): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Evento Seleccionado...',
    });
    try {
      // We choose if we are going to use logged-in user token or anonymous one
      const { data: d } = await eventsResources.getById(
        currentRoute.params.event,
        userData.logged ? userData.token : token,
      );
      const data = d as IEvent;
      if (!fp.isEmpty(data)) {
        setSelectedEvent(data);
        // Get the event registries linked to the event id
        const registries = await retrieveRegistriesForSelectedEvent(
          data.id,
          token,
        );
        setEventsRegistries(registries as IEventRegistry[]);
        // In this app flow, we can confirm right away if the logged-in user
        // was already on the event registries or not.
        const userSignedUpToEvent = checkIfUserAlreadySignedUpToEvent(
          registries as IEventRegistry[],
          userData.email,
        );
        // If is not anonymous and has previously signed-up for the event raise
        // an error.
        if (!anonymous && userSignedUpToEvent) {
          setError({
            code: 'existingRegistry',
            detected: true,
            errorMessage:
              'No puedes registrarte a un evento al que ya estás registrado.',
          });
        }
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'EventSignUp-retrieveSelectedEvent',
        user: userData.logged ? userData.email : 'anonymous',
      };
      logsResources.create(newLog, !anonymous ? userData.token : token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that will handle the user sign-up to the selected event.
   * @param payload
   */
  const handleEventSignUp = async (
    payload: IEventRegistryPayload,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Guardando Registro...',
    });
    try {
      // Assign the corresponding event id
      const outputPayload: IEventRegistryPayload = {
        ...payload,
        event: selectedEvent.id,
      };
      // If the authenticated user was anonymous, from the payload confirm
      // that it is not already registered in the events registries.
      const anonymousSignedUpToEvent = checkIfUserAlreadySignedUpToEvent(
        eventRegistries,
        outputPayload.email,
      );
      // If the user was anonymous and was not present in previous registries
      // perform normal operation.
      if (!anonymousSignedUpToEvent && anonymous) {
        await eventsRegistriesResources.create(outputPayload, anonymousToken);
        setSuccessMessage('Registro realizado exitosamente.');
      }
      // If the user is anonymous and has previously registrated, raise an
      // error to display in the bottom sheet.
      if (anonymous && anonymousSignedUpToEvent) {
        setError({
          code: 'existingRegistry',
          detected: true,
          errorMessage:
            'No puedes registrarte a un evento al que ya estás registrado.',
        });
      }
      // If the user was not anonymous, continue the operation normally
      // with user data.
      if (!anonymous) {
        await eventsRegistriesResources.create(outputPayload, userData.token);
        setSuccessMessage('Registro realizado exitosamente.');
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'EventSignUp-handleEventSignUp',
        user: userData.logged ? userData.email : 'anonymous',
      };
      logsResources.create(
        newLog,
        !anonymous ? userData.token : anonymousToken,
      );
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that wil anonymously authenticate a user that does not have
   * an account. With this, we get a temporary token for working with the
   * rest APIs.
   */
  const handleAnonymousLogIn = async (): Promise<void> => {
    try {
      const { user } = await authResources.anonymouslyLogIn();
      const token = await user.getIdToken();
      setAnonymous(true);
      setAnonymousToken(token);
      // As the app flow is different in this case, after the authentication
      // we retrieve the event.
      await retrieveSelectedEvent(token);
    } catch (e) {
      setError({
        detected: true,
        errorMessage: 'Error inesperado en la autenticación.',
      });
    }
  };

  /**
   * Function that will handle a manual log-in as done in
   * src\screens\LogIn\LogIn.tsx, except we don't take user to another screen.
   * @param payload
   */
  const handleLogIn = async (payload: IBaseAuthentication): Promise<void> => {
    let data = {} as IUser;
    try {
      const { user } = await authResources.auth(payload);
      if (!user.emailVerified) {
        throw new Error('auth/not-verified');
      }
      await storeSession(user);
      const token = await user.getIdToken();
      dispatch(setToken(token));
      const { data: d } = await userResources.getByMail(payload.email, token);
      data = d as IUser;
    } catch (e: any) {
      if (!fp.isNil(e.code)) {
        setError({
          detected: true,
          errorMessage: authErrorHandler.logInError(e.code),
        });
      } else if (!fp.isNil(e.message)) {
        setError({
          detected: true,
          errorMessage: authErrorHandler.logInError(e.message),
        });
      } else {
        setError({
          detected: true,
          errorMessage: authErrorHandler.logInError(e),
        });
      }
    }
    const loggedUser = {
      ...data,
      foreignVisit: true,
      logged: true,
    };
    if (!fp.isEmpty(data)) {
      dispatch(setUserData(loggedUser as IUserState));
      toggleSessionModalOpen();
    }
  };

  /**
   * Function that will try to restore a previous session.
   */
  const tryAutoLogIn = async (): Promise<void> => {
    let userData = {} as IUser;
    try {
      // Read stored data
      const restoredSession = await readSession();
      if (!fp.isEmpty(restoredSession)) {
        setLoadingData({
          loading: true,
          loadingMessage: 'Sesión anterior encontrada. Restaurando...',
        });
        // Generate custom token if recognized session
        const {
          data: { token },
        } = await authResources.createCustomToken({
          token: restoredSession.stsTokenManager.accessToken,
          uid: restoredSession.uid,
        });
        // Sign-in with custom token
        const { user } = await authResources.autoAuth(token);
        const authToken = await user.getIdToken();
        dispatch(setToken(authToken));
        // Retrieve user data from database
        const { data: d } = await userResources.getByMail(
          restoredSession.email,
          authToken,
        );
        userData = d as IUser;
      }
    } catch (e) {
      setError({
        detected: true,
        errorMessage: 'Error inesperado en la autenticación.',
      });
    }
    setLoadingData({
      loading: false,
      loadingMessage: '',
    });
    const loggedUser = {
      ...userData,
      foreignVisit: true,
      logged: true,
    };
    if (!fp.isEmpty(userData)) {
      dispatch(setUserData(loggedUser as IUserState));
    } else {
      toggleSessionModalOpen();
    }
  };

  /**
   * Function that will handle the StatusSheet discard gesture when the
   * operation had success.
   */
  const handleOnDiscardSuccess = (): void => {
    // If there is an anonymous user, ask ReactRouter to change navigation
    // key and avoid the capability of going back. Also, sign out from the
    // anonymous session.
    if (anonymous) {
      dispatch(setLogged(true));
      dispatch(setLogged(false));
      authResources.signOut();
      removeSession();
      // Take anonymous user to log-in screen
      navigation.navigate('logIn' as never);
    } else {
      // If we are an existing user, change ReactRouter navigation key
      // for avoiding user going back and take user to home screen
      dispatch(setLogged(false));
      dispatch(setLogged(true));
      navigation.navigate('home' as never);
    }
  };

  /**
   * Function that will handle the StatusSheet discard gesture when the
   * operation had an error.
   */
  const handleOnDiscardError = (): void => {
    // Check if the issue was that the user was already registered to the event.
    // If it was, take it to the corresponding screen.
    if (error.code === 'existingRegistry') {
      if (anonymous) {
        dispatch(setLogged(true));
        dispatch(setLogged(false));
        authResources.signOut();
        removeSession();
        navigation.navigate('logIn' as never);
      } else {
        navigation.navigate('home' as never);
      }
    } else {
      // Otherwise, clean the states
      setError({ detected: false, errorMessage: '' });
    }
  };

  useFocusEffect(
    useCallback(() => {
      // Try to auto-log-in when component rendered
      if (!userData.logged) tryAutoLogIn();
      if (userData.logged) {
        retrieveSelectedEvent();
      }
    }, [userData.logged]),
  );

  return (
    <UIWrapper
      error={error.detected}
      hideUIAddOns={anonymous}
      onDiscard={() =>
        error.detected ? handleOnDiscardError() : handleOnDiscardSuccess()
      }
      operationStatus={error.detected ? error.errorMessage : successMessage}
      title="Registro a Evento"
    >
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      <NewSessionModal
        eventRoute={currentRoute.path}
        isOpen={sessionModalOpen}
        onAnonymousLogIn={handleAnonymousLogIn}
        onLogIn={handleLogIn}
        onModalClose={() => {
          toggleSessionModalOpen();
          navigation.navigate('logIn' as never);
        }}
      />
      <HStack alignItems="center" flex={1} w="100%">
        {!fp.isEmpty(selectedEvent) ? (
          <Event alignItems="center" flex={1} selectedEvent={selectedEvent} />
        ) : null}
        <ScrollView
          contentContainerStyle={{
            alignItems: 'center',
          }}
          style={{ backgroundColor: 'white', flex: 1, height: '100%' }}
        >
          {userData.logged || anonymous ? (
            <EventSignUpForm
              anonymous={anonymous}
              customFields={
                !fp.isNil(selectedEvent.customFields)
                  ? selectedEvent.customFields
                  : []
              }
              eventId={currentRoute.params.event}
              mt={6}
              onSubmit={(p) => handleEventSignUp(p)}
              pb={6}
              pt={3}
              w="100%"
            />
          ) : null}
        </ScrollView>
      </HStack>
    </UIWrapper>
  );
};
