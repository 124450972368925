import { instance } from 'services/instance';

import { ITherapyProgressPayload, ITherapyProgressResponse } from './types.d';

export const therapyProgressResources = {
  create: (payload: ITherapyProgressPayload, token: string): Promise<void> =>
    instance.post(
      '/therapyProgressAPI',
      { therapyProgress: payload },
      { headers: { authorization: token } },
    ),

  get: (
    appointment: string,
    token: string,
  ): Promise<ITherapyProgressResponse> =>
    instance.get('/therapyProgressAPI', {
      headers: { authorization: token },
      params: { appointmentId: appointment },
    }),

  put: (payload: ITherapyProgressPayload, token: string): Promise<void> =>
    instance.put(
      '/therapyProgressAPI',
      { therapyProgress: payload },
      { headers: { authorization: token } },
    ),
};
