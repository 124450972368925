import fp from 'lodash/fp';
import { IReceivedExpoNotification } from 'services/resources/notifications/types.d';

/**
 * Function that verifies if the so-nested section property exists and is not
 * an empty strings and returns it if does exist, return an empty string if not.
 * @param notification
 * @returns notification data section
 */
export const extractNotificationSection = (
  notification: IReceivedExpoNotification,
): string => {
  if (!fp.isEmpty(notification?.request?.content?.data?.section)) {
    return notification.request.content.data.section;
  }
  return '';
};
