import { SearchBarField } from 'components/inputs';
import fp from 'lodash/fp';
import { Divider, IStackProps, Text, VStack } from 'native-base';
import React, { useState } from 'react';
import { ListRenderItemInfo, ViewStyle, VirtualizedList } from 'react-native';

import { includesSearchText, sortListData } from './helpers/data-helpers';

interface IExtendedVirtualizedList extends IStackProps {
  /**
   * Array containing the information to be used in the virtualized list.
   */
  data: any[];
  /**
   * Custom style to be applied to the virtualized list.
   */
  virtualizedListStyle?: ViewStyle;
  /**
   * Custom message to be shown when data array is an empty array.
   */
  noDataMessage?: string;
  /**
   * Item to be rendered as list element.
   */
  renderItem: JSX.Element;
  /**
   * Placeholder to be used in the search bar.
   */
  searchBarPlaceholder?: string;
  /**
   * Key to be used for finding in the search function.
   */
  searchKey?: string | string[];
  /**
   * Custom key for being used as a custom search criteria for a nested
   * property in an object. Can only be used if the search key is not an array.
   */
  searchSubKey?: string | string[];
  /**
   * Flag for indicating either to sort the VirtualizedList data or not.
   */
  sort?: boolean;
  /**
   * Custom key to be used for sorting the array data.
   */
  sortKey?: string;
  /**
   * Custom key for being used as a custom sorting criteria for a nested
   * property in an object.
   */
  sortSubKey?: string;
  /**
   * Flag for indicating if want to display the searchbar in the virtualized list.
   */
  useSearchBar?: boolean;
  initialNumberToRender?: number;
}

export const ExtendedVirtualizedList: React.FC<IExtendedVirtualizedList> = (
  props,
) => {
  const {
    data,
    renderItem,
    virtualizedListStyle = {},
    initialNumberToRender = 3,
    noDataMessage = 'No se encontró información',
    sort = false,
    searchBarPlaceholder = '',
    searchKey = '',
    sortKey = '',
    sortSubKey = '',
    useSearchBar = true,
    searchSubKey = '',
    ...rest
  } = props;

  const [search, setSearch] = useState<string>('');

  /**
   * Function for rendering list elements based on a search criteria if defined.
   * @param item
   * @returns VirtualizedList item.
   */
  const renderChildren = (
    item: ListRenderItemInfo<unknown>,
  ): React.FunctionComponentElement<any> => {
    if (!useSearchBar) {
      return React.cloneElement(renderItem, { item });
    } else if (
      includesSearchText(JSON.stringify(item), searchKey, searchSubKey, search)
    ) {
      return React.cloneElement(renderItem, { item });
    }
    return <></>;
  };

  return (
    <VStack {...rest}>
      {data.length > 0 ? (
        <VStack h="100%">
          {useSearchBar ? (
            <SearchBarField
              alignSelf="center"
              my={2}
              onChangeText={(text) => setSearch(text)}
              placeholder={searchBarPlaceholder}
              w="95%"
            />
          ) : null}
          <Divider />
          <VirtualizedList
            data={!sort ? data : sortListData(data, sortKey, sortSubKey)}
            getItem={(d, i) => data[i]}
            getItemCount={(d) => data.length}
            initialNumToRender={initialNumberToRender}
            keyExtractor={(item) => JSON.stringify(item)}
            renderItem={({ item }) => renderChildren(item as any)}
            style={
              !fp.isEmpty(virtualizedListStyle)
                ? virtualizedListStyle
                : undefined
            }
          />
        </VStack>
      ) : (
        <Text fontWeight="bold" py={6} textAlign="center">
          {noDataMessage}
        </Text>
      )}
    </VStack>
  );
};

ExtendedVirtualizedList.defaultProps = {
  flex: 1,
};
