import { useFocusEffect } from '@react-navigation/native';
import {
  ExtendedFlatlist,
  LoadingStatusModal,
  UIWrapper,
} from 'components/elements';
import {
  differenceInHours,
  differenceInMinutes,
  isSameHour,
  parseISO,
} from 'date-fns';
import fp from 'lodash/fp';
import { Stack } from 'native-base';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux-service/slices';
import { IScreenProps } from 'screens/types.d';
import { eventsResources } from 'services/resources/events';
import { IEvent } from 'services/resources/events/types.d';
import { eventsRegistriesResources } from 'services/resources/events-registries';
import { IEventRegistry } from 'services/resources/events-registries/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { ILoadingData, IOperationError } from 'types.d';

import { Event } from './components/Event';
import { StaffEvent } from './components/StaffEvent';

export const EventsRegistries: React.FC<IScreenProps> = ({
  route,
}): JSX.Element => {
  const userData = useSelector(getUser);

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [selectedEvent, setSelectedEvent] = useState<IEvent>({} as IEvent);
  const [events, setEvents] = useState<IEvent[]>([]);
  const [eventsRegistries, setEventsRegistries] = useState<IEventRegistry[]>(
    [],
  );
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [error, setError] = useState<IOperationError>({
    code: '',
    detected: false,
    errorMessage: '',
  });

  /**
   * Helper function that will help us determining if the current user is
   * from ET staff.
   * @returns
   */
  const checkIfETStaff = (): boolean => {
    let isStaff = false;
    if (
      userData.accountType === 'therapist' ||
      (!fp.isNil(userData.secretary) && userData.secretary) ||
      (!fp.isNil(userData.financialAdmin) && userData.financialAdmin)
    ) {
      isStaff = true;
    }
    return isStaff;
  };

  /**
   * Function that compares if the current hour is the same registered in the
   * selected event or if have only elapsed three hours since the event began.
   * It also checks if are minutes pending for the event to start.
   * @param selectedEventDate
   * @returns dateTestPass
   */
  const compareDates = (selectedEventDate: Date): boolean => {
    let dateTestPass = true;
    const parsedDate = parseISO(`${selectedEventDate}`);
    const currentDate = new Date();
    if (
      (!isSameHour(parsedDate, currentDate) &&
        differenceInHours(currentDate, parsedDate) > 3) ||
      differenceInMinutes(currentDate, parsedDate) < 0
    ) {
      dateTestPass = false;
      setError({
        detected: true,
        errorMessage:
          'No puedes confirmar tu asistencia a un evento que no se está llevando a cabo.',
      });
    }
    return dateTestPass;
  };

  /**
   * Function that checks if a user has signed-up and therefore, determining if
   * it can confirmate its assistance or not. Also stores the event registry
   * that matches the user in the database.
   * @param registries
   * @returns event registry
   */
  const checkIfUserAlreadySignedUpToEvent = (
    registries: IEventRegistry[],
  ): IEventRegistry => {
    let alreadySignedUp: boolean = false;
    let matchingRegistry = {} as IEventRegistry;
    if (!fp.isEmpty(registries)) {
      registries.forEach((registry) => {
        if (registry.email === userData.email) {
          alreadySignedUp = true;
          matchingRegistry = registry;
        }
      });
    }
    if (!alreadySignedUp) {
      setError({
        detected: true,
        errorMessage:
          'No puedes confirmar tu asistencia a un evento al que no te suscribiste.',
      });
    }
    if (matchingRegistry.assistedConfirmation) {
      setError({
        detected: true,
        errorMessage: 'No puedes confirmar tu asistencia múltiples ocasiones.',
      });
    }
    return matchingRegistry;
  };

  /**
   * Function that will handle event assistance confirmation on the database.
   * @param registry
   */
  const confirmEventAssitance = async (registry: string): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Guardando confirmación...',
    });
    try {
      if (!fp.isNil(registry) && !fp.isEmpty(registry)) {
        await eventsRegistriesResources.putConfirmation(
          registry,
          userData.token,
        );
        setSuccessMessage('Confirmación exitosa.');
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'EventsRegistries-confirmEventAssitance',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    if (checkIfETStaff()) retrieveEvents();
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that will handle event payment confirmation on the database.
   * @param registry
   */
  const confirmEventPayment = async (registry: string): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Guardando Confirmación de Pago...',
    });
    try {
      if (!fp.isNil(registry) && !fp.isEmpty(registry)) {
        await eventsRegistriesResources.putPayment(registry, userData.token);
        setSuccessMessage('Registro de pago a evento exitoso.');
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'EventsRegistries-confirmEventPayment',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    if (checkIfETStaff()) retrieveEvents();
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that will retrieve existing events registries for a given event
   * when the user is ET staff.
   */
  const retrieveRegistries = async (event: string): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Consultando registros existentes...',
    });
    try {
      const { data } = await eventsRegistriesResources.getByEventId(
        event,
        userData.token,
      );
      const d = data as IEventRegistry[];
      if (!fp.isEmpty(d)) {
        setEventsRegistries(d);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'EventsRegistries-retrieveRegistries',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that will retrieve existing event registries for confirming if
   * the user has signed-up before.
   */
  const retrieveRegistriesForSelectedEvent = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Consultando registros existentes...',
    });
    try {
      const { data } = await eventsRegistriesResources.getByEventId(
        route.params.eventInfo.id,
        userData.token,
      );
      const d = data as IEventRegistry[];
      if (!fp.isEmpty(d)) {
        const matchingRegistry = checkIfUserAlreadySignedUpToEvent(d);
        await confirmEventAssitance(matchingRegistry.id);
      } else {
        setError({
          detected: true,
          errorMessage:
            'No puedes confirmar tu asistencia a un evento al que no te suscribiste.',
        });
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'EventsRegistries-retrieveRegistriesForSelectedEvent',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function for retrieving all the events that are currently stored in the
   * database.
   */
  const retrieveEvents = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Eventos...',
    });
    try {
      const { data: d } = await eventsResources.get(userData.token);
      const data = d as IEvent[];
      if (!fp.isEmpty(data)) {
        setEvents(data);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'EventsRegistries-retrieveEvents',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function for retrieving the selected event data.
   */
  const retrieveSelectedEvent = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Evento Seleccionado...',
    });
    try {
      const { data: d } = await eventsResources.getById(
        route.params.eventInfo.id,
        userData.token,
      );
      const data = d as IEvent;
      if (!fp.isEmpty(data)) {
        setSelectedEvent(data);
        const dateTestPass = compareDates(data.date);
        if (dateTestPass) await retrieveRegistriesForSelectedEvent();
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'EventsRegistries-retrieveSelectedEvent',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  useFocusEffect(
    useCallback(() => {
      if (userData.accountType === 'patient') retrieveSelectedEvent();
      if (checkIfETStaff()) retrieveEvents();
    }, []),
  );

  return (
    <UIWrapper
      error={error.detected}
      operationStatus={error.detected ? error.errorMessage : successMessage}
      title="Asistencia"
    >
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      {!fp.isEmpty(selectedEvent) && userData.accountType === 'patient' ? (
        <Event selectedEvent={selectedEvent} />
      ) : null}
      {checkIfETStaff() ? (
        <Stack bg="white" flex={1}>
          <ExtendedFlatlist
            data={events}
            noDataMessage="No se encontraron eventos."
            renderItem={
              <StaffEvent
                eventsRegistries={eventsRegistries}
                onAssistanceConfirm={confirmEventAssitance}
                onPaymentConfirm={confirmEventPayment}
                onRetrieveEventsRegistries={retrieveRegistries}
              />
            }
            searchBarPlaceholder="evento"
            searchKey="title"
            sort
            sortDatesInverted
            sortKey="date"
            useSearchBar
          />
        </Stack>
      ) : null}
    </UIWrapper>
  );
};
