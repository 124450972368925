import { Entypo, MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { removeSession } from 'helpers/storage-helpers/session-helpers';
import fp from 'lodash/fp';
import {
  Divider,
  HStack,
  IconButton,
  IStackProps,
  Text,
  View,
  VStack,
} from 'native-base';
import { useDispatch } from 'react-redux';
import { setLogged } from 'redux-service/slices';
import { authResources } from 'services/resources/auth';
import { themeColors } from 'styles/theme';

interface IScreenContainerProps extends IStackProps {
  /**
   * Title to be render at the top of the screen.
   */
  title?: string;
  /**
   * Flag to indicate weather to show top container or not.
   */
  topContainer?: boolean;
}

export const ScreenContainer: React.FC<IScreenContainerProps> = (
  props,
): JSX.Element => {
  const { children, title = '', topContainer = true, ...rest } = props;
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const handleScreenChange = (screen: string): void => {
    if (screen === 'logIn') {
      authResources.signOut();
      removeSession();
      dispatch(setLogged(false));
    }
    navigation.navigate(screen as never);
  };
  const untitled = fp.isEmpty(title);
  return (
    <VStack bg="black" flex={1} {...rest}>
      {topContainer ? (
        <View flex={1} mt={12}>
          <HStack justifyContent="space-around">
            <IconButton
              icon={
                <Entypo
                  color={themeColors.programmingGreen}
                  name="home"
                  size={28}
                />
              }
              onPress={() => handleScreenChange('home')}
            />
            {untitled ? null : (
              <Text
                color={themeColors.programmingGreen}
                fontSize={22}
                textAlign="center"
              >
                {title}
              </Text>
            )}
            <IconButton
              icon={
                <MaterialIcons
                  color={themeColors.programmingGreen}
                  name="logout"
                  size={28}
                />
              }
              onPress={() => handleScreenChange('logIn')}
            />
          </HStack>
          <Divider
            bg={themeColors.programmingGreen}
            height={1}
            mt={6}
            w="100%"
          />
        </View>
      ) : null}
      <View
        alignItems="center"
        flex={!topContainer ? 1 : 7}
        justifyContent="center"
      >
        {children}
      </View>
    </VStack>
  );
};
