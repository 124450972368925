/**
 * Function that creates a blob from an image uri.
 * @param imageUri
 * @returns image blob
 */
export const createBlob = async (imageUri: string): Promise<any> => {
  // Create a blob with the uri
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      reject(
        new TypeError(JSON.stringify({ e, message: 'Network request failed' })),
      );
    };
    xhr.responseType = 'blob';
    xhr.open('GET', imageUri, true);
    xhr.send(null);
  });
  return blob;
};
