import { useNavigation } from '@react-navigation/native';
import {
  ExternalUIWrapper,
  InputScroller,
  LoadingStatusModal,
} from 'components/elements';
import { PasswordForm, SignUpForm, TaxInfoForm } from 'components/forms';
import { IPasswordPayload } from 'components/forms/PasswordForm/types.d';
import { lowerCasePayload } from 'helpers/data-helpers/string-helpers';
import { PRIVACY, TERMS } from 'helpers/data-helpers/url-helpers';
import { authErrorHandler } from 'helpers/error-helpers/auth-helpers';
import { Logo } from 'helpers/source-helpers/img-src-helpers';
import { useSmallScreen } from 'hooks/dimensions-hooks';
import { usePlatform } from 'hooks/platform-hooks';
import { omit } from 'lodash';
import fp from 'lodash/fp';
import { Center, Checkbox, Image, Link, Text } from 'native-base';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTheme, setToken } from 'redux-service/slices';
import { IScreenProps } from 'screens/types.d';
import { authResources } from 'services/resources/auth';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { notificationsResources } from 'services/resources/notifications';
import { IExpoNotification } from 'services/resources/notifications/types.d';
import { userResources } from 'services/resources/users';
import {
  IPasswordLessSignUpPayload,
  ISignUpPayload,
  ITaxInfo,
} from 'services/resources/users/types.d';
import { getAdministratorsDevices } from 'services/resources-recipes/user-recipes';
import { fontSizes } from 'styles/theme';
import { ILoadingData, IOperationError } from 'types.d';

import { BackButton } from './components/BackButton';
import { styles } from './styles';

export const SignUp: React.FC<IScreenProps> = ({ route }): JSX.Element => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const themeData = useSelector(getTheme);
  const small = useSmallScreen();
  const { ios, web } = usePlatform();

  const [userPayload, setUserPayload] = useState<IPasswordLessSignUpPayload>(
    {} as IPasswordLessSignUpPayload,
  );
  const [error, setError] = useState<IOperationError>({
    detected: false,
    errorMessage: '',
  });
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [passwordFormVisible, setPasswordFormVisible] =
    useState<boolean>(false);
  const [taxInfoFormVisible, setTaxInfoFormVisible] = useState<boolean>(false);
  const [taxInfo, setTaxInfo] = useState<ITaxInfo>();
  const [taxInfoCheckbox, setTaxInfoCheckbox] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });

  /**
   * Function for notifying administrators from a new therapist account
   * creation request.
   * @param therapistPayload
   * @param token
   */
  const notifyAdministrators = async (
    therapistPayload: IPasswordLessSignUpPayload,
    token: string,
  ): Promise<void> => {
    try {
      const { administrators } = await getAdministratorsDevices(
        therapistPayload.email,
        token,
      );
      administrators.map(async (administrator) => {
        const newNotification: IExpoNotification = {
          body: `El usuario nuevo ${therapistPayload.firstName} ${
            therapistPayload.fatherName
          } ${
            !fp.isEmpty(therapistPayload.motherName)
              ? therapistPayload.motherName
              : ''
          }ha solicitado darse de alta como terapeuta`,
          data: {
            section: 'administrator',
          },
          sound: 'default',
          title: 'Nueva solicitud de terapeuta',
          to: administrator.deviceId,
        };
        notificationsResources.create(newNotification, administrator.id, token);
      });
    } catch (e: any) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'notifications',
        user: therapistPayload.email,
      };
      logsResources.create(newLog, token);
    }
  };

  /**
   * Function for handling the submit operation of the form. It creates the
   * user on Firebase auth, sends a confirmation email, writes the user
   * on the database and notifies the admins of a therapist request in case
   * it applies.
   * @param passPayload
   */
  const handleOnSubmit = async (
    passPayload: IPasswordPayload,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage:
        'Estamos preparando todo para ti... Esta operación podría tardar un rato.',
    });
    try {
      const payload: ISignUpPayload = {
        ...userPayload,
        password: passPayload.password,
        // eslint-disable-next-line object-shorthand
        taxInfo: taxInfo,
      };
      // Create the user in Firebase auth
      const { user } = await authResources.signUp(payload);
      // Send confirmation email
      await authResources.verifyEmail(user);
      // Retrieve and save globally auth token
      const token = await user.getIdToken();
      dispatch(setToken(token));
      // Create payload copy without password
      const partialPayload = omit(payload, ['password']);
      const loweredCasePayload = lowerCasePayload(partialPayload);
      // Create the user in database without password and with lower case
      await userResources.create(loweredCasePayload, token);
      // In case a therapist account was requested, notify administrators
      if (loweredCasePayload.accountType === 'therapist') {
        notifyAdministrators(loweredCasePayload, token);
      }
      setPasswordFormVisible(false);
      setSuccessMessage('Usuario creado con éxito');
    } catch (e: any) {
      if (!fp.isNil(e.code)) {
        setError({
          detected: true,
          errorMessage: authErrorHandler.signUpError(e.code),
        });
      } else {
        setError({
          detected: true,
          errorMessage: authErrorHandler.signUpError(e.message),
        });
      }
    }
    setLoadingData({ loading: false, loadingMessage: '' });
  };

  /**
   * Handler function for navigating to log-in screen when sign-up process
   * finished.
   */
  const handleOnDiscardSuccess = (): void => {
    if (!fp.isEmpty(successMessage)) {
      // We take the user to a different screen based on the screen that
      // navigated to this screen. Currently used by eventSignUp features
      if (
        !fp.isNil(route.params.origin) &&
        !route.params.origin.includes('eventSignUp')
      ) {
        setSuccessMessage('');
        navigation.navigate('logIn' as never);
      } else {
        // We send the origin to logIn screen, so when the user logs-in, will be
        // taken to origin screen.
        navigation.navigate(
          'logIn' as never,
          { origin: route.params.origin } as never,
        );
        setSuccessMessage('');
      }
    }
  };

  /**
   * Function to Close the tax info form modal and uncheck the 'Añadir mis
   * datos fiscales' checkbox.
   */
  const handleOnCancelTaxInfoForm = (): void => {
    setTaxInfoFormVisible(false);
    setTaxInfoCheckbox(false);
  };

  /**
   * Function to send the tax info entered in the form to a state later used
   * at the handleOnSubmit() function.
   * @param taxInfoPayload
   */
  const handleOnAddTaxInfo = (taxInfoPayload: ITaxInfo): void => {
    setTaxInfo(taxInfoPayload);
    setTaxInfoFormVisible(false);
  };

  return (
    <ExternalUIWrapper
      error={error.detected}
      onDiscard={() =>
        error.detected
          ? setError({ detected: false, errorMessage: '' })
          : handleOnDiscardSuccess()
      }
      operationStatus={error.detected ? error.errorMessage : successMessage}
    >
      {!web ? (
        <InputScroller bottomOffset disableScrollToStart>
          <BackButton />
          <Image
            alt="logo"
            h={!small ? styles.logo.height : '45px'}
            mb={!small ? 18 : 2}
            mt={!small ? 3 : 0}
            resizeMode="contain"
            source={Logo}
          />
          <SignUpForm
            onSubmit={(p) => {
              setUserPayload(p);
              setPasswordFormVisible(true);
            }}
          />
          <PasswordForm
            closeOnOverlayClick
            isFormVisible={passwordFormVisible}
            onClose={() => setPasswordFormVisible(false)}
            onSubmit={handleOnSubmit}
          />
          <TaxInfoForm
            isFormVisible={taxInfoFormVisible}
            onCancel={handleOnCancelTaxInfoForm}
            onSubmit={(p) => handleOnAddTaxInfo(p)}
            previousValues={{} as ITaxInfo}
          />
          <Checkbox
            isChecked={taxInfoCheckbox}
            onChange={() => {
              setTaxInfoFormVisible(true);
              setTaxInfoCheckbox(true);
            }}
            value="TaxInfoCheckbox"
          >
            <Text
              color={themeData.mainColorDark}
              fontSize={!small ? fontSizes.small : fontSizes.smaller}
            >
              Añadir mi información fiscal
            </Text>
          </Checkbox>
          <Text
            color={themeData.mainColorDark}
            fontSize={!small ? fontSizes.small : fontSizes.smaller}
            mt={!small ? 1 : 3}
            mx={!small ? 0 : 4}
            textAlign="center"
          >
            *Recuerda que, al crear una cuenta aceptas nuestro{' '}
            <Link
              _text={{
                color: themeData.mainColorDark,
                fontSize: !small ? fontSizes.small : fontSizes.smaller,
                fontWeight: 'bold',
                mb: !ios ? (!small ? -1 : '-3px') : 0,
              }}
              href={PRIVACY}
              isExternal
            >
              aviso de privacidad
            </Link>
            , así como nuestros{' '}
            <Link
              _text={{
                color: themeData.mainColorDark,
                fontSize: !small ? fontSizes.small : fontSizes.smaller,
                fontWeight: 'bold',
                mb: !ios ? (!small ? -1 : '-3px') : 0,
              }}
              href={TERMS}
              isExternal
            >
              términos y condiciones.
            </Link>
          </Text>
          <LoadingStatusModal loading={loadingData.loading}>
            {loadingData.loadingMessage}
          </LoadingStatusModal>
        </InputScroller>
      ) : (
        <Center flex={1} h="100%" w="100%">
          <BackButton />
          <img
            alt="logo"
            height="16%"
            src={Logo}
            style={{ marginBottom: 30, marginTop: 0 }}
          />
          <SignUpForm
            onSubmit={(p) => {
              setUserPayload(p);
              setPasswordFormVisible(true);
            }}
          />
          <PasswordForm
            closeOnOverlayClick
            isFormVisible={passwordFormVisible}
            onClose={() => setPasswordFormVisible(false)}
            onSubmit={handleOnSubmit}
          />
          <TaxInfoForm
            isFormVisible={taxInfoFormVisible}
            onCancel={handleOnCancelTaxInfoForm}
            onSubmit={(p) => handleOnAddTaxInfo(p)}
            previousValues={{} as ITaxInfo}
          />
          <Checkbox
            isChecked={taxInfoCheckbox}
            mb={3}
            onChange={() => {
              setTaxInfoFormVisible(true);
              setTaxInfoCheckbox(true);
            }}
            value="TaxInfoCheckbox"
          >
            <Text
              color={themeData.mainColorDark}
              fontSize={!small ? fontSizes.small : fontSizes.smaller}
            >
              Añadir mi información fiscal
            </Text>
          </Checkbox>
          <Text
            color={themeData.mainColorDark}
            fontSize={!small ? fontSizes.small : fontSizes.smaller}
            textAlign="center"
          >
            *Recuerda que, al crear una cuenta aceptas{' '}
            <Link
              _text={{
                color: themeData.mainColorDark,
                fontSize: !small ? fontSizes.small : fontSizes.smaller,
                fontWeight: 'bold',
                mb: -1,
              }}
              href={PRIVACY}
              isExternal
            >
              nuestro aviso de privacidad
            </Link>
            , así como nuestros{' '}
            <Link
              _text={{
                color: themeData.mainColorDark,
                fontSize: !small ? fontSizes.small : fontSizes.smaller,
                fontWeight: 'bold',
                mb: -1,
              }}
              href={TERMS}
              isExternal
            >
              términos y condiciones.
            </Link>
          </Text>
          <LoadingStatusModal loading={loadingData.loading}>
            {loadingData.loadingMessage}
          </LoadingStatusModal>
        </Center>
      )}
    </ExternalUIWrapper>
  );
};
