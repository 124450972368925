import { FormattedEmailField } from 'components/inputs';
import { Formik } from 'formik';
import { useSmallScreen } from 'hooks/dimensions-hooks';
import { usePlatform } from 'hooks/platform-hooks';
import { Button, Text, VStack } from 'native-base';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { fontSizes } from 'styles/theme';

import {
  INITIAL_VALUES,
  LoginFormValidationSchema,
} from './helpers/form-helpers';

interface ILoginFormProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: { email: string }) => Promise<void>;
}

export const PasswordResetForm: React.FC<ILoginFormProps> = (props) => {
  const { onSubmit } = props;
  const themeData = useSelector(getTheme);
  const small = useSmallScreen();
  const { web } = usePlatform();

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={LoginFormValidationSchema}
    >
      {({ errors, handleChange, isValid, handleSubmit, values }) => (
        <VStack justifyContent="center" w={!web ? '80%' : '20%'}>
          <Text
            color={themeData.mainColorDark}
            fontSize={!small ? fontSizes.regular : fontSizes.small}
            fontWeight="bold"
            mb={12}
            textAlign="center"
          >
            ¿Olvidaste tu contraseña?
          </Text>
          <Text
            color={themeData.mainColorDark}
            fontSize={!small ? fontSizes.regular : fontSizes.small}
            mb={22}
            px={2}
            textAlign="center"
          >
            Por favor, para reiniciar tu contraseña, ingresa el correo asociado
            a tu cuenta y revisa tu bandeja de entrada para continuar.
          </Text>
          <FormattedEmailField
            error={errors.email}
            onChangeText={handleChange('email')}
          />
          <Button
            _text={{
              color: 'white',
              fontSize: !small ? fontSizes.regular : fontSizes.small,
              fontWeight: 'bold',
            }}
            bg={themeData.mainColorDark}
            borderRadius={15}
            isDisabled={!isValid || values.email === ''}
            mb={20}
            mt={8}
            onPress={() => handleSubmit()}
          >
            Restablecer Contraseña
          </Button>
        </VStack>
      )}
    </Formik>
  );
};
