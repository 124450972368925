import {
  AcceptDeclineModal,
  IAcceptDeclineModalProps,
} from 'components/elements';
import { format } from 'date-fns';
import { Text } from 'native-base';
import { IAppointment } from 'services/resources/appointments/types.d';

interface ICancelDisclaimerProps extends IAcceptDeclineModalProps {
  /**
   * Appointment from we are retriving data from (state).
   */
  selectedAppointment: IAppointment;
}

export const CancelDisclaimer: React.FC<ICancelDisclaimerProps> = (
  props,
): JSX.Element => {
  const { selectedAppointment, ...rest } = props;
  return (
    <AcceptDeclineModal {...rest}>
      <Text fontWeight="bold">
        {`¿Estás seguro de querer cancelar la cita del día ${format(
          new Date(selectedAppointment.date),
          'dd-MM-yyyy',
        )} a las ${format(
          new Date(selectedAppointment.date),
          'HH:mm',
        )} hrs? Esta acción no se puede deshacer.`}
      </Text>
    </AcceptDeclineModal>
  );
};

CancelDisclaimer.defaultProps = {
  invertColors: true,
};
