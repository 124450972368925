import { AntDesign } from '@expo/vector-icons';
import { addMonths, formatISO, parseISO, subMonths } from 'date-fns';
import { esLocales } from 'helpers/data-helpers/locale-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { Flex, IFlexProps } from 'native-base';
import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import { Calendar, DateData, LocaleConfig } from 'react-native-calendars';
import { MarkingProps } from 'react-native-calendars/src/calendar/day/marking';
import { MarkedDates } from 'react-native-calendars/src/types.d';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

import {
  getMediumTheme,
  getRegularTheme,
  getSmallTheme,
  getTodayStyle,
  selectedDayStyle,
} from './helpers/theme-helpers';
import { IMarkedDate } from './types';

LocaleConfig.locales.es = esLocales;
LocaleConfig.defaultLocale = 'es';

interface ICalendarSelectorProps extends IFlexProps {
  /**
   * If a custom style is passed to this property, it will override the
   * existing theme style.
   */
  customTodayStyle?: MarkingProps;
  /**
   * Handler for setting the date state.
   */
  setSelectedDate: (date: Date) => void;
  /**
   * Dates to be marked in the calendar.
   */
  datesToMark: IMarkedDate[];
  /**
   * Styles' array to be applied to the passed dates to mark.
   */
  markedDatesStyles: MarkingProps[];
  /**
   * Currently selected date available in the state.
   */
  selectedDate: Date;
  /**
   * Handler function that indicates that the next month from the current one
   * has been selected.
   */
  onNextMonthSelect?: (newMonth: number, newYear: number) => void;
  /**
   * Handler function that indicates that the previoys month from the current
   * one has been selected.
   */
  onPreviousMonthSelect?: (newMonth: number, newYear: number) => void;
}

export const CalendarSelector: React.FC<ICalendarSelectorProps> = (
  props,
): JSX.Element => {
  const {
    customTodayStyle,
    setSelectedDate,
    selectedDate,
    datesToMark,
    markedDatesStyles,
    onNextMonthSelect = () => {},
    onPreviousMonthSelect = () => {},
    ...rest
  } = props;

  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const [markedDates, setMarkedDates] = useState<MarkedDates>({
    [formatISO(new Date(), { representation: 'date' })]: !fp.isNil(
      customTodayStyle,
    )
      ? customTodayStyle
      : getTodayStyle(themeData),
  });
  const [containerW, setContainerW] = useState<number>(0);

  /*
   * Function that reads all the existing date items and mark them
   * in the calendar component.
   */
  const markDates = (): void => {
    if (datesToMark.length > 0) {
      // Take the day appointments' dates from the retrieved and add styles
      const styledDates = datesToMark.map((dateToMark) => [
        formatISO(new Date(dateToMark.date), { representation: 'date' }),
        markedDatesStyles[dateToMark.styleOption],
      ]);
      // Build an object with the marked dates and add styles
      const datesObject = Object.fromEntries(styledDates);
      // Set the dates that will be marked on the calendar
      setMarkedDates({ ...markedDates, ...datesObject });
    }
  };

  /*
   * Function that assigns a custom style to the currently selected
   * day on the calendar component
   */
  const handleOnDayPress = (date: DateData): void => {
    // Create Date object from substrings retrieved by calendar
    const dateFromCalendar = parseISO(date.dateString);
    setSelectedDate(dateFromCalendar);
    setMarkedDates({
      [formatISO(new Date(), { representation: 'date' })]: !fp.isNil(
        customTodayStyle,
      )
        ? customTodayStyle
        : getTodayStyle(themeData),
      [date.dateString]: selectedDayStyle,
    });
  };

  /**
   * Function that chooses among screen sizes to return a determined calendar
   * theme.
   * @returns best calendar theme
   */
  const getCalendarTheme = (): any => {
    if (Dimensions.get('screen').height <= 720) {
      return getSmallTheme(themeData);
    } else if (Dimensions.get('screen').height <= 1280) {
      return getMediumTheme(themeData);
    }
    return getRegularTheme(themeData);
  };

  useEffect(() => {
    markDates();
  }, [datesToMark, selectedDate]);

  return (
    <Flex
      bg={themeData.mainColorDark}
      onLayout={({
        nativeEvent: {
          layout: { width },
        },
      }) => setContainerW(width)}
      {...rest}
    >
      <Calendar
        markedDates={markedDates}
        markingType="custom"
        onDayPress={(day) => handleOnDayPress(day)}
        onPressArrowLeft={(addMonth, previousDate) => {
          addMonth();
          const nD = subMonths(new Date(previousDate), 1);
          onPreviousMonthSelect(nD.getMonth(), nD.getFullYear());
        }}
        onPressArrowRight={(substractMonth, nextDate) => {
          substractMonth();
          const pD = addMonths(new Date(nextDate), 1);
          onNextMonthSelect(pD.getMonth(), pD.getFullYear());
        }}
        renderArrow={(direction) => (
          <AntDesign
            color="white"
            name={direction === 'right' ? 'caretright' : 'caretleft'}
            size={14}
          />
        )}
        style={{
          backgroundColor: themeData.mainColorDark,
          flex: 1,
          width: !web ? containerW : containerW / 2,
        }}
        theme={getCalendarTheme()}
      />
    </Flex>
  );
};

CalendarSelector.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
  w: '100%',
};
