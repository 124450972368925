/* eslint-disable prefer-const */
import { parseISO } from 'date-fns';
import fp from 'lodash/fp';

/**
 * Function for sorting an array of objects alphabetically by the defined
 * key and subkey if applies.
 * @param array
 * @param key
 * @param subkey
 * @returns alphabetically sorted by key array.
 */
export const sortObjectArrayByKeys = (
  array: object[],
  key: string,
  subkey?: string,
): object[] => {
  return array.sort((a: object, b: object) => {
    let comparisonA: string = '';
    let comparisonB: string = '';
    if (fp.isNil(subkey)) {
      comparisonA = a[key as keyof typeof a];
      comparisonB = b[key as keyof typeof b];
    } else {
      comparisonA = a[key as keyof typeof a][subkey as keyof typeof a];
      comparisonB = b[key as keyof typeof b][subkey as keyof typeof b];
    }
    if (comparisonA[0] < comparisonB[0]) {
      return -1;
    }
    return 1;
  });
};

/**
 * Function that from an array of objects sorts by a nested date property,
 * confirming if item[key][subkey] is defined and using
 * item[key][alternativeKey] when is not defined and alternativeKey is provided.
 * @param array
 * @param key
 * @param alternativeKey
 * @param subkey
 * @returns sorted array by date in nested property
 */
export const sortObjectArrayByNestedDate = (
  array: object[],
  key: string,
  alternativeKey?: string,
  subkey?: string,
  sortDatesInverted: boolean = false,
): object[] => {
  return array.sort((a: object, b: object) => {
    let comparisonA: string = '';
    let comparisonB: string = '';
    const nilAKey = fp.isNil(
      a[key as keyof typeof a][subkey as keyof typeof a],
    );
    const nilBKey = fp.isNil(
      b[key as keyof typeof a][subkey as keyof typeof b],
    );
    if (fp.isNil(subkey)) {
      comparisonA = a[key as keyof typeof a];
      comparisonB = b[key as keyof typeof b];
    } else {
      if (!nilAKey && !nilBKey) {
        comparisonA = a[key as keyof typeof a][subkey as keyof typeof a];
        comparisonB = b[key as keyof typeof b][subkey as keyof typeof b];
      } else if (nilAKey && !nilBKey) {
        comparisonA =
          a[alternativeKey as keyof typeof a][subkey as keyof typeof a];
        comparisonB = b[key as keyof typeof b][subkey as keyof typeof b];
      } else if (!nilAKey && nilBKey) {
        comparisonA = a[key as keyof typeof a][subkey as keyof typeof a];
        comparisonB =
          b[alternativeKey as keyof typeof b][subkey as keyof typeof b];
      } else if (nilAKey && nilBKey) {
        comparisonA =
          a[alternativeKey as keyof typeof a][subkey as keyof typeof a];
        comparisonB =
          b[alternativeKey as keyof typeof b][subkey as keyof typeof b];
      }
    }

    if (!sortDatesInverted) {
      return Number(parseISO(comparisonA)) - Number(parseISO(comparisonB));
    } else {
      return Number(parseISO(comparisonB)) - Number(parseISO(comparisonA));
    }
  });
};

/**
 * Function that alphabetically returns a sorted array of strings.
 * @param array
 * @returns alphabetically sorted array.
 */
export const sortStringArray = (array: string[]): string[] => {
  return array.sort((a, b) => {
    if (a[0] < b[0]) {
      return -1;
    }
    return 1;
  });
};

/**
 * Function that takes an array of objects containing a date property and
 * sorts the array based on this.
 * @param array
 * @returns sorted by date array.
 */
export const sortObjectArrayByDate = (
  array: object[],
  sortDatesInverted: boolean = false,
): object[] => {
  return array.sort((a: object, b: object) => {
    let dateA = new Date(a['date' as keyof typeof a]);
    let dateB = new Date(b['date' as keyof typeof b]);
    if (!sortDatesInverted) {
      return Number(dateA) - Number(dateB);
    } else {
      return Number(dateB) - Number(dateA);
    }
  });
};

/**
 * Function that sorts data from an array when such has a created property.
 * Assumpting that such property is from FirebaseDate type.
 * @param array
 * @param sortDatesInverted
 * @returns sorted data
 */
export const sortObjectArrayByCreated = (
  array: object[],
  sortDatesInverted: boolean = false,
): object[] =>
  array.sort((a: object, b: object) => {
    const createdAObject = a['created' as keyof typeof a];
    const createdBObject = b['created' as keyof typeof a];
    const dateA = new Date(
      createdAObject['_seconds' as keyof typeof createdAObject] * 1000,
    );
    const dateB = new Date(
      createdBObject['_seconds' as keyof typeof createdBObject] * 1000,
    );
    if (!sortDatesInverted) {
      return Number(dateA) - Number(dateB);
    }
    return Number(dateB) - Number(dateA);
  });
