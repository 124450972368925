import { CommissionForm } from 'components/forms';
import { usePlatform } from 'hooks/platform-hooks';
import { IModalProps, Modal } from 'native-base';
import { ICommission } from 'services/resources/users/types';

interface IPutCommissionModalProps extends IModalProps {
  /**
   * Function to update the commission percentage of the therapist.
   */
  onSubmitCommission: (payload: ICommission) => void;
  /**
   * Function to close the PutCommissionModal.
   */
  onClosePutCommissionModal: () => void;
  /**
   * Existing commission of the therapist.
   */
  previousCommission: number;
}

export const PutCommissionModal: React.FC<IPutCommissionModalProps> = (
  props,
): JSX.Element => {
  const {
    onSubmitCommission,
    onClosePutCommissionModal,
    previousCommission,
    ...rest
  } = props;

  const { web } = usePlatform();

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        borderRadius={!web ? 0 : 10}
        justifyContent="center"
        p={6}
        w={!web ? '100%' : '30%'}
      >
        <CommissionForm
          onCancel={onClosePutCommissionModal}
          onSubmit={onSubmitCommission}
          previousCommission={previousCommission}
        />
      </Modal.Content>
    </Modal>
  );
};

PutCommissionModal.defaultProps = {
  size: 'full',
};
