import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { AcceptDeclineModal } from 'components/elements';
import fp from 'lodash/fp';
import {
  Button,
  HStack,
  IconButton,
  IStackProps,
  Text,
  VStack,
} from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IEventRegistry } from 'services/resources/events-registries/types.d';
import { colors } from 'styles/colors';

interface IEventRegistryProps extends IStackProps {
  /**
   * Item to be inherited from the cloneElement function.
   */
  item?: any;
  /**
   * Identifier used to avoid unwanted registries appearing in the wrong event.
   */
  originId: string;
  /**
   * Handler function for confirming user assitance to an event.
   */
  onAssistanceConfirm: (eventRegistry: string) => Promise<void>;
  /**
   * Handler function for confirming user payment to an event.
   */
  onPaymentConfirm: (eventRegistry: string) => Promise<void>;
}

export const EventRegistry: React.FC<IEventRegistryProps> = (props) => {
  const { onAssistanceConfirm, onPaymentConfirm, originId, item, ...rest } =
    props;
  const themeData = useSelector(getTheme);
  const registry = item as IEventRegistry;

  const [registryVisible, setRegistryVisible] = useState<boolean>(false);
  const [assitanceDisclaimerModalOpen, setAssistanceDisclaimerModalOpen] =
    useState<boolean>(false);
  const [paymentDisclaimerModalOpen, setPaymentDisclaimerModalOpen] =
    useState<boolean>(false);

  const toggleRegistryVisible = (): void => {
    setRegistryVisible(!registryVisible);
  };

  if (originId === registry.event) {
    return (
      <VStack {...rest}>
        <AcceptDeclineModal
          isOpen={assitanceDisclaimerModalOpen}
          onAccept={() => {
            onAssistanceConfirm(registry.id);
            setAssistanceDisclaimerModalOpen(false);
          }}
          onDecline={() => setAssistanceDisclaimerModalOpen(false)}
        >
          <Text fontWeight="bold">
            {`¿Estás seguro de querer confirmar la asistencia de ${registry.fullName}? Esta acción no se puede deshacer.`}
          </Text>
        </AcceptDeclineModal>
        <AcceptDeclineModal
          isOpen={paymentDisclaimerModalOpen}
          onAccept={() => {
            onPaymentConfirm(registry.id);
            setPaymentDisclaimerModalOpen(false);
          }}
          onDecline={() => setPaymentDisclaimerModalOpen(false)}
        >
          <Text fontWeight="bold">
            {`¿Estás seguro de querer marcar como pagado el registro de ${registry.fullName}? Esta acción no se puede deshacer.`}
          </Text>
        </AcceptDeclineModal>
        <HStack
          alignItems="flex-start"
          justifyContent="space-between"
          px={2}
          py={2}
        >
          <Text
            color={themeData.mainColorDark}
            fontSize={20}
            fontWeight="bold"
            textAlign="center"
          >
            {registry.fullName}
          </Text>
          <IconButton
            bg="transparent"
            icon={
              <AntDesign
                color={themeData.mainColorDark}
                name={!registryVisible ? 'caretdown' : 'caretup'}
                size={24}
              />
            }
            mt={-2}
            onPress={() => toggleRegistryVisible()}
          />
        </HStack>
        {registryVisible ? (
          <>
            <VStack>
              <Text
                color={themeData.mainColorDark}
                fontSize={18}
                fontWeight="bold"
                textAlign="center"
              >
                Correo Electrónico
              </Text>
              <Text textAlign="center">{registry.email}</Text>
            </VStack>
            <VStack>
              <Text
                color={themeData.mainColorDark}
                fontSize={18}
                fontWeight="bold"
                textAlign="center"
              >
                Teléfono
              </Text>
              <Text textAlign="center">{registry.phone}</Text>
            </VStack>
            {!fp.isNil(registry.diseases) && !fp.isEmpty(registry.diseases) ? (
              <VStack>
                <Text
                  color={themeData.mainColorDark}
                  fontSize={18}
                  fontWeight="bold"
                  textAlign="center"
                >
                  Enfermedades
                </Text>
                <Text textAlign="center">{registry.diseases}</Text>
              </VStack>
            ) : null}
            {!fp.isEmpty(registry.customFields)
              ? registry.customFields?.map((custom) => (
                  <VStack key={`${custom.field}+${Math.random()}`}>
                    <Text
                      color={themeData.mainColorDark}
                      fontSize={18}
                      fontWeight="bold"
                      textAlign="center"
                    >
                      {custom.field}
                    </Text>
                    <Text textAlign="center">{custom.value}</Text>
                  </VStack>
                ))
              : null}
            <VStack>
              <Text
                color={themeData.mainColorDark}
                fontSize={18}
                fontWeight="bold"
                textAlign="center"
              >
                Estatus de Pago
              </Text>
              <Text textAlign="center">
                {registry.paid ? 'Pagado' : 'Pendiente'}
              </Text>
            </VStack>
            <VStack>
              <Text
                color={themeData.mainColorDark}
                fontSize={18}
                fontWeight="bold"
                textAlign="center"
              >
                Asistencia
              </Text>
              <Text textAlign="center">
                {registry.assistedConfirmation ? 'Confirmada' : 'Pendiente'}
              </Text>
            </VStack>
            <Button
              bg={colors.success}
              borderRadius={0}
              leftIcon={
                <MaterialIcons color="white" name="fact-check" size={24} />
              }
              onPress={() => setAssistanceDisclaimerModalOpen(true)}
            >
              Confirmar Asistencia
            </Button>
            <Button
              bg={colors.success}
              borderRadius={0}
              leftIcon={
                <MaterialIcons color="white" name="payments" size={24} />
              }
              onPress={() => setPaymentDisclaimerModalOpen(true)}
            >
              Confirmar Pago
            </Button>
          </>
        ) : null}
      </VStack>
    );
  } else {
    return null;
  }
};

EventRegistry.defaultProps = {
  flex: 1,
  h: '100%',
  justifyContent: 'center',
  space: 2,
};
