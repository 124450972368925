import { Entypo, MaterialIcons } from '@expo/vector-icons';
import { ExtendedFlatlist } from 'components/elements';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { Button, Flex, IconButton, IModalProps, Modal } from 'native-base';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import {
  IPredefinedTask,
  IPredefinedTaskPayload,
  ITask,
  ITaskPayload,
} from 'services/resources/tasks/types.d';
import { colors } from 'styles/colors';

import { TaskItem } from './TaskItem';
import { TaskModal } from './TaskModal';

interface ITaskModalProps extends IModalProps {
  /**
   * Tasks associated to a given user.
   */
  tasks: ITask[];
  /**
   * Handler function for hiding the modal.
   */
  toggleTasksModalVisible: () => void;
  /**
   * Function for handling an item deletion on database.
   */
  onDelete: (task: ITask) => void;
  /**
   * Function for handling an item edition on database.
   */
  onEdit: (task: ITask) => void;
  /**
   * Function for handling item creation in database.
   */
  onCreate: (task: ITaskPayload) => void;
  /**
   * Tasks that exist already in the database.
   */
  predefinedTasks: IPredefinedTask[];
  /**
   * Function for giving a name to to the predefined task in case we want
   * to add one.
   */
  onPredefinedNameChange: (name: string) => void;
  /**
   * Function for handling an item deletion on database.
   */
  onPredefinedDelete: (task: IPredefinedTask) => void;
  /**
   * Function for handling an item edition on database.
   */
  onPredefinedEdit: (task: IPredefinedTask) => void;
  /**
   * Function for handling item creation in database.
   */
  onPredefinedCreate: (task: IPredefinedTaskPayload) => void;
  /**
   * Function for marking a task as completed when user is patient.
   */
  onComplete: (task: ITask) => void;
  /**
   * Function for showing/hiding tasks' modal.
   */
  togglePredefinedTasksModalVisible: () => void;
  /**
   * Flag to indicate weather to show or hide the modal.
   */
  predefinedTasksModalVisible: boolean;
  /**
   * State for the selected predefined task.
   */
  selectedPredefined: IPredefinedTask;
  /**
   * State setter for selected predefined task.
   */
  setSelectedPredefined: (predefined: IPredefinedTask) => void;
}

export const UserTasksModal: React.FC<ITaskModalProps> = (
  props,
): JSX.Element => {
  const {
    predefinedTasks,
    onEdit,
    onCreate,
    onDelete,
    onComplete,
    tasks,
    toggleTasksModalVisible,
    onPredefinedNameChange,
    onPredefinedCreate,
    onPredefinedDelete,
    onPredefinedEdit,
    togglePredefinedTasksModalVisible,
    selectedPredefined,
    setSelectedPredefined,
    predefinedTasksModalVisible,
    ...rest
  } = props;
  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const [taskModalVisible, setTaskModalVisible] = useState<boolean>(false);
  const [previousValues, setPreviousValues] = useState<ITask>({} as ITask);

  const toggleTaskModalVisible = (): void => {
    setTaskModalVisible(!taskModalVisible);
  };

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        bg="white"
        borderRadius={!web ? 0 : 10}
        flex={1}
        w={!web ? '100%' : '30%'}
      >
        <TaskModal
          isOpen={taskModalVisible}
          onCancel={() => {
            setPreviousValues({} as ITask);
            toggleTaskModalVisible();
          }}
          onPredefinedCreate={onPredefinedCreate}
          onPredefinedDelete={onPredefinedDelete}
          onPredefinedEdit={onPredefinedEdit}
          onPredefinedNameChange={onPredefinedNameChange}
          onSubmit={(payload) => {
            toggleTaskModalVisible();
            if (fp.isEmpty(previousValues)) {
              onCreate(payload);
            } else {
              onEdit(payload as ITask);
              setPreviousValues({} as ITask);
            }
          }}
          predefinedTasks={predefinedTasks}
          predefinedTasksModalVisible={predefinedTasksModalVisible}
          previousValues={previousValues}
          selectedPredefined={selectedPredefined}
          setSelectedPredefined={setSelectedPredefined}
          togglePredefinedTasksModalVisible={togglePredefinedTasksModalVisible}
        />
        <IconButton
          alignSelf="flex-end"
          flex={1}
          icon={
            <Entypo color={themeData.mainColorDark} name="cross" size={28} />
          }
          mr={3}
          mt={3}
          onPress={toggleTasksModalVisible}
        />
        <Flex flex={16} w="100%">
          <ExtendedFlatlist
            data={tasks}
            noDataMessage="No se encontraron tareas para el usuario seleccionado."
            renderItem={
              <TaskItem
                onComplete={onComplete}
                onDelete={(payload) => {
                  toggleTasksModalVisible();
                  onDelete(payload);
                }}
                onEdit={(task) => {
                  setPreviousValues(task);
                  toggleTaskModalVisible();
                }}
              />
            }
            searchBarPlaceholder="tarea"
            searchKey="description"
            sort
            sortKey="description"
            useSearchBar
          />
        </Flex>
        {userData.accountType === 'therapist' ? (
          <Button
            _hover={{ bg: colors.success, opacity: 0.8 }}
            _pressed={{ bg: colors.success, opacity: 0.8 }}
            _text={{ fontWeight: 'bold' }}
            bg={colors.success}
            borderRadius={0}
            flex={1}
            leftIcon={
              <MaterialIcons
                color="white"
                name="add"
                size={24}
                style={{ marginRight: 10 }}
              />
            }
            onPress={toggleTaskModalVisible}
            w="100%"
          >
            Añadir Tarea
          </Button>
        ) : null}
      </Modal.Content>
    </Modal>
  );
};

UserTasksModal.defaultProps = {
  avoidKeyboard: true,
  size: 'full',
};
