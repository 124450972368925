/* eslint-disable quotes */
import { Entypo } from '@expo/vector-icons';
import { AppointmentForm } from 'components/forms';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { Flex, IconButton, IModalProps, Modal, Text, View } from 'native-base';
import { ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import {
  IAppointmentFormPayload,
  IAppointmentPayload,
} from 'services/resources/appointments/types.d';
import { IUser } from 'services/resources/users/types.d';
import { fontSizes } from 'styles/theme';

import { CalendarUserList } from './CalendarUserList';

interface IAppointmentModalProps extends IModalProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IAppointmentFormPayload, user: IUser) => void;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Values that are already set, used for editing them.
   */
  previousValues: IAppointmentPayload;
  /**
   * Date selected from the calendar.
   */
  selectedDate: Date;
  /**
   * Therapist patients.
   */
  patients: IUser[];
  /**
   * Flag for being aware about edition status.
   */
  isEditing: boolean;
  /**
   * User related to the selected appointment.
   */
  selectedUser: IUser;
  /**
   * Function for setting a user as selected.
   */
  setSelectedUser: (user: IUser) => void;
  /**
   * Flag for indicating if the data is being fetched.
   */
  loading: boolean;
}

export const AppointmentModal: React.FC<IAppointmentModalProps> = (
  props,
): JSX.Element => {
  const {
    onSubmit,
    onCancel,
    previousValues,
    selectedDate,
    patients,
    isEditing,
    selectedUser,
    setSelectedUser,
    loading,
    ...rest
  } = props;

  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        bg="white"
        borderRadius={!web ? 0 : 10}
        maxH="90%"
        minH={!web ? '80%' : undefined}
        w={!web ? '100%' : '30%'}
      >
        <IconButton
          alignSelf="flex-end"
          icon={
            <Entypo color={themeData.mainColorDark} name="cross" size={28} />
          }
          mr={3}
          onPress={() => {
            onCancel();
          }}
        />
        {!web ? (
          <>
            <Flex
              alignItems="center"
              alignSelf="center"
              justifyContent="center"
              w="100%"
            >
              <Text
                color={themeData.mainColorDark}
                fontSize={fontSizes.big}
                fontWeight="bold"
                pb={2}
                textAlign="center"
              >
                {!isEditing ? 'Nueva Cita' : 'Editar Cita'}
              </Text>
            </Flex>
            <CalendarUserList
              displayMode="patients"
              loading={loading}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              users={patients}
            />
            {!fp.isEmpty(selectedUser) ? (
              <Flex
                alignItems="center"
                alignSelf="center"
                flex={4}
                justifyContent="center"
                w="100%"
              >
                <AppointmentForm
                  flex={1}
                  isEditing={isEditing}
                  onCancel={onCancel}
                  onSubmit={(p) => onSubmit(p, selectedUser)}
                  previousValues={previousValues}
                  py={2}
                  selectedDate={selectedDate}
                  w="90%"
                />
              </Flex>
            ) : (
              <View />
            )}
          </>
        ) : (
          <ScrollView showsVerticalScrollIndicator style={{ width: '90%' }}>
            <Flex alignItems="center" justifyContent="center" w="100%">
              <Text
                color={themeData.mainColorDark}
                fontSize={fontSizes.big}
                fontWeight="bold"
                pb={2}
                textAlign="center"
              >
                {!isEditing ? 'Nueva Cita' : 'Editar Cita'}
              </Text>
            </Flex>
            <CalendarUserList
              displayMode="patients"
              loading={loading}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              users={patients}
            />
            {!fp.isEmpty(selectedUser) ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                mt="70px"
                w="100%"
              >
                <AppointmentForm
                  flex={1}
                  isEditing={isEditing}
                  onCancel={onCancel}
                  onSubmit={(p) => onSubmit(p, selectedUser)}
                  previousValues={previousValues}
                  py={2}
                  selectedDate={selectedDate}
                />
              </Flex>
            ) : null}
          </ScrollView>
        )}
      </Modal.Content>
    </Modal>
  );
};

AppointmentModal.defaultProps = {
  size: 'full',
};
