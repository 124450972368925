import { Platform } from 'react-native';
import { MarkingProps } from 'react-native-calendars/src/calendar/day/marking';
import { IThemeState } from 'redux-service/types';
import { colors } from 'styles/colors';

const getAppointmentStyle = (themeData: IThemeState): MarkingProps => {
  return {
    customStyles: {
      container: {
        alignItems: 'center',
        backgroundColor: themeData.mainColorLight,
        borderColor: 'red',
        borderRadius: 20,
        borderWidth: 2,
        height: Platform.OS !== 'web' ? 24 : 20,
        justifyContent: 'center',
        width: 26,
      },
      text: {
        color: themeData.mainColorDark,
        fontWeight: 'bold',
      },
    },
  };
};

const getEventStyle = (): MarkingProps => {
  return {
    customStyles: {
      container: {
        alignItems: 'center',
        backgroundColor: colors.terappiaPurple.light,
        borderColor: 'red',
        borderRadius: 20,
        borderWidth: 2,
        height: Platform.OS !== 'web' ? 24 : 20,
        justifyContent: 'center',
        width: 26,
      },
      text: {
        color: 'white',
        fontWeight: 'bold',
      },
    },
  };
};

/**
 * Function that builds the styles' array to be applied to the calendar
 * selector.
 * @param themeData
 * @returns Marking Props Array
 */
export const getCalendarMarkedDatesStyle = (
  themeData: IThemeState,
): MarkingProps[] => {
  return [getAppointmentStyle(themeData), getEventStyle()];
};
