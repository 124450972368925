import { MaterialCommunityIcons } from '@expo/vector-icons';
import { EditionButtons, LinkParser } from 'components/elements';
import { IUserItemProps } from 'components/elements/types.d';
import { Button, Divider, HStack, Text, VStack } from 'native-base';
import React from 'react';
import { IPredefinedTask } from 'services/resources/tasks/types.d';

interface IPredefinedTaskItemProps extends IUserItemProps {
  /**
   * Item to be inherited from the cloneElement function.
   */
  item?: any;
  /**
   * Function for handling an item deletion on database.
   */
  onDelete: (task: IPredefinedTask) => void;
  /**
   * Function for handling an item edition on database.
   */
  onEdit: (task: IPredefinedTask) => void;
  /**
   * Function for setting the selected predefined task as the task to be
   * assigned to patient.
   */
  onSelect: (task: IPredefinedTask) => void;
}

export const PredefinedTaskItem: React.FC<IPredefinedTaskItemProps> = (
  props,
): JSX.Element => {
  const { onSelect, onDelete, onEdit, children, item, ...rest } = props;

  const task = item as IPredefinedTask;

  return (
    <VStack {...rest}>
      <Text fontWeight="bold" textAlign="center">
        {task.name}
      </Text>
      <LinkParser>{task.description}</LinkParser>
      <HStack justifyContent="center" mb={2} space={3}>
        <Button
          h="100%"
          leftIcon={
            <MaterialCommunityIcons
              color="white"
              name="hand-pointing-up"
              size={24}
            />
          }
          onPress={() => onSelect(task)}
        >
          Seleccionar
        </Button>
        <EditionButtons
          h="100%"
          onDelete={() => onDelete(task)}
          onEdit={() => onEdit(task)}
        />
      </HStack>
      <Divider />
    </VStack>
  );
};

PredefinedTaskItem.defaultProps = {
  flex: 1,
  pt: 2,
  w: '100%',
};
