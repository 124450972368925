import { format } from 'date-fns';
import { IAppointmentWithFullNames } from 'services/resources/appointments/types.d';

/**
 * Function that from some given appointments, returns all those which are
 * confirmed by the therapist and not confirmed by a third party or those
 * where both confirmations have been made.
 * @param appointments
 * @returns non-confirmed appointments by third parties or confirmed by both
 */
export const filterManagedAppointments = (
  appointments: IAppointmentWithFullNames[],
): IAppointmentWithFullNames[] => {
  return appointments.filter((appointmentWithFullNames) => {
    const confirmedByThird =
      appointmentWithFullNames.appointment.confirmedByThird;
    const confirmedByTherapist =
      appointmentWithFullNames.appointment.confirmedByTherapist;
    if (
      (confirmedByTherapist && !confirmedByThird) ||
      (confirmedByTherapist && confirmedByThird)
    ) {
      return appointmentWithFullNames;
    } else {
      return null;
    }
  });
};

/**
 * Function that filter the appointments returning only those that correspond
 * to the selected date.
 * @param selectedDate
 * @param appointmentsWithFullNames
 * @returns selected date appointments.
 */
export const filterAppointmentsWithFullNamesIncidences = (
  selectedDate: Date,
  appointmentsWithFullNames: IAppointmentWithFullNames[],
): IAppointmentWithFullNames[] => {
  return appointmentsWithFullNames.filter((appointmentWithFullName) => {
    const formattedCurrent = format(
      new Date(appointmentWithFullName.appointment.date),
      // eslint-disable-next-line prettier/prettier, quotes
      "yyyy'-'MM'-'dd'",
    );
    const formattedSelected = format(
      new Date(selectedDate),
      // eslint-disable-next-line prettier/prettier, quotes
      "yyyy'-'MM'-'dd'",
    );
    if (formattedCurrent === formattedSelected) {
      return appointmentWithFullName;
    } else {
      return null;
    }
  });
};
