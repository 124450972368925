/* eslint-disable prettier/prettier */
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { userResources } from 'services/resources/users';

// Code adapted from
// https://docs.expo.dev/push-notifications/push-notifications-setup/

/**
 * Create the spcecific required Android setup
 */
const androidSetup = (): void => {
  const { android } = usePlatform();
  if (android) {
    Notifications.setNotificationChannelAsync('default', {
      importance: Notifications.AndroidImportance.MAX,
      lightColor: '#FF231F7C',
      name: 'default',
      vibrationPattern: [0, 250, 250, 250],
    });
  }
};

/**
 * Create a Expo device token when all requirements met.
 * @param userToken
 * @param email
 * @returns
 */
export const registerForPushNotifications = async (
  userToken: string,
  email: string,
): Promise<string> => {
  let permissionStatus;
  let token: string = '';
  // We verify we are using a physical device
  if (Device.isDevice) {
    const { status: initialStatus } = await Notifications.getPermissionsAsync();
    permissionStatus = initialStatus;
    if (permissionStatus !== 'granted') {
      const {
        status: finalStatus,
      } = await Notifications.requestPermissionsAsync();
      permissionStatus = finalStatus;
    }
    if (permissionStatus !== 'granted') {
      const newLog: ILogEntry = {
        date: new Date(),
        message: 'Permissions for notifications were not granted',
        service: 'notifications',
        user: email,
      };
      logsResources.create(newLog, userToken);
    } else {
      const { data } = await Notifications.getExpoPushTokenAsync();
      token = data;
      androidSetup();
    }
  } else {
    const newLog: ILogEntry = {
      date: new Date(),
      message: 'Must use physical device for Push Notifications',
      service: 'notifications',
      user: email,
    };
    logsResources.create(newLog, userToken);
  }
  return token;
};

/**
 * Function for retrieving current device id for comparing to the one in
 * database in order to update in case user has changed device.
 * @param dbDeviceId
 * @param userToken
 * @param userId
 * @param userEmail
 */
export const checkDeviceId = async (
  dbDeviceId: string | null | undefined,
  userToken: string,
  userId: string,
  userEmail: string,
): Promise<void> => {
  // We firstly verify if theres is already a device id already registered
  if (!fp.isNil(dbDeviceId)) {
    // We retrieve the current device id, to compare with the one stored in
    // database
    try {
      const currentDeviceId = await registerForPushNotifications(
        userToken,
        userEmail,
      );
      // If the device id changed, store it in database
      if (dbDeviceId !== currentDeviceId) {
        userResources.putDeviceId(currentDeviceId, userId, userToken);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify({ e, message: 'Error storaging device id' }),
        service: 'notifications',
        user: userEmail,
      };
      logsResources.create(newLog, userToken);
    }
  } else {
    try {
      // If is not registered, store it in database
      const deviceId = await registerForPushNotifications(userToken, userEmail);
      userResources.putDeviceId(deviceId, userId, userToken);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify({ e, message: 'Error storaging device id' }),
        service: 'notifications',
        user: userEmail,
      };
      logsResources.create(newLog, userToken);
    }
  }
};
