import { instance } from 'services';

import {
  IPasswordLessSignUpPayload,
  ITaxInfo,
  ITherapyRelationshipOperationPayload,
  IUserResponse,
  IUsersResponse,
  IUsersWithFullDataResponse,
  IUserWithTasksResponse,
} from './types.d';

export const userResources = {
  create: (payload: IPasswordLessSignUpPayload, token: string): Promise<void> =>
    instance.post(
      '/usersAPI',
      { user: payload },
      {
        headers: { authorization: token },
      },
    ),

  createTherapyRelationship: (
    users: ITherapyRelationshipOperationPayload,
    token: string,
  ) =>
    instance.put(
      '/usersAPI/therapyRelationship',
      { users },
      {
        headers: {
          authorization: token,
        },
        params: {
          operation: 'create',
        },
      },
    ),

  delete: (user: string, token: string): Promise<void> =>
    instance.delete('/usersAPI', {
      headers: {
        authorization: token,
      },
      params: { id: user },
    }),

  deleteTherapyRelationship: (
    users: ITherapyRelationshipOperationPayload,
    token: string,
  ) =>
    instance.put(
      '/usersAPI/therapyRelationship',
      { users },
      {
        headers: {
          authorization: token,
        },
        params: {
          operation: 'delete',
        },
      },
    ),

  getAdmins: (token: string): Promise<IUsersResponse> =>
    instance.get('/usersAPI/administrators', {
      headers: { authorization: token },
    }),

  getAllPatientsWithFullData: (
    token: string,
  ): Promise<IUsersWithFullDataResponse> =>
    instance.get('/usersAPI/patientsFullData', {
      headers: { authorization: token },
    }),

  getAllVerifiedTherapistsWithFullData: (
    token: string,
  ): Promise<IUsersWithFullDataResponse> =>
    instance.get('/usersAPI/verifiedTherapistsFullData', {
      headers: { authorization: token },
    }),

  getById: (id: string, token: string): Promise<IUserResponse> =>
    instance.get('/usersAPI', {
      headers: { authorization: token },
      params: { id },
    }),

  getByMail: (email: string, token: string): Promise<IUserResponse> =>
    instance.get('/usersAPI', {
      headers: { authorization: token },
      params: { email },
    }),

  getPatients: (therapist: string, token: string): Promise<IUsersResponse> =>
    instance.get('/usersAPI/patients', {
      headers: { authorization: token },
      params: { id: therapist },
    }),

  getPatientsTasks: (
    therapist: string,
    token: string,
  ): Promise<IUserWithTasksResponse> =>
    instance.get('usersAPI/patientsTasks', {
      headers: { authorization: token },
      params: { id: therapist },
    }),

  getTherapists: (patient: string, token: string): Promise<IUsersResponse> =>
    instance.get('/usersAPI/therapists', {
      headers: { authorization: token },
      params: { id: patient },
    }),

  getTherapistsRequests: (token: string): Promise<IUsersResponse> =>
    instance.get('/usersAPI/therapistsRequests', {
      headers: { authorization: token },
    }),

  getTherapistsTasks: (
    patient: string,
    token: string,
  ): Promise<IUserWithTasksResponse> =>
    instance.get('usersAPI/therapistsTasks', {
      headers: { authorization: token },
      params: { id: patient },
    }),

  getVerifiedTherapists: (token: string): Promise<IUsersResponse> =>
    instance.get('/usersAPI/verifiedTherapists', {
      headers: { authorization: token },
    }),

  putCommission: (
    therapistEmail: string,
    newCommission: string,
    token: string,
  ): Promise<void> =>
    instance.put(
      '/usersAPI/commission',
      { commission: newCommission },
      {
        headers: { authorization: token },
        params: { userEmail: therapistEmail },
      },
    ),

  putDeviceId: (
    deviceId: string,
    userId: string,
    token: string,
  ): Promise<void> =>
    instance.put(
      '/usersAPI',
      { deviceId },
      {
        headers: { authorization: token },
        params: { id: userId, updateField: 'deviceId' },
      },
    ),

  putProfilePicture: (
    profilePicture: string,
    userId: string,
    token: string,
  ): Promise<void> =>
    instance.put(
      '/usersAPI',
      { user: { pictures: { profilePicture } } },
      {
        headers: { authorization: token },
        params: { id: userId, updateField: 'profilePicture' },
      },
    ),

  putTaxInfo: (
    taxInfoPayload: ITaxInfo,
    userEmail: string,
    token: string,
  ): Promise<void> =>
    instance.put(
      '/usersAPI/taxInfo',
      { taxInfo: taxInfoPayload },
      {
        headers: { authorization: token },
        params: { userEmail },
      },
    ),

  resolveTherapistRequest: (
    requestingTherapist: string,
    response: 'accept' | 'decline',
    token: string,
  ): Promise<void> =>
    instance.put(
      '/usersAPI',
      {},
      {
        headers: { authorization: token },
        params: {
          id: requestingTherapist,
          operation: response,
          updateField: 'verifiedTherapist',
        },
      },
    ),
};
