import {
  AcceptDecline,
  AcceptDeclineModal,
  InputScroller,
  UserItem,
} from 'components/elements';
import { IAcceptDeclineProps } from 'components/elements/types.d';
import { SearchUserForm } from 'components/forms';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { IModalProps, Modal, Text, VStack } from 'native-base';
import React, { useState } from 'react';
import { Dimensions } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import { IUser } from 'services/resources/users/types.d';
import { fontSizes } from 'styles/theme';
import { IUserIncidenceError } from './types.d';

interface ILocalModalProps {
  /**
   * User found in the database.
   */
  foundUser: IUser;
  /**
   * Handler function for searching user with the given email.
   */
  onSearchSubmit: (payload: { email: string }) => Promise<void>;
  /**
   * Current patients assigned to therapist.
   */
  currentPatients: IUser[];
}

type INewPatientModalProps = IModalProps &
  IAcceptDeclineProps &
  ILocalModalProps;

export const NewPatientModal: React.FC<INewPatientModalProps> = (props) => {
  const {
    foundUser,
    onSearchSubmit,
    onAccept,
    onDecline,
    invertColors,
    children,
    currentPatients,
    ...rest
  } = props;

  const themeData = useSelector(getTheme);
  const userData = useSelector(getUser);
  const { web } = usePlatform();

  const [submitted, setSubmitted] = useState<boolean>(false);

  /**
   * Function that checks if some error was raised when trying to add a patient.
   * @returns the error status.
   */
  const checkUserAdditionIssues = (): IUserIncidenceError | '' => {
    let error: '' | IUserIncidenceError = '';
    if (foundUser.email === userData.email) {
      error = 'self';
    }
    if (fp.isEmpty(foundUser)) {
      error = 'not-found';
    }
    !fp.isEmpty(currentPatients) &&
      currentPatients.forEach((patient) => {
        if (patient.email === foundUser.email) error = 'existing';
      });
    return error;
  };

  const issues = checkUserAdditionIssues();

  /**
   * Function that return a specific message based on the possible errors.
   * @returns error message.
   */
  const errorMessage = (): string => {
    switch (issues) {
      case 'not-found':
        return 'No se encontraron usuarios con ese correo';
      case 'existing':
        return 'No puedes añadir a un usuario que ya es tu paciente.';
      case 'self':
        return 'No puedes agregarte a ti mismo como paciente';
      default:
        return '';
    }
  };

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        bg="white"
        borderRadius={10}
        justifyContent="center"
        py={2}
        shadow="none"
        w={!web ? Dimensions.get('screen').width : '30%'}
      >
        {!web ? (
          <InputScroller
            bottomOffset
            contentContainerStyle={{
              alignItems: 'center',
              width: '100%',
            }}
            disableScrollToStart
            style={{ height: '60%' }}
          >
            <Text
              color={themeData.mainColorDark}
              fontSize={fontSizes.big}
              fontWeight="bold"
              mb={4}
              mt={20}
              textAlign="center"
            >
              Buscar Usuario
            </Text>
            <SearchUserForm
              onSubmit={(payload) => {
                setSubmitted(true);
                onSearchSubmit(payload);
              }}
            />
            {submitted ? (
              !fp.isEmpty(foundUser) && fp.isEmpty(issues) ? (
                <UserItem item={foundUser} ml={2} />
              ) : (
                <Text textAlign="center">{errorMessage()}</Text>
              )
            ) : (
              <></>
            )}
            <AcceptDecline
              customAcceptMessage="Añadir"
              disabled={!submitted || !fp.isEmpty(issues)}
              invertColors={invertColors}
              mt={6}
              onAccept={() => {
                setSubmitted(false);
                onAccept();
              }}
              onDecline={() => {
                setSubmitted(false);
                onDecline();
              }}
            />
          </InputScroller>
        ) : (
          <VStack justifyContent="center" w="100%">
            <Text
              color={themeData.mainColorDark}
              fontSize={fontSizes.big}
              fontWeight="bold"
              mb={4}
              textAlign="center"
            >
              Buscar Usuario
            </Text>
            <SearchUserForm
              onSubmit={(payload) => {
                setSubmitted(true);
                onSearchSubmit(payload);
              }}
            />
            {submitted ? (
              !fp.isEmpty(foundUser) && fp.isEmpty(issues) ? (
                <UserItem item={foundUser} ml={2} />
              ) : (
                <Text textAlign="center">{errorMessage()}</Text>
              )
            ) : null}
            <AcceptDecline
              customAcceptMessage="Añadir"
              disabled={!submitted || !fp.isEmpty(issues)}
              invertColors={invertColors}
              mt={6}
              onAccept={() => {
                setSubmitted(false);
                onAccept();
              }}
              onDecline={() => {
                setSubmitted(false);
                onDecline();
              }}
            />
          </VStack>
        )}
      </Modal.Content>
    </Modal>
  );
};

AcceptDeclineModal.defaultProps = {
  avoidKeyboard: true,
  size: 'full',
};
