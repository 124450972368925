import { Entypo } from '@expo/vector-icons';
import { ExtendedFlatlist, InputScroller } from 'components/elements';
import { sortObjectArrayByNestedDate } from 'helpers/data-helpers/sorting-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import { IconButton, IModalProps, Modal, Text } from 'native-base';
import { ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

import { IAppointmentWithTherapyProgress } from '../types.d';
import { TherapyProgressItem } from './TherapyProgressItem';

interface IAppointmentsModalProps extends IModalProps {
  /**
   * Appointments (with progress) of the therapist and the selected patient
   */
  appointmentsWithTherapyProgress: IAppointmentWithTherapyProgress[];
  /**
   * Function to toggle the current patient appointments modal.
   */
  onCloseAppointmentsModal: () => void;
  /**
   * Function to toggle the put therapy progress modal.
   */
  togglePutTherapyProgressModal: (
    appointmentWithTherapyProgress: IAppointmentWithTherapyProgress,
  ) => void;
}

export const AppointmentsModal: React.FC<IAppointmentsModalProps> = (props) => {
  const {
    appointmentsWithTherapyProgress,
    children,
    onCloseAppointmentsModal,
    togglePutTherapyProgressModal,
    ...rest
  } = props;

  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const sortedAppointmentsWithTherapyProgress = sortObjectArrayByNestedDate(
    appointmentsWithTherapyProgress,
    'appointment',
    undefined,
    'date',
    true,
  );

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        bg="white"
        borderRadius={!web ? 0 : 10}
        justifyContent="center"
        position="absolute"
        py={6}
        shadow="none"
        w={!web ? '100%' : '30%'}
      >
        <IconButton
          alignSelf="flex-end"
          icon={
            <Entypo color={themeData.mainColorDark} name="cross" size={28} />
          }
          mr={3}
          onPress={() => onCloseAppointmentsModal()}
        />
        <Text
          color={themeData.mainColorDark}
          fontSize={24}
          fontWeight="bold"
          pb={3}
          textAlign="center"
        >
          Progreso de Citas
        </Text>
        {!web ? (
          <InputScroller
            bottomOffset
            contentContainerStyle={{
              width: '100%',
            }}
            disableScrollToStart
          >
            <ExtendedFlatlist
              data={sortedAppointmentsWithTherapyProgress}
              noDataMessage="No se encontraron citas con el/la paciente seleccionado(a)."
              renderItem={
                <TherapyProgressItem
                  onOpenPutTherapyProgressModal={togglePutTherapyProgressModal}
                />
              }
              useSearchBar={false}
            />
          </InputScroller>
        ) : (
          <ScrollView showsVerticalScrollIndicator style={{ width: '100%' }}>
            <ExtendedFlatlist
              data={appointmentsWithTherapyProgress}
              noDataMessage="No se encontraron citas con el/la paciente seleccionado(a)."
              renderItem={
                <TherapyProgressItem
                  onOpenPutTherapyProgressModal={togglePutTherapyProgressModal}
                />
              }
              useSearchBar={false}
            />
          </ScrollView>
        )}
      </Modal.Content>
    </Modal>
  );
};

AppointmentsModal.defaultProps = {
  size: 'full',
};
