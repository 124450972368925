/* eslint-disable quotes */
import { IOption } from 'components/elements/types';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import fp from 'lodash/fp';
import { IAppointmentWithInfoTaxDataAndNames } from 'services/resources/appointments-info/types.d';

import {
  allConstrainedOptions,
  dateOptions,
  fieldOptions,
} from './constant-helpers';

/**
 * Function used to retrieve the current keys/values (properties' names/
 * properties values) of the appointment info.
 * @param key
 * @param value
 * @returns
 */
export const getInfoItemData = (key: string, value: string): IOption => {
  let result: IOption = {} as IOption;
  // We iterate all the field options that we have for an appointment info
  fieldOptions.forEach((fieldOption) => {
    // We proceed if we find an appointment info key that matches an
    // appointment info option
    if (key === fieldOption.value) {
      // We might have a translated label that matches the value of the key,
      // so we look for it into the constrained options.
      const existingValueTrial = allConstrainedOptions.find(
        (constrainedOption) => {
          if (constrainedOption.value === value) {
            return constrainedOption;
          } else return null;
        },
      );
      // As previous operation might return undefined values for non-matching
      // cases, we manually verify its existance
      const existingValue =
        !fp.isNil(existingValueTrial) && !fp.isNil(existingValueTrial.title);
      // We might have the special case where the key is the payment date, we
      // detemrine this for further usage
      const isValueDate = !existingValue && key === 'paymentDate';
      // If we do have a label for the current key value, return it
      if (existingValue) {
        result = {
          title: fieldOption.title,
          value: existingValue ? (existingValueTrial.title as string) : value,
        };
        // Verify if is the special-case date and return a formatted date
      } else if (isValueDate) {
        result = {
          title: fieldOption.title,
          value: format(new Date(value), `EEEE d 'de' MMMM 'de' yyyy`, {
            locale: es,
          }),
        };
        // If none of the above cases, just return the value
      } else {
        result = {
          title: fieldOption.title,
          value,
        };
      }
    }
  });
  return result;
};

/**
 * Function that takes a date value and returns its Spanish translation for
 * displaying it in the UI.
 * @param dateOption
 * @returns
 */
export const getTranslatedDateOption = (dateOption: IOption): string => {
  let result = '';
  const matchingDateOption = dateOptions.find((dO) => {
    if (dO.value === dateOption.value) {
      return dO;
    } else return null;
  });
  if (!fp.isNil(matchingDateOption)) {
    result = matchingDateOption.title as string;
  }
  return result;
};

export const sortAppointmentsByDate = (
  data: IAppointmentWithInfoTaxDataAndNames[],
): IAppointmentWithInfoTaxDataAndNames[] => {
  return data.sort((a, b) => {
    const dateA = new Date(a.appointment.date);
    const dateB = new Date(b.appointment.date);
    return dateA.getTime() - dateB.getTime();
  });
};
