import { Entypo } from '@expo/vector-icons';
import { AcceptDecline } from 'components/elements';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { IconButton, IModalProps, Modal } from 'native-base';
import { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IEvent } from 'services/resources/events/types.d';
import { colors } from 'styles/colors';

import { SelectedEventModalContent } from './SelectedEventModalContent';

interface ISelectedEventModalProps extends IModalProps {
  /**
   * Data of the selected Event.
   */
  eventData: IEvent;
  /**
   * Function that only calls the toggle modal at Events.tsx, but may be used
   * in the future for calling other functions.
   */
  onCloseModal: () => void;
  /**
   * Function that toggles the disclaimer modal when choosing to delete
   * an event.
   */
  toggleDisclaimerModalOpen: () => void;
  /**
   * Function that toggles the event modal (creation/edition).
   */
  toggleEventModalVisible: () => void;
  /**
   * Function that handles the QR save (store in Firebase).
   */
  saveQR: (event: string, snapshotURI: string) => Promise<void>;
}

export const SelectedEventModal: React.FC<ISelectedEventModalProps> = (
  props,
): JSX.Element => {
  const {
    children,
    onCloseModal,
    toggleDisclaimerModalOpen,
    toggleEventModalVisible,
    eventData,
    saveQR,
    ...rest
  } = props;
  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const [scrollRef, setScrollRef] = useState<ScrollView | null>(null);

  useEffect(() => {
    if (!fp.isNil(scrollRef)) scrollRef.flashScrollIndicators();
  }, []);

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        borderRadius={!web ? 0 : 10}
        flex={1}
        h="100%"
        justifyContent="center"
        w={!web ? '100%' : '30%'}
      >
        {children}
        <IconButton
          alignSelf="flex-end"
          icon={
            <Entypo color={themeData.mainColorDark} name="cross" size={28} />
          }
          mr={3}
          onPress={() => onCloseModal()}
        />
        <ScrollView
          ref={(scrollViewRef) => setScrollRef(scrollViewRef)}
          showsVerticalScrollIndicator
          style={{ width: '100%' }}
        >
          <SelectedEventModalContent eventData={eventData} saveQR={saveQR} />
          <AcceptDecline
            customAcceptColor={colors.warning}
            customAcceptMessage="Editar"
            customDeclineMessage="Eliminar"
            my={2}
            onAccept={() => toggleEventModalVisible()}
            onDecline={() => toggleDisclaimerModalOpen()}
          />
        </ScrollView>
      </Modal.Content>
    </Modal>
  );
};

SelectedEventModal.defaultProps = {
  size: 'full',
};
