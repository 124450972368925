import { usePlatform } from 'hooks/platform-hooks';
import { Heading, IStackProps, VStack } from 'native-base';
import { ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

export const LoadingStatus: React.FC<IStackProps> = (props): JSX.Element => {
  const { children, ...rest } = props;

  const themeData = useSelector(getTheme);
  const { ios, web } = usePlatform();

  return (
    <VStack
      alignItems="center"
      bg="transparent"
      py={6}
      space={6}
      w={!web ? '100%' : '30%'}
      {...rest}
    >
      <ActivityIndicator color={themeData.mainColorDark} size="large" />
      <Heading color={themeData.mainColorDark} fontSize="md" px={ios ? 4 : 0}>
        {children}
      </Heading>
    </VStack>
  );
};

LoadingStatus.defaultProps = {
  alignItems: 'center',
  bg: 'transparent',
  py: 6,
  space: 6,
};
