/* eslint-disable quotes */
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { EditionButtons, UserItem } from 'components/elements';
import { differenceInHours, format, isBefore, parseISO } from 'date-fns';
import { isUserDeleted } from 'helpers/data-helpers/item-helpers';
import fp from 'lodash/fp';
import {
  Divider,
  HStack,
  IconButton,
  IStackProps,
  Text,
  VStack,
} from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import { IAppointmentWithUser } from 'screens/types';
import { IAppointment } from 'services/resources/appointments/types.d';
import { IEvent } from 'services/resources/events/types.d';
import { IUser } from 'services/resources/users/types.d';
import { colors } from 'styles/colors';

import { ICalendarItem } from '../types.d';
import { EventItem } from './EventItem';

interface ICalendarItemProps extends IStackProps {
  /**
   * Item to be inherited from the cloneElement function.
   */
  item?: any;
  /**
   * Function for handling item deletion on database.
   */
  onDelete: (appointment: IAppointment) => void;
  /**
   * Function for handling item edition on database.
   */
  onEdit: (appointment: IAppointment, user: IUser) => void;
  /**
   * Handler function for therapist appointment confirmation.
   */
  setSelectedAppointmentWithUser: (
    appointmentWithUser: IAppointmentWithUser,
  ) => void;
  /**
   * Function for showing/hiding the appointment modal.
   */
  toggleAppointmentModalVisible: () => void;
  /**
   * Flag that indicates weather to show the appointment modal or not.
   */
  appointmentModalVisible: boolean;
}

export const CalendarItem: React.FC<ICalendarItemProps> = (
  props,
): JSX.Element => {
  const {
    appointmentModalVisible,
    onDelete,
    onEdit,
    setSelectedAppointmentWithUser,
    children,
    item,
    toggleAppointmentModalVisible,
    ...rest
  } = props;
  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);

  const calendarItem = item as ICalendarItem;

  const userWithAppointment =
    calendarItem.appointmentWithUser as IAppointmentWithUser;
  const user = { ...userWithAppointment.user };
  const appointment = { ...userWithAppointment } as IAppointment;
  const event = { ...calendarItem.event } as IEvent;
  const therapistName = calendarItem.therapistName as string;

  /**
   * Function that checks the cancelation capablity of the given patient.
   * If hours <= 24, patient can cancel, otherwise it can't.
   * @returns
   */
  const canPatientCancel = (): boolean => {
    const difference = differenceInHours(
      new Date(appointment.date),
      new Date(),
    );
    if (difference >= 24) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Function that determines that if the appointment that want to be changed
   * by the user is before current date.
   * @returns is selected date before today?
   */
  const canUserChangeAppointment = (): boolean => {
    const before = isBefore(new Date(appointment.date), new Date());
    if (before) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Function that determines if a therapist can confirm an appointment as
   * completed, when has elapsed at least an hour since the appointment
   * time.
   * @returns can therapist confirm
   */
  const canTherapistConfirm = (): boolean => {
    let canConfirm = false;
    if (differenceInHours(new Date(), parseISO(`${appointment.date}`)) >= 1) {
      canConfirm = true;
    }
    return canConfirm;
  };

  const secretary = !fp.isNil(userData.secretary) && userData.secretary;

  if (!appointment.cancelled || fp.isNil(appointment.cancelled)) {
    return (
      <VStack {...rest}>
        {!fp.isEmpty(user) ? (
          <UserItem item={user} showDivider={false}>
            <Text fontWeight="bold">
              {`${format(new Date(appointment.date), "HH:mm 'hrs'")}`}
            </Text>
            {userData.accountType === 'therapist' || secretary ? (
              <>
                {canUserChangeAppointment() && !isUserDeleted(user) ? (
                  <EditionButtons
                    onDelete={() => onDelete(appointment)}
                    onEdit={() => onEdit(appointment, user)}
                    pr={2}
                  />
                ) : null}
                {!secretary ? (
                  <>
                    <Divider mx={3} orientation="vertical" />{' '}
                    <IconButton
                      bg={themeData.mainColorDark}
                      icon={
                        <FontAwesome5
                          color="white"
                          name="calendar-check"
                          size={24}
                        />
                      }
                      isDisabled={
                        appointment.confirmedByTherapist ||
                        !canTherapistConfirm()
                      }
                      mr={1}
                      onPress={() =>
                        setSelectedAppointmentWithUser(userWithAppointment)
                      }
                    />
                  </>
                ) : null}
              </>
            ) : canUserChangeAppointment() &&
              canPatientCancel() &&
              !isUserDeleted(user) ? (
              <IconButton
                bg={colors.error}
                icon={<FontAwesome color="white" name="trash" size={24} />}
                onPress={() => onDelete(appointment)}
              />
            ) : null}
          </UserItem>
        ) : null}
        {!fp.isEmpty(therapistName) ? (
          <VStack>
            <HStack alignSelf="center" pb={3}>
              <Text fontWeight="bold">Terapeuta:</Text>
              <Text>{` ${therapistName}`}</Text>
            </HStack>
            <Divider mt={1} />
          </VStack>
        ) : null}
        {!fp.isEmpty(event) ? <EventItem event={event} /> : null}
      </VStack>
    );
  } else {
    return <></>;
  }
};

CalendarItem.defaultProps = {
  flex: 1,
  w: '100%',
};
