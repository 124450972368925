import { instance } from 'services';
import { IMail } from './types.d';

export const mailingResources = {
  create: (email: IMail, token: string): Promise<void> =>
    instance.post(
      '/mailingAPI',
      { email },
      {
        headers: { authorization: token },
      },
    ),
};
