/* eslint-disable quotes */
import { format } from 'date-fns';
import { HStack, IStackProps, Text } from 'native-base';
import { IEvent } from 'services/resources/events/types.d';

interface IEventItemProps extends IStackProps {
  /**
   * Event to be shown as calendar info.
   */
  event: IEvent;
}

export const EventItem: React.FC<IEventItemProps> = (props): JSX.Element => {
  const { event, ...rest } = props;

  return (
    <HStack bg={event.accentColor} {...rest}>
      <Text color="white">{event.title}</Text>
      <Text color="white" fontWeight="bold" px={2}>
        {`${format(new Date(event.date), "HH:mm 'hrs'")}`}
      </Text>
    </HStack>
  );
};

EventItem.defaultProps = {
  alignItems: 'center',
  h: '70px',
  px: 3,
  w: '100%',
};
