import { IUser } from 'services/resources/users/types.d';

interface IUserState extends IUser {
  /**
   * Flag to indicate if the user is coming from an external site.
   */
  foreignVisit?: boolean;
  /**
   * Logged status of the user.
   */
  logged: boolean;
  /**
   * Firebase token retrieved from auth operation.
   */
  token: string;
}

interface IThemeColors {
  /**
   * Color to be used as main one.
   */
  mainColorDark: string;
  /**
   * Lighter color to be used when opacity present.
   */
  mainColorLight: string;
}

export enum ThemesEnum {
  PEACEFUL_PEAK = 'PEACEFUL_PEAK',
  MELANCHOLY_HILL = 'MELANCHOLY_HILL',
  QUIET_PORT = 'QUIET_PORT',
  SILENT_COAST = ' SILENT_COAST',
  SUNSET_OASIS = 'SUNSET_OASIS',
}

interface IThemeState extends IThemeColors {
  /**
   * Current wallpaper reference, based on the selected theme.
   */
  wallpaperSrc: number;
  /**
   * Currently selected theme.
   */
  currentTheme: ThemesEnum;
  /**
   * Index for selecting a given theme.
   */
  themeNumber: number;
}
