/* eslint-disable quotes */
import { Entypo } from '@expo/vector-icons';
import { AcceptDecline } from 'components/elements';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { Flex, IconButton, IModalProps, Modal, Text, View } from 'native-base';
import { ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { IUser } from 'services/resources/users/types.d';
import { fontSizes } from 'styles/theme';

import { CalendarUserList } from './CalendarUserList';

interface IAppointmentModalProps extends IModalProps {
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Verified therapists.
   */
  therapists: IUser[];
  /**
   * Therapist related to the selected appointment.
   */
  selectedTherapist: IUser;
  /**
   * Function for setting a therapist as selected.
   */
  setSelectedTherapist: (user: IUser) => void;
  /**
   * Flag for indicating if the data is being fetched.
   */
  loading: boolean;
}

export const TherapistsModal: React.FC<IAppointmentModalProps> = (
  props,
): JSX.Element => {
  const {
    onCancel,
    loading,
    selectedTherapist,
    setSelectedTherapist,
    therapists,
    ...rest
  } = props;

  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        bg="white"
        borderRadius={!web ? 0 : 10}
        maxH="90%"
        minH={!web ? '80%' : undefined}
        w={!web ? '100%' : '30%'}
      >
        <IconButton
          alignSelf="flex-end"
          icon={
            <Entypo color={themeData.mainColorDark} name="cross" size={28} />
          }
          mr={3}
          onPress={() => {
            onCancel();
          }}
        />
        {!web ? (
          <>
            <Flex
              alignItems="center"
              alignSelf="center"
              justifyContent="center"
              w="100%"
            >
              <Text
                color={themeData.mainColorDark}
                fontSize={fontSizes.big}
                fontWeight="bold"
                pb={2}
                textAlign="center"
              >
                Selecciona un terapeuta:
              </Text>
            </Flex>
            <CalendarUserList
              displayMode="therapists"
              loading={loading}
              selectedUser={selectedTherapist}
              setSelectedUser={setSelectedTherapist}
              users={therapists}
            />
            {!fp.isEmpty(selectedTherapist) ? (
              <Flex
                alignItems="center"
                alignSelf="center"
                flex={4}
                justifyContent="center"
                w="100%"
              >
                <AcceptDecline
                  disabled={fp.isEmpty(selectedTherapist)}
                  flex={1}
                  maxH={web ? '80px' : '50px'}
                  my={4}
                  onAccept={onCancel}
                  onDecline={() => {
                    setSelectedTherapist({} as IUser);
                    onCancel();
                  }}
                />
              </Flex>
            ) : (
              <View />
            )}
          </>
        ) : (
          <ScrollView showsVerticalScrollIndicator style={{ width: '90%' }}>
            <Flex alignItems="center" justifyContent="center" w="100%">
              <Text
                color={themeData.mainColorDark}
                fontSize={fontSizes.big}
                fontWeight="bold"
                pb={2}
                textAlign="center"
              >
                Selecciona un terapeuta:
              </Text>
            </Flex>
            <CalendarUserList
              displayMode="therapists"
              loading={loading}
              selectedUser={selectedTherapist}
              setSelectedUser={setSelectedTherapist}
              users={therapists}
            />
            {!fp.isEmpty(selectedTherapist) ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                mt="70px"
                w="100%"
              >
                <AcceptDecline
                  disabled={fp.isEmpty(selectedTherapist)}
                  flex={1}
                  maxH={web ? '80px' : '50px'}
                  my={4}
                  onAccept={onCancel}
                  onDecline={() => {
                    setSelectedTherapist({} as IUser);
                    onCancel();
                  }}
                />
              </Flex>
            ) : null}
          </ScrollView>
        )}
      </Modal.Content>
    </Modal>
  );
};

TherapistsModal.defaultProps = {
  size: 'full',
};
