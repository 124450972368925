import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { removeSession } from 'helpers/storage-helpers/session-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import { Button, IModalProps, Modal } from 'native-base';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTheme, setLogged } from 'redux-service/slices';
import { authResources } from 'services/resources/auth';

interface MenuModalProps extends IModalProps {
  /**
   * State handler for the moment the menu is hide.
   */
  toggleMenuVisible: () => void;
}

export const MenuModal: React.FC<MenuModalProps> = (props): JSX.Element => {
  const { toggleMenuVisible, ...rest } = props;
  const { web } = usePlatform();

  const themeData = useSelector(getTheme);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const customW = !web ? '100%' : '20%';

  /**
   * Function for handling the screen change when pressing the menu buttons.
   * @param screen
   */
  const handleScreenChange = (screen: string): void => {
    toggleMenuVisible();
    if (screen === 'logIn') {
      dispatch(setLogged(false));
      authResources.signOut();
      removeSession();
    }
    navigation.navigate(screen as never);
  };

  return (
    <Modal {...rest}>
      <Modal.Content
        alignItems="center"
        bg="transparent"
        justifyContent="center"
        position="absolute"
        px={3}
        shadow="none"
      >
        <Button
          bg={themeData.mainColorDark}
          leftIcon={
            <Ionicons
              color="white"
              name="settings-sharp"
              size={24}
              style={{ marginRight: 10 }}
            />
          }
          my={2}
          onPress={() => handleScreenChange('account')}
          w={customW}
        >
          Configuración de cuenta
        </Button>
        <Button
          bg={themeData.mainColorDark}
          leftIcon={
            <FontAwesome
              color="white"
              name="power-off"
              size={24}
              style={{ marginRight: 10 }}
            />
          }
          my={2}
          onPress={() => handleScreenChange('logIn')}
          w={customW}
        >
          Cerrar sesión
        </Button>
        <Button
          bg={themeData.mainColorDark}
          leftIcon={
            <Ionicons
              color="white"
              name="arrow-back"
              size={24}
              style={{ marginRight: 10 }}
            />
          }
          my={2}
          onPress={toggleMenuVisible}
          w={customW}
        >
          Regresar
        </Button>
      </Modal.Content>
    </Modal>
  );
};

MenuModal.defaultProps = {
  size: 'full',
};
