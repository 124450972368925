import { instance } from 'services';
import { ILogEntry } from './types.d';

export const logsResources = {
  create: (payload: ILogEntry, token: string): Promise<void> =>
    instance.post(
      '/logsAPI',
      { log: payload },
      { headers: { authorization: token } },
    ),
};
