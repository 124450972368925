import { Actionsheet, IActionsheetProps } from 'native-base';
import { colors } from 'styles/colors';

interface IStatusSheetProps extends IActionsheetProps {
  /**
   * Flag for indicating if an error was tracked on the operation.
   */
  error?: boolean;
}

export const StatusSheet: React.FC<IStatusSheetProps> = (
  props,
): JSX.Element => {
  const { error = false, children, ...rest } = props;
  return (
    <Actionsheet {...rest}>
      <Actionsheet.Content
        _text={{ color: 'white', fontWeight: 'bold' }}
        bg={error ? colors.error : colors.success}
        pb={6}
      >
        {children}
      </Actionsheet.Content>
    </Actionsheet>
  );
};
