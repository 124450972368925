import { MaterialIcons } from '@expo/vector-icons';
import { FormattedEmailField } from 'components/inputs';
import { Formik } from 'formik';
import { Button, IStackProps, VStack } from 'native-base';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { fontSizes } from 'styles/theme';
import {
  INITIAL_VALUES,
  SearchUserFormValidationSchema,
} from './helpers/form-helpers';

interface ILoginFormProps extends IStackProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: { email: string }) => void;
}

export const SearchUserForm: React.FC<ILoginFormProps> = (props) => {
  const { onSubmit, ...rest } = props;
  const themeData = useSelector(getTheme);
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={SearchUserFormValidationSchema}
    >
      {({ errors, handleChange, isValid, handleSubmit, values }) => (
        <VStack {...rest}>
          <FormattedEmailField
            alignSelf="center"
            borderColor={themeData.mainColorDark}
            borderWidth={1}
            error={errors.email}
            focusOutlineColor={themeData.mainColorDark}
            onChangeText={handleChange('email')}
            w="90%"
          />
          <Button
            _text={{
              color: 'white',
              fontSize: fontSizes.regular,
              fontWeight: 'bold',
            }}
            bg={themeData.mainColorDark}
            borderRadius={15}
            isDisabled={!isValid || values.email === ''}
            leftIcon={
              <MaterialIcons
                color="white"
                name="search"
                size={24}
                style={{ marginRight: 10 }}
              />
            }
            mb={8}
            mt={10}
            mx={10}
            onPress={() => handleSubmit()}
          >
            Buscar usuario
          </Button>
        </VStack>
      )}
    </Formik>
  );
};

SearchUserForm.defaultProps = {
  justifyContent: 'center',
  w: '100%',
};
