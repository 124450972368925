import { Divider, IStackProps, Text, VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { genericContent } from '../helpers/content-helpers';

export const SwipperItem: React.FC<IStackProps> = (props): JSX.Element => {
  const { children, ...rest } = props;

  const themeData = useSelector(getTheme);
  return (
    <VStack bg={themeData.mainColorDark} {...rest}>
      <Text color="white" fontWeight="bold" px={6} py={6} textAlign="center">
        {children}
      </Text>
      <Divider />
      <Text color="white" pt={3} px={6} py={6} textAlign="center">
        {genericContent}
      </Text>
    </VStack>
  );
};

SwipperItem.defaultProps = {
  alignSelf: 'center',
  borderRadius: 5,
  justifyContent: 'space-between',
  w: '90%',
};
