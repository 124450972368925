import { instance } from 'services';
import { IExpoNotification, INotificationsResponse } from './types.d';

export const notificationsResources = {
  create: (
    payload: IExpoNotification,
    target: string,
    token: string,
  ): Promise<void> =>
    instance.post(
      '/notificationsAPI',
      { notification: payload },
      {
        headers: { authorization: token },
        params: { user: target },
      },
    ),

  deleteAll: (user: string, token: string): Promise<void> =>
    instance.delete('/notificationsAPI', {
      headers: { authorization: token },
      params: { user },
    }),

  get: (user: string, token: string): Promise<INotificationsResponse> =>
    instance.get('/notificationsAPI', {
      headers: { authorization: token },
      params: { user },
    }),

  putRead: (notification: string, token: string): Promise<void> =>
    instance.put(
      '/notificationsAPI',
      {},
      {
        headers: { authorization: token },
        params: { notification },
      },
    ),
};
