import { IMarkedDate } from 'components/elements/CalendarSelector/types.d';
import { parseISO } from 'date-fns';
import fp from 'lodash/fp';
import { IAppointmentWithFullNames } from 'services/resources/appointments/types.d';
import { IAppointmentWithInfoAndNames } from 'services/resources/appointments-info/types.d';

import { IManagedAppointment } from '../types.d';

/**
 * Function that from appointments, retrieve the dates and assign a style
 * option index.
 * @param appointmentsWithUser
 * @returns dates to mark
 */
export const createDatesToMark = (
  appointments: IAppointmentWithFullNames[],
): IMarkedDate[] => {
  return appointments.map((appointmentWithFullNames) => {
    return {
      date: new Date(appointmentWithFullNames.appointment.date),
      styleOption: 0,
    };
  });
};

/**
 * Function that sorts a managed appointments' array by its appointment.date
 * property.
 * @param appointments
 * @param inverted
 * @returns sorted managed appointments by date.
 */
const sortNestedAppointmentsByDate = (
  appointments: IManagedAppointment[],
  inverted: boolean = false,
): IManagedAppointment[] => {
  return appointments.sort((a, b) => {
    const aDate = parseISO(a.item.appointment.date as string);
    const bDate = parseISO(b.item.appointment.date as string);
    if (!inverted) {
      return Number(aDate) - Number(bDate);
    } else {
      return Number(bDate) - Number(aDate);
    }
  });
};

/**
 * Function that creates a whole data array for populating a FlatList with
 * managed appointments' data.
 * @param allConfirmedAppointments
 * @param appointmentInfoWithNames
 * @returns managed appointments
 */
export const getManagedAppointmentsToSet = (
  allConfirmedAppointments: IAppointmentWithFullNames[] = [],
  appointmentInfoWithNames: IAppointmentWithInfoAndNames[] = [],
): IManagedAppointment[] => {
  // eslint-disable-next-line prefer-const
  let managedAppointments: IManagedAppointment[] = [];

  if (!fp.isEmpty(allConfirmedAppointments)) {
    allConfirmedAppointments.forEach((confirmedAppointment) => {
      // Create boolean to determine if the current confirmedAppointment
      // is already present in the appointments' info, in order to avoid
      // duplicated entries
      const appointmentAlreadyInInfo = !fp.isEmpty(appointmentInfoWithNames)
        ? appointmentInfoWithNames.some((appointmentInfo) => {
            if (
              appointmentInfo.appointment.id ===
              confirmedAppointment.appointment.id
            ) {
              return true;
            } else return false;
          })
        : false;
      if (!appointmentAlreadyInInfo) {
        managedAppointments.push({
          item: confirmedAppointment,
          itemType: 'appointment',
        });
      }
    });
  }

  if (!fp.isEmpty(appointmentInfoWithNames)) {
    appointmentInfoWithNames.forEach((appointmentInfoWithNames) => {
      managedAppointments.push({
        item: appointmentInfoWithNames,
        itemType: 'appointmentInfo',
      });
    });
  }

  const orderedManagedAppointments =
    sortNestedAppointmentsByDate(managedAppointments);

  return orderedManagedAppointments;
};
