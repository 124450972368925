import { UncontrolledInputField } from 'components/inputs';
import { IUncontrolledInputProps } from 'components/inputs/types.d';
import { usePlatform } from 'hooks/platform-hooks';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';

interface IUncontrolledDasboardInputFieldProps extends IUncontrolledInputProps {
  /**
   * Ref to be applied to inner component.
   */
  innerRef: any;
}

export const UncontrolledDashboardInputField: React.FC<
  IUncontrolledDasboardInputFieldProps
> = (props): JSX.Element => {
  const { innerRef, ...rest } = props;
  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  return (
    <UncontrolledInputField
      focusOutlineColor={themeData.mainColorDark}
      innerRef={innerRef}
      placeholderTextColor={themeData.mainColorDark}
      w={!web ? '90%' : '96%'}
      {...rest}
    />
  );
};

UncontrolledDashboardInputField.defaultProps = {
  _focus: { bg: 'white' },
  mx: 2,
};
