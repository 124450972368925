import { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';

export const useKeyboardShow = (): { hidden: boolean; visible: boolean } => {
  const [visible, setVisible] = useState(false);
  const [hidden, setHidden] = useState(true);

  const onKeyboardDidShow = (): void => {
    setVisible(true);
    setHidden(false);
  };

  const onKeyboardDidHide = (): void => {
    setVisible(false);
    setHidden(true);
  };

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      onKeyboardDidShow,
    );
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      onKeyboardDidHide,
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  return { hidden, visible };
};
