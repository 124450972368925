import { InputField } from 'components/inputs';
import { IInputFieldProps } from 'components/inputs/types.d';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { fontSizes } from 'styles/theme';

export const FormattedInputField: React.FC<IInputFieldProps> = (props) => {
  const { ...rest } = props;
  const themeData = useSelector(getTheme);
  return (
    <InputField
      _focus={{ bg: 'white' }}
      bg="white"
      borderColor="transparent"
      borderRadius={15}
      color={themeData.mainColorDark}
      focusOutlineColor="transparent"
      fontSize={fontSizes.regular}
      placeholderTextColor={themeData.mainColorDark}
      textAlign="center"
      {...rest}
    />
  );
};
