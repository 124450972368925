import { useFocusEffect } from '@react-navigation/native';
import {
  ExtendedFlatlist,
  LoadingStatusModal,
  UIWrapper,
  UserItem,
} from 'components/elements';
import fp from 'lodash/fp';
import { VStack } from 'native-base';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setUserData } from 'redux-service/slices';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { userResources } from 'services/resources/users';
import { IUser } from 'services/resources/users/types.d';
import { ILoadingData } from 'types.d';

export const PatientTherapists: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);
  const dispatch = useDispatch();

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [therapists, setTherapists] = useState<IUser[]>([]);

  /**
   * Function for retrieving the therapists of a given patient and writing them
   * to state.
   */
  const retrieveTherapists = async (): Promise<void> => {
    setLoadingData({ loading: true, loadingMessage: 'Cargando Terapeutas...' });
    try {
      const { data: d } = await userResources.getTherapists(
        userData.id,
        userData.token,
      );
      const data = d as string[];
      let retrievedTherapists: IUser[] = [];
      // We verify that there is at least one therapist
      if (data.length > 0) {
        // We wait until all the promises are resolved or one is rejected
        retrievedTherapists = await Promise.all(
          data.map(async (patient) => {
            const { data } = await userResources.getById(
              patient,
              userData.token,
            );
            return data as IUser;
          }),
        );
      }
      // Only write states if therapists changed
      if (!fp.isEmpty(retrievedTherapists)) {
        // Write redux state
        dispatch(setUserData({ ...userData, therapists: userData.therapists }));
        setTherapists(retrievedTherapists);
      } else {
        // Otherwise ovewrite with empty array
        setTherapists([]);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'patient-dashboard',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  useFocusEffect(
    useCallback(() => {
      retrieveTherapists();
    }, [userData.patients]),
  );

  return (
    <UIWrapper title="Paciente">
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      <VStack bg="white" flex={1}>
        <ExtendedFlatlist
          data={therapists}
          noDataMessage="No se encontraron terapeutas registrados."
          renderItem={<UserItem />}
          searchBarPlaceholder="terapeutas"
          searchKey={['firstName', 'fatherName', 'motherName']}
          sort
          sortKey="firstName"
          useSearchBar
        />
      </VStack>
    </UIWrapper>
  );
};
