import { InputScroller } from 'components/elements';
import { PredefinedTaskForm } from 'components/forms';
import { usePlatform } from 'hooks/platform-hooks';
import { IModalProps, Modal } from 'native-base';
import {
  IPredefinedTask,
  IPredefinedTaskPayload,
} from 'services/resources/tasks/types.d';

interface IPredefinedTaskModalProps extends IModalProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IPredefinedTaskPayload | IPredefinedTask) => void;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Values that are already set, used for editing task.
   */
  previousValues?: IPredefinedTask;
}

export const PredefinedTaskModal: React.FC<IPredefinedTaskModalProps> = (
  props,
): JSX.Element => {
  const { onSubmit, onCancel, previousValues = undefined, ...rest } = props;

  const { web } = usePlatform();

  return (
    <Modal {...rest}>
      {!web ? (
        <InputScroller
          bothOffsets
          contentContainerStyle={{
            width: '100%',
          }}
        >
          <Modal.Content alignItems="center" bg="white" borderRadius={0}>
            <PredefinedTaskForm
              onCancel={onCancel}
              onSubmit={onSubmit}
              previousValues={previousValues}
            />
          </Modal.Content>
        </InputScroller>
      ) : (
        <Modal.Content alignItems="center" bg="white" borderRadius={10} w="30%">
          <PredefinedTaskForm
            onCancel={onCancel}
            onSubmit={onSubmit}
            previousValues={previousValues}
          />
        </Modal.Content>
      )}
    </Modal>
  );
};

PredefinedTaskModal.defaultProps = {
  avoidKeyboard: true,
  size: 'full',
};
