import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { ITextProps, Text } from 'native-base';
import { Dimensions } from 'react-native';
import urlRegexSafe from 'url-regex-safe';

import { RNLink as Link } from '../RNLink';

interface ILinkParserProps extends ITextProps {
  /**
   * Text to be rendered inside the component.
   */
  children: string;
}

export const LinkParser: React.FC<ILinkParserProps> = (props): JSX.Element => {
  const { children = '', ...rest } = props;
  const { android } = usePlatform();

  // Split all words in a string array
  const stringArray = children.split(' ');

  return (
    <Text textAlign={!android ? 'justify' : 'center'} {...rest}>
      {stringArray.map((string) => {
        return (
          <Text
            alignItems="center"
            flex={1}
            key={`${string}+${Date.now()}+${Math.random()}`}
            maxW={`${Dimensions.get('screen').width - 10}px`}
          >
            {fp.isNil(string.match(urlRegexSafe())) ? (
              `${string} `
            ) : (
              <Link href={string}>{`${string} `}</Link>
            )}
          </Text>
        );
      })}
    </Text>
  );
};

LinkParser.defaultProps = {
  mb: 2,
  mx: 2,
};
