import { InputScroller } from 'components/elements';
import { TaskForm } from 'components/forms';
import { usePlatform } from 'hooks/platform-hooks';
import { IModalProps, Modal } from 'native-base';
import {
  IPredefinedTask,
  IPredefinedTaskPayload,
  ITask,
  ITaskPayload,
} from 'services/resources/tasks/types.d';

import { PredefinedTasksModal } from './PredefinedTasksModal';

interface ITaskModalProps extends IModalProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (
    payload: ITaskPayload | ITask,
    predefined: IPredefinedTaskPayload,
  ) => void;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Values that are already set, used for editing task.
   */
  previousValues?: ITask;
  /**
   * Tasks that exist already in the database.
   */
  predefinedTasks: IPredefinedTask[];
  /**
   * Function for giving a name to to the predefined task in case we want
   * to add one.
   */
  onPredefinedNameChange: (name: string) => void;
  /**
   * Function for handling an item deletion on database.
   */
  onPredefinedDelete: (task: IPredefinedTask) => void;
  /**
   * Function for handling an item edition on database.
   */
  onPredefinedEdit: (task: IPredefinedTask) => void;
  /**
   * Function for handling item creation in database.
   */
  onPredefinedCreate: (task: IPredefinedTaskPayload) => void;
  /**
   * Function for showing/hiding tasks' modal.
   */
  togglePredefinedTasksModalVisible: () => void;
  /**
   * Flag to indicate weather to show or hide the modal.
   */
  predefinedTasksModalVisible: boolean;
  /**
   * State for the selected predefined task.
   */
  selectedPredefined: IPredefinedTask;
  /**
   * State setter for selected predefined task.
   */
  setSelectedPredefined: (predefined: IPredefinedTask) => void;
}

export const TaskModal: React.FC<ITaskModalProps> = (props): JSX.Element => {
  const {
    predefinedTasks,
    onSubmit,
    onCancel,
    previousValues = undefined,
    onPredefinedNameChange,
    onPredefinedCreate,
    onPredefinedDelete,
    onPredefinedEdit,
    togglePredefinedTasksModalVisible,
    selectedPredefined,
    setSelectedPredefined,
    predefinedTasksModalVisible,
    ...rest
  } = props;

  const { web } = usePlatform();

  return (
    <Modal {...rest}>
      {!web ? (
        <InputScroller
          bothOffsets
          contentContainerStyle={{
            width: '100%',
          }}
        >
          <Modal.Content alignItems="center" bg="white" borderRadius={0}>
            <PredefinedTasksModal
              isOpen={predefinedTasksModalVisible}
              onClose={() => setSelectedPredefined({} as IPredefinedTask)}
              onCreate={onPredefinedCreate}
              onDelete={onPredefinedDelete}
              onEdit={onPredefinedEdit}
              onSelect={(payload) => {
                setSelectedPredefined(payload);
                togglePredefinedTasksModalVisible();
              }}
              predefinedTasks={predefinedTasks}
              predefinedTasksModalVisible={predefinedTasksModalVisible}
              selectedPredefined={selectedPredefined}
              setSelectedPredefined={setSelectedPredefined}
              togglePredefinedTasksModalVisible={
                togglePredefinedTasksModalVisible
              }
              toggleTasksModalVisible={togglePredefinedTasksModalVisible}
            />
            <TaskForm
              onCancel={() => {
                onCancel();
                setSelectedPredefined({} as IPredefinedTask);
              }}
              onPredefinedNameChange={onPredefinedNameChange}
              onSubmit={(payload) => {
                onSubmit(payload, selectedPredefined);
              }}
              previousValues={previousValues}
              selectedPredefined={selectedPredefined}
              togglePredefinedTasksModal={togglePredefinedTasksModalVisible}
            />
          </Modal.Content>
        </InputScroller>
      ) : (
        <Modal.Content alignItems="center" bg="white" borderRadius={10} w="30%">
          <Modal.Content alignItems="center" bg="white" borderRadius={0}>
            <PredefinedTasksModal
              isOpen={predefinedTasksModalVisible}
              onClose={() => setSelectedPredefined({} as IPredefinedTask)}
              onCreate={onPredefinedCreate}
              onDelete={onPredefinedDelete}
              onEdit={onPredefinedEdit}
              onSelect={(payload) => {
                setSelectedPredefined(payload);
                togglePredefinedTasksModalVisible();
              }}
              predefinedTasks={predefinedTasks}
              predefinedTasksModalVisible={predefinedTasksModalVisible}
              selectedPredefined={selectedPredefined}
              setSelectedPredefined={setSelectedPredefined}
              togglePredefinedTasksModalVisible={
                togglePredefinedTasksModalVisible
              }
              toggleTasksModalVisible={togglePredefinedTasksModalVisible}
            />
            <TaskForm
              onCancel={() => {
                onCancel();
                setSelectedPredefined({} as IPredefinedTask);
              }}
              onPredefinedNameChange={onPredefinedNameChange}
              onSubmit={(payload) => {
                onSubmit(payload, selectedPredefined);
                setSelectedPredefined({} as IPredefinedTask);
              }}
              previousValues={previousValues}
              selectedPredefined={selectedPredefined}
              togglePredefinedTasksModal={togglePredefinedTasksModalVisible}
            />
          </Modal.Content>
        </Modal.Content>
      )}
    </Modal>
  );
};

TaskModal.defaultProps = {
  avoidKeyboard: true,
  size: 'full',
};
