import { Ionicons } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import { ExtendedFlatlist, UIWrapper } from 'components/elements';
import { IOption } from 'components/elements/types.d';
import { formatISO } from 'date-fns';
import FormData from 'form-data';
import { sortObjectArrayByNestedDate } from 'helpers/data-helpers/sorting-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { IconButton, VStack } from 'native-base';
import { useCallback, useState } from 'react';
import { Linking } from 'react-native';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import { appointmentsInfoResources } from 'services/resources/appointments-info';
import { IAppointmentWithInfoTaxDataAndNames } from 'services/resources/appointments-info/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { reportsGenerationResources } from 'services/resources/report-generation';
import { IAppointmentsStatsPayload } from 'services/resources/report-generation/types.d';
import { IReportFileType } from 'services/types.d';
import { ILoadingData } from 'types.d';

import { AppointmentStatisticItem } from './components/AppointmentStatisticItem';
import { FilteringModal } from './components/FilteringModal';
import { Statistics } from './components/Statistics';
import { dateOptions } from './helpers/constant-helpers';
import {
  getSelectedPeriodFormattedDate,
  getTranslatedDateOption,
} from './helpers/data-helpers';
import { getAllFilteredAppointmentInfo } from './helpers/filter-helpers';
import { IFilterQueryProps } from './types.d';

export const AppointmentsStatistics: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);
  const { web } = usePlatform();

  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [
    appointmentsWithInfoTaxDataAndNames,
    setAppointmentsWithInfoTaxDataAndNames,
  ] = useState<IAppointmentWithInfoTaxDataAndNames[]>(
    [] as IAppointmentWithInfoTaxDataAndNames[],
  );
  const [queriedDate, setQueriedDate] = useState<Date>(new Date());
  const [appointmentsTherapists, setAppointmentsTherapists] = useState<
    string[]
  >([]);
  const [filteringModalVisible, setFilteringModalVisible] =
    useState<boolean>(true);
  const [filterQuery, setFilterQuery] = useState<IFilterQueryProps>(
    {} as IFilterQueryProps,
  );
  const [selectedDateOption, setSelectedDateOption] = useState<IOption>(
    dateOptions[0],
  );

  const toggleFilteringModalVisible = (): void => {
    setFilteringModalVisible(!filteringModalVisible);
  };

  /**
   * Function that from a given appointments with info and names input,
   * retrieves all the detected appointments, to be used in the corresponding
   * filter.
   * @param appointmentsWithInfoAndNames
   */
  const populateFilterTherapistsOptions = (
    appointmentsWithInfoAndNames: IAppointmentWithInfoTaxDataAndNames[],
  ): void => {
    if (!fp.isEmpty(appointmentsWithInfoAndNames)) {
      setAppointmentsTherapists([
        ...new Set(
          appointmentsWithInfoAndNames.map((aWIAN) => {
            return aWIAN.therapistFullName;
          }),
        ),
      ]);
    }
  };

  /**
   * Function that retrieves appointments with their info and names, with a given
   * date and a date option, gaining optimization with this approach.
   * @param date
   * @param selectedOption
   */
  const retrieveAppointmentsWithInfoTaxDataAndNames = async (
    date: Date,
    selectedOption: string,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Citas...',
    });
    try {
      const { data } =
        await appointmentsInfoResources.getCompletedWithNamesByFixedDate(
          formatISO(date),
          selectedOption,
          userData.token,
        );
      const d = data as IAppointmentWithInfoTaxDataAndNames[];
      if (!fp.isNil(d)) {
        setAppointmentsWithInfoTaxDataAndNames(d);
        populateFilterTherapistsOptions(d);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'AppointmentsStatistics-retrieveAppointmentsWithInfoAndNames',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that from a given appointments stats' payload and a file type,
   * generates the corresponding reports.
   * @param payload
   * @param fileType
   */
  const handleOnAdministrativeReportCreate = async (
    payload: IAppointmentsStatsPayload,
    fileType: IReportFileType,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Generando Reporte...',
    });
    // Since we might deal with lots of data, we use a FormData()
    const formData = new FormData();
    formData.append('data', JSON.stringify(payload.appointmentsData));
    formData.append('stats', JSON.stringify(payload.appointmentsStats));
    formData.append('fileType', JSON.stringify({ fileType: `${fileType}` }));

    try {
      if (!web) {
        // Based on the filetype we send the selected period or don't
        const { data } = await reportsGenerationResources.createMobile(
          formData,
          fileType === 'pdf'
            ? `Por ${getTranslatedDateOption(
                selectedDateOption,
              )}, ${getSelectedPeriodFormattedDate(
                selectedDateOption,
                queriedDate,
              )}`
            : undefined,
          'administrative',
          userData.token,
        );
        const d = data as string;
        // If the result is valid, automatically open it
        if (!fp.isNil(d) && !fp.isEmpty(d) && (await Linking.canOpenURL(d))) {
          await Linking.openURL(d);
        }
      } else {
        // Based on the filetype we send the selected period or don't
        const { data } = await reportsGenerationResources.createWeb(
          formData,
          fileType === 'pdf'
            ? `Por ${getTranslatedDateOption(
                selectedDateOption,
              )}, ${getSelectedPeriodFormattedDate(
                selectedDateOption,
                queriedDate,
              )}`
            : undefined,
          'administrative',
          userData.token,
        );
        const d = data as string;
        // If the result is valid, automatically open it
        if (!fp.isNil(d) && !fp.isEmpty(d) && (await Linking.canOpenURL(d))) {
          await Linking.openURL(d);
        }
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'AppointmentsStatistics-handleOnReportCreate',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  const handleOnAccountantReportCreate = async (
    fileType: IReportFileType,
  ): Promise<void> => {
    // Since we might deal with lots of data, we use a FormData()
    const formData = new FormData();
    formData.append('data', JSON.stringify(filteredData));
    formData.append('fileType', JSON.stringify({ fileType: `${fileType}` }));
    try {
      if (!web) {
        // Based on the filetype we send the selected period or don't
        const { data } = await reportsGenerationResources.createMobile(
          formData,
          undefined,
          'accountant',
          userData.token,
        );
        const d = data as string;
        // If the result is valid, automatically open it
        if (!fp.isNil(d) && !fp.isEmpty(d) && (await Linking.canOpenURL(d))) {
          await Linking.openURL(d);
        }
      } else {
        // Based on the filetype we send the selected period or don't
        const { data } = await reportsGenerationResources.createWeb(
          formData,
          undefined,
          'accountant',
          userData.token,
        );
        const d = data as string;
        // If the result is valid, automatically open it
        if (!fp.isNil(d) && !fp.isEmpty(d) && (await Linking.canOpenURL(d))) {
          await Linking.openURL(d);
        }
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'AppointmentsStatistics-handleOnAccountantReportCreate',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
  };

  /**
   * Function that handles both: date option state set and retrieves
   * data based on the new given date.
   * @param newDate
   * @param selectedOption
   */
  const handleDateChange = (newDate: Date, selectedOption: string): void => {
    setQueriedDate(newDate);
    retrieveAppointmentsWithInfoTaxDataAndNames(newDate, selectedOption);
  };

  useFocusEffect(
    useCallback(() => {
      retrieveAppointmentsWithInfoTaxDataAndNames(queriedDate, 'day');
    }, []),
  );

  const filteredData = !fp.isEmpty(appointmentsWithInfoTaxDataAndNames)
    ? sortObjectArrayByNestedDate(
        getAllFilteredAppointmentInfo(
          appointmentsWithInfoTaxDataAndNames,
          filterQuery,
        ),
        'appointment',
        undefined,
        'date',
        true,
      )
    : getAllFilteredAppointmentInfo(
        appointmentsWithInfoTaxDataAndNames,
        filterQuery,
      );

  // Condition that indicates if an accountant report can be generated
  const accountantDataAvailable =
    !fp.isEmpty(filteredData) &&
    !fp.isNil(filterQuery.invoiceRequest) &&
    filterQuery.invoiceRequest &&
    selectedDateOption.value === 'month';

  return (
    <UIWrapper title="Estadísticas de Citas">
      <VStack alignItems="center" backgroundColor="white" h="100%" w="100%">
        <FilteringModal
          appointmentsTherapists={appointmentsTherapists}
          dateOptionChange={(newOption) =>
            handleDateChange(queriedDate, newOption)
          }
          filterQuery={filterQuery}
          isOpen={filteringModalVisible}
          loadingData={loadingData}
          onClose={toggleFilteringModalVisible}
          selectedDateOption={selectedDateOption}
          selectedQueryDate={queriedDate}
          setFilterQuery={setFilterQuery}
          setSelectedDateOption={setSelectedDateOption}
          setSelectedQueryDate={(d, o) => handleDateChange(d, o)}
        />
        {!fp.isEmpty(filteredData) ? (
          <Statistics
            accountantDataAvailable={accountantDataAvailable}
            data={filteredData as IAppointmentWithInfoTaxDataAndNames[]}
            flex={1}
            onGenerateAccountantReport={handleOnAccountantReportCreate}
            onGenerateAdministrativeReport={handleOnAdministrativeReportCreate}
            w="100%"
          />
        ) : null}
        <ExtendedFlatlist
          data={filteredData}
          flex={!web ? 1 : 3}
          noDataMessage="No se encontraron citas con los criterios seleccionados."
          renderItem={<AppointmentStatisticItem />}
          useSearchBar={false}
          w="100%"
        />
        <IconButton
          alignItems="center"
          bg={themeData.mainColorDark}
          borderColor="white"
          borderWidth="1px"
          bottom={6}
          icon={<Ionicons color="white" name="filter" size={24} />}
          onPress={() => toggleFilteringModalVisible()}
          position="absolute"
        />
      </VStack>
    </UIWrapper>
  );
};
