import { Entypo, MaterialIcons } from '@expo/vector-icons';
import { getMultipleImagePickerURIs } from 'helpers/data-helpers/picker-helpers';
import fp from 'lodash/fp';
import {
  Button,
  FlatList,
  HStack,
  IconButton,
  Image,
  IStackProps,
  Text,
  VStack,
  WarningOutlineIcon,
} from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { colors } from 'styles/colors';

interface IMultipleFilesFieldProps extends IStackProps {
  /**
   * State for multiple files.
   */
  multipleFilesURIs?: string[];
  /**
   * State setter for multiple files.
   * @param files
   * @returns
   */
  setMultipleFilesURIs?: (files: string[]) => void;
  /**
   * Function to be triggered when a file is deleted. Might be useful to handle
   * file deletion on database and/or services.
   */
  onRemoveFile?: (index: number) => void;
  /**
   * Error detected if validation schema is provided.
   */
  error?: string;
  /**
   * Flag to indicate weather disabling the button or not.
   */
  disabled?: boolean;
}

export const MultipleFilesField: React.FC<IMultipleFilesFieldProps> = (
  props,
): JSX.Element => {
  const {
    disabled = false,
    error = '',
    children,
    multipleFilesURIs = [],
    setMultipleFilesURIs = () => {},
    onRemoveFile = () => {},
    ...rest
  } = props;

  const themeData = useSelector(getTheme);

  const handleMultipleFileSelect = async (): Promise<void> => {
    const imagesURIs = await getMultipleImagePickerURIs();
    setMultipleFilesURIs(imagesURIs);
  };

  const handleMultipleFileUnattach = (index: number): void => {
    const snapshot = [...multipleFilesURIs];
    onRemoveFile(index);
    snapshot.splice(index, 1);
    setMultipleFilesURIs(snapshot);
  };

  return (
    <VStack {...rest}>
      <Button
        bg={themeData.mainColorDark}
        disabled={disabled}
        leftIcon={<MaterialIcons color="white" name="attach-file" size={24} />}
        onPress={() => handleMultipleFileSelect()}
        opacity={disabled ? 0.5 : 1}
      >
        {children}
      </Button>
      {!fp.isEmpty(error) ? (
        <HStack alignSelf="center" mt={2}>
          <WarningOutlineIcon color={colors.error} mt={1} size="xs" />
          <Text color={colors.error} fontSize={12} ml={1} textAlign="center">
            {error}
          </Text>
        </HStack>
      ) : null}
      {multipleFilesURIs.length > 0 ? (
        <FlatList
          data={multipleFilesURIs}
          horizontal
          keyExtractor={(item) => item}
          renderItem={({ item, index }) => (
            <VStack alignItems="center" key={item}>
              <Image
                alt="media-item"
                borderRadius={8}
                mt={2}
                mx={1}
                size="sm"
                src={item}
              />
              <IconButton
                icon={<Entypo color="red" name="circle-with-cross" size={24} />}
                onPress={() => handleMultipleFileUnattach(index)}
              />
            </VStack>
          )}
          showsHorizontalScrollIndicator
          style={{ alignSelf: 'center' }}
        />
      ) : null}
    </VStack>
  );
};

MultipleFilesField.defaultProps = {
  flex: 1,
  textAlign: 'center',
  w: '100%',
};
