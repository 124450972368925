import { FormattedPasswordField } from 'components/inputs';
import { Formik } from 'formik';
import { useSmallScreen } from 'hooks/dimensions-hooks';
import { usePlatform } from 'hooks/platform-hooks';
import { Button, IModalProps, Modal, VStack } from 'native-base';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { colors } from 'styles/colors';
import { buttonSizes, fontSizes } from 'styles/theme';

import {
  INITIAL_VALUES,
  PasswordFormValidationSchema,
} from './helpers/form-helpers';
import { IPasswordPayload } from './types.d';

interface IPasswordFormProps extends IModalProps {
  /**
   * Flag to indicate weather to show the form or not.
   */
  isFormVisible: boolean;
  /**
   * Function for handling the form sbmission.
   */
  onSubmit: (payload: IPasswordPayload) => Promise<void>;
}

export const PasswordForm: React.FC<IPasswordFormProps> = (props) => {
  const { isFormVisible, onSubmit, ...rest } = props;
  const themeData = useSelector(getTheme);
  const small = useSmallScreen();
  const { web } = usePlatform();

  const [modalHeight, setModalHeight] = useState<number>(0);

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={PasswordFormValidationSchema}
    >
      {({ errors, handleChange, isValid, handleSubmit, values }) => (
        <Modal isOpen={isFormVisible} {...rest}>
          <Modal.Content
            alignItems="center"
            bg={themeData.mainColorDark}
            onLayout={(e) => setModalHeight(e.nativeEvent.layout.height)}
            w={!web ? '100%' : '30%'}
          >
            <VStack mt={modalHeight / 6} space={8} w={'80%'}>
              <FormattedPasswordField
                error={errors.password}
                onChangeText={handleChange('password')}
                overflow="hidden"
              />
              <FormattedPasswordField
                error={errors.passwordConfirmation}
                onChangeText={handleChange('passwordConfirmation')}
                overflow="hidden"
              />
              <Button
                _disabled={{
                  _text: { color: colors.disabled },
                  bg: themeData.mainColorLight,
                }}
                _text={{
                  color: themeData.mainColorDark,
                  fontSize: !small ? fontSizes.regular : fontSizes.small,
                  fontWeight: 'bold',
                }}
                bg="white"
                borderRadius={15}
                h={!small ? undefined : buttonSizes.small}
                isDisabled={!isValid || values.passwordConfirmation === ''}
                mb={20}
                mt={8}
                mx={10}
                onPress={() => handleSubmit()}
              >
                Crear Cuenta
              </Button>
            </VStack>
          </Modal.Content>
        </Modal>
      )}
    </Formik>
  );
};

PasswordForm.defaultProps = {
  justifyContent: 'center',
  size: 'full',
};
