import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
} from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { IconTextButton, UIWrapper } from 'components/elements';
import { usePlatform } from 'hooks/platform-hooks';
import { HStack, Text, VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { colors } from 'styles/colors';

export const TherapistDashboard: React.FC = (): JSX.Element => {
  const themeData = useSelector(getTheme);
  const navigation = useNavigation();
  const { web, ios } = usePlatform();

  const handleScreenChange = (screen: string): void => {
    navigation.navigate(screen as never);
  };

  return (
    <UIWrapper title="Terapeuta">
      <Text
        color={themeData.mainColorDark}
        flex={1}
        fontSize={22}
        fontWeight="bold"
        pt={3}
        textAlign="center"
      >
        Selecciona un dashboard:
      </Text>
      <VStack
        alignItems="center"
        flex={7}
        justifyContent={!web ? 'space-between' : 'center'}
        mx={ios ? 5 : 3}
        space={2}
      >
        <HStack
          alignItems="center"
          flex={1}
          justifyContent="space-around"
          mx={2}
          space={2}
          w={!web ? '100%' : '50%'}
        >
          <IconTextButton
            bg={colors.terappiaOrange.light}
            flex={1}
            icon={
              <Ionicons
                color="white"
                name="people-outline"
                size={!web ? 60 : 100}
              />
            }
            label="Pacientes"
            labelColor="white"
            minH={!web ? '60%' : '80%'}
            onPress={() => handleScreenChange('therapistPatients')}
          />
          <IconTextButton
            bg={colors.terappiaGreen.light}
            flex={1}
            icon={
              <MaterialIcons
                color="white"
                name="event-seat"
                size={!web ? 60 : 90}
              />
            }
            label="Eventos"
            labelColor="white"
            minH={!web ? '60%' : '80%'}
            onPress={() => handleScreenChange('events')}
          />
        </HStack>
        <HStack
          alignItems="center"
          flex={1}
          justifyContent="space-around"
          mx={2}
          space={2}
          w={!web ? '100%' : '50%'}
        >
          <IconTextButton
            bg={colors.terappiaPurple.light}
            flex={1}
            icon={
              <Octicons color="white" name="checklist" size={!web ? 60 : 90} />
            }
            label="Asistencia"
            labelColor="white"
            minH={!web ? '60%' : '80%'}
            onPress={() => handleScreenChange('eventsRegistries')}
            pt={4}
          />
          <IconTextButton
            bg={colors.terappiaRed.light}
            flex={1}
            icon={
              <MaterialCommunityIcons
                color="white"
                name="typewriter"
                size={!web ? 60 : 90}
              />
            }
            label="Blog"
            labelColor="white"
            minH={!web ? '60%' : '80%'}
            onPress={() => handleScreenChange('blog')}
          />
          <IconTextButton
            bg={colors.terappiaYellow.dark}
            flex={1}
            icon={
              <MaterialIcons
                color="white"
                name="attach-money"
                size={!web ? 60 : 90}
              />
            }
            label="Financiero"
            labelColor="white"
            minH={!web ? '60%' : '80%'}
            onPress={() => handleScreenChange('therapistFinancial')}
          />
        </HStack>
      </VStack>
    </UIWrapper>
  );
};
