import { Ionicons } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import {
  AcceptDeclineModal,
  ExtendedFlatlist,
  LoadingStatusModal,
  UIWrapper,
} from 'components/elements';
import { checkIfUserAlreadySignedUpToEvent } from 'helpers/event-helpers/registries-helpers';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { Button, Text, VStack } from 'native-base';
import { useCallback, useState } from 'react';
import { Linking } from 'react-native';
import { useSelector } from 'react-redux';
import { getUser } from 'redux-service/slices';
import { eventsResources } from 'services/resources/events';
import { IEvent, IEventPayload } from 'services/resources/events/types.d';
import { eventsRegistriesResources } from 'services/resources/events-registries';
import {
  IEventRegistry,
  IEventRegistryPayload,
} from 'services/resources/events-registries/types.d';
import { logsResources } from 'services/resources/logs';
import { ILogEntry } from 'services/resources/logs/types.d';
import { storageResources } from 'services/resources/storage';
import {
  handleUploadEventCover,
  handleUploadEventMedia,
  handleUploadQRFile,
  handleUploadTempImage,
} from 'services/resources-recipes/storage-recipes';
import { colors } from 'styles/colors';
import { ILoadingData, IOperationError } from 'types.d';

import { EventCard } from './components/EventCard';
import { EventItem } from './components/EventItem';
import { EventModal } from './components/EventModal';
import { SelectedEventModal } from './components/SelectedEventModal';

export const Events: React.FC = (): JSX.Element => {
  const userData = useSelector(getUser);
  const { web } = usePlatform();

  const [selectedEventModalVisible, setSelectedEventModalVisible] =
    useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<IEvent>({} as IEvent);
  const [disclaimerModalOpen, setDisclaimerModalOpen] =
    useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [loadingColors, setLoadingColors] = useState<ILoadingData>({
    loading: false,
    loadingMessage: '',
  });
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [error, setError] = useState<IOperationError>({
    code: '',
    detected: false,
    errorMessage: '',
  });
  const [eventsEntries, setEventsEntries] = useState<IEvent[]>([]);
  const [eventModalVisible, setEventModalVisible] = useState<boolean>(false);
  const [coverColors, setCoverColors] = useState<string[]>([]);
  const [selectedColor, setSelectedColor] = useState<string>('');

  const onCloseEventModal = (): void => {
    setSelectedEvent({} as IEvent);
    setEventModalVisible(false);
  };

  const onCloseSelectedEventModal = (): void => {
    setSelectedEvent({} as IEvent);
    setSelectedEventModalVisible(false);
  };

  const toggleEventModalVisible = (): void => {
    setEventModalVisible(!eventModalVisible);
  };

  const toggleSelectedEventModalVisible = (): void => {
    setSelectedEventModalVisible(!selectedEventModalVisible);
  };

  const toggleDisclaimerModalOpen = (): void => {
    setDisclaimerModalOpen(!disclaimerModalOpen);
  };

  const verifiedTherapist =
    !fp.isNil(userData.verifiedTherapist) && userData.verifiedTherapist;

  /**
   * Function for retrieving all the events that are currently stored in the
   * database.
   */
  const retrieveEvents = async (): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Cargando Eventos...',
    });
    try {
      const { data: d } = await eventsResources.get(userData.token);
      const data = d as IEvent[];
      if (!fp.isEmpty(data)) {
        setEventsEntries(data);
      } else {
        setEventsEntries([]);
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Events-retrieveEvents',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that writes the event to the database, doing the required special
   * procedures firstly.
   * @param event
   */
  const handleOnCreateEvent = async (event: IEventPayload): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Creando Evento...',
    });
    try {
      // We firstly store the cover file in Firebase storage
      const coverURL = await handleUploadEventCover(event.title, event.cover);
      // We store (if needed) the media files in Firebase storage
      const mediaURLS = !fp.isNil(event.media)
        ? await handleUploadEventMedia(event.title, event.media)
        : [];
      // We create a new payload with the output
      const outputPayload: IEventPayload = {
        ...event,
        cover: coverURL,
        media: mediaURLS,
      };
      await eventsResources.create(outputPayload, userData.token);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Events-handleOnCreateEvent',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    retrieveEvents();
  };

  /**
   * Function that overwrites the event in the database, doing the special
   * procedures firstly, if required.
   * @param event
   */
  const handleOnEditEvent = async (event: IEventPayload): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Modificando Evento...',
    });
    try {
      // We firstly store the cover file in Firebase storage
      const coverURL =
        selectedEvent.cover !== event.cover
          ? await handleUploadEventCover(event.title, event.cover)
          : event.cover;
      // We store (if needed) the media files in Firebase storage
      const mediaURLS =
        !fp.isNil(event.media) && selectedEvent.media !== event.media
          ? await handleUploadEventMedia(event.title, event.media)
          : event.media;
      // We create a new payload with the output
      const outputPayload: IEventPayload = {
        ...event,
        cover: coverURL,
        media: mediaURLS,
      };
      await eventsResources.putAll(
        outputPayload,
        selectedEvent.id,
        userData.token,
      );
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Events-handleOnEditEvent',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    setSelectedEvent({} as IEvent);
    retrieveEvents();
  };

  /**
   * Function for deleting a given event.
   */
  const handleOnDeleteEvent = async (): Promise<void> => {
    toggleDisclaimerModalOpen();
    setLoadingData({
      loading: true,
      loadingMessage: 'Eliminando Evento...',
    });
    try {
      await eventsResources.delete(selectedEvent.id, userData.token);
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Events-handleOnDeleteEvent',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
    setSelectedEvent({} as IEvent);
    retrieveEvents();
  };

  /**
   * Function that will handle the user sign-up to the selected event.
   * @param payload
   */
  const handleEventSignUp = async (
    payload: IEventRegistryPayload,
  ): Promise<void> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Guardando Registro...',
    });
    try {
      const eventRegistries = await retrieveRegistries(payload.event);
      let userHasSignedUpBefore = false;
      if (!fp.isNil(eventRegistries)) {
        userHasSignedUpBefore = checkIfUserAlreadySignedUpToEvent(
          eventRegistries,
          userData.email,
        );
      }
      if (!userHasSignedUpBefore) {
        await eventsRegistriesResources.create(payload, userData.token);
        setSuccessMessage('Registro realizado exitosamente.');
      } else {
        setError({
          detected: true,
          errorMessage:
            'No puedes suscribirte a un evento al que ya estás suscrito.',
        });
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Events-handleEventSignUp',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that will retrieve existing events registries for a given event.
   */
  const retrieveRegistries = async (
    event: string,
  ): Promise<IEventRegistry[] | undefined> => {
    setLoadingData({
      loading: true,
      loadingMessage: 'Consultando registros existentes...',
    });
    try {
      const { data } = await eventsRegistriesResources.getByEventId(
        event,
        userData.token,
      );
      const d = data as IEventRegistry[];
      return d;
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'EventsRegistries-retrieveSelectedEvent',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
    setLoadingData({ ...loadingData, loading: false });
  };

  /**
   * Function that saves the generated QR and gets a download reference.
   * Based on the current platform it generates a base64 data_url from the
   * svg component or uses Viewshot capabilities for generating an image
   * file.
   */
  const saveQR = async (event: string, snapshotURI: string): Promise<void> => {
    try {
      if (!fp.isEmpty(snapshotURI) && !web) {
        const downloadURL = await handleUploadQRFile(snapshotURI, event);
        const supported = await Linking.canOpenURL(downloadURL);
        if (supported) {
          await Linking.openURL(downloadURL);
        }
      } else {
        // Algorithm retrieved from https://ourcodeworld.com/articles/read/1072/how-to-convert-a-html-svg-node-to-base64-with-javascript-in-the-browser
        const qrContainer = document.getElementById('et-qr-container');
        const qrSVG = qrContainer?.childNodes[0];
        const serializedSVG = new XMLSerializer().serializeToString(
          qrSVG as Element,
        );
        const base64Data = window.btoa(serializedSVG);
        const qrOutput = `data:image/svg+xml;base64,${base64Data}`;
        const downloadURL = await handleUploadQRFile(qrOutput, event);
        window.open(downloadURL, '_blank', 'noopener,noreferrer');
      }
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'Events-saveQR',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
  };

  /**
   * Function that handles the temp file deletion for the images used in color
   * palette extraction.
   */
  const handleTempFilesDeletion = async (): Promise<void> => {
    try {
      // We firstly get all those files under 'temp' folder
      const { items } = await storageResources.listTempFiles(userData.email);
      // We get their full paths, since we need them for the file deletion
      const tempItemsPaths = items.map((item) => {
        return item.fullPath;
      });
      // Delete each file that matches the path
      tempItemsPaths.forEach((path) => {
        storageResources.deleteFileFromPath(path);
      });
    } catch (e) {
      const newLog: ILogEntry = {
        date: new Date(),
        message: JSON.stringify(e),
        service: 'EventForm-handleTempFilesDeletion',
        user: userData.email,
      };
      logsResources.create(newLog, userData.token);
    }
  };

  /**
   * Function meant to retrieve the color palette from an image. This process
   * is done in the backend, due to some limitations regarding React Native
   * that would imply that we had to eject our project from Expo which by the
   * time is something we don't want to do.
   * @param imageURI
   */
  const handleStoreColors = async (imageURI: string): Promise<void> => {
    // We only move on if er successfully retrieved the file from the device
    if (!fp.isEmpty(imageURI)) {
      // Let the user know we are performing a process
      setLoadingColors({
        loading: true,
        loadingMessage: 'Obteniendo Colores...',
      });
      try {
        // Upload the image to our storage service, in the user's temp folder
        const imageURL = await handleUploadTempImage(userData.email, imageURI);
        // Send the uploaded file to our backend for its processing
        const { data } = await eventsResources.getPalette(
          userData.token,
          imageURL,
        );
        // If we got the color palette store it in the state, as well as the
        // first color from the palette as the selection
        if (!fp.isEmpty(data) && !fp.isNil(data)) {
          setCoverColors(data);
          setSelectedColor(data[0]);
        }
        // When we got the palette, we can delete the temp files from storage
        handleTempFilesDeletion();
      } catch (e) {
        const newLog: ILogEntry = {
          date: new Date(),
          message: JSON.stringify(e),
          service: 'EventForm-handleStoreColors',
          user: userData.email,
        };
        logsResources.create(newLog, userData.token);
      }
      setLoadingColors({ ...loadingData, loading: false });
    }
  };

  /**
   * Function that resets the possible StatusSheet content after such component
   * is scrolled down, allowing to get it again if needed.
   */
  const handleDiscard = (): void => {
    setSuccessMessage('');
    setError({
      code: '',
      detected: false,
      errorMessage: '',
    });
  };

  useFocusEffect(
    useCallback(() => {
      retrieveEvents();
    }, []),
  );

  return (
    <UIWrapper
      error={error.detected}
      onDiscard={() => handleDiscard()}
      operationStatus={error.detected ? error.errorMessage : successMessage}
      title="Eventos"
    >
      <LoadingStatusModal loading={loadingData.loading}>
        {loadingData.loadingMessage}
      </LoadingStatusModal>
      {/* Event Modal for creation (non-depending on selected event) */}
      <EventModal
        colorsLoading={loadingColors}
        coverColors={coverColors}
        handleStoreColors={handleStoreColors}
        handleTempFilesDeletion={handleTempFilesDeletion}
        isEditing={false}
        isOpen={eventModalVisible}
        onCloseModal={onCloseEventModal}
        onSubmit={(payload) => {
          handleOnCreateEvent(payload);
          onCloseEventModal();
          onCloseSelectedEventModal();
        }}
        previousValues={selectedEvent}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
      />
      {!fp.isEmpty(selectedEvent) ? (
        <SelectedEventModal
          eventData={selectedEvent}
          isOpen={selectedEventModalVisible}
          onCloseModal={onCloseSelectedEventModal}
          saveQR={saveQR}
          toggleDisclaimerModalOpen={toggleDisclaimerModalOpen}
          toggleEventModalVisible={toggleEventModalVisible}
        >
          {/* Event Modal for edition (dependant from selected event) */}
          <EventModal
            colorsLoading={loadingColors}
            coverColors={coverColors}
            handleStoreColors={handleStoreColors}
            handleTempFilesDeletion={handleTempFilesDeletion}
            isEditing={true}
            isOpen={eventModalVisible}
            onCloseModal={onCloseEventModal}
            onSubmit={(payload) => {
              handleOnEditEvent(payload);
              onCloseEventModal();
              onCloseSelectedEventModal();
            }}
            previousValues={selectedEvent}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
          <AcceptDeclineModal
            invertColors
            isOpen={disclaimerModalOpen}
            onAccept={handleOnDeleteEvent}
            onDecline={toggleDisclaimerModalOpen}
          >
            <Text fontWeight="bold">
              {`¿Estás seguro de querer eliminar el evento ${selectedEvent.title}? Esta acción no se puede deshacer.`}
            </Text>
          </AcceptDeclineModal>
        </SelectedEventModal>
      ) : null}
      <VStack bg="white" flex={17}>
        <ExtendedFlatlist
          data={eventsEntries}
          noDataMessage="No se encontraron eventos."
          renderItem={
            userData.accountType === 'patient' ||
            userData.accountType === 'regular' ? (
              <EventCard onSubmit={handleEventSignUp} />
            ) : (
              <EventItem
                onSelectEvent={(event) => {
                  toggleSelectedEventModalVisible();
                  setCoverColors(event.colorPalette as string[]);
                  setSelectedColor(event.accentColor);
                  setSelectedEvent(event);
                }}
              />
            )
          }
          searchBarPlaceholder="evento"
          searchKey="title"
          sort
          sortDatesInverted
          sortKey="date"
          useSearchBar
        />
      </VStack>
      {userData.accountType === 'therapist' && verifiedTherapist ? (
        <Button
          _hover={{ bg: colors.success, opacity: 0.8 }}
          _pressed={{ bg: colors.success, opacity: 0.8 }}
          _text={{ fontWeight: 'bold' }}
          bg={colors.success}
          borderRadius={0}
          flex={1}
          leftIcon={<Ionicons color="white" name="add" size={24} />}
          onPress={() => toggleEventModalVisible()}
          w="100%"
        >
          Añadir nuevo evento
        </Button>
      ) : null}
    </UIWrapper>
  );
};
