import FormData from 'form-data';
import { instance } from 'services';

import { IReportResponse } from './types.d';

export const reportsGenerationResources = {
  createMobile: (
    formData: FormData,
    period: string | undefined = '',
    reportType: string,
    token: string,
  ): Promise<IReportResponse> =>
    instance.post(
      '/reportsGeneratorAPI/mobile',
      { formData },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: token,
        },
        params: { period, reportType },
      },
    ),

  createWeb: (
    formData: FormData,
    period: string | undefined = '',
    reportType: string,
    token: string,
  ): Promise<IReportResponse> =>
    instance.post('/reportsGeneratorAPI', formData, {
      headers: {
        'Content-Type': 'application/json',
        authorization: token,
      },
      params: { period, reportType },
    }),
};
