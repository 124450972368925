import { useSmallScreen } from 'hooks/dimensions-hooks';
import { usePlatform } from 'hooks/platform-hooks';
import fp from 'lodash/fp';
import { FormControl, Input, WarningOutlineIcon } from 'native-base';
import React, { useState } from 'react';
import { fontSizes, inputSizes } from 'styles/theme';

import { IUncontrolledInputProps } from '../types.d';

export const UncontrolledInputField: React.FC<IUncontrolledInputProps> = (
  props,
): JSX.Element => {
  const {
    innerRef,
    label = '',
    error = '',
    secureText = false,
    ...rest
  } = props;

  const small = useSmallScreen();
  const { web } = usePlatform();

  const [errorHeight, setErrorHeight] = useState<number>(5);

  return (
    <FormControl
      h={
        !small
          ? `${inputSizes.regular + errorHeight}px`
          : `${inputSizes.small + errorHeight}px`
      }
      isInvalid={!fp.isEmpty(error)}
      mb={4}
      w="100%"
    >
      <Input
        h={!small ? undefined : `${inputSizes.small}px`}
        placeholder={label}
        py={!web ? undefined : 3}
        ref={innerRef}
        style={{ fontSize: !small ? fontSizes.regular : fontSizes.smaller }}
        type={secureText ? 'password' : 'text'}
        {...rest}
      />
      <FormControl.ErrorMessage
        alignSelf="center"
        leftIcon={<WarningOutlineIcon size="xs" />}
        mb={2}
        mt={1}
        onLayout={({
          nativeEvent: {
            layout: { height },
          },
        }) => setErrorHeight(height)}
      >
        {fp.isEmpty(error) ? '' : error}
      </FormControl.ErrorMessage>
    </FormControl>
  );
};
