import { AcceptDecline } from 'components/elements';
import { DashboardInputField, LinksField } from 'components/inputs';
import { Formik } from 'formik';
import { getLinksArrayFromText } from 'helpers/data-helpers/string-helpers';
import fp from 'lodash/fp';
import { Text, VStack } from 'native-base';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import {
  IGlossaryEntry,
  IGlossaryPayload,
} from 'services/resources/glossary/types.d';
import { fontSizes } from 'styles/theme';
import { INITIAL_VALUES } from './helpers/form-helpers';

interface IGlossaryTermFormProps {
  /**
   * Function for handling the form submission.
   */
  onSubmit: (payload: IGlossaryPayload | IGlossaryEntry) => Promise<void>;
  /**
   * Function for handling the cancel action.
   */
  onCancel: () => void;
  /**
   * Values that are already set, used for editing them.
   */
  previousValues?: IGlossaryEntry;
}

export const GlossaryTermForm: React.FC<IGlossaryTermFormProps> = (props) => {
  const { onSubmit, onCancel, previousValues = undefined } = props;

  const themeData = useSelector(getTheme);

  const stringifiedLinks =
    !fp.isNil(previousValues) &&
    !fp.isEmpty(previousValues.links) &&
    fp.isArray(previousValues.links)
      ? previousValues.links.join(', ')
      : '';

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={onSubmit}>
      {({ handleChange, handleSubmit, values, setFieldValue }) => (
        <VStack
          alignItems="center"
          justifyContent="center"
          onLayout={() => {
            if (!fp.isNil(previousValues)) {
              setFieldValue('term', previousValues.term);
              setFieldValue('description', previousValues.description);
              setFieldValue('links', previousValues.links);
              setFieldValue('id', previousValues.id);
            }
          }}
          w="100%"
        >
          <Text
            color={themeData.mainColorDark}
            fontSize={fontSizes.regular}
            fontWeight="bold"
            py={4}
            textAlign="center"
          >
            {!fp.isNil(previousValues) ? 'Actualizar Término' : 'Nuevo Término'}
          </Text>
          <Text color={themeData.mainColorDark} py={2} textAlign="center">
            Título
          </Text>
          <DashboardInputField
            defaultValue={
              !fp.isNil(previousValues) ? previousValues.term : values.term
            }
            onChangeText={handleChange('term')}
          />
          <Text color={themeData.mainColorDark} py={2} textAlign="center">
            Descripción
          </Text>
          <DashboardInputField
            defaultValue={
              !fp.isNil(previousValues)
                ? previousValues.description
                : values.description
            }
            onChangeText={handleChange('description')}
          />
          <Text color={themeData.mainColorDark} py={2} textAlign="center">
            Links
          </Text>
          <LinksField
            defaultValue={
              !fp.isNil(previousValues) ? stringifiedLinks : values.links
            }
            onChangeText={(text) => {
              setFieldValue('links', getLinksArrayFromText(text));
            }}
            title="Añade los links, separándolos por comas"
          />
          <AcceptDecline
            customAcceptMessage={
              fp.isNil(previousValues) ? 'Añadir' : 'Actualizar'
            }
            disabled={values.term === '' || values.description === ''}
            mt={6}
            onAccept={handleSubmit}
            onDecline={onCancel}
            py={4}
          />
        </VStack>
      )}
    </Formik>
  );
};
