import { usePlatform } from 'hooks/platform-hooks';
import { HStack, IStackProps, ScrollView, VStack } from 'native-base';
import { IEventWithRegistries } from 'services/resources/events-registries/types';
import { colors } from 'styles/colors';

import {
  getCompletedEventsAmount,
  getETProfitsAmount,
  getTotalAssistanceAmount,
  getTotalRegistriesAmount,
  getUpcomingEventsAmount,
} from '../helpers/filter-helpers';
import { StatisticsItemAmount } from './StatisticsAmountItem';

interface IStatisticsProps extends IStackProps {
  /**
   * All retrieved and filtered data to be used for calculating stats.
   */
  data: IEventWithRegistries[];
}

export const Statistics: React.FC<IStatisticsProps> = (props): JSX.Element => {
  const { data, ...rest } = props;
  const { web } = usePlatform();

  const listData = [
    {
      amount: getCompletedEventsAmount(data),
      label: 'Eventos Completados: ',
      value: 'completed',
    },
    {
      amount: getUpcomingEventsAmount(data),
      label: 'Próximos Eventos: ',
      value: 'upcoming',
    },
    {
      amount: getTotalRegistriesAmount(data),
      label: 'Personas Registradas: ',
      value: 'registries',
    },
    {
      amount: getTotalAssistanceAmount(data),
      label: 'Personas que Asistieron: ',
      value: 'confirmedAssistance',
    },
    {
      amount: getETProfitsAmount(data),
      label: 'Ganancias de ET: ',
      value: 'ETProfitsAmount',
    },
  ];

  /**
   * Function that for a given value, returns the color that has to be displayed
   * accordingly.
   * @param value
   * @returns
   */
  const getDisplayColor = (value: string): string | undefined => {
    if (value === 'completed') return colors.success;
    if (value === 'upcoming') return colors.warning;
    if (value === 'ETProfitsAmount') return colors.success;
    return undefined;
  };

  return (
    <HStack w="100%" {...rest}>
      {web ? (
        <VStack alignItems="center">
          <HStack>
            <StatisticsItemAmount
              amount={listData[0].amount}
              amountColor={getDisplayColor(listData[0].value)}
              label={listData[0].label}
            />
            <StatisticsItemAmount
              amount={listData[1].amount}
              amountColor={getDisplayColor(listData[1].value)}
              label={listData[1].label}
            />
          </HStack>
          <HStack>
            <StatisticsItemAmount
              amount={listData[2].amount}
              amountColor={getDisplayColor(listData[2].value)}
              label={listData[2].label}
            />
            <StatisticsItemAmount
              amount={listData[3].amount}
              amountColor={getDisplayColor(listData[3].value)}
              label={listData[3].label}
            />
          </HStack>
          <StatisticsItemAmount
            amount={listData[4].amount}
            amountColor={getDisplayColor(listData[4].value)}
            label={listData[4].label}
          />
        </VStack>
      ) : (
        <ScrollView width="100%">
          <VStack w="100%">
            <StatisticsItemAmount
              amount={listData[0].amount}
              amountColor={getDisplayColor(listData[0].value)}
              label={listData[0].label}
            />
            <StatisticsItemAmount
              amount={listData[1].amount}
              amountColor={getDisplayColor(listData[1].value)}
              label={listData[1].label}
            />
            <StatisticsItemAmount
              amount={listData[2].amount}
              amountColor={getDisplayColor(listData[2].value)}
              label={listData[2].label}
            />
            <StatisticsItemAmount
              amount={listData[3].amount}
              amountColor={getDisplayColor(listData[3].value)}
              label={listData[3].label}
            />
            <StatisticsItemAmount
              amount={listData[4].amount}
              amountColor={getDisplayColor(listData[4].value)}
              label={listData[4].label}
            />
          </VStack>
        </ScrollView>
      )}
    </HStack>
  );
};

Statistics.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
  space: 3,
};
