import { useSelector } from 'react-redux';
import { getTheme } from 'redux-service/slices';
import { fontSizes } from 'styles/theme';
import { InputField } from '../InputField';
import { IInputFieldProps } from '../types.d';

export const FormattedInputField: React.FC<IInputFieldProps> = (props) => {
  const { ...rest } = props;
  const themeData = useSelector(getTheme);
  return (
    <InputField
      color={themeData.mainColorDark}
      fontSize={fontSizes.regular}
      placeholderTextColor={themeData.mainColorDark}
      {...rest}
    />
  );
};

FormattedInputField.defaultProps = {
  _focus: { bg: 'white' },
  bg: 'white',
  borderColor: 'transparent',
  borderRadius: 15,
  focusOutlineColor: 'transparent',
  mb: 8,
  textAlign: 'center',
};
