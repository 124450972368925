export const colors = {
  disabled: '#3b3a3a',
  error: '#ed2311',
  excelGreen: '#185c37',
  success: '#17bf17',
  terappiaBlue: {
    dark: '#003766',
    light: '#E0F9FF',
  },
  terappiaGreen: {
    dark: '#527a00',
    light: '#7CBA00',
  },
  terappiaOrange: {
    dark: '#ba6b0d',
    light: '#EA830A',
  },
  terappiaPurple: {
    dark: '#7a51b0',
    light: '#AE74F9',
  },
  terappiaRed: {
    light: '#f73b3b',
  },
  terappiaYellow: {
    dark: '#e0da12',
    light: '#f5fc28',
  },
  warning: '#ddae02',
};
