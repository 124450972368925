import { EditionButtons, LinkParser } from 'components/elements';
import { IUserItemProps } from 'components/elements/types.d';
import { Checkbox, Divider, Flex, HStack, Text, VStack } from 'native-base';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTheme, getUser } from 'redux-service/slices';
import { ITask } from 'services/resources/tasks/types.d';

interface ITaskPatientItemProps extends IUserItemProps {
  /**
   * Item to be inherited from the cloneElement function.
   */
  item?: any;
  /**
   * Function for handling an item deletion on database.
   */
  onDelete: (task: ITask) => void;
  /**
   * Function for handling an item edition on database.
   */
  onEdit: (task: ITask) => void;
  /**
   * Function for marking a task as completed when user is patient.
   */
  onComplete: (task: ITask) => void;
}

export const TaskItem: React.FC<ITaskPatientItemProps> = (
  props,
): JSX.Element => {
  const { onComplete, onDelete, onEdit, children, item, ...rest } = props;
  const userData = useSelector(getUser);
  const themeData = useSelector(getTheme);

  const task = item as ITask;

  return (
    <VStack {...rest}>
      <Flex alignItems="center" px={3} py={3} w="100%">
        <LinkParser strikeThrough={task.finished}>
          {task.description}
        </LinkParser>
        <HStack mt={2}>
          {userData.accountType === 'therapist' ? (
            <>
              <Text fontWeight="bold">Estatus: </Text>
              <Text>{task.finished ? 'completada' : 'sin completar'}</Text>
            </>
          ) : !task.finished ? (
            <Checkbox
              _checked={{ bg: themeData.mainColorDark }}
              _text={{ color: themeData.mainColorDark, fontSize: 14 }}
              isDisabled={task.finished}
              my={2}
              onChange={() => onComplete(task)}
              value="completed"
            >
              Marcar tarea como completada
            </Checkbox>
          ) : null}
        </HStack>
        {userData.accountType === 'therapist' && !task.finished ? (
          <EditionButtons
            deleteDisabled={task.finished}
            editDisabled={task.finished}
            onDelete={() => onDelete(task)}
            onEdit={() => onEdit(task)}
            py={3}
          />
        ) : null}
      </Flex>
      <Divider />
    </VStack>
  );
};

TaskItem.defaultProps = {
  flex: 1,
  w: '100%',
};
